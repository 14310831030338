import React from 'react';

import { Form } from 'antd';

import { Button } from 'components/Button';
import { defaultFormTailLayout } from 'components/FormItems';

import { UNITS } from 'constants/styles';

import { LineItem } from './LineItem';

export const LineItems = ({
  locationId,
  vendorId,
  setCurrentStep,
  lineItems,
  lineItemsFormValues,
  deleteLineItem,
  addLineItem,
  setLineItems,
}: {
  locationId: FIXME;
  vendorId: FIXME;
  setCurrentStep: FIXME;
  lineItems: FIXME;
  lineItemsFormValues: FIXME;
  deleteLineItem: FIXME;
  addLineItem: FIXME;
  setLineItems: FIXME;
}) => {
  const [form] = Form.useForm();

  return (
    <Form name="lineItemsForm" form={form} onFinishFailed={console.error} initialValues={lineItemsFormValues}>
      {lineItems.map((lineItem: FIXME, index: FIXME) => (
        <LineItem
          locationId={locationId}
          onDelete={index !== 0 ? () => deleteLineItem(index) : null}
          key={index}
          index={index}
          vendorId={vendorId}
          lineItems={lineItems}
          lineItem={lineItem}
          form={form}
          setLineItems={setLineItems}
        />
      ))}
      <Form.Item {...defaultFormTailLayout}>
        <Button onClick={addLineItem}>Add Line Item</Button>
      </Form.Item>
      <Form.Item {...defaultFormTailLayout}>
        <Button
          onClick={() => {
            setCurrentStep(0);
          }}
          style={{ marginRight: UNITS.LG }}
        >
          Previous
        </Button>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};
