import React from 'react';

import { TenantDefaultData } from '@recurrency/core-api';
import { Form, Space, FormInstance } from 'antd';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useCarriersSelectProps,
  useCompaniesSelectProps,
  useFreightsSelectProps,
  useLocationsSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button/Button';
import { defaultFormTailLayout, responsiveFormLayout } from 'components/FormItems';
import { Typography } from 'components/Typography';

import { joinIfIdNameObj, splitIfIdNameStr } from 'utils/formatting';

interface DefaultSettingsFieldsValue {
  quoteCompany: string;
  quoteFreightType: string;
  quoteCarrier: string;
  quoteLocation: string;
  prospectCompany: string;
}

function defaultDataFromForm(form: FormInstance<DefaultSettingsFieldsValue>): TenantDefaultData {
  const fieldsValue = form.getFieldsValue();
  return {
    quote: {
      company: splitIfIdNameStr(fieldsValue.quoteCompany),
      freightType: splitIfIdNameStr(fieldsValue.quoteFreightType),
      carrier: splitIfIdNameStr(fieldsValue.quoteCarrier),
      location: splitIfIdNameStr(fieldsValue.quoteLocation),
    },
    prospect: {
      company: splitIfIdNameStr(fieldsValue.prospectCompany),
    },
  };
}

export function FormDefaultSettings({
  defaultData,
  onDefaultDataChange,
}: {
  defaultData: TenantDefaultData;
  onDefaultDataChange: (newDefaultData: TenantDefaultData) => void;
}) {
  const locationsSelectProps = useLocationsSelectProps();
  const carriersSelectProps = useCarriersSelectProps();
  const freightsSelectProps = useFreightsSelectProps({ companyId: undefined });
  const companiesSelectProps = useCompaniesSelectProps();
  const [form] = Form.useForm<DefaultSettingsFieldsValue>();
  const handleSetQuoteDefaultDataButtonClick = () => {
    onDefaultDataChange(defaultDataFromForm(form));
  };

  return (
    <Form
      form={form}
      onFinishFailed={console.error}
      initialValues={{
        quoteCompany: joinIfIdNameObj(defaultData.quote?.company),
        quoteFreightType: joinIfIdNameObj(defaultData.quote?.freightType),
        quoteCarrier: joinIfIdNameObj(defaultData.quote?.carrier),
        quoteLocation: joinIfIdNameObj(defaultData.quote?.location),
        prospectCompany: joinIfIdNameObj(defaultData.prospect?.company),
      }}
      {...responsiveFormLayout}
    >
      <Typography>Quotes</Typography>
      <Form.Item name="quoteCompany" label="Company">
        <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" allowClear />
      </Form.Item>
      <Form.Item name="quoteFreightType" label="Freight Type">
        <AsyncSelect selectProps={freightsSelectProps} entityPlural="freight codes" allowClear />
      </Form.Item>
      <Form.Item name="quoteCarrier" label="Carrier">
        <AsyncSelect selectProps={carriersSelectProps} entityPlural="carriers" allowClear />
      </Form.Item>
      <Form.Item name="quoteLocation" label="Location">
        <AsyncSelect selectProps={locationsSelectProps} entityPlural="locations" allowClear />
      </Form.Item>
      <Typography>Prospects</Typography>

      <Form.Item name="prospectCompany" label="Company">
        <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" allowClear />
      </Form.Item>
      <Form.Item {...defaultFormTailLayout}>
        <Space>
          <Button type="primary" onClick={handleSetQuoteDefaultDataButtonClick}>
            Update Quote Defaults
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
