import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { formatDate, formatNumber } from 'utils/formatting';
import { encodeLegacyApiParam, routes } from 'utils/routes';
import { typedColumn } from 'utils/tables';
import { qtyUnitConverter } from 'utils/units';

import { TransferLine } from 'types/legacy-api';

export const OrderTransferLinesTable = ({ orderNo }: { orderNo: string | undefined }) => {
  const columns: ColumnType<TransferLine>[] = [
    typedColumn({
      title: 'Transfer No',
      dataIndex: 'transferNo',
    }),
    typedColumn({
      title: 'Transfer Date',
      dataIndex: 'transferHdr',
      render: (transferHdr) => formatDate(transferHdr.transferDate),
    }),
    typedColumn({
      title: 'From Location',
      dataIndex: 'transferHdr',
      render: (transferHdr) => `${transferHdr.fromLocationId}: ${transferHdr.fromLocation.locationName}`,
    }),
    typedColumn({
      title: 'To Location',
      dataIndex: 'transferHdr',
      render: (transferHdr) => `${transferHdr.toLocationId}: ${transferHdr.toLocation.locationName}`,
    }),
    typedColumn({
      title: 'Item ID',
      dataIndex: 'item',
      render: (item) => <Link to={routes.sales.itemDetails(item.itemId)}>{item.itemId}</Link>,
    }),
    typedColumn({
      title: 'Item',
      dataIndex: 'item',
      render: (item) => item.itemDesc,
    }),
    typedColumn({
      title: 'Qty to Transfer',
      dataIndex: 'qtyToTransfer',
      render: (qtyToTransfer, record) => formatNumber(qtyUnitConverter(qtyToTransfer, record.unitSize)),
    }),
    typedColumn({
      title: 'Qty Transferred',
      dataIndex: 'qtyTransferred',
      render: (qtyTransferred, record) => formatNumber(qtyUnitConverter(qtyTransferred, record.unitSize)),
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    typedColumn({
      title: 'Carrier',
      dataIndex: 'transferHdr',
      render: (transferHdr) => (transferHdr.carrierId ? `${transferHdr.carrierId}: ${transferHdr.carrier.name}` : '-'),
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/orders/${encodeLegacyApiParam(orderNo || '')}/transfer-lines`,
      })}
      columns={columns}
    />
  );
};
