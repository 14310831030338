import React from 'react';

import styled from 'styled-components';

import { PerformanceChart } from 'components/Charts/PerformanceChart';
import { CenteredLoader } from 'components/Loaders';
import { NoDataMessage } from 'components/NoDataMessage';
import { StatsBar } from 'components/StatsBar';
import { Tooltip } from 'components/Tooltip';

import { getElapsedMonths, getLastTwoYears } from 'utils/date';

import { DashboardData } from './types';

type PerfChartEntry = Obj<string | number>;

function transformData(data: DashboardData, key: 'lineGm' | 'lineSales', lastTwoYears: string[]): PerfChartEntry[] {
  const results: PerfChartEntry[] = [];

  getElapsedMonths().forEach((month) => {
    const entry: PerfChartEntry = { date: `${month}` };
    lastTwoYears.forEach((year) => {
      const dataVal = data[parseInt(year, 10)]?.[month.toLowerCase()]?.[key];
      if (dataVal) {
        entry[year] = dataVal;
      } else {
        entry[year] = 0;
      }
    });
    results.push(entry);
  });

  return results;
}

const StyledChartContainer = styled.div`
  display: flex;
  margin-top: 4rem;
  justify-content: space-around;
  flex-wrap: wrap;
`;

interface PerformanceChartsProps {
  dashboardData: DashboardData;
}

export const PerformanceCharts = ({ dashboardData }: PerformanceChartsProps) => {
  const lastTwoYears = getLastTwoYears();

  if (!dashboardData) {
    return <CenteredLoader />;
  }

  if (Object.keys(dashboardData).length === 0) {
    return <NoDataMessage />;
  }

  const { stats } = dashboardData;

  const revenueData: PerfChartEntry[] = transformData(dashboardData, 'lineSales', lastTwoYears);
  const gmData: PerfChartEntry[] = transformData(dashboardData, 'lineGm', lastTwoYears);

  return (
    <>
      <StatsBar statsbarData={stats} />
      <StyledChartContainer>
        <PerformanceChart
          title={<Tooltip title="Revenue Year over Year">Revenue YoY</Tooltip>}
          years={lastTwoYears}
          data={revenueData}
        />
        <PerformanceChart
          title={<Tooltip title="Gross Margin Year over Year">GM YoY</Tooltip>}
          years={lastTwoYears}
          data={gmData}
        />
      </StyledChartContainer>
    </>
  );
};
