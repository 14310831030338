import styled from "styled-components";
import { media } from "utils/responsive";
const mdTypes = ["h1", "h2", "h3", "large", "middle", "subtitle", "small"];
const smTypes = ["h2", "h3", "large", "middle", "subtitle", "small", "extrasmall"];
const textStyles = {
  h1: `
    font-weight: 500;
    font-size: 56px;
    line-height: 65px;
  `,
  h2: `
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  `,
  h3: `
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  `,
  large: `
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  `,
  middle: `
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  `,
  subtitle: `
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  `,
  small: `
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  `,
  extrasmall: `
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
  `
};
const getStyle = (props) => {
  var _a, _b, _c;
  const { type = "middle", responsive = true } = props;
  const mdStyle = (_a = textStyles == null ? void 0 : textStyles[type]) != null ? _a : "";
  if (!responsive) {
    return mdStyle;
  }
  const typeIndex = (_b = mdTypes.findIndex((value) => value === type)) != null ? _b : 0;
  const smIndex = smTypes[typeIndex];
  const smStyle = (_c = textStyles == null ? void 0 : textStyles[smIndex]) != null ? _c : "";
  return `
  ${smStyle}
  ${media.sm`
    ${mdStyle}
  `}
  `;
};
export const StyledTypography = styled.p`
  ${getStyle};
  color: ${(props) => props.theme.colors.darkGray};
  ${(props) => (props == null ? void 0 : props.weight) ? `font-weight: ${props.weight};` : ""}
  margin-bottom: ${(props) => props.margin !== void 0 ? `${props.margin}px` : "1em"};
  ${(props) => props.align && `text-align: ${props.align};`}

  cursor: ${(props) => props.copyable ? "pointer" : "inherit"};
  &:hover {
    background: ${(props) => props.copyable ? props.theme.colors.gray300 : "transparent"};
  }
`;
