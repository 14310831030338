import { Input as AntdInput } from "antd";
import styled from "styled-components";
import { Typography as TypographyComponent } from "components/Typography";
const heights = {
  small: "30px",
  middle: "40px",
  large: "50px"
};
const getHeights = (size = "middle") => heights[size];
export const Input = styled(AntdInput)`
  border-radius: ${(props) => props.theme.border.radius};
  height: ${(props) => getHeights(props.size)};

  ${(props) => props.hasError && `border-color: ${props.theme.colors.red};`}
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Typography = styled(TypographyComponent)`
  margin-bottom: 10px;
`;
