import React from 'react';

import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Grid } from 'antd';

import { RECURRENCY_BLUE } from 'constants/styles';

import { Tooltip } from './Tooltip';

export type Props = {
  title: string;
  children?: React.ReactNode;
};

export const InfoTooltip = (props: Props) => {
  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;
  if (isDesktopView) {
    return (
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {props.children}
        <Tooltip title={props.title}>
          <QuestionCircleTwoTone
            style={{ verticalAlign: 'middle', marginLeft: '5px' }}
            twoToneColor={RECURRENCY_BLUE}
          />
        </Tooltip>
      </span>
    );
  }
  return (
    <Tooltip title={props.title}>
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {props.children}
        <QuestionCircleTwoTone style={{ verticalAlign: 'middle', marginLeft: '5px' }} twoToneColor={RECURRENCY_BLUE} />
      </span>
    </Tooltip>
  );
};
