import styled from "styled-components";
import { media } from "utils/responsive";
export const HeaderContainer = styled.div`
  width: 100%;

  ${media.sm`
    width: 100%;
  `}

  .ant-form-item {
    margin-bottom: 4px;
  }
`;
