import { IdNameObj } from './legacy-api';

export interface NavTabHashState {
  tab: string;
}

export interface PricingHashState {
  customer?: string;
  item?: string;
  itemInvMastUid?: string;
  tab?: string;
  unitOfMeasure?: string;
}

export interface PurchaseOrderNewHashState {
  vendorId?: string;
  vendorName?: string;
  locationId?: string;
  locationName?: string;
  itemId?: string;
  itemFullDesc?: string;
  qty?: number;
  uom?: string;
  price?: number;
}

export interface PurchasingForecastsHashState {
  query?: string;
  /** selected itemId */
  itemId?: string;
}

export enum QuoteEditStep {
  Header,
  LineItems,
  Review,
}

export interface QuoteLineItem extends IdNameObj {
  unitOfMeasure?: string;
  price?: number;
  quantity?: number;
  description?: string;
}

export type ForeignIdNameStr = string;
export type ISODateStr = string;

export interface QuoteEditHashState {
  company?: ForeignIdNameStr;
  customer?: ForeignIdNameStr;
  shipTo?: ForeignIdNameStr;
  contact?: ForeignIdNameStr;
  location?: ForeignIdNameStr;
  freightType?: ForeignIdNameStr;
  carrier?: ForeignIdNameStr;
  requiredDate?: ISODateStr;
  validUntilDate?: ISODateStr;
  poNo?: string;
  comments?: string;
  step?: QuoteEditStep;
  taker?: string;
  items?: QuoteLineItem[];
  jobNumber?: string;
}

export interface QuotesListHashState {
  page?: number;
}

export type SearchFrameSortByOrder = 'asc' | 'desc';
export interface SearchFrameHashState {
  /** query string from search bar */
  query?: string;
  /** current page number */
  page?: number;
  /** other filters */
  extFilter?: Obj<string[]>;
  /** value filters */
  where?: Obj<string[]>;
  /** numeric filters */
  whereNum?: Obj<{ min: number; max: number }>;
  sortBy?: { field: string; order: SearchFrameSortByOrder };
}

export interface TaskNewHashState {
  title?: string;
}

export interface ProspectHashState extends Obj {
  // Step of the flow
  step?: ProspectStep;
  // Associated company
  company?: string;
  // Customer Step
  salesRep?: string;
  customerName?: string;
  customerPhone?: string;
  customerFax?: string;
  customerEmail?: string;
  'physical-address1'?: string;
  'physical-address2'?: string;
  'physical-state'?: string;
  'physical-city'?: string;
  'physical-zipcode'?: string;
  addressesSame?: boolean;
  'mailing-address1'?: string;
  'mailing-address2'?: string;
  'mailing-state'?: string;
  'mailing-city'?: string;
  'mailing-zipcode'?: string;
  // Shipto
  makeShipTo?: boolean;
  shipToDefaultBranch?: string;
  shipToPreferredLocation?: string;
  shipToDefaultFreight?: string;
  // Contact Step
  contactFirstName?: string;
  contactLastName?: string;
  contactTitle?: string;
  contactPhone?: string;
  contactFax?: string;
  contactEmail?: string;
}

export enum ProspectStep {
  Customer,
  Contact,
  Review,
}
