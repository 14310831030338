import { Row as AntRow } from "antd";
import styled from "styled-components";
import { Stats as StatsComponent } from "components/Stats";
export const Row = styled(AntRow)`
  padding: 0px 20px;
  background: ${(props) => props.theme.colors.lightGray};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
`;
export const Stats = styled(StatsComponent)`
  min-width: 200px;
  padding: 10px 0;
  margin-right: 30px;
`;
export const FlexGrowItem = styled.div`
  flex-grow: 1;
`;
