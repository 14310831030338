import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { EditOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateIntegrationRequest, TenantFeatureFlags, UpdateFeatureFlagsRequest } from '@recurrency/core-api';
import { PageHeader, Descriptions, Modal as antdModal, message, Form, Input, Popover } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { DescriptionsContainer } from 'components/DescriptionsContainer';
import { InputFormItem, RadioFormItem, responsiveFormLayout2 } from 'components/FormItems';
import { Container } from 'components/Layout/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { UserTable } from 'components/Table/UserTable';
import { Typography } from 'components/Typography';

import { useApi } from 'hooks/useApi';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { formatDate } from 'utils/formatting';
import { routes, IdPathParams, usePathParams } from 'utils/routes';
import { booleanColumn, sortableNumberColumn, sortableStringColumn } from 'utils/tables';

import { TenantFeatureFlagsTable } from './TenantFeatureFlagsTable';
import { TenantMetadataTable } from './TenantMetadataTable';

const EMPTY_ID = '';

export const TenantDetailsPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = usePathParams<IdPathParams>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIntegrationId, setEditingIntegrationId] = useState(EMPTY_ID);

  const {
    data,
    error: tenantError,
    isLoading: tenantIsLoading,
    reload: tenantReload,
  } = useApi().tenants().getTenantById(id);

  // incorrect core-api return type
  const tenant = data as FIXME;

  const {
    data: integrationsData,
    error: integrationsError,
    isLoading: integrationsIsLoading,
    reload: integrationsReload,
  } = useApi().tenants().getIntegrations(id);
  // incorrect core-api return type
  const integrations = integrationsData as FIXME;

  const { data: usersData, error: usersError, isLoading: usersIsLoading } = useApi().tenants().getUsers(id);

  const error = integrationsError || tenantError || usersError;
  const isLoading = integrationsIsLoading || tenantIsLoading;

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !tenant) {
    return <CenteredLoader />;
  }

  const columns: ColumnType<FIXME>[] = [
    sortableNumberColumn({
      title: 'Integration ID',
      dataIndex: 'id',
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    sortableStringColumn({
      title: 'ERP Type',
      dataIndex: 'erpType',
    }),
    sortableStringColumn({
      title: 'Type',
      dataIndex: 'type',
    }),
    sortableStringColumn({
      title: 'Environment',
      dataIndex: 'environment',
    }),
    booleanColumn({
      title: 'Enabled',
      dataIndex: 'isEnabled',
    }),
    {
      align: 'right',
      render: (record: FIXME) => {
        const { isEnabled, credentials, name } = record;

        return (
          <div>
            <Button onClick={() => enableDisable(record)}>
              <EditOutlined /> {isEnabled ? 'Disable' : 'Enable'}
            </Button>
            <Popover content={credentials} title={`Credentials ARN: ${name}`} trigger="click">
              <Button>View Credentials ARN</Button>
            </Popover>
            <Button onClick={() => onEdit(record)}>
              <EditOutlined /> Edit
            </Button>
            <Button danger onClick={() => onRemove(record.id)}>
              <MinusCircleOutlined /> Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (data: CreateIntegrationRequest) => {
    data.port = parseInt(`${data.port}`, 10);
    try {
      if (!tenant.id) {
        throw new Error('No tenant ID found');
      }
      if (editingIntegrationId !== EMPTY_ID) {
        await api().tenants().updateIntegration(tenant.id, editingIntegrationId, data);
        message.success('Integration updated.');
      } else {
        await api().tenants().createIntegration(tenant.id, data);
        message.success('New integration added.');
      }
      closeModal();
      integrationsReload();
    } catch (err) {
      captureAndShowError(err, `updating integration`);
    }
  };

  const postRemove = async (integrationId: string) => {
    try {
      await api().tenants().deleteIntegration(tenant.id, integrationId);
      message.success('Deleted integration.');
      setIsModalOpen(false);
      integrationsReload();
    } catch (err) {
      captureAndShowError(err, `deleting integration`);
    }
  };

  const patchEnableDisable = async (integration: FIXME) => {
    try {
      await api().tenants().updatePartialIntegration(tenant.id, integration.id, { isEnabled: !integration.isEnabled });
      message.success('Updated integration.');
      setIsModalOpen(false);
      integrationsReload();
    } catch (err) {
      captureAndShowError(err, `updating integration`);
    }
  };

  const onRemove = (integrationId: string) => {
    antdModal.confirm({
      title: 'Are you sure you want to delete this integration?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postRemove(integrationId);
      },
      onCancel() {},
    });
  };

  const onEdit = async (record: FIXME) => {
    form.setFields(Object.entries(record).map(([key, value]) => ({ name: key, value })));
    setEditingIntegrationId(record.id);
    setIsModalOpen(true);
  };

  const enableDisable = async (record: FIXME) => {
    antdModal.confirm({
      title: `Are you sure you want to ${record.isEnabled ? 'disable' : 'enable'} this integration?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk() {
        patchEnableDisable(record);
      },
      onCancel() {},
    });
  };

  const addToTenant = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingIntegrationId(EMPTY_ID);
    form.resetFields();
  };

  const dbTypes = [
    { value: 'database', label: 'Database' },
    { value: 'api', label: 'API' },
  ];

  const erpTypes = [
    { value: 'p21', label: 'P21' },
    { value: 'sapb1', label: 'SAP Business One' },
    { value: 'msftd', label: 'Microsoft Dynamics', disabled: true },
  ];

  const environments = [
    { value: 'production', label: 'Production' },
    { value: 'staging', label: 'Staging', disabled: true },
    { value: 'demo', label: 'Demo', disabled: true },
  ];

  const onFeatureFlagsChange = async (newFeatureFlags: TenantFeatureFlags) => {
    const request: UpdateFeatureFlagsRequest = { featureFlags: newFeatureFlags };
    try {
      // validate SSO option
      if (request.featureFlags.sso_enabled && tenant?.metadata?.ssoConnectionName === undefined) {
        message.error(`Please set SSO Connection Name before enabling SSO`, 5);
        return;
      }
      await api().tenants().updateFeatureFlags(tenant.id, request);
      message.success('Feature flags updated successfully, refresh page to see them in action.');
      tenantReload();
    } catch (err) {
      captureAndShowError(err, `updating feature flags`);
    }
  };

  return (
    <Container>
      <PageHeader
        title={`#${tenant.id}: ${tenant.name}`}
        subTitle={tenant.companyName}
        onBack={() => history.push(routes.internal.tenantList())}
        extra={[
          <Button id="tenantdetails_edit" key="1" onClick={() => history.push(routes.internal.tenantEdit(tenant.id))}>
            Edit
          </Button>,
        ]}
      >
        <DescriptionsContainer size="middle" column={2} bordered>
          <Descriptions.Item label="Created At">{formatDate(tenant.createdAt)}</Descriptions.Item>
          <Descriptions.Item label="Updated At">{formatDate(tenant.updatedAt)}</Descriptions.Item>
          <Descriptions.Item label="Legacy ID">{tenant.metadata?.legacyId}</Descriptions.Item>
          <Descriptions.Item label="UID">{tenant.id}</Descriptions.Item>
          <Descriptions.Item label="Algolia Key (AIS)">{tenant.ais}</Descriptions.Item>
          <Descriptions.Item label="Slug">{tenant.slug}</Descriptions.Item>
        </DescriptionsContainer>
        <UserTable
          users={usersData?.items?.map((userTenant) => userTenant.user) ?? []}
          isLoading={usersIsLoading}
          title="Users"
        />
        <TenantFeatureFlagsTable featureFlags={tenant?.featureFlags} onFeatureFlagsChange={onFeatureFlagsChange} />
        <TenantMetadataTable tenant={tenant} />

        <Table
          title="Integrations"
          columns={columns}
          data={integrations && integrations.items}
          rowKey="id"
          isLoading={integrationsIsLoading}
        >
          <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Button type="primary" onClick={addToTenant}>
                <PlusOutlined /> Add Integration
              </Button>
            </div>
          </div>
        </Table>
        <Modal
          visible={isModalOpen}
          title="Manage Integration"
          onCancel={closeModal}
          footer={
            <div>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type="primary" onClick={form.submit} disabled={!tenant.id}>
                Save Changes
              </Button>
            </div>
          }
          centered
        >
          <Form {...responsiveFormLayout2} form={form} onFinish={onSubmit}>
            <InputFormItem
              name="name"
              label="Integration Name"
              rules={[{ required: true, message: 'Please add an integration name.' }]}
            />
            <RadioFormItem
              label="ERP Type"
              name="erpType"
              values={erpTypes}
              rules={[{ required: true, message: 'Please select an ERP type.' }]}
              onChange={() => {}}
              optionType="default"
            />
            <RadioFormItem
              label="Type"
              name="type"
              values={dbTypes}
              rules={[{ required: true, message: 'Please select a type.' }]}
              onChange={() => {}}
              optionType="default"
            />

            <RadioFormItem
              label="Environment"
              name="environment"
              values={environments}
              rules={[{ required: true, message: 'Please select an environment.' }]}
              onChange={() => {}}
            />

            {editingIntegrationId !== EMPTY_ID && (
              <Typography type="subtitle">
                To update database credentials, provide a full new set of login details.
              </Typography>
            )}
            <InputFormItem
              name="host"
              label="Host Address"
              rules={[{ required: true, message: 'Please add a host address.' }]}
            />
            <InputFormItem
              name="port"
              label="Port"
              type="number"
              rules={[{ required: false, message: 'Please add a port.' }]}
            />
            <InputFormItem
              name="database"
              label="Database Name"
              rules={[{ required: false, message: 'Please add a database name.' }]}
            />

            <InputFormItem
              label="Username"
              name="username"
              rules={[{ required: false, message: 'Please add a username.' }]}
            />

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: false, message: 'Please add a password.' }]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </PageHeader>
    </Container>
  );
};
