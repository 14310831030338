import React from 'react';

import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';

import { RadioGroup } from 'components/Radio';

export type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

export interface Props extends FormItemProps {
  values: Option[];
  onChange: (e: RadioChangeEvent) => void;
  optionType?: RadioGroupProps['optionType'];
}

export const RadioFormItem = ({ label, name, values, rules, onChange, optionType = 'button' }: Props): JSX.Element => (
  <Form.Item label={label} name={name} rules={rules}>
    <RadioGroup
      options={values}
      onChange={onChange}
      value={values[0].value}
      optionType={optionType}
      buttonStyle="solid"
    />
  </Form.Item>
);
