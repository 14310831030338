import React from 'react';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { upperNavModules, lowerNavModules } from '../navModulesData';
import { NavModule } from '../types';
import * as Styled from './SecondaryNavigation.style';

export interface SecondaryNavigationProps {
  isDesktopView: boolean;
  /** What modules should be shown? if module key doesn't exist in obj then it is shown by default */
  navModuleVisibility: Obj<boolean>;
  onModuleSelect: (navModule: NavModule) => void;
  curModuleSlug: string | undefined;
  isHamburgerOpen: boolean;
}

export const SecondaryNavigation = ({
  isDesktopView,
  navModuleVisibility,
  onModuleSelect,
  curModuleSlug: selectedModuleSlug,
  isHamburgerOpen,
}: SecondaryNavigationProps) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const renderMenuItem = (navModule: NavModule) => {
    const { slug: moduleSlug, label, selectedIcon, unselectedIcon } = navModule;
    const isSelected = selectedModuleSlug === moduleSlug;

    if (navModuleVisibility[moduleSlug] !== undefined && !navModuleVisibility[moduleSlug]) {
      return null;
    }

    const itemContent = (
      <Styled.MenuItem
        className={`fs-nav-module-${moduleSlug}`}
        selected={isSelected}
        href={
          navModule.routeFn?.() ||
          navModule.subModules
            .filter((option) =>
              option.featureFlag ? shouldShowFeatureFlag(activeTenant, activeUser, option.featureFlag) : true,
            )[0]
            ?.routeFn() ||
          ''
        }
        onClick={(ev) => {
          // allow ctrl/cmd+click to open in new tab
          if (!ev.ctrlKey && !ev.metaKey) {
            ev.preventDefault();
            onModuleSelect(navModule);
          }
        }}
      >
        <Styled.MenuItemChild>
          <Styled.Icon isDesktopView={isDesktopView}>{isSelected ? selectedIcon : unselectedIcon}</Styled.Icon>
          {!isDesktopView && <Styled.HamburgerTitle>{label}</Styled.HamburgerTitle>}
        </Styled.MenuItemChild>
      </Styled.MenuItem>
    );

    return (
      <React.Fragment key={moduleSlug}>
        {isDesktopView ? (
          <Tooltip slow title={label} placement="right">
            {itemContent}
          </Tooltip>
        ) : (
          itemContent
        )}
      </React.Fragment>
    );
  };

  if (isDesktopView || isHamburgerOpen) {
    return (
      <Styled.Container isDesktopView={isDesktopView} data-test-id="secondaryNavContainer">
        <Styled.Content>
          <Styled.MenuContainer>{upperNavModules.map(renderMenuItem)}</Styled.MenuContainer>
          <Styled.MenuContainer>{lowerNavModules.map(renderMenuItem)}</Styled.MenuContainer>
        </Styled.Content>
      </Styled.Container>
    );
  }

  return null;
};
