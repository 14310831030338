import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam } from 'utils/routes';
import { idColumn, sortableStringColumn, salesAndGMColumns, getProductGroupSearchRoute } from 'utils/tables';

export const TopProductGroups = ({ customerId }: { customerId: string | undefined }) => {
  const [isPercent, setIsPercent] = useState(false);

  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Product Group ID',
      dataIndex: 'productGroupId',
      render: (productGroupId: string, { productGroupDesc }: { productGroupDesc: string }) => (
        <Link to={getProductGroupSearchRoute(productGroupId, productGroupDesc)}>{productGroupId}</Link>
      ),
    }),
    sortableStringColumn({
      title: 'Product Group',
      dataIndex: 'productGroupDesc',
    }),
    ...salesAndGMColumns({ isPercent }),
    {
      render: ({ productGroupId, productGroupDesc }: { productGroupId: string; productGroupDesc: string }) =>
        ButtonLink(getProductGroupSearchRoute(productGroupId, productGroupDesc)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/top-product-groups`,
      })}
      columns={columns}
      isPercent={isPercent}
      setIsPercent={setIsPercent}
      rowKey="productGroupId"
    />
  );
};
