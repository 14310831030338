import React from 'react';

import {
  AreaChart,
  CartesianGrid,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelProps,
  LabelFormatter,
  TooltipFormatter,
  TickFormatterFunction,
} from 'recharts';

import { RECURRENCY_BLUE } from 'constants/styles';

export type ChartValue = {
  x: number;
  y: number;
};

export type Props = {
  data: ChartValue[];
  tickFormatter?: TickFormatterFunction;
  tooltipLabelFormatter?: LabelFormatter;
  tooltipValueFormatter?: TooltipFormatter;
  xAxisLabel?: string;
  yAxisLabel?: string;
  margin?: any;
};

export const DefaultChart = (props: Props): JSX.Element => {
  const { data, xAxisLabel, yAxisLabel, tickFormatter, tooltipLabelFormatter, tooltipValueFormatter, ...otherProps } =
    props;

  const xAxisLabelProps: LabelProps | undefined = xAxisLabel
    ? { value: xAxisLabel, position: 'insideBottom' }
    : undefined;
  const yAxisLabelProps: LabelProps | undefined = yAxisLabel
    ? { value: yAxisLabel, angle: -90, position: 'insideLeft' }
    : undefined;

  return (
    <ResponsiveContainer>
      <AreaChart data={data} {...otherProps}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={RECURRENCY_BLUE} stopOpacity={0.8} />
            <stop offset="95%" stopColor={RECURRENCY_BLUE} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="x"
          label={xAxisLabelProps}
          tickFormatter={tickFormatter}
          ticks={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]}
          height={40} // need custom height, otherwise x-axis label will overlap with ticks
        />
        <YAxis dataKey="y" label={yAxisLabelProps} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip labelFormatter={tooltipLabelFormatter} formatter={tooltipValueFormatter} />
        <Area type="monotone" dataKey="x" stroke={RECURRENCY_BLUE} fillOpacity={1} fill="url(#colorUv)" />
        <Area type="monotone" dataKey="y" stroke={RECURRENCY_BLUE} fillOpacity={1} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
