import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Form, message } from 'antd';
import moment, { Moment } from 'moment';

import { Button } from 'components/Button';
import { ContactsAutoComplete } from 'components/ContactsAutoComplete';
import { DatePickerFormItem, InputFormItem, TextAreaFormItem, responsiveFormLayout } from 'components/FormItems';
import { AlgoliaSelectFormItem } from 'components/FormItems/AlgoliaSelectFormItem';
import { AssigneeSelectFormItem } from 'components/FormItems/AssigneeSelectFormItem';
import { Container } from 'components/Layout/Container';
import { NewContactModal } from 'components/Modal/NewContactModal';
import { PageHeading } from 'components/Typography/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { IndexName } from 'utils/algolia';
import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes, useHashState } from 'utils/routes';

import { AlgoliaCustomer } from 'types/algolia-collections';
import { TaskNewHashState } from 'types/hash-state';

import * as Styled from './TaskNewPage.style';

interface NewTaskFormData {
  subject: string;
  comments: string;
  assigneeId: string;
  deadline: Moment;
  followUp: Moment;
  /** joined "{id}: {name}" string */
  contact: string;
  customerName: string;
  customerId: string;
}
export const TaskNewPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const name: FIXME = activeUser?.fullName;
  const userId: FIXME = activeUser?.id;

  const [hashState] = useHashState<TaskNewHashState>();
  const [showContacts, setShowContacts] = useState<FIXME>(false);
  const [isCreateNewContactModalOpen, setIsCreateNewContactModalOpen] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const onSubmit = async (data: NewTaskFormData) => {
    try {
      let contact;
      if (data.contact) {
        const contactSegments = data.contact.split(': ');
        if (contactSegments.length > 1) {
          contact = { foreignId: contactSegments[0], name: contactSegments[1] };
        }
      }
      const request = {
        title: data.subject,
        body: data.comments,
        tenantId: activeTenant.id,
        assigneeUserId: data.assigneeId,
        type: 'personal',
        dueAt: data.deadline.toDate().toISOString(),
        reminderAt: data.followUp?.toDate().toISOString(),
        customer: {
          name: data.customerName,
          foreignId: data.customerId,
        },
        contact,
      };
      await api().tasks().createTask(request);
      message.success('New task created.');
      history.push(routes.tasks.taskList());
    } catch (err) {
      captureAndShowError(err, 'creating task');
    }
  };

  return (
    <Container>
      <PageHeading style={{ marginTop: '24px', marginBottom: '24px', textAlign: 'center' }}>New Task</PageHeading>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === 'contactForm') {
            const { taskForm } = forms;

            taskForm.setFieldsValue({
              contact: `${values.firstName} ${values.lastName}`,
            });
          }
        }}
      >
        <Form
          {...responsiveFormLayout}
          name="taskForm"
          form={form}
          onFinish={onSubmit}
          onError={console.error}
          initialValues={{ deadline: moment(), subject: hashState.title }}
        >
          <InputFormItem
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please add a subject.' }]}
          />

          <DatePickerFormItem
            label="Deadline"
            name="deadline"
            rules={[{ required: true, message: 'Please select a deadline.' }]}
          />

          <DatePickerFormItem label="Reminder Date" name="followUp" />

          <Form.Item hidden name="customerId" rules={[{ required: true, message: 'Please add a customer id.' }]} />

          <Form.Item hidden name="customerName" rules={[{ required: true, message: 'Please add a customer name.' }]} />

          <AlgoliaSelectFormItem<AlgoliaCustomer>
            id="taskForm_customer"
            label="Customer"
            name="customer"
            initialValue={undefined}
            indexName={IndexName.Customers}
            rules={[{ required: true, message: 'Please add a customer.' }]}
            mapHitFn={(hit) => ({
              key: hit.customer_id,
              value: `${hit.customer_id}: ${hit.customer_name}`,
            })}
            setFieldsValue={form.setFieldsValue}
            postValueChange={(value: FIXME, option: FIXME) => {
              if (value === '') {
                setShowContacts(false);
                return;
              }

              if (option) {
                const { key, value } = option;
                form.setFieldsValue({ customerId: key, customerName: value.split(': ')[1] });
                setShowContacts(key);
              }
            }}
            salesRepRefined
          />

          {showContacts && (
            <ContactsAutoComplete
              customerId={showContacts}
              setIsCreateNewContactModalOpen={setIsCreateNewContactModalOpen}
              setFieldsValue={form.setFieldsValue}
            />
          )}

          <AssigneeSelectFormItem
            name="assigneeId"
            label="Assigned To"
            rules={[{ required: true, message: 'Please add an assignee.' }]}
            setFieldsValue={form.setFieldsValue}
            initialValue={userId}
          />

          <InputFormItem label="Assigned By" name="assignedBy" disabled prefix={name} />

          <TextAreaFormItem label="Comments" name="comments" />

          <Form.Item>
            <Styled.FlexCenter>
              <Button id="submitTaskButton" type="primary" htmlType="submit">
                Submit
              </Button>
            </Styled.FlexCenter>
          </Form.Item>
        </Form>

        <NewContactModal
          isOpen={isCreateNewContactModalOpen}
          onCancel={() => setIsCreateNewContactModalOpen(false)}
          customerId={showContacts}
        />
      </Form.Provider>
    </Container>
  );
};
