/**
 * TODO: should remove all hard coded styles.
 */

export const UNITS = {
  XS: 2,
  SM: 4,
  MD: 8,
  LG: 16,
  XL: 24,
  XXL: 32,
};

export const DEFAULT_TRANSITION = 'all 150ms ease-in-out';

export const RECURRENCY_BLUE = '#1EA4CC';
export const RECURRENCY_DARK_BLUE = '#1880A0';
export const RECURRENCY_LIGHT_BLUE = '#68CBE8';
export const RECURRENCY_DARK_VIOLET = '#0D4454';
export const RECURRENCY_RED = '#DB3300';
export const RECURRENCY_GREEN = '#1BB143';

export const GRAY_400 = '#D9D9D9';
export const RECURRENCY_LIGHT_GRAY = '#F0F0F0';
export const RECURRENCY_GRAY = '#595959';
export const RECURRENCY_DARK_GRAY = '#1F1F1F';

export const RECURRENCY_NEW_DARK_BLUE = '#1E4158';

export const RECURRENCY_SUCCESS = '#1BB143';
export const RECURRENCY_DANGER = '#1E4158';
export const RECURRENCY_WARNING = '#FAAD14';
