/* eslint-disable no-console */
import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Tenant, TenantMetadata } from '@recurrency/core-api';
import { Form, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button/Button';
import { InputFormItem, RadioFormItem, responsiveFormLayout2 } from 'components/FormItems';
import { Table } from 'components/Table';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { objRemoveNilValues } from 'utils/object';

export function TenantMetadataTable({ tenant }: { tenant: Tenant }) {
  const [metadataForm] = Form.useForm();
  const tableRowsData: Array<{ label: string; value: string | undefined }> = [
    { label: 'Legacy Id', value: tenant?.metadata?.legacyId },
    { label: 'Default Company Id', value: tenant?.metadata?.companyId },
    { label: 'Region', value: tenant?.metadata?.region },
    { label: 'Exporter Url', value: tenant?.metadata?.exporterUrl },
    { label: 'SSO Connection Name', value: tenant?.metadata?.ssoConnectionName },
  ];

  type MetadataLabelValuePairs = {
    title: 'Name' | 'Value';
    dataIndex: 'label' | 'value';
  };
  const columns: ColumnType<MetadataLabelValuePairs>[] = [
    {
      title: 'Name',
      dataIndex: 'label',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeMetadataModal = () => {
    setIsModalOpen(false);
    // metadataForm.resetFields();
  };
  const handleSetMetadataButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleMetadataFormSubmit = async (data: TenantMetadata) => {
    try {
      if (!tenant.id) {
        throw new Error('No tenant ID found');
      }
      tenant.metadata = data;
      // strip-undefined
      const payload = objRemoveNilValues(data);
      await api().tenants().updateTenantMetadata(tenant.id, { metadata: payload });
      closeMetadataModal();
      message.success('Updated metadata.');
    } catch (err) {
      captureAndShowError(err, `updating tenant metadata`);
    }
  };

  const regions = [
    { label: 'East', value: 'us-east-1' },
    { label: 'West', value: 'us-west-1' },
  ];

  return (
    <div>
      <Table title="Metadata" columns={columns} data={tableRowsData} rowKey="metadataKey">
        <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={handleSetMetadataButtonClick}>
            <EditOutlined />
            Edit Metadata
          </Button>
        </div>
      </Table>
      <Modal
        visible={isModalOpen}
        title="Manage Metadata"
        onCancel={closeMetadataModal}
        footer={
          <div>
            <Button onClick={closeMetadataModal}>Cancel</Button>
            <Button type="primary" onClick={metadataForm.submit} disabled={!tenant.id}>
              Save Changes
            </Button>
          </div>
        }
        centered
      >
        <Form
          {...responsiveFormLayout2}
          form={metadataForm}
          initialValues={tenant?.metadata}
          onFinish={handleMetadataFormSubmit}
        >
          <InputFormItem name="legacyId" label="Legacy Id" />
          <InputFormItem name="companyId" label="Default Company Id" />
          <RadioFormItem
            label="Region"
            name="region"
            values={regions}
            rules={[{ required: true, message: 'Please select an region.' }]}
            onChange={() => {}}
          />
          <InputFormItem name="exporterUrl" label="Exporter URL" />
          <InputFormItem name="ssoConnectionName" label="SSO Connection Name" />
        </Form>
      </Modal>
    </div>
  );
}
