import React from 'react';

import { Tabs } from 'antd';
import type { TabsProps } from 'antd/lib/tabs';

import { toDashCase } from 'utils/formatting';
import { useHashState } from 'utils/routes';

import { NavTabHashState } from 'types/hash-state';

import { InfoTooltip } from './Tooltip/InfoTooltip';

interface TabContent {
  header: string;
  content: JSX.Element;
  infoTooltip?: string;
}

interface Props extends TabsProps {
  tabs: TabContent[];
}

export function NavTabs({ tabs }: Props) {
  const [hashState, updateHashState] = useHashState<NavTabHashState>();

  // intial tab is the first tab, if not present in url query params
  let activeTab = hashState.tab;

  if (!activeTab && tabs.length > 0) {
    activeTab = toDashCase(tabs[0].header);
    // requestAnimationFrame prevents 'Cannot update during an existing state transition' error
    requestAnimationFrame(() => {
      updateHashState({ tab: activeTab });
    });
  }

  return (
    <Tabs
      style={{ overflowX: 'auto' }}
      activeKey={activeTab}
      onChange={(tabKey: string) => {
        updateHashState({ tab: tabKey });
      }}
    >
      {tabs.map((tab) => (
        <Tabs.TabPane
          key={toDashCase(tab.header)}
          tab={tab.infoTooltip ? <InfoTooltip title={tab.infoTooltip}>{tab.header}</InfoTooltip> : tab.header}
        >
          {tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}
