import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import { sortableStringColumn, salesAndGMColumns, idColumn } from 'utils/tables';

export const TopShiptos = ({ itemId }: { itemId: string | undefined }) => {
  const [isPercent, setIsPercent] = useState(false);

  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Ship To ID',
      dataIndex: 'shipToId',
    }),
    sortableStringColumn({
      title: 'Ship To',
      dataIndex: 'shipToName',
    }),
    idColumn({
      title: 'Customer ID',
      dataIndex: 'customerId',
      render: (customerId: any) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customerName',
    }),
    ...salesAndGMColumns({ isPercent }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(itemId || '')}/top-shiptos`,
      })}
      columns={columns}
      isPercent={isPercent}
      setIsPercent={setIsPercent}
      rowKey="shipToId"
    />
  );
};
