import React, { useState } from 'react';

import moment from 'moment';

import { Typography } from 'components/Typography';

import { RECURRENCY_GRAY } from 'constants/styles';

const HISTORICAL_COLOR = '#266BF3';
const FORECAST_COLOR = '#3AD6CE';

type ForecastSparklinePoint = {
  date: string;
  value: number | null;
  uom: string;
  type: 'forecast' | 'historical';
};

export type ForecastSparklineProps = {
  historicalData: ForecastSparklinePoint[];
  forecastData: ForecastSparklinePoint[];
};

export const ForecastSparkline = ({ historicalData, forecastData }: ForecastSparklineProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [point, setPoint] = useState<ForecastSparklinePoint>();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const OFFSET = 5;
  const WIDTH = 300;
  const adjustedWidth = WIDTH - 2 * OFFSET;

  const HEIGHT = 50;
  const adjustedHeight = HEIGHT - 2 * OFFSET;

  const MAX_X = new Date(forecastData[forecastData.length - 1].date).getTime();
  const MIN_X = new Date(historicalData[0].date).getTime();
  const MAX_Y = Math.max(...historicalData.map((d) => d.value ?? 0), ...forecastData.map((d) => d.value ?? 0)) || 1;

  const xDataToChartPlane = (date: string) =>
    ((new Date(date).getTime() - MIN_X) / (MAX_X - MIN_X)) * adjustedWidth + OFFSET;
  const yDataToChartPlane = (val: number) =>
    MAX_Y <= 0 ? OFFSET : adjustedHeight - (val / MAX_Y) * adjustedHeight + OFFSET;

  return (
    <div
      className="LineChart"
      style={{
        cursor: `crosshair`,
      }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseMove={(ev) => {
        setMouse({ x: ev.nativeEvent.offsetX, y: ev.nativeEvent.offsetY });
        // Find the closest point on the x axis of the sparkline to the mouse
        // TODO: This can be done in much better than linear time, this was just quick to implement
        let closest = historicalData.reduce((prev, current) =>
          Math.abs(xDataToChartPlane(prev.date) - ev.nativeEvent.offsetX) <
          Math.abs(xDataToChartPlane(current.date) - ev.nativeEvent.offsetX)
            ? prev
            : current,
        );
        closest = forecastData.reduce(
          (prev, current) =>
            Math.abs(xDataToChartPlane(prev.date) - ev.nativeEvent.offsetX) <
            Math.abs(xDataToChartPlane(current.date) - ev.nativeEvent.offsetX)
              ? prev
              : { ...current },
          closest,
        );
        setPoint(closest);
      }}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && point && (
        // Tooltip that follows mouse when the user is hovering over the sparkline
        <div
          className="dataToolTip"
          style={{
            position: 'absolute',
            left: `${mouse.x + 20}px`,
            top: `${mouse.y + 20}px`,
            width: '100px',
            height: '80px',
            backgroundColor: 'rgba(255, 255, 255, .85)',
            fontWeight: 500,
            zIndex: 10,
            border: `1px solid ${point.type === 'historical' ? HISTORICAL_COLOR : FORECAST_COLOR}`,
          }}
        >
          {point.type === 'historical' ? (
            <Typography style={{ color: HISTORICAL_COLOR, textAlign: 'center' }}>Historical</Typography>
          ) : (
            <Typography style={{ color: FORECAST_COLOR, textAlign: 'center' }}>Forecast</Typography>
          )}
          <Typography style={{ textAlign: 'center' }}>{moment(point.date).format('MMM YYYY')}</Typography>
          <Typography style={{ textAlign: 'center' }}>
            {point.value} {point.uom}
          </Typography>
        </div>
      )}
      <svg
        width={WIDTH}
        height={HEIGHT}
        style={{
          display: 'block',
          strokeWidth: '2px',
        }}
      >
        <line
          x1={xDataToChartPlane(historicalData[0].date)}
          x2={xDataToChartPlane(forecastData[forecastData.length - 1].date)}
          y1={yDataToChartPlane(0)}
          y2={yDataToChartPlane(0)}
          stroke={RECURRENCY_GRAY}
          strokeOpacity={0.1}
          strokeWidth="2"
          strokeLinecap="round"
        />
        {historicalData.slice(0, -1).map((_, i) => (
          <ForecastLine
            x1={xDataToChartPlane(historicalData[i].date)}
            x2={xDataToChartPlane(historicalData[i + 1].date)}
            y1={yDataToChartPlane(historicalData[i].value ?? 0)}
            y2={yDataToChartPlane(historicalData[i + 1].value ?? 0)}
            type="historical"
            // Using x value of line as key, as there shouldn't be any replicated dates
            key={`${xDataToChartPlane(historicalData[i].date)} ${xDataToChartPlane(historicalData[i + 1].date)}`}
          />
        ))}

        <ForecastLine
          x1={xDataToChartPlane(historicalData[historicalData.length - 1].date)}
          x2={xDataToChartPlane(forecastData[0].date)}
          y1={yDataToChartPlane(historicalData[historicalData.length - 1].value ?? 0)}
          y2={yDataToChartPlane(forecastData[0].value ?? 0)}
          type="forecast"
        />

        {forecastData.slice(0, -1).map((data, i) => (
          <ForecastLine
            x1={xDataToChartPlane(forecastData[i].date)}
            x2={xDataToChartPlane(forecastData[i + 1].date)}
            y1={yDataToChartPlane(forecastData[i].value ?? 0)}
            y2={yDataToChartPlane(forecastData[i + 1].value ?? 0)}
            type="forecast"
            // Using x value of line as key, as there shouldn't be any replicated dates
            key={`${xDataToChartPlane(forecastData[i].date)} ${xDataToChartPlane(forecastData[i + 1].date)}`}
          />
        ))}
        {showTooltip && point && (
          <circle
            cx={xDataToChartPlane(point?.date)}
            cy={yDataToChartPlane(point?.value ?? 0)}
            r="3"
            fill={point?.type === 'historical' ? HISTORICAL_COLOR : FORECAST_COLOR}
          />
        )}
      </svg>
    </div>
  );
};

type ForecastLineProps = {
  type: 'historical' | 'forecast';
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};
const ForecastLine = ({ type, x1, x2, y1, y2 }: ForecastLineProps) => (
  <line
    x1={x1}
    x2={x2}
    y1={y1}
    y2={y2}
    stroke={type === 'historical' ? HISTORICAL_COLOR : FORECAST_COLOR}
    strokeWidth="2"
    strokeLinecap="round"
  />
);
