import React from 'react';

import { Link } from 'react-router-dom';

import { Alert } from 'components/Alert';
import { EntityDescriptions } from 'components/Descriptions';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { IndexName, searchAlgoliaIndex } from 'utils/algolia';
import { formatPartialAddress, splitIfIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';

import { AlgoliaCustomer } from 'types/algolia-collections';
import { ProspectHashState } from 'types/hash-state';

export const ProspectReviewStep = ({ prospectState }: { prospectState: ProspectHashState }) => {
  const prospectDescriptions = [
    { label: 'Customer Name', render: prospectState.customerName },
    {
      label: 'Physical Address',
      render: formatPartialAddress({
        address1: prospectState['physical-address1'],
        address2: prospectState['physical-address2'],
        city: prospectState['physical-city'],
        state: prospectState['physical-state'],
        zip: prospectState['physical-zipcode'],
      }),
    },
    {
      label: 'Mailing Address',
      render: prospectState.addressesSame
        ? formatPartialAddress({
            address1: prospectState['physical-address1'],
            address2: prospectState['physical-address2'],
            city: prospectState['physical-city'],
            state: prospectState['physical-state'],
            zip: prospectState['physical-zipcode'],
          })
        : formatPartialAddress({
            address1: prospectState['mailing-address1'],
            address2: prospectState['mailing-address2'],
            city: prospectState['mailing-city'],
            state: prospectState['mailing-state'],
            zip: prospectState['mailing-zipcode'],
          }),
    },
    { label: 'Central Phone', render: prospectState.customerPhone },
    { label: 'Central Fax', render: prospectState.customerFax },
    { label: 'Email', render: prospectState.customerEmail },
    { label: 'Assigned Sales Rep', render: splitIfIdNameStr(prospectState.salesRep)?.foreignId },
    { label: 'Company', render: prospectState.company },
  ];

  const contactDescriptions = [
    {
      label: 'First Name',
      render: prospectState.contactFirstName,
    },
    { label: 'Last Name', render: prospectState.contactLastName },
    { label: 'Title', render: prospectState.contactTitle },
    { label: 'Direct Phone', render: prospectState.contactPhone },
    { label: 'Direct Fax', render: prospectState.contactFax },
    { label: 'Direct Email', render: prospectState.contactEmail },
  ];

  const shipToDescriptions = [
    { label: 'Ship To Name', render: prospectState.customerName },
    {
      label: 'Address',
      render: formatPartialAddress({
        address1: prospectState['physical-address1'],
        address2: prospectState['physical-address2'],
        city: prospectState['physical-city'],
        state: prospectState['physical-state'],
        zip: prospectState['physical-zipcode'],
      }),
    },
    { label: 'Default Branch', render: prospectState.shipToDefaultBranch },
    { label: 'Default Freight', render: prospectState.shipToDefaultFreight },
    { label: 'Default Location', render: prospectState.shipToPreferredLocation },
  ];

  const { activeTenant, searchClient } = useGlobalApp();
  const tenantId = activeTenant.id;

  const {
    data: algoliaResponse,
    isLoading: isHitsLoading,
    error,
  } = usePromise(
    () =>
      searchAlgoliaIndex<AlgoliaCustomer>(searchClient, {
        tenantId,
        indexName: IndexName.Customers,
        query: prospectState.customerName,
        hitsPerPage: 5,
      }),
    [prospectState.customerName],
  );

  return (
    <>
      <Typography type="large">Prospect</Typography>
      <EntityDescriptions descriptions={prospectDescriptions} />
      {prospectState.customerName && !isHitsLoading && !error && (algoliaResponse?.hits?.length ?? 0) > 0 && (
        <Alert
          message="Similar Customers Found"
          description={
            <>
              <div>The customer name you used is similar to these customers:</div>
              <ul>
                {algoliaResponse?.hits.map(({ customer_name, customer_id }) => (
                  <li key={customer_id}>
                    <Link to={routes.sales.customerDetails(customer_id)}>{customer_id}</Link>: {customer_name}
                  </li>
                ))}
              </ul>
            </>
          }
          type="warning"
          closable
        />
      )}
      {
        // Only show contact if one is going to be created
        (prospectState.contactFirstName ||
          prospectState.contactLastName ||
          prospectState.contactTitle ||
          prospectState.contactPhone ||
          prospectState.contactFax ||
          prospectState.contactEmail) && (
          <>
            <Typography type="large">Contact</Typography>
            <EntityDescriptions descriptions={contactDescriptions} />
          </>
        )
      }
      {
        // Only show shipto if one is going to be created
        prospectState.makeShipTo && (
          <>
            <Typography type="large">Ship To</Typography>
            <EntityDescriptions descriptions={shipToDescriptions} />
          </>
        )
      }
    </>
  );
};
