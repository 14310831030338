import { useCallback, useMemo } from 'react';

import { useHistory, useParams as useReactRouterParams, matchPath } from 'react-router-dom';

import urltron from 'urltron';

import { objFlattenedValues, objKeys } from 'utils/object';

import {
  PricingHashState,
  PurchaseOrderNewHashState,
  PurchasingForecastsHashState,
  QuoteEditHashState,
  QuotesListHashState,
  SearchFrameHashState,
  TaskNewHashState,
} from 'types/hash-state';

export interface IdPathParams {
  id: string;
}

export interface ParsePathResult {
  /** matching routePath e.g '/:tenant/sales/customer/:id' */
  routePath: string;
  /** params in path e.g {tenant: 'rai', id: '123'} */
  pathParams: Obj<string> | undefined;
  tenantSlug: string;
  moduleSlug: string;
  subModuleSlug: string;
  /** trimmed path, split by '/' */
  pathSlashParts: string[];
}

export const routePaths = {
  home: '/',
  sales: {
    dashboard: '/:tenant/sales/dashboard',
    customerList: '/:tenant/sales/customers',
    customerDetails: '/:tenant/sales/customer/:id',
    customerNew: '/:tenant/sales/customers/new',
    itemList: '/:tenant/sales/items',
    itemDetails: '/:tenant/sales/item/:id',
    pricing: '/:tenant/sales/pricing',
  },
  orders: {
    orderList: '/:tenant/orders/orders',
    orderDetails: '/:tenant/orders/order/:id',
    orderNew: '/:tenant/orders/orders/new',
    quoteList: '/:tenant/orders/quotes',
    quoteNew: '/:tenant/orders/quotes/new',
    quoteEdit: '/:tenant/orders/quote/:id/edit',
    // TODO: confirm with @sam whether we can have the url 'recommended-quotes' for consistency
    recommendedQuotes: '/:tenant/orders/opportunities',
  },
  purchasing: {
    purchaseOrderList: '/:tenant/purchasing/purchase-orders',
    purchaseOrderDetails: '/:tenant/purchasing/purchase-order/:id',
    purchaseOrderNew: '/:tenant/purchasing/purchase-orders/new',
    vendorList: '/:tenant/purchasing/vendors',
    vendorDetails: '/:tenant/purchasing/vendor/:id',
    itemList: '/:tenant/purchasing/items',
    itemDetails: '/:tenant/purchasing/item/:id',
    forecasts: '/:tenant/purchasing/forecasts',
  },
  tasks: {
    taskList: '/:tenant/tasks/tasks',
    taskDetails: '/:tenant/tasks/task/:id',
    taskNew: '/:tenant/tasks/tasks/new',
    taskEdit: '/:tenant/tasks/task/:id/edit',
  },
  admin: {
    settings: '/:tenant/admin/settings',
  },
  internal: {
    userList: '/internal/users',
    userDetails: '/internal/user/:id',
    userNew: '/internal/users/new',
    userEdit: '/internal/user/:id/edit',
    tenantList: '/internal/tenants',
    tenantDetails: '/internal/tenant/:id',
    tenantNew: '/internal/tenants/new',
    tenantEdit: '/internal/tenant/:id/edit',
  },
  account: {
    accountEdit: '/account/edit',
  },
};

export const oldToNewRoutePaths = {
  '/sales/dashboard': routePaths.sales.dashboard,
  '/sales/customers': routePaths.sales.customerList,
  '/sales/customer/:id': routePaths.sales.customerDetails,
  '/sales/customers/new': routePaths.sales.customerNew,
  '/sales/items': routePaths.sales.itemList,
  '/sales/item/:id': routePaths.sales.itemDetails,
  '/sales/pricing': routePaths.sales.pricing,
  '/orders/orders': routePaths.orders.orderList,
  '/orders/order/:id': routePaths.orders.orderDetails,
  '/orders/orders/new': routePaths.orders.orderNew,
  '/orders/quotes': routePaths.orders.quoteList,
  '/orders/quotes/new': routePaths.orders.quoteNew,
  '/orders/quote/:id/edit': routePaths.orders.quoteEdit,
  '/orders/opportunities': routePaths.orders.recommendedQuotes,
  '/purchasing/purchase-orders': routePaths.purchasing.purchaseOrderList,
  '/purchasing/purchase-order/:id': routePaths.purchasing.purchaseOrderDetails,
  '/purchasing/purchase-orders/new': routePaths.purchasing.purchaseOrderNew,
  '/purchasing/vendors': routePaths.purchasing.vendorList,
  '/purchasing/vendor/:id': routePaths.purchasing.vendorDetails,
  '/purchasing/items': routePaths.purchasing.itemList,
  '/purchasing/item/:id': routePaths.purchasing.itemDetails,
  '/purchasing/forecasts': routePaths.purchasing.forecasts,
  '/tasks/tasks': routePaths.tasks.taskList,
  '/tasks/task/:id': routePaths.tasks.taskDetails,
  '/tasks/tasks/new': routePaths.tasks.taskNew,
  '/tasks/task/:id/edit': routePaths.tasks.taskEdit,
  '/admin/settings': routePaths.admin.settings,
};

export const routes = {
  home: () => routePaths.home,
  sales: {
    dashboard: () => makePath(routePaths.sales.dashboard),
    customerList: (hashState?: SearchFrameHashState) => makePath(routePaths.sales.customerList, null, hashState),
    customerDetails: (id: string) => makePath(routePaths.sales.customerDetails, { id }),
    customerNew: () => makePath(routePaths.sales.customerNew),
    itemList: (hashState?: SearchFrameHashState) => makePath(routePaths.sales.itemList, null, hashState),
    itemDetails: (id: string) => makePath(routePaths.sales.itemDetails, { id }),
    pricing: (hashState?: PricingHashState) => makePath(routePaths.sales.pricing, null, hashState),
  },
  orders: {
    orderList: (hashState?: SearchFrameHashState) => makePath(routePaths.orders.orderList, null, hashState),
    orderDetails: (id: string) => makePath(routePaths.orders.orderDetails, { id }),
    orderNew: (hashState?: QuoteEditHashState) => makePath(routePaths.orders.orderNew, null, hashState),
    quoteList: (hashState?: QuotesListHashState) => makePath(routePaths.orders.quoteList, null, hashState),
    quoteNew: (hashState?: QuoteEditHashState) => makePath(routePaths.orders.quoteNew, null, hashState),
    quoteEdit: (id: string) => makePath(routePaths.orders.quoteEdit, { id }),
    recommendedQuotes: () => makePath(routePaths.orders.recommendedQuotes),
  },
  purchasing: {
    purchaseOrderList: (hashState?: SearchFrameHashState) =>
      makePath(routePaths.purchasing.purchaseOrderList, null, hashState),
    purchaseOrderDetails: (id: string) => makePath(routePaths.purchasing.purchaseOrderDetails, { id }),
    purchaseOrderNew: (hashState?: PurchaseOrderNewHashState) =>
      makePath(routePaths.purchasing.purchaseOrderNew, null, hashState),
    vendorList: (hashState?: SearchFrameHashState) => makePath(routePaths.purchasing.vendorList, null, hashState),
    vendorDetails: (id: string) => makePath(routePaths.purchasing.vendorDetails, { id }),
    itemList: (hashState?: SearchFrameHashState) => makePath(routePaths.purchasing.itemList, null, hashState),
    itemDetails: (id: string) => makePath(routePaths.purchasing.itemDetails, { id }),
    forecasts: (hashState?: PurchasingForecastsHashState) => makePath(routePaths.purchasing.forecasts, null, hashState),
  },
  tasks: {
    taskList: () => makePath(routePaths.tasks.taskList),
    taskDetails: (id: string) => makePath(routePaths.tasks.taskDetails, { id }),
    taskNew: (hashState?: TaskNewHashState) => makePath(routePaths.tasks.taskNew, null, hashState),
    taskEdit: (id: string) => makePath(routePaths.tasks.taskEdit, { id }),
  },
  admin: {
    settings: () => makePath(routePaths.admin.settings),
  },
  internal: {
    userList: () => makePath(routePaths.internal.userList),
    userDetails: (id: string) => makePath(routePaths.internal.userDetails, { id }),
    userNew: () => makePath(routePaths.internal.userNew),
    userEdit: (id: string) => makePath(routePaths.internal.userEdit, { id }),
    tenantList: () => makePath(routePaths.internal.tenantList),
    tenantDetails: (id: string) => makePath(routePaths.internal.tenantDetails, { id }),
    tenantNew: () => makePath(routePaths.internal.tenantNew),
    tenantEdit: (id: string) => makePath(routePaths.internal.tenantEdit, { id }),
  },
  account: {
    accountEdit: () => makePath(routePaths.account.accountEdit),
  },
};

///  Uri builder helpers ///

/**
 * React router does magic to automatically decode encoded urls
 * Not a great decision on their part, because it causes all sorts of consistency issues
 * depending on whether use use history.push or <Link>
 * @see https://github.com/ReactTraining/history/issues/505
 * We work around it by encoding and decoding % as ％ (U+FF05)
 */

export function encodePercentChars(str: string): string {
  return str.replace(/%/g, '％');
}

export function decodePercentChars(str: string): string {
  return str.replace(/％/g, '%');
}

export function encodeHashState(hashState: Obj): string {
  return `#${urltron.stringify(hashState)}`;
}

function encodePathParams(path: string, pathParams: Obj): string {
  let pathStr = path;
  for (const key of Object.keys(pathParams)) {
    pathStr = pathStr.replace(`/:${key}`, `/${encodePercentChars(encodeURIComponent(pathParams[key]))}`);
  }
  return pathStr;
}

/** singleton module var so tenant path param doesn't need to be set for every makePath call */
let defaultTenantSlug = '';
export function setDefaultTenantSlugForMakePath(tenantSlug: string) {
  defaultTenantSlug = tenantSlug;
}

/**
 * Build uri string with path params and query params
 */
export function makePath(routePath: string, pathParams?: Obj<string | number> | null, hashState?: Obj): string {
  if (routePath.includes('/:tenant/') && (!pathParams || !pathParams.tenant)) {
    // automatically insert defaultTenantSlug in paths
    if (!defaultTenantSlug) {
      throw new Error('No defaultTenantSlug set, makePath function called too early');
    }
    pathParams = { tenant: defaultTenantSlug, ...pathParams };
  }

  const pathStr = pathParams ? encodePathParams(routePath, pathParams) : routePath;
  const hashStr = hashState ? encodeHashState(hashState) : '';
  return pathStr + hashStr;
}

export const encodeLegacyApiParam = (id: string) => encodeURIComponent(id);

export const encodeLegacyApiItemIdParam = (itemId: string) =>
  // we double encode because, legacy api uses flask which already decodes before passing to req handler
  // item ids can contain all sorts of characters e.g. "/", which flask doesn't handle well.
  // until we fully refactor our frontend and backend to use inv_mast_uid (inventory master uid), this is a necessary evil
  // @see https://github.com/pallets/flask/issues/900#issuecomment-582499607
  // @see https://github.com/recurrency/frontend/issues/1273#issuecomment-845180347
  // @see https://github.com/recurrency/api/pull/257/files
  encodeURIComponent(encodeURIComponent(itemId));

export const routePathsList = objFlattenedValues(routePaths);

/**
 * @returns matching routePath e.g '/sales/customer/:id'
 */
export function getMatchingRoutePath(path: string): string | undefined {
  const match = matchPath(path, { path: routePathsList, exact: true });
  return match ? match.path : undefined;
}

/** translates old route path to new route path */
export function oldToNewRoutePath(oldRoutePath: string): string | undefined {
  const oldRoutePathsList = objKeys(oldToNewRoutePaths);
  const match = matchPath(oldRoutePath, { path: oldRoutePathsList, exact: true });
  if (match) {
    // @ts-expect-error matchPath should match this properly
    return makePath(oldToNewRoutePaths[match.path], match.params);
  }
  return undefined;
}

/** parses location path into routePath, tenant, module and subModule slugs */
export function parsePath(path: string): ParsePathResult {
  // ignore leading and trailing slashes
  const slashTrimmedPath = path.replace(/^\/|\/$/g, '');
  const pathSlashParts = slashTrimmedPath.split('/');
  const routeMatch = matchPath(path, { path: routePathsList, exact: true });

  const result: ParsePathResult = {
    routePath: routeMatch?.path || '',
    pathParams: routeMatch?.params,
    tenantSlug: '',
    moduleSlug: '',
    subModuleSlug: '',
    pathSlashParts,
  };

  if (result.routePath.startsWith('/:tenant/')) {
    [result.tenantSlug, result.moduleSlug, result.subModuleSlug] = pathSlashParts;
  } else {
    [result.moduleSlug, result.subModuleSlug] = pathSlashParts;
  }
  return result;
}

/// Location hooks ///

/**
 * @returns path params from location e.g /item/:id -> {id: 123}
 */
export function usePathParams<PathParamsT>(): PathParamsT {
  const routerParams = useReactRouterParams<FIXME>();
  const pathParams: Obj = {};
  for (const key of Object.keys(routerParams)) {
    pathParams[key] = decodeURIComponent(decodePercentChars(routerParams[key]));
  }
  return pathParams as unknown as PathParamsT;
}

export interface UpdateHashStateOptions {
  pushToHistory?: boolean;
}

/**
 * @returns [hashState from location.hash, updateHashState function]
 */
export function useHashState<HashStateT>() {
  const history = useHistory();
  const hashState: HashStateT = useMemo(
    () => urltron.parse(window.location.hash),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.hash],
  );
  const updateHashState = useCallback(
    (update: Partial<HashStateT>, options?: UpdateHashStateOptions) => {
      // use Object.assign so we don't clear out existing hashState values
      const newHashState = Object.assign(urltron.parse(window.location.hash), update);
      const newHash = encodeHashState(newHashState);

      if (window.location.hash !== newHash) {
        // use history.replace by default so we can change url without adding to history stack
        // hashState updates can be very frequent
        if (options?.pushToHistory) {
          history.push(newHash);
        } else {
          history.replace(newHash);
        }
      }
    },
    [history],
  );
  return [hashState, updateHashState] as const;
}
