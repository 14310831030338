import styled from "styled-components";
import { Container as LayoutContainer } from "components/Layout/Container";
import { Table } from "components/Table";
import { Typography } from "components/Typography";
export const Container = styled(LayoutContainer)`
  padding-top: 24px;
  padding-bottom: 30px;

  .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
    background: rgb(250, 250, 250);
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;

  padding: 30px 0;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px;

  .ant-table.ant-table-small {
    margin: 0 !important;
  }

  .ant-descriptions-view table {
    width: 100% !important;
  }
`;
export const QuoteTable = styled(Table)`
  overflow: hidden;
  width: 100%;

  padding: 10px 0;

  .ant-table-row {
    cursor: pointer;
  }
`;
export const FooterLabel = styled(Typography).attrs({
  type: "subtitle"
})`
  &:first-child {
    margin-bottom: 6px;
  }
`;
export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 8px;
  padding-right: 10px;
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 26px;
  }
`;
