import React, { useCallback } from 'react';

import { formatUSD, formatPercent } from 'utils/formatting';

import { DefaultChart } from '../DefaultChart';

export type ChartValue = {
  x: number;
  y: number;
};

export type Props = {
  data: ChartValue[];
  costAll: number;
};

export const PriceChart = (props: Props): JSX.Element => {
  const { data, costAll } = props;

  const tickFormatter = useCallback((tick) => `${Math.floor(tick * 100)}%`, []);

  const tooltipF = useCallback((value, name) => {
    if (name === 'x') {
      return [formatPercent(value), 'GM%'];
    }
    return [Math.floor(value), 'Units Sold'];
  }, []);

  const labelF = useCallback(
    (value) => {
      const tooltipPrice = Number(costAll / (1 - value));
      return `Price: ${formatUSD(tooltipPrice, true)}`;
    },
    [costAll],
  );

  return (
    <DefaultChart
      data={data}
      tickFormatter={tickFormatter}
      tooltipLabelFormatter={labelF}
      tooltipValueFormatter={tooltipF}
      xAxisLabel="Gross Margin Percentage"
      yAxisLabel="Units Sold"
      margin={{ bottom: 5 }}
    />
  );
};
