import styled from "styled-components";
import { media } from "utils/responsive";
export const ToolWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.sm`
    flex-direction: row;
  `};
`;
export const DropdownWrapper = styled.div`
  flex: 1;
`;
export const ToolActionWrapper = styled.div`
  flex: 1;
  margin-top: 10px;
  margin-left: 0px;

  ${media.sm`
    flex: none;
    margin-top: 0px;
    margin-left: 10px;
  `}
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

  ${media.md`
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0px;
  `};
`;
export const HeaderTagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0px;
  margin-left: 10px;

  ${media.md`
    justify-content: center;
    margin-top: 10px;
    margin-left: 0px;
  `};
`;
export const AppliedFilters = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  align-items: center;
`;
