import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { Container } from 'components/Layout/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { SplitPage } from 'components/SplitPage';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';
import { Typography, PageHeading } from 'components/Typography/Typography';

import { useLegacyApi } from 'hooks/useApi';

import { formatUSD, joinIdNameObj, joinIfIdNameObj } from 'utils/formatting';
import { routes } from 'utils/routes';

import { RecommendedQuotesResponse, RecommendedQuote, RecommendedQuoteItem } from 'types/legacy-api';

import * as Styled from './RecommendedQuotesPage.style';

const rationaleColors: Obj<string> = {
  usage: 'orange',
  cadence: 'blue',
  seasonal: 'green',
  popular: 'red',
  default: 'purple',
};

const rationaleTooltip: Obj<string> = {
  usage:
    'This customer seems to use this item at a consistent rate. We predict that they will run out of their current stock soon.',
  cadence: 'This customer buys this item with a regular cadence. It is about time for them to reorder.',
  seasonal: 'This customer usually buys this item around this time of year.',
  popular: 'This item is popular with customers like this one',
  default: 'Based on similar customers, we think this is an item that this customer would be interested in buying.',
};

const SelectedSidebar = ({ recQuote }: { recQuote: RecommendedQuote }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  // select all items by default when recQuoteChanges
  useEffect(() => {
    setSelectedRowKeys(recQuote.items.map((line: RecommendedQuoteItem) => line.foreignId));
  }, [recQuote]);

  const itemTableColumns: ColumnType<RecommendedQuoteItem>[] = [
    {
      title: 'Item',
      render: (_, item: RecommendedQuoteItem) => (
        <Styled.TableCell>
          {item.name}
          <Styled.TableCellDetail>
            <Typography type="subtitle">
              <Link to={routes.sales.itemDetails(item.foreignId)}>{item.foreignId}</Link>
            </Typography>
          </Styled.TableCellDetail>
        </Styled.TableCell>
      ),
    },
    {
      title: 'Rationales',
      dataIndex: 'rationales',
      align: 'right',
      render: (rationales: RecommendedQuoteItem['rationales']) => (
        <Styled.TableCell>
          <Styled.TagContainer>
            {rationales.map((rationale) => (
              <Styled.RationaleTag key={rationale.tag} color={rationaleColors[rationale.tag]}>
                <Tooltip title={rationaleTooltip[rationale.tag]} placement="bottomRight">
                  {rationale.tag}
                </Tooltip>
              </Styled.RationaleTag>
            ))}
          </Styled.TagContainer>
        </Styled.TableCell>
      ),
    },
  ];
  const selectedRows = recQuote.items.filter((item) => selectedRowKeys.includes(item.foreignId));
  return (
    <>
      <Styled.Box>
        <Styled.BoxPadding>
          <Typography>
            Recommended Quote for: <br /> {recQuote.customer.name}
          </Typography>
        </Styled.BoxPadding>
        <Styled.ItemTable
          data={recQuote.items}
          columns={itemTableColumns}
          rowKey="foreignId"
          pagination={false}
          size="small"
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
        />
      </Styled.Box>
      <br />
      <Styled.Footer>
        <NewQuoteButton
          hashState={{
            customer: joinIfIdNameObj(recQuote.customer),
            shipTo: joinIfIdNameObj(recQuote.shipTo),
            location: joinIfIdNameObj(recQuote.location),
            items: selectedRows.map((item) => ({
              foreignId: item.foreignId,
              name: item.name,
              price: item.price,
              quantity: item.quantity,
              unitOfMeasure: item.uom,
            })),
          }}
          props={{ block: true }}
        />
      </Styled.Footer>
    </>
  );
};

export const RecommendedQuotesPage = () => {
  const [selectedRecQuote, setSelectedRecQuote] = useState<RecommendedQuote | undefined>();
  const [selectedRowIdx, setSelectedRowIdx] = useState<number | undefined>();

  const { data, error, isLoading } = useLegacyApi<RecommendedQuotesResponse>('/v3/recommended-quotes');

  // TODO: remove the filter once https://github.com/recurrency/ml/issues/129 is fixed
  // flatten and create list we're showing and filter out all quote without shiptos and customers (which shouldn't happen)
  const recQuotes = data
    ? data.items
        .map((quoteByCustomer) => quoteByCustomer.quotes)
        .flat()
        .filter((quote) => quote?.shipTo && quote?.customer)
    : null;

  const recQuotesColumns: ColumnType<RecommendedQuote>[] = [
    {
      title: '',
      align: 'right',
      render: (_: any, _recQuote: RecommendedQuote, rowIdx: number) => (
        // ant tables are weird, they make a new copy of the row so equality checks on selectedRow will fail
        // i.e recQuotesTableRows?.findIndex((q) => q === selectedRecQuote) will always return -1
        // so instead we use selectedRowIdx to know the selectedRow
        <Styled.SelectionCell selected={selectedRowIdx === rowIdx}>&nbsp;</Styled.SelectionCell>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 300,
      render: (customer: RecommendedQuote['customer']) => (
        <Styled.TableCell>
          <span>
            <Link to={routes.sales.customerDetails(customer.foreignId)}>{customer.foreignId}</Link>
            {': '}
            {customer.name}
          </span>
        </Styled.TableCell>
      ),
    },
    {
      title: 'Ship To',
      dataIndex: 'shipTo',
      render: (shipTo: RecommendedQuote['shipTo']) => (
        <Styled.TableCell>
          {joinIdNameObj(shipTo)}
          <Styled.TableCellDetail>
            <Typography type="subtitle">{shipTo.address}</Typography>
          </Styled.TableCellDetail>
        </Styled.TableCell>
      ),
    },
    {
      title: <Tooltip title="These are the items that we think this customer will reorder">Reorder Items</Tooltip>,
      dataIndex: 'items',
      align: 'right',
      render: (items: RecommendedQuote['items']) => <Styled.TableCell>{items.length}</Styled.TableCell>,
    },
    {
      title: 'Predicted Value',
      dataIndex: 'potentialValue',
      align: 'right',
      render: (potentialValue: RecommendedQuote['potentialValue']) => (
        <Styled.TableCell>{formatUSD(potentialValue)}</Styled.TableCell>
      ),
    },
  ];

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !recQuotes) {
    return <CenteredLoader />;
  }

  return (
    <Container style={{ marginTop: '24px' }}>
      <PageHeading>
        <InfoTooltip title="Opportunities leverages our intelligence platform to recommend quotes throughout the week. It suggests not only which customers to talk to but also which specific items to consider selling to them.">
          Opportunities
        </InfoTooltip>
      </PageHeading>
      <br />
      <SplitPage
        left={
          <Styled.HeaderTable
            data={recQuotes}
            columns={recQuotesColumns}
            size="small"
            rowKey=""
            onRow={(recQuote: RecommendedQuote, rowIdx: number) => ({
              onClick: () => {
                setSelectedRowIdx(rowIdx);
                setSelectedRecQuote(recQuote);
              },
            })}
          />
        }
        right={
          selectedRecQuote !== undefined ? (
            <SelectedSidebar recQuote={selectedRecQuote} />
          ) : (
            <Styled.Box>
              <Styled.BoxPadding>Select an opportunity to view line items.</Styled.BoxPadding>
            </Styled.Box>
          )
        }
      />
    </Container>
  );
};
