import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form, message, Steps, Tooltip } from 'antd';

import { Button } from 'components/Button';
import { responsiveFormLayout } from 'components/FormItems';
import { Container } from 'components/Layout/Container';
import { PageHeader } from 'components/PageHeader';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { objMapValues, objPickKeys } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { UNITS } from 'constants/styles';

import { RECURRENCY_RED } from 'settings/theme/colors';

import { ProspectHashState, ProspectStep } from 'types/hash-state';

import { ProspectContactStep } from './ProspectContactStep';
import { ProspectCustomerStep } from './ProspectCustomerStep';
import { ProspectReviewStep } from './ProspectReviewStep';
import { prospectRequestFromHashState } from './prospectUtils';

export const ProspectNewPage = () => {
  const [prospectState, updateProspectState] = useHashState<ProspectHashState>();
  const [validationErrorMsg, setValidationErrorMsg] = useState('');

  const history = useHistory();

  const [form] = Form.useForm<ProspectHashState>();

  const [isProspectSubmitting, setIsProspectSubmitting] = useState(false);
  const { activeTenant } = useGlobalApp();
  const tenantId = activeTenant?.id;

  const handleProspectSubmit = async () => {
    try {
      setIsProspectSubmitting(true);

      await form.validateFields();

      const prospectRequest = prospectRequestFromHashState(prospectState, tenantId);
      const response = await api()
        .customers()
        .createCustomer({
          ...prospectRequest,
        });
      message.success(
        `Prospect submitted as number ${response.data?.foreignId}, it may take up to five minutes to sync.`,
        // Last for 6 seconds to allow for copy pasting
        6,
      );
      track(TrackEvent.Customers_NewProspect_Submit, {});
      history.push(routes.sales.customerList());
    } catch (err) {
      captureAndShowError(err, `creating prospect`);
    } finally {
      setIsProspectSubmitting(false);
    }
  };

  useEffect(() => {
    // explicit pick, so undefined values are explicitly set, and override internal form state
    const fieldsValue = objPickKeys(
      prospectState,
      'customerName',
      'physical-address1',
      'physical-address2',
      'physical-city',
      'physical-state',
      'physical-zipcode',
      'mailing-address1',
      'mailing-address2',
      'mailing-state',
      'mailing-city',
      'mailing-zipcode',
      'customerPhone',
      'customerFax',
      'customerEmail',
      'salesRep',
      'company',
      'addressesSame',
      'contactFirstName',
      'contactLastName',
      'contactTitle',
      'contactPhone',
      'contactFax',
      'contactEmail',
      'makeShipTo',
      'shipToPreferredLocation',
      'shipToDefaultFreight',
      'shipToDefaultBranch',
    );
    // use prospectState as the source of state
    form.setFieldsValue(fieldsValue);
  }, [form, prospectState]);
  const { step: currentStep = ProspectStep.Customer } = prospectState;

  useEffect(() => {
    track(TrackEvent.Customers_NewProspect_StepChange, { step: ProspectStep[currentStep] });
  }, [currentStep]);

  const canGoToStep = useCallback(
    async (step: ProspectStep): Promise<boolean> => {
      if (step > ProspectStep.Customer) {
        try {
          // antd Form makes you jumps through async try/catch hoop just to know whether a form is valid or not
          await form.validateFields();
        } catch (err) {
          setValidationErrorMsg('Please ensure that all required customer fields are filled.');
          return false;
        }
      }
      setValidationErrorMsg('');
      return true;
    },
    [form],
  );

  const isCurrentStepValid = useCallback(
    async (): Promise<boolean> => canGoToStep(currentStep + 1),
    [canGoToStep, currentStep],
  );

  useEffect(() => {
    // Clear error message immediately when user has fixed the errors
    if (validationErrorMsg !== '') {
      isCurrentStepValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectState, validationErrorMsg]);

  const goToStep = async (step: ProspectStep) => {
    // don't go to next step, until every previous state is valid
    if (step >= currentStep && !(await canGoToStep(step))) {
      return;
    }
    updateProspectState({ step });
  };

  return (
    <Container>
      <PageHeader title="New Prospect" noColon />
      <Steps current={currentStep} style={{ marginTop: UNITS.XL }} onChange={(newStep) => goToStep(newStep)}>
        {['Customer', 'Contact', 'Review'].map((step) => (
          <Steps.Step key={step} title={step} />
        ))}
      </Steps>
      <Form
        name="prospectForm"
        form={form}
        onFinishFailed={console.error}
        onValuesChange={(newState: ProspectHashState) =>
          updateProspectState(objMapValues(newState, (value) => (value === '' ? undefined : value)))
        }
        {...responsiveFormLayout}
        style={{ paddingTop: '30px' }}
      >
        {currentStep === ProspectStep.Customer && (
          <ProspectCustomerStep prospectState={prospectState} onProspectStateChange={updateProspectState} />
        )}
        {currentStep === ProspectStep.Contact && <ProspectContactStep prospectState={prospectState} />}

        {currentStep === ProspectStep.Review && <ProspectReviewStep prospectState={prospectState} />}
      </Form>
      {validationErrorMsg && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 16,
            color: RECURRENCY_RED,
          }}
        >
          {validationErrorMsg}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          padding: '0 8px',
        }}
      >
        <Button onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 0}>
          <LeftOutlined />
          Previous
        </Button>
        {currentStep === ProspectStep.Review ? (
          <Tooltip title="This will lock the prospect in Recurrency. Once sent to your primary ERP, you will no longer be able to edit it here.">
            <Button type="primary" onClick={handleProspectSubmit} loading={isProspectSubmitting} htmlType="submit">
              Send to ERP
            </Button>
          </Tooltip>
        ) : (
          <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
            Next
            <RightOutlined />
          </Button>
        )}
      </div>
    </Container>
  );
};
