import React from 'react';

import { Descriptions } from 'antd';

import { DescriptionsContainer } from 'components/DescriptionsContainer';
import { linkableAddress } from 'components/Links';

import { formatAddress } from 'utils/formatting';

export const AddressBar = ({ hdr }: { hdr: any }) => {
  const shippingAddr = formatAddress(hdr.ship2Add1, hdr.ship2City, hdr.ship2State, hdr.ship2Zip);

  const shipping = (
    <div style={{ fontSize: '16px' }}>
      {hdr?.ship2Name && <div>{linkableAddress(shippingAddr, hdr.ship2Name)}</div>}
      {hdr?.ship2Add1 && <div>{linkableAddress(shippingAddr, hdr.ship2Add1)}</div>}
      {hdr?.ship2Add2 && <div>{hdr.ship2Add2}</div>}
      {hdr?.ship2Add3 && <div>{hdr.ship2Add3}</div>}
      {hdr?.ship2City && hdr.ship2State && hdr.ship2Zip && (
        <div>{linkableAddress(shippingAddr, `${hdr.ship2City}, ${hdr.ship2State} ${hdr.ship2Zip}`)}</div>
      )}
      {hdr?.ship2Country && <div>{hdr.ship2Country}</div>}
    </div>
  );

  const requester = (
    <div style={{ fontSize: '16px' }}>
      {hdr?.contactsName} (#{hdr?.contactsId})
    </div>
  );

  return (
    <DescriptionsContainer bordered>
      <Descriptions.Item key="shipping" label="Ship To" span={1}>
        {shipping}
      </Descriptions.Item>
      <Descriptions.Item key="requester" label="Requested By" span={1}>
        {requester}
      </Descriptions.Item>
    </DescriptionsContainer>
  );
};
