import { Squash } from "hamburger-react";
import styled from "styled-components";
export const Container = styled.div`
  width: ${(props) => props.isDesktopView ? `calc(100% - ${props.theme.secondaryNavigationWidth}px)` : "100%"};
  height: ${(props) => props.theme.tertiaryNavigationHeight}px;
  position: fixed;
  top: ${(props) => props.theme.primaryNavigationHeight}px;
  left: ${(props) => props.isDesktopView ? props.theme.secondaryNavigationWidth : 0}px;
  right: 0;
  z-index: 1;
  ${(props) => !props.isDesktopView && "overflow-x: scroll"};

  transition: ${(props) => props.theme.transition(0.05)};
  background: ${(props) => props.theme.colors.newWhite};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 ${(props) => props.theme.units.LG}px;
  border-bottom: ${(props) => props.theme.units.XS}px solid ${(props) => props.theme.colors.newBlueGray100};
`;
export const SubItem = styled.a`
  -webkit-font-smoothing: antialiased;
  height: 100%;
  text-transform: uppercase;
  margin: 0 ${(props) => props.theme.units.LG}px;
  display: flex;
  letter-spacing: 0.05em;
  font-size: 0.9em;
  align-items: center;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.newBlueGray900};
  opacity: 1;
  border: 0;
  transform: translate(0, ${(props) => props.theme.units.XS}px);
  border-bottom: ${(props) => props.theme.units.XS}px solid
    ${(props) => props.selected ? props.theme.colors.newBlueGray900 : props.theme.colors.newBlueGray100};
  cursor: pointer;
  transition: ${(props) => props.theme.transition(0.05)};

  :hover {
    border-color: ${(props) => props.theme.colors.newBlueGray900};
  }
`;
export const HamburgerWrapper = styled.div`
  margin-top: -4px;
  margin-left: -16px;

  > div {
    height: 42px !important;
  }
`;
export const Hamburger = styled(Squash).attrs((props) => ({
  color: props.theme.colors.newBlueGray900,
  size: 10,
  duration: 0.2
}))``;
