import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { isPurchaserRole, shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePreviousVal } from 'hooks/usePreviousVal';

import { dashCaseToTitleCase } from 'utils/formatting';
import { perfMark, PerfMarkName, trackPageLoadMetrics } from 'utils/perfMeasure';
import { decodePercentChars, parsePath, routes, useHashState } from 'utils/routes';
import { setSuperProps, track, TrackEvent } from 'utils/track';

import { NavTabHashState } from 'types/hash-state';

import * as Styled from './Navigation.style';
import { navModules } from './navModulesData';
import { PrimaryNavigation } from './PrimaryNavigation';
import { SecondaryNavigation } from './SecondaryNavigation';
import { TertiaryNavigation } from './TertiaryNavigation';
import { NavModule, NavSubModule } from './types';

export interface NavigationProps {
  children: JSX.Element;
  isDesktopView: boolean;
}

export const Navigation = ({ children, isDesktopView }: NavigationProps) => {
  const history = useHistory();
  const location = useLocation();
  const [hashState] = useHashState<NavTabHashState>();
  const { tab: curNavTab } = hashState;
  const prevLocation = usePreviousVal(location);
  const prevNavTab = usePreviousVal(curNavTab);
  const { activeRole, activeTenant, activeUser } = useGlobalApp();
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const navModuleVisibility: Obj<boolean> = {
    // currently restricted to recurrency admins for demo sake
    // when feature is ready, we should turn it into feature flag and change this logic to isAdminRole(activeRole)
    admin: !!activeUser?.isRecurrencyAdmin,
    purchasing: isPurchaserRole(activeRole),
    internal: !!activeUser?.isRecurrencyAdmin,
  };

  const curPath = location.pathname;
  const pathParseResult = useMemo(() => parsePath(curPath), [curPath]);
  let { moduleSlug: curModuleSlug, subModuleSlug: curSubModuleSlug } = pathParseResult;

  if (!curModuleSlug) {
    curModuleSlug = navModules[0].slug;
  }
  const curNavModule = navModules.find((module) => module.slug === curModuleSlug);
  if (!curSubModuleSlug && curNavModule) {
    curSubModuleSlug = curNavModule.subModules[0].slug;
  }

  useEffect(() => {
    // track page load metrics after nav shell loads
    perfMark(PerfMarkName.NavShellRender);
    trackPageLoadMetrics();
  }, []);

  if (location.pathname !== prevLocation?.pathname) {
    // set curPath and curRoutePath as super props so every event gets it
    const curRoutePath = pathParseResult.routePath;
    setSuperProps({ curPath, curRoutePath });

    track(TrackEvent.Nav_HistoryChange, {
      prevPath: prevLocation?.pathname,
    });
  } else if (curNavTab && prevNavTab && curNavTab !== prevNavTab) {
    track(TrackEvent.Nav_TabChange, {
      tab: curNavTab,
    });
  }

  // Add pathname to title so when user goes to their browser history, or long clicks back button
  // they see meaningful names (and can go back to a previously saved state)
  useEffect(() => {
    const { pathSlashParts } = pathParseResult;
    if (pathParseResult.tenantSlug) {
      // skip tenantSlug in tab name breadcrumbs, it's not relevant
      pathSlashParts.shift();
    }

    const titleFromPathname = pathSlashParts
      .map((part) => dashCaseToTitleCase(decodeURIComponent(decodePercentChars(part))))
      .join(' › ');
    document.title = `${titleFromPathname} | Recurrency`;
  }, [pathParseResult]);

  const goToRoute = useCallback(
    (route?: string) => {
      if (route) {
        history.push(route);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsHamburgerOpen(false);
      }
    },
    [history],
  );

  const handleLogoClick = useCallback(() => {
    history.push(routes.home());
  }, [history]);

  const handleModuleSelect = useCallback(
    (navModule: NavModule) => {
      const { routeFn, subModules } = navModule;

      if (routeFn) {
        goToRoute(routeFn());
      } else if (subModules && subModules.length > 0) {
        goToRoute(
          subModules
            .filter((option) =>
              option.featureFlag ? shouldShowFeatureFlag(activeTenant, activeUser, option.featureFlag) : true,
            )[0]
            .routeFn(),
        );
      }
    },
    [activeTenant, activeUser, goToRoute],
  );

  const handleSubModuleSelect = useCallback(
    (navSubModule: NavSubModule) => {
      if (navSubModule?.routeFn) {
        goToRoute(navSubModule?.routeFn());
      }
    },
    [goToRoute],
  );

  const toggleHamburger = useCallback(() => {
    setIsHamburgerOpen(!isHamburgerOpen);
  }, [isHamburgerOpen]);

  return (
    <Styled.Container>
      <PrimaryNavigation
        isDesktopView={isDesktopView}
        onLogoClick={handleLogoClick}
        itemVisibility={navModuleVisibility}
      />
      <Styled.Splitter isDesktopView={isDesktopView}>
        <SecondaryNavigation
          isDesktopView={isDesktopView}
          navModuleVisibility={navModuleVisibility}
          curModuleSlug={curModuleSlug}
          onModuleSelect={handleModuleSelect}
          isHamburgerOpen={isHamburgerOpen}
        />
        <Styled.ContentWrapper isDesktopView={isDesktopView}>
          <TertiaryNavigation
            isDesktopView={isDesktopView}
            curNavModule={curNavModule}
            curSubModuleSlug={curSubModuleSlug}
            onSubModuleSelect={handleSubModuleSelect}
            isHamburgerOpen={isHamburgerOpen}
            toggleHamburger={toggleHamburger}
          />
          <Styled.Content isDesktopView={isDesktopView}>{children}</Styled.Content>
        </Styled.ContentWrapper>
      </Styled.Splitter>
    </Styled.Container>
  );
};
