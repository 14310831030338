import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { formatUSD } from 'utils/formatting';
import { encodeLegacyApiParam, routes } from 'utils/routes';
import { sortableNumberColumn, sortableStringColumn, idColumn } from 'utils/tables';

export const POLines = ({ vendorId, open }: { vendorId: string | undefined; open?: boolean }) => {
  const columns: ColumnType<FIXME>[] = [
    sortableStringColumn({
      title: 'PO No',
      dataIndex: 'poNo',
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
    }),
    idColumn({
      title: 'Location',
      dataIndex: 'location',
      render: (location: FIXME) => `${location.locationId}: ${location.locationName}`,
    }),
    idColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (id: string) => <Link to={routes.purchasing.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
    }),
    sortableNumberColumn({
      title: 'Qty',
      dataIndex: 'qtyOrdered',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    {
      title: 'Cost',
      dataIndex: 'cost',
      render: (_: string, { unitPrice, qtyOrdered }: { unitPrice: number; qtyOrdered: number }) =>
        formatUSD(unitPrice * qtyOrdered, true),
    },
    {
      render: ({ poNo }: { poNo: string }) => ButtonLink(routes.purchasing.purchaseOrderDetails(poNo)),
    },
  ];
  // NOTE: rowKey is a function of various values to maximize uniqueness likelihood
  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/vendors/${encodeLegacyApiParam(vendorId || '')}/purchase-order-lines`,
        queryParams: open ? { open } : undefined,
      })}
      columns={columns}
      rowKey={({ poNo, itemId }: { poNo: string; itemId: string }) => `${poNo}-${itemId}`}
    />
  );
};
