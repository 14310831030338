import React, { useEffect, useState } from 'react';

import { CreateTaskRequest } from '@recurrency/core-api';
import { message } from 'antd';
import moment from 'moment';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Select, SelectOption } from 'components/Select';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes } from 'utils/routes';

interface PostEntry {
  title: string;
  type: string;
  customer: { name: string; foreignId: string };
  items: FIXME[];
}

export const ModalAssignee = ({
  title,
  postSetAssignee,
}: {
  title: string;
  postSetAssignee: (assignee: { key: string; value?: string }) => void;
}) => {
  const { activeUser, activeTenant } = useGlobalApp();
  const { id: activeUserId } = activeUser;
  const [selectedValue, setSelectedValue] = useState(activeUserId);

  // bind initial default to external state
  // NOTE: This is hacky, we gotta revamp the how we do select interfaces throughout the app
  useEffect(() => {
    postSetAssignee({ key: selectedValue });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isLoading } = useApi().tenants().getUsers(activeTenant?.id);
  const selectOptions: SelectOption[] = (data?.items || []).map((tenantUser) => ({
    value: tenantUser.user.id,
    label: `${tenantUser.user.firstName} ${tenantUser.user.lastName}`,
  }));

  return (
    <div>
      <div>{title}</div>
      <br />
      <div>
        <Select
          style={{ display: 'block' }}
          showSearch
          value={selectedValue}
          // @ts-expect-error option isn't React.Node
          filterOption={(inputValue, option: SelectOption) =>
            typeof option.label === 'string' &&
            option.label.normalize().toLowerCase().includes(`${inputValue}`.normalize().toLowerCase())
          }
          onSelect={(value) => {
            setSelectedValue(value);
          }}
          isLoading={isLoading}
          placeholder="Search for an assignee..."
          options={selectOptions}
        />
      </div>
      <br />
    </div>
  );
};

const CreateTaskButton = ({
  style,
  onClick,
  disabled,
  buttonMessage,
  buttonText,
}: {
  style: React.CSSProperties;
  onClick: () => void;
  disabled: boolean;
  buttonMessage: string | boolean;
  buttonText: string;
}) => (
  <>
    <Button id="createTaskButton" type="primary" style={style} onClick={onClick} disabled={disabled}>
      {buttonText}
    </Button>
    {buttonMessage && <span style={{ marginLeft: 8 }}>{buttonMessage}</span>}
  </>
);

const ModalBody = ({
  postBody,
  postSetAssignee,
}: {
  postBody: PostEntry[];
  postSetAssignee: (index: number) => FIXME;
}) => (
  <>
    {postBody.map((entry, index) => (
      <ModalAssignee
        key={entry.customer.foreignId}
        title={`Task for ${entry.customer.name}`}
        postSetAssignee={postSetAssignee(index)}
      />
    ))}
  </>
);

export const CreateTask = ({
  disabled = false,
  postSubmit,
  style,
  hasSelected,
  numberOfSelected,
  postBody,
}: {
  disabled: boolean;
  postSubmit: () => void;
  style: React.CSSProperties;
  hasSelected: boolean;
  numberOfSelected: number;
  postBody: PostEntry[];
}) => {
  const { activeTenant } = useGlobalApp();
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [assignees, setAssignees] = useState<{ key: string; value: string }[]>([]);

  const plural = postBody.length > 1 ? 's' : '';

  const postSetAssignee = (index: number) => (assignee: { key: string; value: string }) => {
    // we have to use functional setter since react will only call the component function once per raf
    // this means we'll have a race condition when all the assignees are set
    setAssignees((curAssignees) => {
      const assigneesClone = [...curAssignees];
      assigneesClone[index] = assignee;
      return assigneesClone;
    });
  };

  const isOkButtonDisabled = assignees.length !== postBody.length;

  const onSubmit = async () => {
    try {
      const promises = [];
      for (let postIdx = 0; postIdx < postBody.length; postIdx++) {
        const request: CreateTaskRequest = {
          ...postBody[postIdx],
          assigneeUserId: assignees[postIdx].key,
          body: `Automatically generated on ${moment().format('YYYY-MM-DD')}`,
          dueAt: moment().add(7, 'd').format('YYYY-MM-DD'),
          tenantId: activeTenant.id,
        };
        promises.push(api().tasks().createTask(request));
      }
      // TODO: fix when fixing <void> return type of api().tasks().createTask(request)
      const results = await Promise.all(promises);
      if (results.length === 1) {
        const { data }: { data: FIXME } = results[0];
        message.success(<a href={routes.tasks.taskDetails(data.id)}>{`Task ${data.title} created.`}</a>);
      } else {
        message.success(<a href={routes.tasks.taskList()}>New tasks created.</a>);
      }
    } catch (err) {
      captureAndShowError(err, `creating task${plural}`);
    }
  };

  const buttonMessage = hasSelected && `Selected ${numberOfSelected} item${numberOfSelected > 1 ? 's' : ''}`;
  const buttonText = `New Task${plural}`;
  const titleText = `Assign Task${plural}`;

  return (
    <>
      <CreateTaskButton
        disabled={disabled}
        style={style}
        onClick={() => {
          setIsAssigneeModalOpen(true);
        }}
        buttonMessage={buttonMessage}
        buttonText={buttonText}
      />
      <Modal
        title={titleText}
        centered
        visible={isAssigneeModalOpen}
        okText={buttonText}
        okButtonProps={{ disabled: isOkButtonDisabled }}
        onOk={() => {
          onSubmit();
          setIsAssigneeModalOpen(false);
          postSubmit();
        }}
        onCancel={() => setIsAssigneeModalOpen(false)}
      >
        <ModalBody postBody={postBody} postSetAssignee={postSetAssignee} />
      </Modal>
    </>
  );
};
