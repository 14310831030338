import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  GiftOutlined,
  IdcardOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { TenantFeature } from '@recurrency/core-api';
import { Menu, Dropdown } from 'antd';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { Button } from 'components/Button';
import { GlobalSearch } from 'components/GlobalSearch/GlobalSearch';
import { DropdownMenu } from 'components/Layout/DropdownMenu';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { setLocalStorageItem, LocalStorageKey } from 'utils/localStorage';
import { roleToString, reloadPageWithTenantSlug } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import * as Styled from './PrimaryNavigation.style';

export interface PrimaryNavigationProps {
  isDesktopView: boolean;
  onLogoClick: () => void;
  itemVisibility: Obj<boolean>;
}

export const PrimaryNavigation = ({ isDesktopView, onLogoClick, itemVisibility }: PrimaryNavigationProps) => {
  const { tenants, activeTenant, activeRole, roles, activeUser } = useGlobalApp();
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const shouldShowNonSearch = isDesktopView || !isMobileSearchOpen;

  return (
    <Styled.Container data-test-id="primaryNavContainer">
      <Styled.Content isDesktopView={isDesktopView}>
        <Styled.ContentChild isDesktopView={isDesktopView} flex={0}>
          {shouldShowNonSearch && (
            <Styled.LogoWrapper onClick={onLogoClick}>
              {isDesktopView ? <Styled.Logo /> : <Styled.MobileLogo />}
            </Styled.LogoWrapper>
          )}
          {tenants && activeTenant && tenants.length > 1 && shouldShowNonSearch && (
            <Styled.DropdownWrapper>
              <Dropdown
                overlay={
                  <Menu>
                    {tenants.map((tenant) => (
                      <Menu.Item
                        key={tenant.id}
                        onClick={() => {
                          reloadPageWithTenantSlug(tenant.slug);
                        }}
                      >
                        {!isDesktopView && tenant.id === activeTenant.id && <CheckOutlined />}
                        {tenant.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button size="small">
                  <BankOutlined />
                  {isDesktopView && ` ${activeTenant.name} `}
                  {isDesktopView && <DownOutlined />}
                </Button>
              </Dropdown>
            </Styled.DropdownWrapper>
          )}
          {roles && activeRole && roles.length > 1 && shouldShowNonSearch && (
            <Styled.DropdownWrapper>
              <Dropdown
                overlay={
                  <Menu>
                    {roles.map((role, idx) => (
                      <Menu.Item
                        key={idx}
                        onClick={() => {
                          setLocalStorageItem(LocalStorageKey.ActiveRole, role);
                          window.location.reload();
                        }}
                      >
                        {!isDesktopView && role.foreignId === activeRole.foreignId && <CheckOutlined />}
                        {roleToString(role)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button size="small">
                  <IdcardOutlined />
                  {isDesktopView && ` ${roleToString(activeRole)} `}
                  {isDesktopView && <DownOutlined />}
                </Button>
              </Dropdown>
            </Styled.DropdownWrapper>
          )}
        </Styled.ContentChild>
        <Styled.ContentChild isDesktopView={isDesktopView} isMobileSearchOpen={isMobileSearchOpen} flex={1}>
          <Styled.SearchInput isDesktopView={isDesktopView}>
            {(isDesktopView || isMobileSearchOpen) && <GlobalSearch isDesktopView={isDesktopView} />}
            {!isDesktopView &&
              (isMobileSearchOpen ? (
                <Styled.LeftPadButton>
                  <Button size="small" onClick={() => setIsMobileSearchOpen(false)}>
                    <CloseOutlined />
                  </Button>
                </Styled.LeftPadButton>
              ) : (
                <Button size="small" onClick={() => setIsMobileSearchOpen(true)}>
                  <SearchOutlined />
                </Button>
              ))}
          </Styled.SearchInput>
          {shouldShowNonSearch && (
            <Styled.CreateButton>
              <Dropdown
                overlay={
                  <Menu>
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateQuote) && (
                      <Menu.Item>
                        <Link
                          to={routes.orders.quoteNew()}
                          onClick={() =>
                            track(TrackEvent.Nav_CtaClick, {
                              ctaName: CtaName.NewQuote,
                              ctaType: CtaType.PrimaryNavNewRecord,
                            })
                          }
                        >
                          Quote
                        </Link>
                      </Menu.Item>
                    )}
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateOrder) && (
                      <Menu.Item>
                        <Link
                          to={routes.orders.orderNew()}
                          onClick={() =>
                            track(TrackEvent.Nav_CtaClick, {
                              ctaName: CtaName.NewOrder,
                              ctaType: CtaType.PrimaryNavNewRecord,
                            })
                          }
                        >
                          Order
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <Link
                        to={routes.tasks.taskNew()}
                        onClick={() =>
                          track(TrackEvent.Nav_CtaClick, {
                            ctaName: CtaName.NewTask,
                            ctaType: CtaType.PrimaryNavNewRecord,
                          })
                        }
                      >
                        Task
                      </Link>
                    </Menu.Item>
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.SalesCreateProspect) && (
                      <Menu.Item>
                        <Link
                          to={routes.sales.customerNew()}
                          onClick={() =>
                            track(TrackEvent.Nav_CtaClick, {
                              ctaName: CtaName.NewProspect,
                              ctaType: CtaType.PrimaryNavNewRecord,
                            })
                          }
                        >
                          Prospect
                        </Link>
                      </Menu.Item>
                    )}
                    {itemVisibility && itemVisibility.purchasing && <Menu.Item disabled>Purchase</Menu.Item>}
                  </Menu>
                }
              >
                <Button size="small">
                  <PlusOutlined />
                  {isDesktopView && ' New Record'}
                  {isDesktopView && <DownOutlined />}
                </Button>
              </Dropdown>
            </Styled.CreateButton>
          )}
        </Styled.ContentChild>
        {shouldShowNonSearch && (
          <Styled.ContentChild isDesktopView={isDesktopView} flex={0}>
            <GiftOutlined className="headwayapp-badge" />
            <Styled.MenuItem selected={false}>
              <Dropdown
                arrow
                placement="bottomRight"
                overlay={
                  <Menu>
                    <DropdownMenu />
                  </Menu>
                }
              >
                <Styled.MenuItemChild>
                  <Styled.Icon>
                    <Styled.Avatar icon={<Styled.UserProfileIcon />} />
                  </Styled.Icon>
                  {isDesktopView && <Styled.Label>{activeUser?.fullName || 'Profile'}</Styled.Label>}
                  {isDesktopView && (
                    <Styled.SmallIcon>
                      <DownOutlined />
                    </Styled.SmallIcon>
                  )}
                </Styled.MenuItemChild>
              </Dropdown>
            </Styled.MenuItem>
          </Styled.ContentChild>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
