import React, { useState } from 'react';

import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { Card } from 'components/Card';

interface FacetCardProps {
  title: string;
  show?: boolean;
  children: React.ReactNode;
}

export function FacetCard({ title, children, show = true }: FacetCardProps) {
  const [showChildren, setShowChildren] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <Card
      title={title}
      extra={
        showChildren ? (
          <DownOutlined onClick={() => setShowChildren(false)} />
        ) : (
          <RightOutlined onClick={() => setShowChildren(true)} />
        )
      }
      bodyStyle={{
        display: showChildren ? 'inherit' : 'none',
      }}
      style={{ marginBottom: 20 }}
    >
      {children}
    </Card>
  );
}
