import { Row as AntRow } from "antd";
import styled from "styled-components";
export const Row = styled(AntRow)`
  margin-top: 2rem;

  justify-content: center;
  align-items: center;
`;
export const SearchInput = styled.div`
  flex: 1;
`;
