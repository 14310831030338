import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { CreateTask } from 'components/CreateTask';
import { ButtonLink } from 'components/Links';

import { formatPercent } from 'utils/formatting';
import { encodeLegacyApiParam, routes } from 'utils/routes';
import { sortableStringColumn, idColumn } from 'utils/tables';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { UNITS } from 'constants/styles';

export const RecommendedCustomers = ({
  itemId,
  itemDesc,
}: {
  itemId: string | undefined;
  itemDesc: string | undefined;
}) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Customer ID',
      dataIndex: 'customerId',
      render: (customerId: any) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customerName',
      render: (customerName: any, record: any) => (
        <Link to={routes.sales.customerDetails(record.customerId)}>{customerName}</Link>
      ),
    }),
    {
      title: 'Match %',
      dataIndex: 'rank',
      render: (rank: any) => formatPercent(rank),
      sorter: (a: any, b: any) => a.rank - b.rank,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
    },
    {
      render: ({ customerId }) => ButtonLink(routes.sales.customerDetails(customerId)),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys]: any = useState([]);
  const [selectedRows, setSelectedRows]: any = useState([]);

  const postSubmit = async () => {
    track(TrackEvent.Nav_CtaClick, {
      ctaName: CtaName.NewTask,
      ctaType: CtaType.Button,
    });
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows.map((row: any) => ({ id: row.customerId, name: row.customerName })));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <CreateTask
        style={{ marginBottom: UNITS.LG }}
        disabled={!hasSelected}
        postBody={selectedRows.map((row: any) => ({
          title: `Customer Recommendations for ${itemDesc}`,
          customer: {
            name: row.name,
            foreignId: row.id,
          },
          type: 'customer',
        }))}
        postSubmit={postSubmit}
        hasSelected={hasSelected}
        numberOfSelected={selectedRowKeys.length}
      />
      <AsyncTable
        rowSelection={rowSelection}
        rowKey="customerId"
        tableProps={useLegacyApiTableProps({
          apiPath: `/v3/items/${encodeLegacyApiParam(itemId || '')}/recommendations/customers`,
        })}
        columns={columns}
      />
    </div>
  );
};
