import React from 'react';

import { Form, message } from 'antd';

import { Button } from 'components/Button';
import { InputFormItem } from 'components/FormItems';
import { Modal } from 'components/Modal';

import { legacyApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

export const NewContactModal = ({
  isOpen,
  onCancel,
  customer,
  customerId,
}: {
  isOpen: boolean;
  onCancel: FIXME;
  customer?: FIXME;
  customerId?: FIXME;
}) => {
  const [form] = Form.useForm();

  const onSubmit = async (data: FIXME) => {
    try {
      await legacyApiFetch('/contacts', {
        method: 'POST',
        data: {
          customer: customerId,
          firstName: data.firstName,
          lastName: data.lastName,
          title: data.title,
          phone: data.phone,
          email: data.email,
        },
      });

      message.success('New contact created.');
      onCancel();
    } catch (err) {
      captureAndShowError(err, 'creating contact');
    }
  };

  return (
    <Modal
      visible={isOpen}
      title={`New Contact for ${customer}`}
      onCancel={onCancel}
      bodyStyle={{ minHeight: '30vh' }}
      centered
      footer={null}
    >
      <Form {...layout} name="contactForm" form={form} onFinish={onSubmit} onError={console.error}>
        <InputFormItem
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Please add a first name.' }]}
        />
        <InputFormItem
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please add a last name.' }]}
        />
        <InputFormItem label="Title" name="title" />
        <InputFormItem label="Phone" name="phone" />
        <InputFormItem label="Email" name="email" />
        <Form.Item {...tailLayout}>
          <Button key="submit" type="primary" htmlType="submit" style={{ marginRight: 10 }}>
            Create
          </Button>
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
