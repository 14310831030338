import { Layout as AntdLayout } from "antd";
import styled from "styled-components";
import { media } from "utils/responsive";
export const Layout = styled(AntdLayout)`
  max-width: 1600px;
  margin: 0 auto;
  background-color: white;

  padding-left: 1rem;
  padding-right: 1rem;

  ${media.sm`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}

  ${media.md`
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`;
export const Footer = styled(Layout.Footer)`
  background-color: white;
  text-align: center;
  margin-top: 4rem;
`;
