import { Avatar as AntAvatar } from "antd";
import styled from "styled-components";
import RecurrencyLogomark from "assets/svgs/logomark.svg";
import UserProfile from "assets/svgs/user-profile.svg";
import RecurrencyWordmark from "assets/svgs/wordmark.svg";
export const Container = styled.div`
  width: 100%;
  height: ${(props) => props.theme.primaryNavigationHeight}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  background-color: ${(props) => props.theme.colors.newBlueGray900};
  transition: ${(props) => props.theme.transition(0.05)};

  .headwayapp-badge {
    color: ${(props) => props.theme.colors.newBlueGray100};
    font-size: 1.25rem;
    position: relative;
    margin-right: ${(props) => props.theme.units.MD}px;
    cursor: pointer;
  }

  // see https://headway.gitbooks.io/docs/content/widget.html#styling-the-badge
  .HW_badge_cont {
    position: absolute;
    top: -0.9rem;
    left: 0.25rem;
  }

  .HW_badge {
    border: 3px solid #1e4158;
    height: 21px;
    width: 21px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  button {
    background: ${(props) => props.isDesktopView ? "inherit" : "rgba(255, 255, 255, 0.15)"};
    color: ${(props) => props.isDesktopView ? "inherit" : "white"};
    border: ${(props) => props.isDesktopView ? "inherit" : "none"};
    ${(props) => props.isDesktopView && `padding: 0 ${props.theme.units.MD}px`};
  }
`;
export const ContentChild = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.isDesktopView ? "center" : "flex-start"};
  flex: ${(props) => props.flex};
  padding: ${(props) => props.isMobileSearchOpen ? props.theme.units.MD : 0}px;
`;
export const Logo = styled(RecurrencyWordmark)`
  height: 27px;
`;
export const MobileLogo = styled(RecurrencyLogomark)`
  height: 27px;
`;
export const UserProfileIcon = styled(UserProfile)`
  width: ${(props) => props.theme.units.LG}px;
  height: ${(props) => props.theme.units.LG}px;
  fill: ${(props) => props.theme.colors.darkViolet};
`;
export const Avatar = styled(AntAvatar)`
  background-color: ${(props) => props.theme.colors.lightBlue};
`;
export const LogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${(props) => props.theme.units.LG}px;
  padding-right: ${(props) => props.theme.units.LG + props.theme.units.MD}px;
  height: 100%;
`;
export const Icon = styled.div`
  display: flex;
  width: ${(props) => props.theme.units.XXL}px;
  justify-content: center;
  align-self: center;
`;
export const SmallIcon = styled(Icon)`
  font-size: 0.6em;
`;
export const MenuItem = styled.div`
  cursor: pointer;
  padding: ${(props) => props.theme.units.MD}px;
`;
export const MenuItemChild = styled.div`
  color: ${(props) => props.theme.colors.newBlueGray100};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Label = styled.div`
  font-weight: 400;
  white-space: nowrap;
  margin-left: ${(props) => props.theme.units.MD}px;
`;
export const SearchInput = styled.div`
  flex: 1;
  max-width: ${(props) => props.isDesktopView ? "70" : "100"}%;
  display: flex;
  justify-content: flex-end;
`;
export const DropdownWrapper = styled.div`
  margin-right ${(props) => props.theme.units.MD}px;

  button {
    background: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
  }
`;
export const LeftPadButton = styled.div`
  margin-left ${(props) => props.theme.units.MD}px;
`;
export const CreateButton = styled(DropdownWrapper)`
  margin-left ${(props) => props.theme.units.MD}px;
  margin-right ${(props) => props.theme.units.LG}px;
`;
