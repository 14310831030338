import { DefaultTheme } from 'styled-components';

import { colors } from './colors';
import { units } from './spacing';
import { transition } from './utils';

export const PRIMARY_NAVIGATION_HEIGHT = 50;
export const SECONDARY_NAVIGATION_WIDTH = 56;
export const TERTIARY_NAVIGATION_HEIGHT = 40;

export const theme: DefaultTheme = {
  colors,
  units,
  transition,
  border: {
    width: '1px',
    radius: '8px',
  },
  primaryNavigationHeight: PRIMARY_NAVIGATION_HEIGHT,
  secondaryNavigationWidth: SECONDARY_NAVIGATION_WIDTH,
  tertiaryNavigationHeight: TERTIARY_NAVIGATION_HEIGHT,
};

export { colors, units };
