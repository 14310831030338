import { useCallback, useState } from 'react';

import { IdNameObj } from 'types/legacy-api';

import { captureError } from './error';

// 'rai:' prefixed keys are json encoded. We prefix so the keys don't collide with 3rd party keys.
export const enum LocalStorageKey {
  ActiveTenantSlug = 'rai:ActiveTenantSlug',
  ActiveRole = 'rai:ActiveRole',
  SelectedItemsForNewQuote = 'rai:SelectedItemsForNewQuote',
}

export interface TypedLocalStorage {
  [LocalStorageKey.ActiveTenantSlug]: string;
  [LocalStorageKey.ActiveRole]: IdNameObj;
  [LocalStorageKey.SelectedItemsForNewQuote]: IdNameObj[];
}

export function setLocalStorageItem<KeyT extends keyof TypedLocalStorage>(key: KeyT, value: TypedLocalStorage[KeyT]) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    // setItem could fail with QuotaExceededError if the storage is full
    captureError(err);
  }
}

export function getLocalStorageItem<KeyT extends keyof TypedLocalStorage>(key: KeyT): TypedLocalStorage[KeyT] | null {
  try {
    const jsonStr = localStorage.getItem(key);
    if (jsonStr) {
      return JSON.parse(jsonStr);
    }
  } catch (err) {
    // gracefully fail if json parsing fails
    captureError(err);
    localStorage.removeItem(key);
  }
  return null;
}

export function removeLocalStorageItem(key: keyof TypedLocalStorage) {
  localStorage.removeItem(key);
}

/** helper hook that keeps in-memory state in sync with localStorage state */
export function useLocalStorage<KeyT extends keyof TypedLocalStorage>(
  key: KeyT,
  defaultValue: TypedLocalStorage[KeyT],
): [TypedLocalStorage[KeyT], (value: TypedLocalStorage[KeyT]) => void] {
  const [itemState, setItemState] = useState<TypedLocalStorage[KeyT]>(() => getLocalStorageItem(key) ?? defaultValue);

  const setState = useCallback(
    (value: TypedLocalStorage[KeyT]) => {
      setItemState(value);
      setLocalStorageItem(key, value);
    },
    [key],
  );
  return [itemState, setState];
}
