import { Role, Tenant, User } from '@recurrency/core-api';
import { SearchClient } from 'algoliasearch';

import { StateStore } from 'utils/stateStore';

import { useStore } from './useStore';

export interface ExtendedUser extends User {
  fullName: string;
  isRecurrencyAdmin: boolean;
}

export interface GlobalAppState {
  activeUser: ExtendedUser;
  activeTenant: Tenant;
  activeRole: Role;
  accessToken: string;
  tenants: Tenant[];
  roles: Role[];
  searchClient: SearchClient;
  logout: () => void;
  loginWithRedirect: () => void;
}

// singleton global app state store
// the store values are assigned by Auth0Context
export const globalAppStore = new StateStore<GlobalAppState>({} as GlobalAppState);

export function useGlobalApp() {
  return useStore(globalAppStore);
}
