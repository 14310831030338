import React, { ReactElement } from 'react';

import { EntityDescriptions } from 'components/Descriptions';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { StatsGroup } from 'components/StatsGroup';
import { StatsProp } from 'components/StatsGroup/StatsGroup';

type Tab = {
  header: string;
  content: ReactElement;
};

type Props = {
  tabs: Tab[];
  prefix?: string;
  title?: string;
  subTitle?: string;
  showTitle?: boolean;
  descriptions?: ReactElement[];
  descriptionColumn?: number;
  stats?: StatsProp[];
  onBack?: () => void;
  backTitle?: string;
  copyable?: boolean;
  headerActions?: React.ReactNode;
};

export const DetailsFrame = ({
  tabs,
  prefix,
  title,
  subTitle,
  descriptions,
  descriptionColumn,
  stats,
  showTitle = true,
  onBack,
  backTitle,
  copyable,
  headerActions,
}: Props) => (
  <>
    {showTitle && !!title && (
      <PageHeader
        title={title}
        prefix={prefix}
        subTitle={subTitle}
        copyable={copyable}
        onBack={onBack}
        backTitle={backTitle}
        headerActions={headerActions}
      />
    )}
    <EntityDescriptions descriptions={descriptions} column={descriptionColumn} />
    {stats && (
      <div>
        <StatsGroup data={stats} copyable />
        <br />
      </div>
    )}
    <NavTabs tabs={tabs} />
  </>
);
