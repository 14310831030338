import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  sortableStringColumn,
  idColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
} from 'utils/tables';

export const OpenQuoteLines = ({ invMastUid }: { invMastUid: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Quote No',
      dataIndex: 'orderNo',
      render: (orderNo: string) => <Link to={routes.orders.orderDetails(orderNo)}>{orderNo}</Link>,
    }),
    sortableDateColumn({
      title: 'Quote Date',
      dataIndex: 'orderDate',
      defaultSortOrder: 'descend',
    }),
    idColumn({
      title: 'Customer ID',
      dataIndex: 'customerId',
      render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customerName',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'qtyOrdered',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    {
      render: ({ orderNo }: { orderNo: string }) => ButtonLink(routes.orders.orderDetails(orderNo)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(invMastUid || '')}/orders-quotes`,
        queryParams: { type: 'quote' },
      })}
      columns={columns}
    />
  );
};
