import React from 'react';

import { FormItemProps } from 'antd/lib/form/FormItem';

import { SelectFormItem } from 'components/FormItems/SelectFormItem';
import { SmallLoader } from 'components/Loaders';
import { SelectOption } from 'components/Select';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

export interface Props extends FormItemProps {
  label: string;
  name: string | number;
  initialValue: string;
  disabled?: boolean;
  placeholder?: string;
  setFieldsValue: (value: any) => void;
}

export const AssigneeSelectFormItem = ({
  label,
  name,
  rules,
  disabled,
  setFieldsValue,
  initialValue,
  placeholder,
}: Props): JSX.Element => {
  const { activeTenant } = useGlobalApp();
  const { data, isLoading } = useApi().tenants().getUsers(activeTenant?.id);

  const selectOptions: SelectOption[] = (data?.items || []).map((tenantUser) => ({
    value: tenantUser.user.id,
    label: `${tenantUser.user.firstName} ${tenantUser.user.lastName}`,
  }));

  return (
    <SelectFormItem
      showSearch
      placeholder={placeholder}
      label={label}
      name={name}
      options={selectOptions}
      notFoundContent={isLoading ? <SmallLoader /> : 'No assignees found'}
      isLoading={isLoading}
      disabled={disabled}
      rules={rules}
      setFieldsValue={setFieldsValue}
      initialValue={initialValue}
    />
  );
};
