import React from 'react';

import { SelectFormItem } from 'components/FormItems';

import { useLegacyApi } from 'hooks/useApi';

import { encodeLegacyApiParam } from 'utils/routes';

import { SmallLoader } from './Loaders';

const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

export const ContactsAutoComplete = ({
  customerId,
  name = 'contact',
  setIsCreateNewContactModalOpen,
  setFieldsValue,
  initialValue = '',
  disabled = false,
  ...otherProps
}: {
  customerId: string;
  name?: string;
  setIsCreateNewContactModalOpen?: FIXME;
  setFieldsValue: FIXME;
  initialValue?: string;
  disabled?: boolean;
}) => {
  const { data, error, isLoading } = useLegacyApi(`/v3/customers/${encodeLegacyApiParam(customerId)}/contacts`, {
    limit: 100,
    offset: 0,
  });

  const isDisabled = disabled || !!error || !data?.items;
  const contacts = data?.items ?? [];

  return (
    <SelectFormItem
      showSearch
      label="Contact"
      name={name}
      customHandlers={{
        [CREATE_NEW_CONTACT]: () => setIsCreateNewContactModalOpen(true),
      }}
      options={[
        ...contacts.map((contact: FIXME, index: FIXME) => ({
          key: `${contact.contactId}--${index}`,
          value: `${contact.contactId}: ${contact.firstName} ${contact.lastName}`,
        })),
      ]}
      allowClear
      notFoundContent={isLoading ? <SmallLoader /> : 'No contacts found'}
      isLoading={isLoading}
      disabled={isDisabled}
      setFieldsValue={setFieldsValue}
      initialValue={initialValue}
      {...otherProps}
    />
  );
};
