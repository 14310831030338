export type Environment = 'production' | 'staging' | 'development' | 'test';

export type Config = {
  apiUrl: string;
  pricingV4Origin: string;
  environment: Environment;
  authAudience: string;
  authDomain: string;
  authClientId: string;
  algoliaAppId: string;
  appVersion: string;
};

export const config: Config = {
  environment: process.env.APP_ENV as Environment,
  apiUrl: process.env.APP_API_URL as string,
  pricingV4Origin: process.env.PRICING_V4_ORIGIN as string,
  authDomain: process.env.APP_AUTH_DOMAIN as string,
  authAudience: process.env.APP_AUTH_AUDIENCE as string,
  authClientId: process.env.APP_AUTH_CLIENTID as string,
  algoliaAppId: process.env.APP_ALGOLIA_APP_ID as string,
  appVersion: process.env.APP_VERSION as string,
};
