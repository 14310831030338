import React, { useEffect, useState } from 'react';

import { DeleteOutlined, LinkOutlined, PercentageOutlined, PlusOutlined } from '@ant-design/icons';
import { Descriptions, Space, Switch } from 'antd';
import { ColumnType } from 'antd/lib/table';

import {
  getTotalPrice,
  getNumLineItems,
  validateGrossMargin,
  calcGM,
  calcPrice,
  QuoteLineItemWithInfo,
  getMacOrUnitCost,
  getUnitOfMeasure,
} from 'pages/orders/quotes/quoteUtils';

import { AsyncSelect } from 'components/AsyncSelect';
import { LabelContainer, LabelTitle, LabelSubtitle } from 'components/AsyncSelect/AsyncSelect.style';
import { useJobPricedItemsSelectProps, useItemsWithDescSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { DescriptionsContainer } from 'components/DescriptionsContainer';
import { Input } from 'components/Input';
import { ExternalLink } from 'components/Links';
import { Select, SelectOption } from 'components/Select';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import {
  formatDate,
  formatNumber,
  formatUSD,
  roundTo2Decimals,
  splitIfIdNameStr,
  joinIdNameObj,
} from 'utils/formatting';
import { objOmitKeys } from 'utils/object';
import { routes } from 'utils/routes';
import { typedColumn } from 'utils/tables';
import { isTenantCWC, isTenantIMS } from 'utils/tenants';
import { OrderType, ReplacePriceClickSource, ReplaceQuantityClickSource, track, TrackEvent } from 'utils/track';
import { priceUnitConverter, qtyUnitConverter } from 'utils/units';

import { RECURRENCY_RED, RECURRENCY_WARNING } from 'constants/styles';

import { QuoteEditHashState, QuoteLineItem, PricingHashState } from 'types/hash-state';
import { ItemJobPrice, ItemPriceInfo } from 'types/legacy-api';

import * as Styled from './QuoteLineItems.style';

export const QuoteLineItems = ({
  quoteState,
  lineItemsWithInfo,
  orderType,
  onLineItemsChange,
}: {
  quoteState: QuoteEditHashState;
  lineItemsWithInfo: QuoteLineItemWithInfo[];
  orderType: OrderType;
  onLineItemsChange: (lineItems: QuoteLineItem[], jobNumber?: string) => void;
}) => {
  const { activeTenant } = useGlobalApp();
  const [shouldShowDetails, setShouldShowDetails] = useState(true);

  const customer = splitIfIdNameStr(quoteState.customer);
  const shipTo = splitIfIdNameStr(quoteState.shipTo);
  const location = splitIfIdNameStr(quoteState.location);
  // GM is inferred from price and mac,
  // but until user presses enter while editing GM, we need to store the user entered temporary value
  const [tempGMValues, setTempGMValues] = useState<Obj<string>>({});

  const updateLineItem = (index: number, update: Partial<QuoteLineItem>, jobNumber?: string) => {
    const newLineItems = quoteState.items ? [...quoteState.items] : [];
    newLineItems[index] = { ...newLineItems[index], ...update };
    onLineItemsChange(newLineItems, jobNumber);
  };

  const addLineItem = () => {
    updateLineItem(lineItemsWithInfo.length, {});
  };

  const removeLineItem = (index: number) => {
    onLineItemsChange(quoteState.items!.filter((_, idx) => idx !== index));
  };

  const updateQuantity = (index: number, quantity: number | string, quantityType?: ReplaceQuantityClickSource) => {
    if (quantityType) {
      track(TrackEvent.Quotes_EditQuote_QuantityClick, { quantityType, orderType });
    }
    if (typeof quantity === 'string') {
      quantity = (quantity !== '' ? Number(quantity) : '') as number;
    }

    updateLineItem(index, { quantity });
  };

  const updateUOM = (index: number, unitOfMeasure: string) => {
    const item = lineItemsWithInfo[index];
    const prevUOM = getUnitOfMeasure(item.unitOfMeasure, item);
    const nextUOM = getUnitOfMeasure(unitOfMeasure, item);
    const price = item.price && nextUOM ? priceUnitConverter(item.price, nextUOM?.size, prevUOM?.size) : item.price;

    updateLineItem(index, { price, unitOfMeasure });
  };

  const updatePrice = (
    index: number,
    price: number | string,
    item: QuoteLineItemWithInfo,
    priceType?: ReplacePriceClickSource,
  ) => {
    if (priceType) {
      track(TrackEvent.Quotes_EditQuote_ReplacePriceClick, { priceType, orderType });
    }

    // we need to allow user to backspace to empty and enter new value,
    // hence allow setting price to empty string.
    // this price quantity won't be set to recommended value
    if (typeof price === 'string') {
      price = (price !== '' ? Number(price) : '') as number;
    }

    setTempGMValues(objOmitKeys(tempGMValues, item.foreignId));
    updateLineItem(index, { price });
  };

  const updateGM = (index: number, gm: number, item: QuoteLineItemWithInfo) => {
    const cost = getMacOrUnitCost(item);
    if (cost) {
      const price = roundTo2Decimals(calcPrice(gm, cost));
      updateLineItem(index, { price });
    }
    setTempGMValues(objOmitKeys(tempGMValues, item.foreignId));
  };

  // Temporary logic that can be per-tenant
  const forcePricing = (tenantId: string, baseUnitPrice: ItemPriceInfo['baseUnitPrice'] | undefined) =>
    // DID SOMETHING WRONG, ignore CWC for right now
    !isTenantCWC(tenantId) && baseUnitPrice && (!isTenantIMS(tenantId) || orderType === OrderType.Order);

  useEffect(() => {
    // if a brand new quote, add a new blank line item to get the user started
    if (lineItemsWithInfo.length === 0) {
      addLineItem();
    }
    // for new line items that don't have price, qty or unitOfMeasure, set defaults from price info
    for (const lineItem of lineItemsWithInfo) {
      if (lineItem.priceInfo) {
        const lineItemUpdate: Partial<QuoteLineItem> = {};
        if (lineItem.quantity === undefined) {
          lineItemUpdate.quantity = 1;
        }
        // If pricing returns a baseUnitPrice, that is the price that must be set.
        // Price is returned in base UOM, so needs to be converted to line item's UOM
        if (forcePricing(activeTenant.id, lineItem.priceInfo.current?.baseUnitPrice)) {
          const currentUnitOfMeasure = (lineItem.priceInfo.unitOfMeasureOptions || []).find(
            (uom) => uom.symbol === lineItem.unitOfMeasure,
          );
          let price = lineItem.priceInfo.current!.baseUnitPrice!.amount;
          if (
            currentUnitOfMeasure &&
            currentUnitOfMeasure.symbol !== lineItem.priceInfo.current!.baseUnitOfMeasure?.symbol
          ) {
            price = priceUnitConverter(
              price,
              currentUnitOfMeasure.size,
              lineItem.priceInfo.current!.baseUnitOfMeasure?.size,
            );
          }

          if (lineItem.price !== price) {
            lineItemUpdate.price = price;
          }
        } else if (
          lineItem.price === undefined &&
          (lineItem.priceInfo.current?.recommendedUnitPrice !== null || lineItem.priceInfo.last?.unitPrice !== null)
        ) {
          lineItemUpdate.price = roundTo2Decimals(
            lineItem.priceInfo.current?.recommendedUnitPrice?.amount || lineItem.priceInfo.last?.unitPrice.amount || 0,
          );
        }
        if (lineItem.unitOfMeasure === undefined) {
          const nextUOM = lineItem.priceInfo?.defaultUnit;
          lineItemUpdate.unitOfMeasure = nextUOM?.symbol;

          // FIXME: UOM logic needs to be better consolidated
          // NOTE: This is necessary because price could be pre-set to a non-valid UOM
          // Price has to be that value per that UOM and so we need to convert it here
          const price = lineItemUpdate.price || lineItem.price;
          if (price && nextUOM) {
            lineItemUpdate.price = priceUnitConverter(price, nextUOM.size);
          }
        }
        if (lineItem.description === undefined) {
          lineItemUpdate.description = lineItem.algoliaItem?.extended_desc || '';
        }
        if (Object.keys(lineItemUpdate).length > 0) {
          updateLineItem(lineItemsWithInfo.indexOf(lineItem), lineItemUpdate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemsWithInfo]);

  const renderError = (error: string | null | undefined, render = true) =>
    render && error && <span style={{ color: RECURRENCY_RED }}>{error}</span>;

  const renderWarning = (warning: string | null | undefined, render = true) =>
    render && warning && <span style={{ color: RECURRENCY_WARNING }}>{warning}</span>;

  const useIMSJobPricedItemsSelectProps = () => useJobPricedItemsSelectProps(customer?.foreignId || ``);

  const lineItemColumns: ColumnType<QuoteLineItemWithInfo>[] = [
    typedColumn({
      title: '',
      align: 'center',
      render: (_, item) => (
        <Styled.TableCell style={{ width: '22px' }}>
          {item.foreignId && (
            <ExternalLink
              to={routes.sales.itemDetails(item.foreignId)}
              onClick={() => track(TrackEvent.Quotes_EditQuote_LineItemLinkClick, {})}
            >
              <LinkOutlined />
            </ExternalLink>
          )}
        </Styled.TableCell>
      ),
    }),
    typedColumn({
      title: 'Item ID',
      dataIndex: 'foreignId',
      width: '15%',
      render: (foreignId, item, index, error = item.errors.foreignId) => (
        <Styled.TableCell>
          {isTenantIMS(activeTenant.id) ? (
            <AsyncSelect
              selectProps={useIMSJobPricedItemsSelectProps}
              entityPlural="items"
              value={foreignId}
              // @ts-expect-error onSelect type OptionData is not assignable to SelectOption<DataT>
              onSelect={(selectedId: string, option: SelectOption<ItemJobPrice>) =>
                // reset fields when selecting new line item so defaults are re-set
                updateLineItem(
                  index,
                  {
                    name: option.data!.itemDesc,
                    description: option.data!.extendedDesc,
                    foreignId: selectedId,
                  },
                  option.data!.contractNo,
                )
              }
              dropdownMatchSelectWidth={550}
            />
          ) : (
            <AsyncSelect
              // TODO: change this to call the new legacy api endpoint
              // also type the response type of onSelect option:any so we get jobNumber
              selectProps={useItemsWithDescSelectProps}
              entityPlural="items"
              value={foreignId}
              onSelect={(selectedId: string, option: any) =>
                // reset fields when selecting new line item so defaults are re-set
                updateLineItem(index, {
                  name: option.name,
                  description: option.description,
                  foreignId: selectedId,
                  price: undefined,
                  quantity: undefined,
                  unitOfMeasure: undefined,
                })
              }
              dropdownMatchSelectWidth={550}
            />
          )}
          {renderError(error)}
          {shouldShowDetails && item.priceInfo && (
            <Styled.TableCellDetail>
              {item.priceInfo.last && (
                <div>
                  <Typography type="small">Last Sold Date</Typography>
                  <Typography>{formatDate(item.priceInfo.last.orderDate)}</Typography>
                </div>
              )}
            </Styled.TableCellDetail>
          )}
        </Styled.TableCell>
      ),
    }),
    typedColumn({
      title: 'Item Description',
      dataIndex: 'description',
      width: '35%',
      render: (itemDesc, item, index) => (
        <Styled.TableCell>
          <Styled.TextSpacer />
          <Typography>{item.name}</Typography>
          <Styled.TextSpacer />

          {shouldShowDetails && item.priceInfo && (
            <>
              <Styled.TableCellDetail>
                <Typography type="small" style={{ marginTop: '5px', marginBottom: '5px' }}>
                  Ext. Description/Line Notes
                </Typography>
                <Input
                  value={itemDesc}
                  placeholder="Add optional line notes"
                  onChange={(ev) => updateLineItem(index, { description: ev.target.value })}
                  disabled={!item.foreignId}
                />
              </Styled.TableCellDetail>
              {item.priceInfo.contract?.messages.length ? (
                <Styled.TableCellDetail>
                  <Typography type="small" style={{ marginTop: '5px', marginBottom: '5px' }}>
                    Contract Pricing
                  </Typography>
                  {item.priceInfo.contract.messages.map((msg) => (
                    <Typography key={msg} type="small">
                      {msg}
                    </Typography>
                  ))}
                </Styled.TableCellDetail>
              ) : null}
            </>
          )}
        </Styled.TableCell>
      ),
    }),
    typedColumn({
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '15%',
      render: (quantity, item, index, error = item.errors.quantity, warning = item.warnings.quantity) => {
        const selectedUom = getUnitOfMeasure(item.unitOfMeasure, item);
        const last = item.priceInfo?.last;

        const lastQtyOrdered =
          last && selectedUom
            ? qtyUnitConverter(last.qtyOrdered, selectedUom.size, last.unitOfMeasure.size)
            : last?.qtyOrdered || null;

        const availability = item.priceInfo?.availability;
        const qtyOnHandLocation: number | null =
          location && availability && selectedUom
            ? qtyUnitConverter(
                availability.locations[location.foreignId]?.qtyOnHand.amount || 0,
                selectedUom.size,
                availability.locations[location.foreignId]?.qtyOnHand.unitOfMeasure.size,
              )
            : availability?.locations[location?.foreignId as string]?.qtyOnHand.amount || null;
        const qtyOnHandAll: number | null =
          selectedUom && availability
            ? qtyUnitConverter(
                availability.total.qtyOnHand.amount,
                selectedUom.size,
                availability.total.qtyOnHand.unitOfMeasure.size,
              )
            : availability?.total.qtyOnHand.amount || null;
        return (
          <Styled.TableCell>
            <Input
              type="number"
              value={quantity}
              onChange={(ev) => updateQuantity(index, ev.target.value)}
              disabled={!item.foreignId}
              isLoading={item.isLoading}
              min={1}
              validate={() => !error}
            />
            {renderError(error)}
            {renderWarning(warning)}
            {shouldShowDetails && item.priceInfo && (
              <Styled.TableCellDetail>
                <Space direction="vertical">
                  {lastQtyOrdered !== null && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() => updateQuantity(index, lastQtyOrdered || 0, ReplaceQuantityClickSource.Last)}
                      >
                        <Typography type="small">Last Quantity</Typography>
                        <Typography>
                          {formatNumber(lastQtyOrdered)} {selectedUom?.symbol}
                        </Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                  {qtyOnHandLocation !== null && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updateQuantity(index, qtyOnHandLocation || 0, ReplaceQuantityClickSource.AvailableAtLocation)
                        }
                      >
                        <Typography type="small">{quoteState.location} Stock</Typography>
                        <Typography>
                          {qtyOnHandLocation > 0 ? (
                            `${formatNumber(qtyOnHandLocation)} ${selectedUom?.symbol}`
                          ) : (
                            <span style={{ color: RECURRENCY_RED }}>{`${formatNumber(qtyOnHandLocation)} ${
                              selectedUom?.symbol
                            }`}</span>
                          )}
                        </Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                  {qtyOnHandAll !== null && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updateQuantity(index, qtyOnHandAll || 0, ReplaceQuantityClickSource.AvailableAtLocation)
                        }
                      >
                        <Typography type="small">Company Stock</Typography>
                        <Typography>
                          {qtyOnHandAll > 0 ? (
                            `${formatNumber(qtyOnHandAll)} ${selectedUom?.symbol}`
                          ) : (
                            <span style={{ color: RECURRENCY_RED }}>{`${formatNumber(qtyOnHandAll)} ${
                              selectedUom?.symbol
                            }`}</span>
                          )}
                        </Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                </Space>
              </Styled.TableCellDetail>
            )}
          </Styled.TableCell>
        );
      },
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      width: '8%',
      render: (unitOfMeasure, item, index) => (
        <Styled.TableCell>
          <Select
            value={unitOfMeasure}
            onChange={(value) => updateUOM(index, value)}
            options={(item.priceInfo?.unitOfMeasureOptions || []).map((u, i) => ({
              value: u.symbol,
              label: (
                <LabelContainer key={i}>
                  <LabelTitle>{u.symbol}</LabelTitle>
                  <LabelSubtitle>
                    {u.size} {u.name}
                  </LabelSubtitle>
                </LabelContainer>
              ),
            }))}
            disabled={!item.foreignId}
            isLoading={item.isLoading}
          />
        </Styled.TableCell>
      ),
    }),
    typedColumn({
      title: 'Price per UOM',
      dataIndex: 'price',
      width: '15%',
      render: (price, item, index, error = item.errors.price, warning = item.warnings.price) => {
        if (forcePricing(activeTenant.id, item.priceInfo?.current?.baseUnitPrice)) {
          return (
            <Styled.TableCell>
              <Styled.TextSpacer />
              <InfoTooltip title="If you need to change this price, please contact accounting to request a price override.">
                <Typography>{formatUSD(price, true) || '$0.00'}</Typography>
              </InfoTooltip>
            </Styled.TableCell>
          );
        }
        const selectedUom = getUnitOfMeasure(item.unitOfMeasure, item);

        const current = item.priceInfo?.current;
        const recommendedUnitPrice: number | null =
          current && selectedUom && current.recommendedUnitPrice
            ? priceUnitConverter(
                current.recommendedUnitPrice.amount,
                selectedUom.size,
                current.recommendedUnitPrice.unitOfMeasure?.size,
              )
            : current?.recommendedUnitPrice?.amount || null;
        const last = item.priceInfo?.last;
        const lastUnitPrice: number | null =
          last && selectedUom
            ? priceUnitConverter(last.unitPrice.amount, selectedUom.size, last.unitOfMeasure.size)
            : last?.unitPrice.amount || null;

        const contractPrice = item.priceInfo?.contract?.policies[0];
        const minUnitPrice =
          contractPrice && contractPrice.minUnitPrice && selectedUom
            ? priceUnitConverter(contractPrice.minUnitPrice.amount, selectedUom?.size, contractPrice.unitOfMeasure.size)
            : contractPrice?.minUnitPrice || null;
        const maxUnitPrice =
          contractPrice && contractPrice.maxUnitPrice && selectedUom
            ? priceUnitConverter(contractPrice.maxUnitPrice.amount, selectedUom?.size, contractPrice.unitOfMeasure.size)
            : contractPrice?.maxUnitPrice || null;

        const baseUnitPrice = item.priceInfo?.current?.baseUnitPrice;
        const fixedPrice = !isTenantCWC(activeTenant.id)
          ? baseUnitPrice && selectedUom
            ? priceUnitConverter(baseUnitPrice.amount, selectedUom.size)
            : baseUnitPrice?.amount
          : undefined;

        return (
          <Styled.TableCell>
            <Input
              type="number"
              value={price}
              onChange={(ev) => updatePrice(index, ev.target.value, item)}
              disabled={!item.foreignId}
              isLoading={item.isLoading}
              prefix={
                <>
                  {' '}
                  <ExternalLink
                    to={routes.sales.pricing({
                      item: joinIdNameObj({ foreignId: item.foreignId, name: item.name }),
                      customer: quoteState.customer,
                      itemInvMastUid: item?.algoliaItem?.inv_mast_uid || '',
                      unitOfMeasure: item.unitOfMeasure,
                    } as PricingHashState)}
                    onClick={() => track(TrackEvent.Quotes_EditQuote_LineItemLinkClick, {})}
                  >
                    $
                  </ExternalLink>
                </>
              }
              validate={() => !error}
            />
            {renderError(error)}
            {renderWarning(warning)}
            {shouldShowDetails && item.priceInfo && (
              <Styled.TableCellDetail>
                <Space direction="vertical">
                  {lastUnitPrice != null && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updatePrice(index, roundTo2Decimals(lastUnitPrice ?? 0), item, ReplacePriceClickSource.Last)
                        }
                      >
                        <Typography type="small">Last</Typography>
                        <Typography>{formatUSD(lastUnitPrice, true)}</Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                  {recommendedUnitPrice !== null && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updatePrice(
                            index,
                            roundTo2Decimals(recommendedUnitPrice ?? 0),
                            item,
                            ReplacePriceClickSource.Recommended,
                          )
                        }
                      >
                        <Typography type="small">Recommended</Typography>
                        <Typography>{formatUSD(recommendedUnitPrice, true)}</Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                  {typeof minUnitPrice === 'number' && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updatePrice(index, roundTo2Decimals(minUnitPrice ?? 0), item, ReplacePriceClickSource.Min)
                        }
                      >
                        <Typography type="small">Min</Typography>
                        <Typography>{formatUSD(minUnitPrice, true)}</Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                  {typeof maxUnitPrice === 'number' && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updatePrice(index, roundTo2Decimals(maxUnitPrice ?? 0), item, ReplacePriceClickSource.Max)
                        }
                      >
                        <Typography type="small">Max</Typography>
                        <Typography>{formatUSD(maxUnitPrice, true)}</Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                  {typeof fixedPrice === 'number' && (
                    <Tooltip title="Use" placement="top">
                      <Styled.UseThisValue
                        type="subtitle"
                        onClick={() =>
                          updatePrice(index, roundTo2Decimals(fixedPrice ?? 0), item, ReplacePriceClickSource.Fixed)
                        }
                      >
                        <Typography type="small">Pricing Library</Typography>
                        <Typography>{formatUSD(fixedPrice, true)}</Typography>
                      </Styled.UseThisValue>
                    </Tooltip>
                  )}
                </Space>
              </Styled.TableCellDetail>
            )}
          </Styled.TableCell>
        );
      },
    }),
    typedColumn({
      title: 'GM',
      width: '15%',
      render: (_, item, index) => {
        const costInfo = shouldShowDetails && item.priceInfo && (
          <Styled.TableCellDetail>
            <Space direction="vertical">
              {getMacOrUnitCost(item) !== null && (
                <div>
                  {item.priceInfo?.cost ? (
                    item.priceInfo.cost.movingAverageUnitCost ? (
                      <Tooltip title="Moving Average Cost">
                        <div>
                          <Typography type="small">MAC at {quoteState.location}</Typography>
                          <Typography>{formatUSD(getMacOrUnitCost(item), true)}</Typography>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="This item has not been stocked at this location recently, so it does not have a MAC">
                        <div>
                          <Typography type="small">Unit Cost at {quoteState.location}</Typography>
                          <Typography>{formatUSD(getMacOrUnitCost(item), true)}</Typography>
                        </div>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="Something went wrong receiving cost data, if this issue persists please contact support">
                      <div>
                        <Typography type="small">Unit Cost at {quoteState.location}</Typography>
                        <Typography>UNKNOWN</Typography>
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </Space>
          </Styled.TableCellDetail>
        );

        if (isTenantIMS(activeTenant.id)) {
          return (
            <Styled.TableCell>
              <Styled.TextSpacer />
              <Typography>{calcGM(item.price || 0, getMacOrUnitCost(item))}%</Typography>
              {costInfo}
            </Styled.TableCell>
          );
        }
        return (
          <Styled.TableCell>
            <Input
              type="number"
              value={
                tempGMValues[item.foreignId] === undefined
                  ? calcGM(item.price || 0, getMacOrUnitCost(item))
                  : tempGMValues[item.foreignId]
              }
              onChange={(ev) => {
                // FIXME: temp GM values should be index, not item ID
                setTempGMValues({ ...tempGMValues, [item.foreignId]: ev.currentTarget.value });
              }}
              // FIXME: We probably want to use some sort of debounce here instead of `onBlur`
              // This setup means price will only change when clicking out of GM box
              onBlur={(ev) => {
                updateGM(index, Number(ev.currentTarget.value), item);
              }}
              suffix={<PercentageOutlined />}
              disabled={!item.foreignId || !getMacOrUnitCost(item)}
              isLoading={item.isLoading}
              validate={validateGrossMargin}
            />
            {costInfo}
          </Styled.TableCell>
        );
      },
    }),
    typedColumn({
      align: 'center',
      title: '',
      render: (_, _item, index) => (
        <Styled.TableCell>
          <Styled.TableAction onClick={() => removeLineItem(index)}>
            <DeleteOutlined />
          </Styled.TableAction>
        </Styled.TableCell>
      ),
    }),
  ];

  return (
    <Styled.Container>
      <DescriptionsContainer bordered>
        <Descriptions.Item key="customer" label="Customer" span={1}>
          {customer?.name}
          <Typography type="small">{customer?.foreignId}</Typography>
        </Descriptions.Item>
        <Descriptions.Item key="shipping" label="Ship To" span={1}>
          {shipTo?.name}
          <Typography type="small">{shipTo?.foreignId}</Typography>
        </Descriptions.Item>
        <Descriptions.Item key="location" label="Location" span={1}>
          {location?.name}
          <Typography type="small">{location?.foreignId}</Typography>
        </Descriptions.Item>
      </DescriptionsContainer>
      <br />
      <Styled.Header>
        <Styled.HeaderLeft>
          <Typography>Items ({getNumLineItems(lineItemsWithInfo)})</Typography>
        </Styled.HeaderLeft>
        <Styled.HeaderActions>
          Show Item Details
          <Switch checked={shouldShowDetails} onChange={(newVal) => setShouldShowDetails(newVal)} />
        </Styled.HeaderActions>
      </Styled.Header>
      <Styled.ItemTable data={lineItemsWithInfo} columns={lineItemColumns} pagination={false} size="small" />
      <br />
      <Button block onClick={addLineItem}>
        <Styled.AddButtonContent>
          <PlusOutlined /> Add Line
        </Styled.AddButtonContent>
      </Button>
      <Styled.Footer>
        <Styled.FooterContent>
          <Typography type="middle">Total Lines: {getNumLineItems(lineItemsWithInfo)}</Typography>
          <Typography type="middle">Subtotal: {formatUSD(getTotalPrice(lineItemsWithInfo), true)}</Typography>
        </Styled.FooterContent>
      </Styled.Footer>
    </Styled.Container>
  );
};
