import React from 'react';

import { Form, Checkbox } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

export interface Props extends FormItemProps {
  name: string;
}

export const CheckboxFormItem = ({ label, name, rules }: Props): JSX.Element => (
  <Form.Item label={label} name={name} rules={rules} valuePropName="checked">
    <Checkbox />
  </Form.Item>
);
