import { Role, Tenant } from '@recurrency/core-api';

import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from './localStorage';
import { makePath, parsePath, routePathsList } from './routes';

export function getActiveTenant(tenants: Tenant[]): Tenant {
  // certain routes e.g '/account/settings' don't contain tenantSlug in url
  // in those scenarios we need to use the last activeTenantSlug saved in localStorage.
  const parsePathResult = parsePath(window.location.pathname);
  const pathTenantSlug = parsePathResult.tenantSlug;
  const lStorageTenantSlug = getLocalStorageItem(LocalStorageKey.ActiveTenantSlug);
  let activeTenantSlug = pathTenantSlug || lStorageTenantSlug || ``;

  const activeTenant = tenants.find((tenant: Tenant) => tenant.slug === activeTenantSlug) || tenants[0];
  activeTenantSlug = activeTenant.slug;

  // user doesn't have access to tenant specified in path slug
  // reload with a tenant, that they have access too
  if (pathTenantSlug && pathTenantSlug !== activeTenantSlug) {
    reloadPageWithTenantSlug(activeTenantSlug);
  }

  return activeTenant;
}

export function reloadPageWithTenantSlug(activeTenantSlug: string) {
  setLocalStorageItem(LocalStorageKey.ActiveTenantSlug, activeTenantSlug);
  const parsePathResult = parsePath(window.location.pathname);
  let { routePath } = parsePathResult;

  if (routePath.includes('/:id')) {
    // ids aren't shared across tenants, and the page is likely to 404
    // replace detail page with the list page e.g '<entity>/:id>' to '<entitiy>s'
    routePath = routePath.replace('/:id', 's');
    if (!routePathsList.includes(routePath)) {
      // redirect to homepage, no matching path found
      routePath = '/';
    }
  }

  const replacedPath = makePath(routePath, { ...parsePathResult.pathParams, tenant: activeTenantSlug });
  window.location.replace(replacedPath);
}

export function getActiveRole(roles: Role[]): Role {
  const lStorageRole = getLocalStorageItem(LocalStorageKey.ActiveRole);
  const activeRole =
    (lStorageRole &&
      roles.find((role: Role) => role.name === lStorageRole.name && role.foreignId === lStorageRole.foreignId)) ||
    roles[0];
  return activeRole;
}

export function reloadPageWithRole(role: Role) {
  setLocalStorageItem(LocalStorageKey.ActiveRole, role);
  window.location.reload();
}

export function roleToString(role: Role | undefined) {
  let roleString = '';
  if (role) {
    roleString = `${role.name.charAt(0).toUpperCase() + role.name.slice(1)}`;
    if (role.foreignId) {
      roleString += ` (${role.foreignId})`;
    }
  }
  return roleString;
}
