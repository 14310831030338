import React from 'react';

import { Contacts } from './Contacts';
import { ItemSalesHistory } from './ItemSalesHistory';
import { LinkedTasks } from './LinkedTasks';
import { Orders } from './Orders';
import { Quotes } from './Quotes';
import { RecommendedItems } from './RecommendedItems';
import { ShipTos } from './ShipTos';
import { TopItems } from './TopItems';
import { TopProductGroups } from './TopProductGroups';

export const getTabs = (customer: FIXME) => {
  const { customerId, customerName } = customer;

  return [
    {
      header: 'Sales History',
      content: <ItemSalesHistory customerId={customerId} />,
    },
    {
      header: 'Top Items',
      content: <TopItems customerId={customerId} customerName={customerName} />,
    },
    { header: 'Top Product Groups', content: <TopProductGroups customerId={customerId} /> },
    {
      header: 'Recommended Items',
      content: <RecommendedItems customerId={customerId} customerName={customerName} />,
    },
    { header: 'Open Quotes', content: <Quotes customerId={customerId} /> },
    { header: 'Open Orders', content: <Orders customerId={customerId} /> },
    { header: 'Contacts', content: <Contacts customerId={customerId} /> },
    { header: 'Ship Tos', content: <ShipTos customerId={customerId} /> },
    { header: 'Linked Tasks', content: <LinkedTasks customerId={customerId} /> },
  ];
};
