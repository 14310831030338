import { captureError } from 'utils/error';
import { splitIdNameStr } from 'utils/formatting';

export const steps = [
  {
    title: 'Header',
  },
  {
    title: 'Line',
  },
  {
    title: 'Review',
  },
];

export const transformLineItemToList = (data: FIXME = {}) => {
  const items = new Map();

  Object.keys(data).forEach((key) => {
    try {
      const index = parseInt(key.split('_')[1], 10);
      if (!items.get(index) && data[`item_${index}`]) {
        const nameAndForeignId = splitIdNameStr(data[`item_${index}`]);
        items.set(index, {
          ...nameAndForeignId,
          quantity: data[`quantity_${index}`],
          price: data[`price_${index}`],
          uom: data[`uom_${index}`],
        });
      }
    } catch (err) {
      captureError(err);
    }
  });

  return Array.from(items.values() || []);
};
