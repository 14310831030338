import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { TenantFeature } from '@recurrency/core-api';
import { ColumnType } from 'antd/lib/table';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { ButtonLink } from 'components/Links';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatPercent, joinIdNameObj } from 'utils/formatting';
import { encodeLegacyApiParam, routes } from 'utils/routes';
import { getProductGroupSearchRoute } from 'utils/tables';

import { IdNameObj } from 'types/legacy-api';

export const RecommendedItems = ({ customerId, customerName }: { customerId: string; customerName: string }) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const columns: ColumnType<FIXME>[] = [
    {
      title: 'Item ID',
      dataIndex: 'itemId',
      sorter: (a, b) => a.itemId.localeCompare(b.itemId),
      sortDirections: ['descend', 'ascend'],
      render: (itemId: string) => <Link to={routes.sales.itemDetails(itemId)}>{itemId}</Link>,
    },
    {
      title: 'Item',
      dataIndex: 'itemDesc',
      sorter: (a, b) => a.itemDesc.localeCompare(b.itemDesc),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Product Group ID',
      dataIndex: 'productGroupId',
      sorter: (a, b) => a.productGroupId.localeCompare(b.productGroupId),
      sortDirections: ['descend', 'ascend'],
      render: (pgId: string, { productGroupDesc }: { productGroupDesc: string }) => (
        <Link to={getProductGroupSearchRoute(pgId, productGroupDesc)}>{pgId}</Link>
      ),
    },
    {
      title: 'Product Group',
      dataIndex: 'productGroupDesc',
      sorter: (a, b) => a.productGroupDesc.localeCompare(b.productGroupDesc),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Match %',
      dataIndex: 'rank',
      sorter: (a, b) => a.rank - b.rank,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (match: any) => formatPercent(match),
      align: 'right',
    },
    {
      render: ({ itemId }) => ButtonLink(routes.sales.itemDetails(itemId)),
    },
  ];

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [selectedItemIdNameObjs, setSelectedItemIdNameObjs] = useState<IdNameObj[]>([]);

  const handleRowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: FIXME[]) => {
    // we can assume rowKeys are string[] instead of Array<string | number>
    setSelectedItemIds(selectedRowKeys as string[]);
    setSelectedItemIdNameObjs(selectedRows.map((row) => ({ foreignId: row.itemId, name: row.itemDesc })));
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
        <NewQuoteButton
          showItemCount
          hashState={{
            customer: joinIdNameObj({ foreignId: customerId, name: customerName }),
            items: selectedItemIdNameObjs,
          }}
          props={{ disabled: selectedItemIds.length === 0 }}
        />
      </div>
      <AsyncTable
        tableProps={useLegacyApiTableProps({
          apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/recommendations/items`,
        })}
        columns={columns}
        rowKey="itemId"
        rowSelection={
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateQuote)
            ? {
                selectedRowKeys: selectedItemIds,
                onChange: handleRowSelectionChange,
              }
            : undefined
        }
      />
    </div>
  );
};
