import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  sortableNumberColumn,
  sortableStringColumn,
  sortableDateColumn,
  sortableDollarColumn,
  sortablePercentColumn,
  // humanDateColumn,
} from 'utils/tables';

export const Quotes = ({ customerId }: { customerId: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    // humanDateColumn({
    //   title: 'Timeline',
    //   dataIndex: 'orderDate',
    // }),
    sortableStringColumn({
      title: 'Quote No',
      dataIndex: 'orderNo',
      render: (id: string) => <Link to={routes.orders.orderDetails(id)}>{id}</Link>,
    }),
    sortableDateColumn({
      title: 'Quote Date',
      dataIndex: 'orderDate',
    }),
    sortableDollarColumn({
      title: 'Total Amount',
      dataIndex: 'quoteValue',
    }),
    sortableDollarColumn({
      title: 'Total GM',
      dataIndex: 'quoteGm',
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'gmPct',
    }),
    sortableNumberColumn({
      title: 'Item Count',
      dataIndex: 'itemCount',
      responsive: ['lg', 'xl', 'xxl'],
    }),
    {
      render: ({ orderNo }) => ButtonLink(routes.orders.orderDetails(orderNo)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/quotes`,
      })}
      columns={columns}
      rowKey="orderNo"
    />
  );
};
