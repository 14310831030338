/**
 * This module encapsulates data fetching needs for various select dropdown entities,
 * with an uniform UseAsyncSelectResponse interface.
 */

import React, { useCallback, useState } from 'react';

import { FreightType } from '@recurrency/core-api';
import { fuzzyFilter, fuzzyMatch } from 'fuzzbunny';

import { isAdmin } from 'contexts/Auth0Context';

import { SelectOption } from 'components/Select';

import { IndexName, searchAlgoliaIndex, DEFAULT_HITS_PER_PAGE } from 'utils/algolia';
import { truthy } from 'utils/boolean';
import { captureError } from 'utils/error';
import { formatAddress, joinIdNameObj } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { RECURRENCY_BLUE } from 'constants/styles';

import { AlgoliaBranch, AlgoliaCustomer, AlgoliaInventoryItem } from 'types/algolia-collections';
import {
  BranchesResponse,
  CarriersResponse,
  CompaniesResponse,
  CustomerContactsResponse,
  CustomerShipTosResponse,
  FreightsResponse,
  ItemJobPrice,
  JobPriceResponse,
  SalesRepsResponse,
} from 'types/legacy-api';

import { NULLIFY_API_CALL, useApi, useLegacyApi } from '../../hooks/useApi';
import { useGlobalApp } from '../../hooks/useGlobalApp';
import { usePromise } from '../../hooks/usePromise';
import * as Styled from './AsyncSelect.style';

export interface UseAsyncSelectProps<DataT = unknown> {
  options: SelectOption<DataT>[];
  isLoading: boolean;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  onSelect?: (value: string) => void;
}

export function useAlgoliaSelectProps<HitT>({
  indexName,
  mapHitFn,
  salesRepRefined,
}: {
  indexName: IndexName;
  mapHitFn: (hit: HitT, index: number) => SelectOption;
  salesRepRefined?: boolean;
}): UseAsyncSelectProps {
  const { activeRole, activeTenant, searchClient } = useGlobalApp();
  const { id: tenantId } = activeTenant;
  const [hitsPerPage, setHitsPerPage] = useState(DEFAULT_HITS_PER_PAGE);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: algoliaResponse, isLoading } = usePromise(
    () =>
      searchAlgoliaIndex(searchClient, {
        indexName,
        tenantId,
        query: searchQuery,
        facetValueFilters:
          salesRepRefined && !isAdmin(activeRole.foreignId, activeRole.name)
            ? { salesrep_ids: [activeRole.foreignId] }
            : undefined,
        hitsPerPage,
      }),
    [indexName, tenantId, searchQuery, hitsPerPage],
    { onError: captureError },
  );

  const numHits = algoliaResponse?.nbHits ?? 0;
  const handleLoadMoreClick = useCallback(() => {
    track(TrackEvent.Components_AlgoliaConnectedFormItem_LoadMore, {
      searchQuery,
      searchQueryLength: searchQuery.length,
      numResults: numHits,
    });
    setHitsPerPage(hitsPerPage + DEFAULT_HITS_PER_PAGE);
  }, [searchQuery, numHits, hitsPerPage]);

  // @ts-expect-error HitT is custom type, hits.map complains
  const options = algoliaResponse ? algoliaResponse.hits.map(mapHitFn) : [];

  if (algoliaResponse && algoliaResponse.nbHits > hitsPerPage) {
    // Add special load more option, if there are more hits to be shown
    options.push({
      value: '$loadMore',
      label: <div onClick={handleLoadMoreClick}>Load More ({algoliaResponse.nbHits.toLocaleString()} total)</div>,
      disabled: true,
      style: { cursor: 'pointer', color: RECURRENCY_BLUE },
    });
  }

  // reset hitsPerPage after user makes a selection
  const onSelect = useCallback(() => {
    setHitsPerPage(DEFAULT_HITS_PER_PAGE);
  }, [setHitsPerPage]);

  return {
    isLoading,
    options: fuzzyHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
    onSelect,
  };
}

export function useCustomersSelectProps(): UseAsyncSelectProps {
  return useAlgoliaSelectProps<AlgoliaCustomer>({
    indexName: IndexName.Customers,
    mapHitFn: (hit) => ({ value: `${hit.customer_id}: ${hit.customer_name}` }),
    salesRepRefined: true,
  });
}

export function useItemsSelectProps(): UseAsyncSelectProps {
  return useAlgoliaSelectProps<AlgoliaInventoryItem>({
    indexName: IndexName.Items,
    mapHitFn: (hit) => ({
      value: `${hit.item_id}: ${hit.item_desc}${hit.extended_desc ? ` - ${hit.extended_desc}` : ''}`,
    }),
  });
}

export function useItemsWithDescSelectProps(): UseAsyncSelectProps {
  return useAlgoliaSelectProps<AlgoliaInventoryItem>({
    indexName: IndexName.Items,
    mapHitFn: (hit, idx) => ({
      value: hit.item_id,
      label: (
        <Styled.LabelContainer key={idx}>
          <Styled.LabelTitle>{hit.item_id}</Styled.LabelTitle>
          <Styled.LabelSubtitle>
            {`${hit.item_desc}${hit.extended_desc ? ` - ${hit.extended_desc}` : ''}`}
          </Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      name: hit.item_desc,
      description: hit.extended_desc,
    }),
  });
}

export const useJobPricedItemsSelectProps = (customerId: string): UseAsyncSelectProps<ItemJobPrice> => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<JobPriceResponse>(`/v3/job-price/${encodeLegacyApiParam(customerId)}`);

  const options: Array<SelectOption<ItemJobPrice> & { itemDesc: string; extendedDesc: string }> = (
    data?.items || []
  ).map((jobItem) => ({
    value: jobItem.itemId,
    label: (
      <Styled.LabelContainer key={jobItem.itemId}>
        <Styled.LabelTitle>{jobItem.itemId}</Styled.LabelTitle>
        <Styled.LabelSubtitle>
          {`${jobItem.itemDesc}${jobItem.extendedDesc ? ` - ${jobItem.extendedDesc}` : ''}`}
        </Styled.LabelSubtitle>
      </Styled.LabelContainer>
    ),

    data: jobItem,
    // hack for fuzzyFilterHighlighting, will need to be worked around later
    // to support label + subLabel searching across all select usage
    itemDesc: jobItem.itemDesc,
    extendedDesc: jobItem.extendedDesc,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery, ['value', 'itemDesc', 'extendedDesc']),
    searchQuery,
    setSearchQuery,
  };
};

export function useSalesRepsSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<SalesRepsResponse>(`/v3/salesreps`, { query: searchQuery });
  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.id}: ${item.firstName} ${item.lastName}`,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useLocationsSelectProps(): UseAsyncSelectProps {
  return useAlgoliaSelectProps<AlgoliaBranch>({
    indexName: IndexName.Branches,
    mapHitFn: (hit) => ({ value: `${hit.branch_id}: ${hit.branch_description}` }),
  });
}

export function useBranchesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<BranchesResponse>(`/v3/branches`, { query: searchQuery });
  const options: SelectOption[] = (data?.items || [])
    .sort((a, b) => a.branchId.localeCompare(b.branchId))
    .map((item) => ({
      value: `${item.branchId}: ${item.branchDescription}`,
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}
export function useCompaniesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<CompaniesResponse>('/v3/companies');

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.companyId}: ${item.companyName}`,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

// Tenants from the user has access to
export function useTenantUsersSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { activeUser } = useGlobalApp();
  const { data, isLoading } = useApi().users().getUserById(activeUser?.id, true);

  const options: SelectOption[] = (data?.tenants || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export type ShipToSelectOption = SelectOption & { freightType?: FreightType };

export function useShipTosSelectProps({
  customerId,
  companyId,
}: {
  customerId: string;
  companyId: string;
}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<CustomerShipTosResponse>(
    customerId ? `/v3/customers/${encodeLegacyApiParam(customerId)}/shiptos` : NULLIFY_API_CALL,
    { limit: 100, offset: 0, query: searchQuery, companyId },
  );

  const options: ShipToSelectOption[] = (data?.items || [])
    // filtering out null shipToAddress because they sometimes come as null from api (dunno why)
    .filter((item) => truthy(item.shipToAddress))
    .map((item, idx) => ({
      value: `${item.shipToId}: ${item.shipToAddress!.name}`,
      label: (
        <Styled.LabelContainer key={idx}>
          <Styled.LabelTitle>
            {item.shipToId}: {item.shipToAddress!.name}
          </Styled.LabelTitle>
          <Styled.LabelSubtitle>
            {formatAddress(
              item.shipToAddress!.physAddress1,
              item.shipToAddress!.physCity,
              item.shipToAddress!.physState,
              item.shipToAddress!.physPostalCode,
            )}
          </Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      freightType: item.freight ? { foreignId: item.freight.freightCodeUid, name: item.freight.freightCd } : undefined,
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useContactsSelectProps({
  customerId,
  companyId,
}: {
  customerId: string;
  companyId: string;
}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<CustomerContactsResponse>(
    customerId ? `/v3/customers/${encodeLegacyApiParam(customerId)}/contacts` : NULLIFY_API_CALL,
    { limit: 100, offset: 0, companyId },
  );

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.contactId}: ${item.firstName} ${item.lastName}`,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useCarriersSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading } = useLegacyApi<CarriersResponse>(`/v3/carriers`, {
    limit: 100,
    offset: 0,
  });

  const options: SelectOption[] = (data?.items || [])
    // we sort by id, because as a heuristic the lowest id is usually the default carrier
    .sort((a, b) => a.id.localeCompare(b.id))
    .map((item) => ({
      value: joinIdNameObj({ foreignId: item.id, name: item.name }),
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useFreightsSelectProps({ companyId }: { companyId: string | undefined }): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useLegacyApi<FreightsResponse>(
    '/v3/freights',
    companyId ? { company_id: companyId } : {},
  );

  const options: SelectOption[] = (data?.items || [])
    .map((item) => ({ foreignId: item.freightCodeUid, name: item.freightCd }))
    .sort((a, b) => a.foreignId.localeCompare(b.foreignId))
    .map((item) => ({
      value: joinIdNameObj(item),
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

/// helpers ///

export function highlightedLabel(highlightParts: string[]) {
  return (
    <div>
      {highlightParts.map((part, partIdx) =>
        partIdx % 2 ? (
          <Styled.LabelHighlightPart key={partIdx}>{part}</Styled.LabelHighlightPart>
        ) : (
          <span key={partIdx}>{part}</span>
        ),
      )}
    </div>
  );
}

/** filter and higlight matching values - used when doing local search */
export function fuzzyFilterAndHighlight<ExtraPropsT>(
  options: (SelectOption & ExtraPropsT)[],
  searchQuery: string,
  fields: (keyof (SelectOption & ExtraPropsT))[] = ['value'],
): Array<SelectOption & ExtraPropsT> {
  const filteredOptions = fuzzyFilter(options, searchQuery, { fields });
  return filteredOptions.map((opt, optIdx) => ({
    key: optIdx, // backend sometimes replies with duplicate values so using index as the key
    label: opt.highlights.value ? highlightedLabel(opt.highlights.value) : opt.item.value,
    ...opt.item,
  }));
}

/** only higlight matching values - used when doing remote algolia search */
export function fuzzyHighlight(options: SelectOption[], searchQuery: string): SelectOption[] {
  return options.map((opt, optIdx) => {
    const highlights = fuzzyMatch(opt.value, searchQuery)?.highlights;
    return {
      key: optIdx, // backend sometimes replies with duplicate values so using index as the key
      label: highlights ? highlightedLabel(highlights) : opt.value,
      ...opt,
    };
  });
}
