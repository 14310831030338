import React from 'react';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { formatNumber, formatUSD } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { sortableNumberColumn, sortableDollarWithCentsColumn, idColumn, sortableStringColumn } from 'utils/tables';
import { priceUnitConverter, qtyUnitConverter } from 'utils/units';

export const Availability = ({ invMastUid }: { invMastUid: string | undefined }) => {
  const mapItemFn = (item: any) => ({ qtyAvailable: item.qtyOnHand - item.qtyAllocated, ...item });

  const columns = [
    idColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: FIXME) => `${record.locationId}: ${record.locationName}`,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'defaultSellingUnit',
    }),
    sortableNumberColumn({
      title: 'Qty on Hand',
      dataIndex: 'qtyOnHand',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.qtyOnHand, record.defaultUnitSize)),
    }),
    sortableNumberColumn({
      title: 'Qty Allocated',
      dataIndex: 'qtyAllocated',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.qtyAllocated, record.defaultUnitSize)),
    }),
    sortableNumberColumn({
      title: 'Qty Available',
      dataIndex: 'qtyAvailable',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.qtyAvailable, record.defaultUnitSize)),
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'orderQuantity',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.orderQuantity, record.defaultUnitSize)),
    }),
    sortableNumberColumn({
      title: 'Qty B/O',
      dataIndex: 'qtyBackordered',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.qtyBackordered, record.defaultUnitSize)),
    }),
    sortableNumberColumn({
      title: 'Qty in Process',
      dataIndex: 'qtyInProcess',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.qtyInProcess, record.defaultUnitSize)),
    }),
    sortableNumberColumn({
      title: 'Qty in Transit',
      dataIndex: 'qtyInTransit',
      render: (_, record: FIXME) => formatNumber(qtyUnitConverter(record.qtyInTransit, record.defaultUnitSize)),
    }),
    sortableDollarWithCentsColumn({
      title: 'Moving Avg Cost',
      dataIndex: 'movingAverageCost',
      render: (movingAverageCost: number, record: FIXME) =>
        formatUSD(priceUnitConverter(movingAverageCost, record.defaultUnitSize), true),
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(invMastUid || '')}/availability`,
        // passing undefined for pagination params so we get the full data set.
        // since useMemoizedItemStats also fetches the same data,
        // we want to avoid making duplicate calls to the same endpoint.
        queryParams: { offset: undefined, limit: undefined },
        mapItemFn,
      })}
      columns={columns}
      rowKey="locationId"
    />
  );
};
