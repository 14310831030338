import { CreateOrderRequest, CreateQuoteRequest, Quote } from '@recurrency/core-api';
import camelcaseKeys from 'camelcase-keys';

import { legacyApiFetch } from 'utils/api';
import { captureError } from 'utils/error';
import { joinIfIdNameObj, roundTo2Decimals, splitIfIdNameStr } from 'utils/formatting';
import { objRemoveUndefinedValues } from 'utils/object';
import { encodeLegacyApiParam } from 'utils/routes';
import { priceUnitConverter, qtyUnitConverter } from 'utils/units';

import { AlgoliaInventoryItemPartial } from 'types/algolia-collections';
import { QuoteEditHashState, QuoteLineItem } from 'types/hash-state';
import {
  Currency,
  ItemAvailability,
  ItemContract,
  ItemPriceInfo,
  ItemUnitOfMeasure,
  QuoteLineItemInfo,
  Quantity,
  UnitOfMeasureTags,
  LineItemPriceInfoV3,
  ItemPriceResponseV4,
  LineItemRequest,
} from 'types/legacy-api';

/// helper interfaces ///

export interface LineItemInfoCacheValue {
  // params are stored, so the price info can be reloaded if params change
  params: {
    companyId: string;
    customerId: string;
    locationId: string;
    shipToId: string;
  };
  priceInfo?: QuoteLineItemInfo;
  algoliaItem?: AlgoliaInventoryItemPartial;
  isLoading: boolean;
}

export interface QuoteLineItemWithInfo extends QuoteLineItem {
  priceInfo?: LineItemInfoCacheValue['priceInfo'];
  algoliaItem?: AlgoliaInventoryItemPartial;
  isLoading: boolean;
  errors: {
    [key in keyof QuoteLineItem]?: string | null;
  };
  warnings: {
    [key in keyof QuoteLineItem]?: string | null;
  };
}

export type LineItemsInfoCache = Obj<LineItemInfoCacheValue>;

/// helper functions ///

export function getUnitOfMeasure(symbol: string | undefined, item: QuoteLineItemWithInfo) {
  return item.priceInfo?.unitOfMeasureOptions?.find((uomObj) => uomObj.symbol === symbol);
}

export const getNumLineItems = (items: QuoteLineItem[]) => items.filter((item) => !!item.foreignId).length;

export const getTotalPrice = (items: QuoteLineItem[]) =>
  roundTo2Decimals(items.reduce((total: number, item) => (item.quantity || 0) * (item.price || 0) + total, 0));

export const validateGrossMargin = (value?: string) => !isNaN(Number(value)) && Number(value) <= 100;

export const validateName = (item: QuoteLineItemWithInfo) => {
  if (!item.name || !item.foreignId) return 'Please select a valid item or delete this line item.';
  return null;
};

export const validateQuantity = (item: QuoteLineItemWithInfo, locationId?: string) => {
  if (!item.quantity) {
    return 'Quantity must be greater than 0';
  }

  if (!locationId) {
    // NOTE: this shouldn't happen but if it does it's caught as warning, not error
    return null;
  }

  const locationAvailability = item.priceInfo?.availability?.locations[locationId];

  if (!locationAvailability) {
    return 'This item has not been allocated for sale at this location. Please allocate it in your source ERP first';
  }

  return null;
};

export const validatePrice = (item: QuoteLineItemWithInfo) => {
  const { minUnitPrice: minOrNull, maxUnitPrice: max } = item.priceInfo?.contract?.policies[0] || {};

  const min = minOrNull?.amount ?? 0;

  const error = max ? `Price must be between ${min} and ${max.amount}` : `Price must be at least ${min}`;
  if ((!item.price && item?.price !== 0) || (min && item.price < min) || (max && item.price > max.amount)) {
    return error;
  }
  return null;
};

export const warnPrice = (item: QuoteLineItemWithInfo) => {
  if (validatePrice(item) !== null) {
    return null;
  }
  if (item?.price === 0) {
    return 'Price is 0';
  }
  return null;
};

export const warnQuantity = (item: QuoteLineItemWithInfo, locationId?: string) => {
  if (!locationId) {
    return 'Location not specified';
  }

  const locationAvailability = item.priceInfo?.availability?.locations[locationId];

  // Not available at location means error, not warning
  if (!locationAvailability) {
    return null;
  }

  const { qtyOnHand } = locationAvailability;
  if (!qtyOnHand?.amount) {
    return 'No quantity available at this location';
  }

  const unitOfMeasure = getUnitOfMeasure(item.unitOfMeasure, item);

  if (!unitOfMeasure) {
    // This shouldn't happen, but if it does there's no error/warning at this point (need to load UOM)
    return null;
  }

  if (item.quantity && compareQuantities({ amount: item.quantity, unitOfMeasure }, qtyOnHand) > 0) {
    return 'Quantity exceeds amount currently available at this location';
  }
  return null;
};

/** gm = ((price - cost) / price) * 100 */
export const calcGM = (price: number, cost: number) =>
  price === 0 ? 0 : roundTo2Decimals(((price - cost) / price) * 100);

/** price = cost / (1 - gm / 100) */
export const calcPrice = (gm: number, cost: number) => roundTo2Decimals(cost / (1 - gm / 100));

/** price = cost / (1 - gm / 100) */
export const calcCost = (gm: number, price: number) => roundTo2Decimals(price * (1 - gm / 100));

export const getTotalGrossMargin = (items: QuoteLineItemWithInfo[]) =>
  roundTo2Decimals(
    items.reduce(
      (total: number, item) =>
        (item.quantity || 0) * ((item.price || 0) - (item.priceInfo?.cost?.movingAverageUnitCost?.amount || 0)) + total,
      0,
    ),
  );

export const getAverageGrossMarginPct = (items: QuoteLineItemWithInfo[]): number => {
  const filteredGMs: number[] = items
    .filter((lineItem) => !!lineItem.priceInfo?.cost?.movingAverageUnitCost)
    .map((lineItem) => calcGM(lineItem.price || 0, lineItem.priceInfo?.cost?.movingAverageUnitCost?.amount || 0));
  return filteredGMs.length > 0 ? roundTo2Decimals(filteredGMs.reduce((a, b) => a + b) / filteredGMs.length) : NaN;
};

export function getLineItemTrackProps(lineItems: QuoteLineItemWithInfo[]) {
  return {
    numLineItems: getNumLineItems(lineItems),
    totalPrice: getTotalPrice(lineItems),
    totalGrossMargin: getTotalGrossMargin(lineItems),
    avgGrossMarginPercentage: getAverageGrossMarginPct(lineItems),
  };
}

export function getMacOrUnitCost(item: QuoteLineItemWithInfo) {
  const targetSize = getUnitOfMeasure(item.unitOfMeasure, item)?.size || 1;
  const unitCost: number | null =
    item.priceInfo?.cost?.movingAverageUnitCost?.amount || item.priceInfo?.cost?.unitCost?.amount || 0;
  return priceUnitConverter(unitCost, targetSize, item.priceInfo?.cost?.unitOfMeasure?.size);
}

export function quoteRequestFromHashState(hashState: QuoteEditHashState, tenantId: string): CreateQuoteRequest {
  return {
    tenantId,
    description: hashState.comments || '',
    date: hashState.requiredDate!,
    validUntilDate: hashState.validUntilDate!,
    purchaseOrder: hashState.poNo || '',
    approved: true,
    metadata: {
      company: splitIfIdNameStr(hashState.company)!,
      freightType: splitIfIdNameStr(hashState.freightType)!,
      carrier: splitIfIdNameStr(hashState.carrier),
      customer: splitIfIdNameStr(hashState.customer)!,
      contact: splitIfIdNameStr(hashState.contact),
      location: splitIfIdNameStr(hashState.location)!,
      shipTo: splitIfIdNameStr(hashState.shipTo)!,
      // @ts-expect-error (passed after validation so we can assume it's non-null)
      items: hashState.items || [],
      taker: hashState.taker,
      jobNumber: hashState.jobNumber,
    },
  };
}

export function orderRequestFromHashState(hashState: QuoteEditHashState, tenantId: string): CreateOrderRequest {
  return {
    tenantId,
    description: hashState.comments || '',
    date: hashState.requiredDate!,
    purchaseOrder: hashState.poNo || '',
    approved: true,
    metadata: {
      company: splitIfIdNameStr(hashState.company)!,
      // @ts-expect-error (passed after validation so we can assume it's non-null)
      freightType: splitIfIdNameStr(hashState.freightType),
      carrier: splitIfIdNameStr(hashState.carrier),
      customer: splitIfIdNameStr(hashState.customer)!,
      contact: splitIfIdNameStr(hashState.contact),
      location: splitIfIdNameStr(hashState.location)!,
      shipTo: splitIfIdNameStr(hashState.shipTo)!,
      // @ts-expect-error (passed after validation so we can assume it's non-null)
      items: hashState.items || [],
      taker: hashState.taker,
    },
  };
}

export function hashStateFromQuote(quoteData: Quote): QuoteEditHashState {
  return {
    company: joinIfIdNameObj(quoteData.metadata.company),
    customer: joinIfIdNameObj(quoteData.metadata.customer),
    contact: joinIfIdNameObj(quoteData.metadata.contact),
    shipTo: joinIfIdNameObj(quoteData.metadata.shipTo),
    location: joinIfIdNameObj(quoteData.metadata.location),
    freightType: joinIfIdNameObj(quoteData.metadata.freightType),
    carrier: joinIfIdNameObj(quoteData.metadata.carrier),
    requiredDate: quoteData.date,
    validUntilDate: quoteData.validUntilDate,
    poNo: quoteData.purchaseOrder,
    comments: quoteData.description,
    items: quoteData.metadata.items,
    jobNumber: quoteData.metadata?.jobNumber,
  };
}

function sumQuantities(a: Quantity, b: Quantity, unitOfMeasure = a.unitOfMeasure): Quantity {
  return {
    amount:
      qtyUnitConverter(a.amount, unitOfMeasure.size, a.unitOfMeasure.size) +
      qtyUnitConverter(b.amount, unitOfMeasure.size, b.unitOfMeasure.size),
    unitOfMeasure,
  } as Quantity;
}

function compareQuantities(a: Quantity, b: Quantity, unitOfMeasure = a.unitOfMeasure): number {
  return (
    qtyUnitConverter(a.amount, unitOfMeasure.size, a.unitOfMeasure.size) -
    qtyUnitConverter(b.amount, unitOfMeasure.size, b.unitOfMeasure.size)
  );
}

type ItemsOrError<T> = { error: true } | { error?: undefined; items: T };

async function transformPriceResponse(
  priceInfoResponse: ItemsOrError<ItemPriceResponseV4>,
  last?: QuoteLineItemInfo['last'],
  contract?: QuoteLineItemInfo['contract'],
  v3PricePromise?: Promise<LineItemPriceInfoV3>,
  v3PriceResolved?: boolean,
) {
  const EACH = { symbol: 'EA', name: 'Each', size: 1.0 };
  let total = {
    qtyOnHand: { amount: 0, unitOfMeasure: EACH } as Quantity,
    qtyAllocated: { amount: 0, unitOfMeasure: EACH } as Quantity,
    qtyBackordered: { amount: 0, unitOfMeasure: EACH } as Quantity,
    qtyInProcess: { amount: 0, unitOfMeasure: EACH } as Quantity,
    qtyInTransit: { amount: 0, unitOfMeasure: EACH } as Quantity,
  };

  if (priceInfoResponse.error) {
    return { error: priceInfoResponse.error };
  }

  const data = priceInfoResponse.items;

  const availability: QuoteLineItemInfo['availability'] = {
    locations: data.availability.reduce((map: Record<string, ItemAvailability>, obj: ItemAvailability) => {
      total = {
        qtyOnHand: sumQuantities(obj.qtyOnHand, total.qtyOnHand),
        qtyAllocated: sumQuantities(obj.qtyAllocated, total.qtyAllocated),
        qtyBackordered: sumQuantities(obj.qtyBackordered, total.qtyBackordered),
        qtyInProcess: sumQuantities(obj.qtyInProcess, total.qtyInProcess),
        qtyInTransit: sumQuantities(obj.qtyInTransit, total.qtyInTransit),
      };
      map[obj.locationId] = obj;
      return map;
    }, {}),
    total,
  };
  const { cost } = data;

  const unitOfMeasureOptions: ItemUnitOfMeasure[] = data.unitsOfMeasure;
  unitOfMeasureOptions.sort((a, b) => a.size - b.size);

  const baseUnit =
    unitOfMeasureOptions.find((unitOfMeasure) => unitOfMeasure.tags.includes(UnitOfMeasureTags.Base)) ||
    unitOfMeasureOptions.find((unitOfMeasure) => unitOfMeasure.size === 1) ||
    unitOfMeasureOptions[0];
  const defaultUnit =
    unitOfMeasureOptions.find((unitOfMeasure) => unitOfMeasure.tags.includes(UnitOfMeasureTags.Sales)) ||
    data?.unitOfMeasure ||
    unitOfMeasureOptions[0];

  let current: ItemPriceInfo = {
    baseUnitOfMeasure: data.baseUnitOfMeasure,
    baseUnitPrice: data.baseUnitPrice,
    recommendedUnitPrice: data.recommendedUnitPrice,
    unitOfMeasure: data.unitOfMeasure,
  };
  if (v3PriceResolved && v3PricePromise) {
    const v3PriceResponse = await v3PricePromise;
    const lastUnitOfMeasure = unitOfMeasureOptions.find(
      (unitOfMeasure) => unitOfMeasure.symbol === v3PriceResponse.lastUnitOfMeasure,
    );
    if (!last && v3PriceResponse.lastUnitPrice && lastUnitOfMeasure) {
      last = {
        orderDate: v3PriceResponse.lastOrderDate as string,
        qtyOrdered: v3PriceResponse.lastQtyOrdered || 0,
        unitOfMeasure: lastUnitOfMeasure,
        unitPrice: {
          amount: v3PriceResponse.lastUnitPrice,
          unitOfMeasure: lastUnitOfMeasure,
        },
      };
    }
    if (!current.recommendedUnitPrice && v3PriceResponse.recommendedUnitPrice) {
      current = {
        baseUnitPrice: current?.baseUnitPrice || null,
        baseUnitOfMeasure: current?.baseUnitOfMeasure || null,
        recommendedUnitPrice: {
          amount: v3PriceResponse.recommendedUnitPrice,
          unitOfMeasure: lastUnitOfMeasure || defaultUnit,
        },
        unitOfMeasure: current?.unitOfMeasure || defaultUnit,
      };
    }
  }

  return {
    availability,
    baseUnit,
    defaultUnit,
    unitOfMeasureOptions,
    contract,
    current,
    cost,
    last,
  };
}

export async function getItemPriceInfo(
  line: LineItemRequest,
  params: LineItemInfoCacheValue['params'],
): Promise<QuoteLineItemInfo> {
  // FIXME: this should be done differently but am plugging it into old setup for now and will disentangle later
  // NOTE: V4 is ready when no longer using any V3 content
  const v3PricePromise = getItemPriceInfoV3(line.inv_mast_uid, params);
  let v3PriceResolved = false;

  v3PricePromise.then(() => {
    v3PriceResolved = true;
  });

  const [priceInfoResponseArray, contractInfoResponse, lastResponse] = await Promise.all([
    getItemPriceMulti(params.companyId, params.customerId, params.locationId, params.shipToId, [line]),
    getItemContract(line.inv_mast_uid, params),
    getLastOrderFromCustomer(line.inv_mast_uid, params.customerId, params.companyId),
  ]);

  const priceInfoResponse = priceInfoResponseArray[0];

  const last = lastResponse.error ? null : lastResponse.items;

  const contract = contractInfoResponse.error ? null : contractInfoResponse.items;

  return transformPriceResponse(priceInfoResponse, last, contract, v3PricePromise, v3PriceResolved);
}

export async function getItemContract(
  itemInvMastUId: string,
  params: LineItemInfoCacheValue['params'],
): Promise<ItemsOrError<ItemContract>> {
  try {
    const { data } = await legacyApiFetch(`/v4/customers/${encodeLegacyApiParam(params?.customerId)}/contract`, {
      method: 'GET',
      data: objRemoveUndefinedValues({
        company_id: params.companyId,
        customer_id: params.customerId,
        inv_mast_uid: itemInvMastUId,
      }),
    });
    return {
      items: camelcaseKeys<ItemContract>(data, { deep: true }),
    };
  } catch (error) {
    captureError(error);
    return { error: true };
  }
}

async function getLastOrderFromCustomer(
  itemInvMastUId: string,
  customerId: string | undefined,
  companyId: string | undefined,
): Promise<ItemsOrError<QuoteLineItemInfo['last']>> {
  if (!customerId) {
    return { error: true };
  }
  try {
    const { data } = await legacyApiFetch(`/v4/customers/${encodeLegacyApiParam(customerId)}/sales-orders`, {
      method: 'GET',
      data: objRemoveUndefinedValues({
        company_id: companyId,
        inv_mast_uid: itemInvMastUId,
        limit: 1,
      }),
    });

    const orders = camelcaseKeys<
      {
        invoiceDate: string;
        qtyRequested: Quantity;
        unitOfMeasure: string;
        unitPrice: Currency;
        pricingUnitSize: Quantity;
      }[]
    >(data.items, { deep: true });
    return {
      items: orders.length
        ? orders.map((order): QuoteLineItemInfo['last'] => ({
            orderDate: order.invoiceDate,
            qtyOrdered: order.qtyRequested.amount,
            unitOfMeasure: {
              symbol: order.unitOfMeasure,
              name: '',
              size: order.pricingUnitSize.amount,
              tags: [],
            },
            unitPrice: order.unitPrice,
          }))[0]
        : null,
    };
  } catch (error) {
    captureError(error);
    return { error: true };
  }
}

// NOTE: This is used to handle missing features in V4
// Currently, recommended price and last price don't work in an ideal way and are also slow to calculate, so V4 tries an agnostic other thing
async function getItemPriceInfoV3(
  itemInvMastUId: string,
  params: LineItemInfoCacheValue['params'],
): Promise<LineItemPriceInfoV3> {
  const { data } = await legacyApiFetch(`/v3/items/${encodeLegacyApiParam(itemInvMastUId)}/price`, {
    method: 'GET',
    data: objRemoveUndefinedValues({
      company_id: params.companyId,
      customer_id: params.customerId,
      location_id: params.locationId,
      ship_to_id: params.shipToId,
    }),
  });

  return camelcaseKeys<LineItemPriceInfoV3>(data, { deep: true });
}

async function getItemPriceMulti(
  companyId: string,
  customerId: string,
  locationId: string,
  shipToId: string,
  lines: Array<LineItemRequest>,
): Promise<ItemsOrError<ItemPriceResponseV4>[]> {
  try {
    const { data } = await legacyApiFetch(`/v4/items/price/multi`, {
      method: 'POST',
      data: {
        company_id: companyId,
        customer_id: customerId,
        location_id: locationId,
        ship_to_id: shipToId,
        lines,
      },
    });
    const result = camelcaseKeys<FIXME>(data, { deep: true });

    return result.map((line: FIXME) => {
      if (line.error) {
        return { error: true };
      }
      return {
        items: line.results,
      };
    });
  } catch (error) {
    captureError(error);
    return lines.map(() => ({ error: true }));
  }
}

export async function getItemPriceInfoMulti(
  companyId: string,
  customerId: string,
  locationId: string,
  shipToId: string,
  lines: Array<LineItemRequest>,
): Promise<QuoteLineItemInfo[]> {
  const priceInfoResponseArray = await getItemPriceMulti(companyId, customerId, locationId, shipToId, lines);

  return Promise.all(priceInfoResponseArray.map((line) => transformPriceResponse(line)));
}
