import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Button as ButtonComponent } from "components/Button";
import { Typography } from "components/Typography";
import { media } from "utils/responsive";
export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin: 24px 0;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 8px;
  ${media.lg`
    margin-bottom: 0px;
  `}
`;
export const HeaderCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const LeftIcon = styled(ArrowLeftOutlined)`
  margin-right: 0px;

  ${media.lg`
    margin-right: 8px;
  `}
`;
export const Button = styled(ButtonComponent)`
  margin-right: 8px;
  cursor: pointer;
  border: none;
  padding: 0;
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;
export const SubTitleText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;

  ${media.lg`
    width: 300px;
  `}

  ${media.xl`
    width: 400px;
  `}

  ${media.xxl`
    width: 500px;
  `}
`;
export const CopyIcon = styled.div`
  display: flex;
  align-items: center;

  margin-left: 8px;
  padding: 2px;

  border: 1px solid ${(props) => props.theme.colors.gray500};
  border-radius: 4px;
  cursor: pointer;

  width: 24px;
  height: 28px;

  svg {
    color: ${(props) => props.theme.colors.gray500};
    width: 18px;
    height: 18px;
  }
`;
