import { Radio } from "antd";
import styled from "styled-components";
const radius = {
  small: "4px",
  middle: "8px",
  large: "10px"
};
const getBorderRadius = (size = "middle") => radius[size];
export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper:first-child {
    border-radius: ${(props) => `${getBorderRadius(props.size)} 0px 0px ${getBorderRadius(props.size)}`};
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: ${(props) => `0px ${getBorderRadius(props.size)} ${getBorderRadius(props.size)} 0px`};
  }
`;
