/**
 * We often switch on frontend for tenant specific functionality to release an alpha/beta feature.
 * Please don't abuse this, ideally we use tenant feature flags for switching,
 * Use the constants instead of hardcoding tenantIds throughout the app.
 */

export const tenantIds = {
  prod: {
    azparts: '0f057d85-af33-4f29-930e-9cb2491abd63',
    cwc: 'd8aa8977-7e0b-4cb3-8659-d1024a141b3f',
    galarson: '955341f3-7dc9-40e7-95c7-67b85a37d46c',
    gtire: '74e763ef-1a52-488b-9dfd-8bdde6675db8',
    hoff: '511fa1ab-5fb7-4296-8182-478c63f77425',
    ims: 'c5b9619e-6c9e-432f-92ec-7ec31f2f2b68',
    pace_intl: '00e87877-f917-4810-997c-93c60c4abc5c',
    safeware: '4a709767-9354-4dca-9e09-a6d39d697185',
  },
  staging: {
    cwc: 'd9d4b3c7-c1e1-45ae-b803-92c25f4001b4',
    ims: '9054965b-cb46-45bc-a51d-62617a15c77e',
  },
};

export function isTenantIMS(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.ims || activeTenantId === tenantIds.staging.ims;
}

export function isTenantCWC(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.cwc || activeTenantId === tenantIds.staging.cwc;
}

export function isTenantSafeware(activeTenantId: string): boolean {
  return activeTenantId === tenantIds.prod.safeware;
}
