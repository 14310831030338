import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router';

import { CenteredLoader } from 'components/Loaders';

import { track, TrackEvent } from 'utils/track';

import { config } from 'settings/environment';

// TODO: make this an enum in settings/environment and standardize app-wide
const versionCheckEnvironments = ['production', 'staging'];
const versionCheckDomains = [
  'app.recurrency.ai',
  'app-staging.recurrency.ai',
  'app.recurrency.com',
  'app-staging.recurrency.com',
];

export const haveNewAppVersion = async () => {
  try {
    if (versionCheckEnvironments.includes(config.environment) && versionCheckDomains.includes(window.location.host)) {
      // NOTE: version.json is bundled when build is created
      const { version } = await (await fetch('/version.json')).json();
      const isLatestVersion = config.appVersion === version;
      track(TrackEvent.App_VersionPoll, { version, isLatestVersion });
      return !isLatestVersion;
    }
  } catch (err) {}
  return false;
};

export const reloadPage = () => {
  window.location.reload();
};

export const AppVersionPoller = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldReloadPage, setShouldReloadPage] = useState(false);

  useEffect(() => {
    setInterval(async () => {
      setShouldReloadPage(await haveNewAppVersion());
    }, 1000 * 60 * 5);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // only refresh the page when user navigates to a different page (pathname),
    // this ensures we don't disrupt the user's flow
    if (shouldReloadPage) {
      setIsLoading(true);
      reloadPage();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return children;
};
