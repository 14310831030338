import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { Button } from 'components/Button';
import { Container } from 'components/Layout/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';

import { useLegacyApi } from 'hooks/useApi';

import { routes, encodeLegacyApiParam, IdPathParams, usePathParams } from 'utils/routes';

import { getTabs } from './Tabs/getTabs';

export const VendorDetailsPage = () => {
  const history = useHistory();
  const { id } = usePathParams<IdPathParams>();
  const { data, isLoading, error } = useLegacyApi(`/v3/vendors/${encodeLegacyApiParam(id)}`);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !data) {
    return <CenteredLoader />;
  }

  if (Object.keys(data).length === 0) {
    return <NoDataMessage />;
  }

  const { vendorId, vendorName } = data;

  const tabs = getTabs(id);

  return (
    <Container>
      <PageHeader
        prefix="Vendor #"
        title={vendorId}
        subTitle={vendorName}
        headerActions={
          <Button
            disabled
            type="primary"
            onClick={() => {
              history.push(
                routes.purchasing.purchaseOrderNew({
                  vendorId,
                  vendorName,
                }),
              );
            }}
          >
            <PlusOutlined /> New PO
          </Button>
        }
        onBack={onBack}
        copyable
      />
      <NavTabs tabs={tabs} />
    </Container>
  );
};
