/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { createChart, LineData, BarData, IChartApi, isBusinessDay } from 'lightweight-charts';
import moment from 'moment';
import { ResponsiveContainer } from 'recharts';

import { usePreviousVal } from 'hooks/usePreviousVal';

import * as Styled from './FinanceChart.style';

type ForecastData = {
  prev: LineData[] | BarData[];
  future: LineData[] | BarData[];
};

export type FinanceChartProps = {
  type?: 'area' | 'line';
  data: ForecastData;
  tooltipPrefix?: string;
};

// constant height for charts
const HEIGHT = 300;

export const FinanceChart = (props: FinanceChartProps) => {
  const containerRef = React.useRef<ResponsiveContainer>(null);

  // @ts-expect-error (converted from ts-ignore)
  const [width, setWidth] = useState(containerRef?.current?.container?.clientWidth ?? 500);

  const isClient = typeof window === 'object';
  useEffect(() => {
    if (!isClient) return;
    function handleResize() {
      // @ts-expect-error (converted from ts-ignore)
      setWidth(containerRef?.current?.container?.clientWidth ?? width);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <ResponsiveContainer ref={containerRef}>
      <TradingView {...props} width={width} />
    </ResponsiveContainer>
  );
};

export type TradingViewProps = {
  type?: 'area' | 'line';
  data: ForecastData;
  width: number;
  tooltipPrefix?: string;
};

const TradingView = React.memo(
  ({ type = 'area', data = { prev: [], future: [] }, tooltipPrefix = '', width, ...others }: TradingViewProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [chartCreated, setChartCreated] = useState<IChartApi>();
    const [chartDataAdded, setChartDataAdded] = useState(false);

    const dataPrev = usePreviousVal(data);

    useEffect(() => {
      if (data !== dataPrev && chartCreated) {
        // remove the tooltip element
        const tooltip = document.getElementById('tooltip-id');
        const node = document.getElementById('finance-chart');
        if (tooltip) {
          node?.removeChild(tooltip);
        }
        chartCreated.resize(0, 0);
        setChartCreated(undefined);
        setChartDataAdded(false);
      }
    }, [chartCreated, data, dataPrev]);

    // responsiveness
    useEffect(() => {
      if (width && chartCreated) {
        chartCreated.resize(width, HEIGHT);
        chartCreated.timeScale().scrollToPosition(0, true);
      }
    }, [chartCreated, width]);

    useEffect(() => {
      if (!chartCreated && ref.current) {
        // remove prev one

        const chartEl = document.getElementsByClassName('tv-lightweight-charts')?.[0];
        const node = document.getElementById('finance-chart');
        if (chartEl) {
          node?.removeChild(chartEl);
        }

        const chart = createChart(ref.current, {
          width,
          height: HEIGHT,
          rightPriceScale: {
            scaleMargins: {
              top: 0.2,
              bottom: 0.2,
            },
            borderVisible: false,
          },
          timeScale: {
            borderVisible: false,
          },
        });

        setChartCreated(chart);
      }
    }, [chartCreated, width]);

    useEffect(() => {
      if (chartCreated && ref.current) {
        const { prev, future } = data;
        if (type === 'area' && !chartDataAdded) {
          const series = chartCreated.addAreaSeries({
            topColor: '#3881F0',
            bottomColor: '#F0F6FF',
            lineColor: '#3881F0',
            lineWidth: 2,
          });
          series.setData(prev);
          const series2 = chartCreated.addAreaSeries({
            lineWidth: 2,
          });
          series2.setData(future);
          setChartDataAdded(true);

          // tooltip

          const tooltip = document.getElementById('tooltip-id');
          const node = document.getElementById('finance-chart');
          if (tooltip) {
            node?.removeChild(tooltip);
          }

          const toolTip = document.createElement('div');
          toolTip.setAttribute('id', 'tooltip-id');
          toolTip.className = 'tooltip-classname';
          ref.current.appendChild(toolTip);
          toolTip.style.display = 'none';
          toolTip.style.fontWeight = '500';
          toolTip.style.left = `${-4}px`;
          toolTip.style.top = `-${8}px`;
          toolTip.style.backgroundColor = 'rgba(255, 255, 255, .7)';

          const toolTipWidth = 100;
          const toolTipHeight = 80;
          const toolTipMargin = 15;
          // update tooltip
          chartCreated.subscribeCrosshairMove((param: any) => {
            if (
              !param.time ||
              param.point.x < 0 ||
              param.point.x > width ||
              param.point.y < 0 ||
              param.point.y > HEIGHT
            ) {
              toolTip.style.display = 'none';
              return;
            }

            function businessDayToString(businessDay: any) {
              return `${businessDay.year}-${businessDay.month}-${businessDay.day}`;
            }

            const dateStr = isBusinessDay(param.time)
              ? businessDayToString(param.time)
              : new Date(param.time * 1000).toLocaleDateString();

            toolTip.style.display = 'block';
            if (moment(dateStr).isBefore()) {
              const price = param.seriesPrices.get(series);
              toolTip.innerHTML = `${'<div style="color: #3881F0">Historical</div><div style="font-size: 18px; margin: 2px 0px">'}${
                Math.round(price * 100) / 100
              } ${tooltipPrefix}</div><div>${dateStr}</div>`;
            } else {
              const price = param.seriesPrices.get(series2);
              toolTip.innerHTML = `${'<div style="color: #33b400">Forecasted</div><div style="font-size: 18px; margin: 2px 0px">'}${
                Math.round(price * 100) / 100
              } ${tooltipPrefix}</div><div>${dateStr}</div>`;
            }

            const { y } = param.point;

            let left = param.point.x + toolTipMargin;
            if (left > width - toolTipWidth) {
              left = param.point.x - toolTipMargin - toolTipWidth;
            }

            let top = y + toolTipMargin;
            if (top > HEIGHT - toolTipHeight) {
              top = y - toolTipHeight - toolTipMargin;
            }

            toolTip.style.left = `${left}px`;
            toolTip.style.top = `${top}px`;
          });
        }
      }
    }, [chartCreated, data, type, width, chartDataAdded, tooltipPrefix]);

    return (
      <div {...others}>
        <Styled.ChartWrapper id="finance-chart" ref={ref} style={{ width: '100%' }} />
      </div>
    );
  },
);
