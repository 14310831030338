/* eslint-disable no-multi-assign */
import type {
  SearchResponse,
  SearchOptions as AlgoliaSearchParams,
  ObjectWithObjectID,
  MultipleQueriesQuery,
  SearchForFacetValuesResponse,
} from '@algolia/client-search';
import type { SearchClient } from 'algoliasearch';

import { objOmitKeys, objRemoveUndefinedValues } from 'utils/object';

import { AlgoliaInventoryItemPartial } from 'types/algolia-collections';

export const MAX_VALUES_PER_FACET = 100;
// using 20 so it divides cleanly with 100 and 1000
export const DEFAULT_HITS_PER_PAGE = 20;

export enum IndexName {
  Customers = '{tenantUid}_customers',
  Items = '{tenantUid}_items',
  Orders = '{tenantUid}_sales_orders',
  Vendors = '{tenantUid}_vendors',
  PurchaseOrders = '{tenantUid}_purchase_orders',
  SalesReps = '{tenantUid}_reps',
  // TODO: See https://github.com/recurrency/frontend/issues/1138
  // should ideally be called '{tenantUid}_locations'
  Branches = '{tenantUid}_branches',
  Forecasts = '{tenantUid}_forecast_item_locations',
}

export function getIndexNameForTenant(
  tenantUid: string,
  index: IndexName,
  /** usually the pre-ranked sort suffix e.g 'gm_ytd_asc' */
  virtualIndexSuffix?: string,
) {
  let indexName = index.replace('{tenantUid}', tenantUid);
  if (virtualIndexSuffix) {
    indexName += `_${virtualIndexSuffix}`;
  }
  return indexName;
}

export interface SearchOptions {
  indexName: IndexName;
  tenantId: string;
  query?: string;
  /** fields to return counts of */
  facetFields?: string[];
  /** string[] means OR, facet filters themseves are AND */
  facetValueFilters?: Obj<string[]>;
  facetNumericFilters?: Obj<{ min?: number; max?: number }>;
  facetSortValuesBy?: 'count' | 'alpha';
  fieldsToHighlight?: string[];
  fieldsToRetrieve?: string[];
  maxValuesPerFacet?: number;
  /** sort results by field. NOTE: needs a virtual index configured */
  sortBy?: { field: string; order: 'asc' | 'desc' };
  /** number of results per page */
  hitsPerPage?: number;
  /** page number */
  page?: number;
}

/** algolia search params that are shared with global search */
export const commonAlgoliaSearchParams = {
  // see https://www.algolia.com/doc/guides/managing-results/optimize-search-results/override-search-engine-defaults/in-depth/prefix-searching/#querytypeprefixall
  queryType: 'prefixAll' as const,
  // typoTolerance:min to increase signal:noise ratio (we have quite a few categories)
  typoTolerance: 'min' as const,
};

/**
 * Internal function that converts to algolia search params format
 * (which requires string mangling for filters)
 */
function convertToAlgoliaSearchParams(options: SearchOptions): AlgoliaSearchParams {
  const searchParams: AlgoliaSearchParams = {
    query: options.query,
    ...commonAlgoliaSearchParams,
    facets: options.facetFields,
    facetFilters:
      options.facetValueFilters &&
      Object.entries(options.facetValueFilters)?.map(([field, vals]) => vals.map((val) => `${field}:${val}`)),
    numericFilters:
      options.facetNumericFilters &&
      Object.entries(options.facetNumericFilters)
        ?.map(([field, { min, max }]) => [`${field}>=${min}`, `${field}<=${max}`])
        .flat(),
    sortFacetValuesBy: options.facetSortValuesBy,
    maxValuesPerFacet: options.maxValuesPerFacet,
    hitsPerPage: options.hitsPerPage || DEFAULT_HITS_PER_PAGE,
    page: options.page,
    attributesToHighlight: options.fieldsToHighlight,
    attributesToRetrieve: options.fieldsToRetrieve,
  };

  // remove undefined values otherwise algolia api will fail with 400 Bad Request
  return objRemoveUndefinedValues(searchParams);
}

/**
 * Search an algolia index returning hits and facet counts, with filters, sorting and pagination
 */
export async function searchAlgoliaIndex<ObjectT extends ObjectWithObjectID>(
  searchClient: SearchClient,
  options: SearchOptions,
): Promise<SearchResponse<ObjectT>> {
  const searchIndexName = getIndexNameForTenant(
    options.tenantId,
    options.indexName,
    options.sortBy && `${options.sortBy.field}_${options.sortBy.order}`,
  );

  const queries: MultipleQueriesQuery[] = [
    { indexName: searchIndexName, params: convertToAlgoliaSearchParams(options) },
  ];

  // handle proper facet counting when there are fieldValueFilters
  // so we don't just get one {field:count}, but all the field counts (even unselected ones).
  // this is the same way that react-instant-search does it's magic
  // it's annoying that the default algolia api doesn't give this out of the box :(
  const multipleQueryFacetFieldNames: string[] = [/** first query is always the full query */ ''];
  if (options.facetFields?.length) {
    if (options.facetValueFilters) {
      for (const whereField of Object.keys(options.facetValueFilters)) {
        if (options.facetFields.includes(whereField)) {
          queries.push({
            indexName: searchIndexName,
            params: convertToAlgoliaSearchParams({
              ...options,
              // we just need the facet counts (without the filter being applied - so we get all the counts)
              facetFields: [whereField],
              facetValueFilters: objOmitKeys(options.facetValueFilters, whereField),
              hitsPerPage: 1,
              page: 0,
              fieldsToRetrieve: [],
              fieldsToHighlight: [],
            }),
          });
          multipleQueryFacetFieldNames.push(whereField);
        }
      }
      if (options.facetNumericFilters) {
        for (const whereNumField of Object.keys(options.facetNumericFilters)) {
          if (options.facetFields.includes(whereNumField)) {
            queries.push({
              indexName: searchIndexName,
              params: convertToAlgoliaSearchParams({
                ...options,
                facetFields: [whereNumField],
                facetNumericFilters: objOmitKeys(options.facetNumericFilters, whereNumField),
                hitsPerPage: 1,
                page: 0,
                fieldsToRetrieve: [],
                fieldsToHighlight: [],
              }),
            });
            multipleQueryFacetFieldNames.push(whereNumField);
          }
        }
      }
    }
  }

  const { results } = await searchClient.multipleQueries(queries);

  // merge the facet counts with the first query (that includes the results)
  const firstResult = results[0];
  for (let resultIdx = 1; resultIdx < results.length; ++resultIdx) {
    const fieldName = multipleQueryFacetFieldNames[resultIdx];
    if (
      // fieldName could be a numeric filter, check it's a value filter
      options.facetValueFilters?.[fieldName] !== undefined &&
      results[resultIdx].facets?.[fieldName] !== undefined
    ) {
      firstResult.facets = firstResult.facets ?? {};
      firstResult.facets[fieldName] = results[resultIdx].facets![fieldName];
    } else if (results[resultIdx].facets_stats?.[fieldName] !== undefined) {
      firstResult.facets_stats = firstResult.facets_stats ?? {};
      firstResult.facets_stats[fieldName] = results[resultIdx].facets_stats![fieldName];
    }
  }

  return results[0] as SearchResponse<ObjectT>;
}

/**
 * Search for facet values.
 * Used when number of values > 100, and user needs to search for more
 */
export function searchAlgoliaIndexForFacetValues(
  searchClient: SearchClient,
  facetField: string,
  facetQuery: string,
  options: SearchOptions,
): Promise<SearchForFacetValuesResponse> {
  const searchIndexName = getIndexNameForTenant(
    options.tenantId,
    options.indexName,
    options.sortBy && `${options.sortBy.field}_${options.sortBy.order}`,
  );
  // omit filter for currently searched facet
  const facetSearchOptions = {
    ...options,
    facetFields: [facetField],
    hitsPerPage: MAX_VALUES_PER_FACET,
    facetValueFilters: objOmitKeys(options.facetValueFilters || {}, facetField),
    page: 0,
  };

  const results = searchClient
    .initIndex(searchIndexName)
    .searchForFacetValues(facetField, facetQuery, convertToAlgoliaSearchParams(facetSearchOptions));
  return results as Promise<SearchForFacetValuesResponse>;
}

// maintain a lookup of itemId -> algolia item
// so we don't hit the algolia api twice for the same item
const algoliaItemByIdCache = new Map<string, Promise<AlgoliaInventoryItemPartial>>();

export async function getAlgoliaItemByItemId(
  itemId: string,
  searchClient: SearchClient,
  tenantId: string,
): Promise<AlgoliaInventoryItemPartial> {
  if (!algoliaItemByIdCache.has(itemId)) {
    algoliaItemByIdCache.set(
      itemId,
      searchAlgoliaIndex<AlgoliaInventoryItemPartial>(searchClient, {
        tenantId,
        indexName: IndexName.Items,
        query: itemId,
        fieldsToRetrieve: ['item_id', 'inv_mast_uid', 'item_desc', 'extended_desc'],
        fieldsToHighlight: [],
      }).then((algoliaResponse) => {
        const item = algoliaResponse.hits.find((hit) => hit.item_id === itemId);
        if (!item) {
          throw new Error(`itemId:'${itemId}' not found in algolia items collection`);
        }
        return item;
      }),
    );
  }

  return algoliaItemByIdCache.get(itemId)!;
}
