import React, { useEffect, useMemo } from 'react';

import { Form, FormInstance } from 'antd';
import moment from 'moment';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useCarriersSelectProps,
  useCompaniesSelectProps,
  useContactsSelectProps,
  useCustomersSelectProps,
  useFreightsSelectProps,
  useLocationsSelectProps,
  useShipTosSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { TextArea } from 'components/TextArea';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { joinIfIdNameObj, splitIdNameStr } from 'utils/formatting';
import { objKeys, objPickKeys } from 'utils/object';
import { OrderType } from 'utils/track';
import { validatePONumber } from 'utils/validation';

import { QuoteEditHashState } from 'types/hash-state';

import * as Styled from './QuoteHeader.style';

export const QuoteHeader = ({
  form,
  quoteState,
  onQuoteStateChange,
  orderType,
}: {
  form: FormInstance<QuoteEditHashState>;
  quoteState: QuoteEditHashState;
  onQuoteStateChange: (newState: QuoteEditHashState) => void;
  orderType: OrderType;
}) => {
  const { activeTenant } = useGlobalApp();
  const defaultQuoteFieldData = activeTenant.defaultData.quote;

  useEffect(() => {
    // explicit pick, so undefined values are explicitly set, and override internal form state
    const fieldsValue = objPickKeys(
      quoteState,
      'company',
      'customer',
      'shipTo',
      'contact',
      'location',
      'freightType',
      'carrier',
      'requiredDate',
      'validUntilDate',
      'poNo',
      'comments',
    );
    // use quoteState as the source of state
    form.setFieldsValue(fieldsValue);
  }, [form, quoteState]);

  const companyId = quoteState.company ? splitIdNameStr(quoteState.company).foreignId : '';

  const customerId = quoteState.customer ? splitIdNameStr(quoteState.customer).foreignId : '';

  const companiesSelectProps = useCompaniesSelectProps();
  const customersSelectProps = useCustomersSelectProps();
  const freightsSelectProps = useFreightsSelectProps({ companyId });
  const contactsSelectProps = useContactsSelectProps({ companyId, customerId });
  const shipTosSelectProps = useShipTosSelectProps({ companyId, customerId });
  const locationsSelectProps = useLocationsSelectProps();
  const carriersSelectProps = useCarriersSelectProps();

  const smartFieldDefaults = {
    company: {
      options: companiesSelectProps.options,
      default: joinIfIdNameObj(defaultQuoteFieldData.company),
    },
    // freight type is also defaulted based on the ship to chosen as a higher priority
    freightType: {
      options: freightsSelectProps.options,
      default: joinIfIdNameObj(defaultQuoteFieldData.freightType),
    },
    shipTo: {
      options: shipTosSelectProps.options,
      default: undefined,
    },
    carrier: {
      options: carriersSelectProps.options,
      default: joinIfIdNameObj(defaultQuoteFieldData.carrier),
    },
  };

  // make smart default selection for field values
  // either the settings specified default, or the only item in options
  for (const field of objKeys(smartFieldDefaults)) {
    const smartDefault = smartFieldDefaults[field];
    if (!quoteState[field]) {
      const defaultValue = smartDefault.default || (smartDefault.options.length === 1 && smartDefault.options[0].value);
      if (defaultValue) {
        requestAnimationFrame(() => {
          onQuoteStateChange({ [field]: defaultValue });
        });
      }
    }
  }

  // add date field defaults
  if (!quoteState.requiredDate) {
    requestAnimationFrame(() => {
      onQuoteStateChange({ requiredDate: moment().toISOString() });
    });
  }
  if (orderType === OrderType.Quote && !quoteState.validUntilDate) {
    requestAnimationFrame(() => {
      onQuoteStateChange({ validUntilDate: moment().add(7, 'd').toISOString() });
    });
  }

  const quickButtons = useMemo(
    () => [
      { name: 'in a week', value: moment().add(7, 'd') },
      { name: 'in a month', value: moment().add(1, 'M') },
    ],
    [],
  );

  return (
    <Styled.HeaderContainer>
      <Form
        form={form}
        onFinishFailed={console.error}
        onValuesChange={(changedValues) => onQuoteStateChange(changedValues)}
        {...responsiveFormLayout}
      >
        <Form.Item
          name="company"
          label="Company"
          rules={[{ required: true, message: 'Please add a company.' }]}
          hidden={companiesSelectProps.options.length <= 1}
        >
          <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" />
        </Form.Item>
        {companiesSelectProps.options.length > 1 && <br />}

        <Form.Item name="customer" label="Customer" rules={[{ required: true, message: 'Please add a customer.' }]}>
          <AsyncSelect
            selectProps={customersSelectProps}
            entityPlural="customers"
            onSelect={() => {
              // clear form when customer changes if there was a customer
              if (quoteState.customer) {
                onQuoteStateChange({
                  contact: undefined,
                  shipTo: undefined,
                  freightType: undefined,
                  location: undefined,
                  carrier: undefined,
                  requiredDate: undefined,
                  validUntilDate: undefined,
                  poNo: undefined,
                  comments: undefined,
                  items: undefined,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item name="shipTo" label="Ship To" rules={[{ required: true, message: 'Please add a ship to.' }]}>
          <AsyncSelect
            selectProps={shipTosSelectProps}
            entityPlural="ship tos"
            disabled={!customerId}
            placeholder={!customerId ? 'Please select a customer first' : ''}
            // defaulting to the freight type associated with the ship to as it's location agnostic, just based on the ship to itself
            onSelect={(_, option) =>
              option.freightType ? onQuoteStateChange({ freightType: joinIfIdNameObj(option.freightType) }) : undefined
            }
          />
        </Form.Item>
        <Form.Item name="contact" label="Contact">
          <AsyncSelect
            selectProps={contactsSelectProps}
            entityPlural="contacts"
            disabled={!customerId}
            placeholder={!customerId ? 'Please select a customer first' : ''}
            allowClear
          />
        </Form.Item>
        <br />

        <Form.Item
          name="requiredDate"
          label="Required Date"
          rules={[{ required: true, message: 'Please add a required date.' }]}
        >
          <IsoDatePicker quickButtons={quickButtons} />
        </Form.Item>
        {orderType === OrderType.Quote && (
          <Form.Item
            name="validUntilDate"
            label="Valid Until Date"
            rules={[{ required: true, message: 'Please add a valid until date.' }]}
          >
            <IsoDatePicker quickButtons={quickButtons} />
          </Form.Item>
        )}

        <br />

        <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Please add a location.' }]}>
          <AsyncSelect selectProps={locationsSelectProps} entityPlural="locations" />
        </Form.Item>
        <Form.Item
          name="freightType"
          label="Freight Type"
          rules={[{ required: true, message: 'Please add a freight code.' }]}
        >
          <AsyncSelect selectProps={freightsSelectProps} entityPlural="freight codes" />
        </Form.Item>
        <Form.Item name="carrier" label="Carrier">
          <AsyncSelect selectProps={carriersSelectProps} entityPlural="carriers" allowClear />
        </Form.Item>
        <br />

        <Form.Item
          name="poNo"
          label="PO No"
          rules={[
            {
              required: orderType === OrderType.Order,
              message: 'Please add a customer PO number.',
            },
            { validator: validatePONumber },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="comments" label="Comments">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Styled.HeaderContainer>
  );
};
