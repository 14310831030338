import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { User, UserEmailEvent } from '@recurrency/core-api/dist/api';
import { Input, message } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';

import { Button } from 'components/Button';
import { ButtonLink } from 'components/Links';
import { Table } from 'components/Table';

import { api } from 'utils/api';
import { formatDate, formatHumanDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { typedColumn } from 'utils/tables';

const columns: ColumnType<User>[] = [
  typedColumn({
    title: 'User ID',
    dataIndex: 'id',
    render: (id: string) => <Link to={routes.internal.userDetails(id)}>{id}</Link>,
  }),
  typedColumn({
    title: 'First Name',
    dataIndex: 'firstName',
  }),
  typedColumn({
    title: 'Last Name',
    dataIndex: 'lastName',
  }),
  typedColumn({
    title: 'Email',
    dataIndex: 'email',
  }),
  typedColumn({
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (date) => formatDate(date),
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    sortDirections: ['descend', 'ascend'],
  }),
  typedColumn({
    title: 'Updated At',
    dataIndex: 'updatedAt',
    render: (date) => formatDate(date),
    sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
    sortDirections: ['descend', 'ascend'],
  }),
  typedColumn({
    title: 'Last Welcomed',
    dataIndex: 'emailEvents',
    render: (emailEvents?: UserEmailEvent[]) =>
      emailEvents && emailEvents.length > 0 && formatHumanDate(emailEvents[emailEvents.length - 1].createdAt),
  }),
  {
    render: (id) => ButtonLink(routes.internal.userDetails(id)),
  },
];

export const UserTable = ({ users, isLoading, title }: { users: User[]; isLoading: boolean; title?: string }) => {
  const [query, setQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  let filteredUsers: { firstName: string; lastName: string; email: string }[] = [];
  if (users) {
    filteredUsers = fuzzyFilter(users, query, {
      fields: ['firstName', 'lastName', 'email'],
    }).map(({ item }) => item);
  }

  const sendWelcomeEmails = async () => {
    setIsSubmitting(true);
    // Send welcome emails to each selected user
    await Promise.allSettled(selectedRowKeys.map((key) => api().users().sendWelcomeEmail({ id: key }))).then(
      (results) => {
        // Only keep the failed emails selected
        const mask = results.map((result) => result.status === 'rejected');
        setSelectedRowKeys(selectedRowKeys.filter((_, i) => mask[i]));
        // Show message based on result
        if (selectedRowKeys.filter((_, i) => mask[i]).length === 0) {
          message.success('Welcome emails sent!');
        } else {
          message.error(
            `${
              selectedRowKeys.filter((_, i) => mask[i]).length
            } welcome emails failed. All currently selected users did not receive emails.`,
          );
        }
      },
    );
    setIsSubmitting(false);
  };

  return (
    <Table
      columns={columns}
      data={filteredUsers}
      rowKey="id"
      isLoading={isLoading}
      rowSelection={rowSelection}
      title={title}
    >
      <>
        <div style={{ marginBottom: '1rem', display: 'flex' }}>
          <Input
            placeholder="Search users"
            title="search"
            onChange={(ev) => setQuery(ev.target.value)}
            style={{ maxWidth: '300px' }}
          />
        </div>
        <div style={{ marginBottom: '1rem', display: 'flex' }}>
          <Button
            type="primary"
            onClick={sendWelcomeEmails}
            disabled={selectedRowKeys.length === 0}
            loading={isSubmitting}
          >
            Send Welcome Email ({selectedRowKeys.length} users selected)
          </Button>
          <Button type="primary" style={{ marginLeft: 'auto' }}>
            <Link to={routes.internal.userNew()}>
              <PlusOutlined /> New User
            </Link>
          </Button>
        </div>
      </>
    </Table>
  );
};
