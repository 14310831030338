import { Rule } from 'antd/lib/form';

export const validatePrice = (rawPrice: string | number) => {
  if (!rawPrice || Number.isNaN(Number(rawPrice))) {
    return '';
  }

  const newPrice = Number(rawPrice);
  if (newPrice < 0.1) {
    return '';
  }
  return newPrice;
};

export const validateGM = (rawGM: string | number) => {
  if (!rawGM || Number.isNaN(Number(rawGM))) {
    return '';
  }

  const newGM = Math.min(100, Math.max(0, Number(rawGM)));
  if (newGM < 0.1) {
    return '';
  }
  return newGM;
};

const disallowedPOSymbols = {
  '`': 'a backtick',
  // eslint-disable-next-line
  "'": 'a single quote',
  '"': 'a double quote',
  '~': 'a tilde',
  '’': 'an apostrophe',
};

export const validatePONumber = (_: Rule, rawPONumber: string) => {
  if (rawPONumber) {
    if (rawPONumber.length > 50) {
      return Promise.reject(new Error('PO Number maximum length is 50'));
    }
    for (const [character, characterName] of Object.entries(disallowedPOSymbols)) {
      if (rawPONumber.includes(character)) {
        return Promise.reject(new Error(`PO Number cannot contain ${characterName}.`));
      }
    }
  }
  return Promise.resolve();
};
