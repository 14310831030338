import React, { useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeature } from '@recurrency/core-api';
import { Divider, message } from 'antd';
import { AxiosError } from 'axios';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { Button } from 'components/Button';
import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { EntityDescriptions } from 'components/Descriptions';
import { Container } from 'components/Layout/Container';
import { ButtonLink } from 'components/Links';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { Tag } from 'components/Tag';

import { useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinIdNameObj, formatDate } from 'utils/formatting';
import { routes, encodeLegacyApiParam, usePathParams, IdPathParams } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { Order } from 'types/legacy-api';

import { AddressBar } from './AddressBar';
import { OrderLinesTable } from './OrderLinesTable';
import { OrderTransferLinesTable } from './OrderTransferLinesTable';

interface Description {
  label: string;
  render: string | React.ReactNode;
  span?: number;
}

export const OrderDetailsView = ({ order }: { order: Order }) => {
  const descriptions: Description[] = [
    {
      label: 'Customer',
      render:
        order?.customerId && order.customer?.customerName ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 10 }}>
              <Link to={routes.sales.customerDetails(order.customerId)}>
                {`${order.customer.customerName} (#${order.customerId})`}
              </Link>
            </div>
            <div>{ButtonLink(routes.sales.customerDetails(order.customerId))}</div>
          </div>
        ) : (
          'N/A'
        ),
      span: 2,
    },
    { label: 'Order Date', render: order.orderDate ? formatDate(order.orderDate) : 'N/A' },
    { label: 'PO No', render: order.poNo ? order.poNo : 'N/A' },
    { label: 'Taker', render: order?.taker ? order.taker : 'N/A' },
    {
      label: 'Location',
      render: order?.location?.locationId
        ? joinIdNameObj({
            foreignId: order.location?.locationId,
            name: order.location?.locationName,
          })
        : 'N/A',
    },
  ];

  if (order.purchaseOrder) {
    descriptions.push({
      label: 'Dropship PO No',
      render: (
        <Link to={routes.purchasing.purchaseOrderDetails(order.purchaseOrder.poNo)}>{order.purchaseOrder.poNo}</Link>
      ),
    });
  }

  return (
    <>
      <EntityDescriptions bordered descriptions={descriptions} />
      <Divider />
      <AddressBar order={order} />
      <Divider />
      <NavTabs
        tabs={[
          { header: 'Order Lines', content: <OrderLinesTable orderLines={order.lines || []} /> },
          { header: 'Transfer Lines', content: <OrderTransferLinesTable orderNo={order.orderNo} /> },
        ]}
      />
    </>
  );
};

export const OrderDetailsPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  const [isConvertQuotePending, setIsConvertQuotePending] = useState(false);
  const { data: order, error, isLoading } = useLegacyApi<Order>(`/v3/orders/${encodeLegacyApiParam(id)}`);

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !order) {
    return <CenteredLoader />;
  }

  if (Object.keys(order).length === 0) {
    return <NoDataMessage />;
  }

  if (order) {
    order.complete = order.completed === 'Y';
    order.canceled = order.cancelFlag === 'Y';
  }
  const status = order?.canceled ? 'Canceled' : order?.complete ? 'Completed' : 'Open';
  const typeLabel = `${status} ${order?.quote ? 'Quote' : 'Order'}`;

  const colorStatus = order?.canceled ? 'red' : order?.complete ? 'green' : 'blue';
  const colorLabel = order?.quote ? 'purple' : colorStatus;

  const handleConvertQuote = async () => {
    setIsConvertQuotePending(true);
    await api()
      .quotes()
      .convertQuoteToOrder({
        tenantId: activeTenant.id,
        foreignId: id,
        userId: activeUser.id,
      })
      .then(() => {
        message.success(`Quote converted to order, it may take up to five minutes to sync.`);
        track(TrackEvent.Orders_ConvertQuoteToOrder, {});
        history.push(routes.orders.orderList());
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          message.error(err.response.data.message);
        } else {
          captureAndShowError(
            err,
            `converting quote to order`,
            `Try again in a few minutes or click 'Copy as New Quote`,
          );
        }
      });
    setIsConvertQuotePending(false);
  };

  return (
    <Container>
      <PageHeader
        onBack={() => history.goBack()}
        title={`${typeLabel} #${id}`}
        headerActions={
          <div>
            <Tag color={colorLabel}>{typeLabel}</Tag>
            {order?.quote && shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersConvertQuoteToOrder) && (
              <Button
                loading={isConvertQuotePending}
                type="primary"
                style={{ marginRight: '10px' }}
                onClick={handleConvertQuote}
              >
                <PlusOutlined /> Convert Quote to Order
              </Button>
            )}
            <NewQuoteButton
              label="Copy as New Quote"
              hashState={{
                customer:
                  order.customer && joinIdNameObj({ foreignId: order.customerId, name: order.customer.customerName }),
                contact:
                  order.contact &&
                  joinIdNameObj({
                    foreignId: order.contactId,
                    name: `${order.contact.firstName} ${order.contact.lastName}`,
                  }),
                shipTo: order.ship2Name && joinIdNameObj({ foreignId: order.addressId, name: order.ship2Name }),
                location:
                  order.location &&
                  joinIdNameObj({ foreignId: order.location.locationId, name: order.location.locationName }),
                poNo: order.poNo,
                items: order.lines.map((orderLine) => ({
                  foreignId: orderLine.item.itemId,
                  name: orderLine.item.itemDesc,
                  description: orderLine.extendedDesc,
                  unitOfMeasure: orderLine.unitOfMeasure,
                  quantity: orderLine.qtyOrdered,
                  price: orderLine.unitPrice,
                })),
              }}
            />
          </div>
        }
        noColon
      />
      <OrderDetailsView order={order} />
    </Container>
  );
};
