import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  sortableStringColumn,
  sortableDateColumn,
  sortableDollarColumn,
  idColumn,
  sortableBooleanColumn,
} from 'utils/tables';

export const PurchaseOrders = ({ vendorId, open }: { vendorId: string | undefined; open?: boolean }) => {
  const columns: ColumnType<FIXME>[] = [
    sortableStringColumn({
      title: 'PO No',
      dataIndex: 'poNo',
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
    }),
    idColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: FIXME) => `${record.locationId}: ${record.locationName}`,
    }),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: 'orderDate',
      defaultSortOrder: 'descend',
    }),
    sortableDollarColumn({
      title: 'Purchase Value',
      dataIndex: 'purchaseValue',
    }),
    sortableBooleanColumn({
      title: 'Complete',
      dataIndex: 'complete',
    }),
    {
      render: ({ poNo }: { poNo: any }) => ButtonLink(routes.purchasing.purchaseOrderDetails(poNo)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/vendors/${encodeLegacyApiParam(vendorId || '')}/purchase-orders`,
        queryParams: open ? { open } : undefined,
      })}
      columns={columns}
      rowKey="poNo"
    />
  );
};
