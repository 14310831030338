import React, { useState, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { sortableStringColumn, salesAndGMColumns, idColumn, getProductGroupSearchRoute } from 'utils/tables';

export const TopProductGroups = () => {
  const [isPercent, setIsPercent] = useState(false);
  const columns = useMemo(
    () => [
      idColumn({
        title: 'Product Group ID',
        dataIndex: 'productGroupId',
        render: (productGroupId: string, { productGroupDesc }: { productGroupDesc: string }) => (
          <Link to={getProductGroupSearchRoute(productGroupId, productGroupDesc)}>{productGroupId}</Link>
        ),
      }),
      sortableStringColumn({
        title: 'Product Group',
        dataIndex: 'productGroupDesc',
      }),
      ...salesAndGMColumns({ isPercent }),
      {
        render: ({ productGroupId, productGroupDesc }) =>
          ButtonLink(getProductGroupSearchRoute(productGroupId, productGroupDesc)),
      },
    ],
    [isPercent],
  );

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({ apiPath: '/v3/sales/top-product-groups' })}
      columns={columns}
      isPercent={isPercent}
      setIsPercent={setIsPercent}
      rowKey="productGroupId"
    />
  );
};
