import React from 'react';

import type { ColumnType, TableProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';

import { Table } from 'components/Table';

import { SearchFrameSortByOrder } from 'types/hash-state';

export interface HitResultsTableProps<HitT> {
  hits: HitT[];
  columns: ColumnType<HitT>[];
  isPercent?: boolean;
  setIsPercent?: (newValue: boolean) => void;
  hitFn?: (values: HitT[]) => Any[];
  isLoading?: boolean;
  onSortChange?: (field: string, order: SearchFrameSortByOrder) => void;
  rowKey?: TableProps<HitT>['rowKey'];
  rowSelection?: TableProps<HitT>['rowSelection'];
  expandable?: ExpandableConfig<any>;
}

export function HitResultsTable<HitT>({
  hits,
  columns,
  isPercent,
  setIsPercent,
  hitFn,
  isLoading,
  onSortChange,
  rowKey,
  rowSelection,
  expandable,
}: HitResultsTableProps<HitT>) {
  const handleTableChange: TableProps<any>['onChange'] = (_pagination, _filters, sorter, { action }) => {
    // !Array.isArray is there to typeguard into one sorter field
    // all our search frame tables use single sorting, so this is okay
    if (action === 'sort' && !Array.isArray(sorter)) {
      // {x} as {Type} casts to appease tsc
      onSortChange?.(sorter.field as string, sorter.order === 'descend' ? 'desc' : 'asc');
    }
  };

  return (
    <Table
      columns={columns}
      isLoading={isLoading}
      data={hitFn ? hitFn(hits) : hits}
      rowKey={rowKey || 'objectId'}
      rowSelection={rowSelection}
      pagination={false}
      isPercent={isPercent}
      setIsPercent={setIsPercent}
      onChange={handleTableChange}
      expandable={expandable}
    />
  );
}
