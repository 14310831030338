import React from 'react';

import { Col } from 'antd';

import * as Styled from './StatsGroup.style';

export type GridType = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
};

export type StatsProp = {
  label: string;
  value: string;
};

export type Props = {
  data: StatsProp[];
  grid?: GridType;
  copyable?: boolean;
};

const defaultGridProps: GridType = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 4,
};

export const StatsGroup = (props: Props): JSX.Element => {
  const { data, grid = defaultGridProps, copyable, ...otherProps } = props;

  return (
    <Styled.Row {...otherProps}>
      {data.map(({ label, value }, index) => (
        <Col key={index} {...grid}>
          <Styled.Stats label={label} value={value} copyable={copyable} />
        </Col>
      ))}
      <Styled.FlexGrowItem />
    </Styled.Row>
  );
};
