import React from 'react';

import { Tooltip as AntDesignTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

export type Props = {
  title: string;
  placement?: TooltipPlacement;
  children: React.ReactChild;
  slow?: boolean;
  overlayInnerStyle?: React.CSSProperties;
};

export const Tooltip = ({ children, title = '', placement = 'top', slow, overlayInnerStyle }: Props): JSX.Element => (
  <AntDesignTooltip
    mouseEnterDelay={slow ? 0.15 : 0.1}
    title={title}
    placement={placement}
    overlayInnerStyle={overlayInnerStyle}
  >
    {children}
  </AntDesignTooltip>
);
