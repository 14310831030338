import React from 'react';

import { Grid } from 'antd';

import { Navigation } from 'components/Layout/Navigation';
import { CenteredAccountError } from 'components/Loaders';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { AppContainer, AppLayout, ContentLayout } from './App.style';
import { AppRoutes } from './AppRoutes';

export const App = () => {
  const { activeUser, activeTenant } = useGlobalApp();

  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;

  // don't render app if user doesn't load or has no tenants
  if (!activeUser || !activeTenant) {
    return <CenteredAccountError />;
  }

  return (
    <AppContainer>
      <AppLayout>
        <Navigation isDesktopView={isDesktopView}>
          <ContentLayout $isDesktopView={isDesktopView}>
            <div className="fadeIn">
              <AppRoutes />
            </div>
          </ContentLayout>
        </Navigation>
      </AppLayout>
    </AppContainer>
  );
};
