import React, { useEffect, useMemo } from 'react';

import { Row, Col } from 'antd';

import { PriceChart } from 'components/Charts/PriceChart';
import { StatsGroup } from 'components/StatsGroup';
import { Typography } from 'components/Typography';
import { UOMTable } from 'components/UOMTable';

import { useLegacyApi } from 'hooks/useApi';

import { formatNumber, formatPercent, formatDate, formatUSD } from 'utils/formatting';
import { encodeLegacyApiItemIdParam } from 'utils/routes';
import { validateGM } from 'utils/validation';

import { LineItemPriceInfoV3 } from 'types/legacy-api';

import { ItemDetailsWrapper, Loader } from './PricingPage.style';
import { SalesTable } from './SalesTable';

// NOTE: using React.memo here because something in this component is causing an infinite setState loop.
// Which causes "Maximum update depth exceeded" error.
export const ItemInfo = React.memo(
  ({
    customerId,
    itemInvMastUid,
    unitOfMeasure: selectedUOM,
    qty: selectedQty,
    setUOMs,
    setUOM,
    priceFieldName,
    setFieldsValue,
    setGm,
  }: {
    customerId: string;
    itemInvMastUid: string;
    unitOfMeasure: string;
    qty: number;
    setUOMs: (value: Array<{ value: string }>) => void;
    setUOM: (value: string) => void;
    priceFieldName: string;
    setFieldsValue: FIXME;
    setGm: (value: string) => void;
    // since the component is memo-ized but the internal table depends on hashState.tab, we need the prop
    // eslint-disable-next-line react/no-unused-prop-types
    tableTab: string | undefined;
  }) => {
    const {
      data: lineData,
      error,
      isLoading,
    } = useLegacyApi<LineItemPriceInfoV3>(`/v3/items/${encodeLegacyApiItemIdParam(itemInvMastUid)}/price`, {
      customer_id: customerId,
      unit_of_measure: selectedUOM,
      quantity: selectedQty,
    });

    useEffect(() => {
      if (!lineData) {
        return;
      }
      if (lineData.recommendedUnitPrice) {
        const suggestedPrice = formatNumber(lineData.recommendedUnitPrice);
        setFieldsValue({
          [priceFieldName]: suggestedPrice,
        });
      }
    }, [lineData, setFieldsValue, priceFieldName]);

    useEffect(() => {
      if (!lineData) {
        return;
      }
      const { recommendedUnitPrice, unitCost } = lineData;
      if (recommendedUnitPrice && unitCost) {
        const nextGm = validateGM(((recommendedUnitPrice - unitCost) / recommendedUnitPrice) * 100);
        if (nextGm) {
          setGm(formatNumber(nextGm));
        }
      }
    }, [setGm, lineData]);

    useEffect(() => {
      if (!lineData) {
        return;
      }
      setUOMs(
        lineData.uoms.map((data) => ({
          value: data.unitOfMeasure,
        })),
      );
      const defaultUOM = lineData.uoms.find((unitOfMeasure) => unitOfMeasure.default);
      if (!selectedUOM && defaultUOM) {
        setUOM(defaultUOM.unitOfMeasure);
      }
      // we should not check if `uoms` changes because if we do, we will get into an infinite loop
      // eslint-disable-next-line
    }, [lineData, setFieldsValue, setUOMs]);

    const stats = useMemo(() => {
      if (!lineData) {
        return [];
      }
      const profit = (lineData.recommendedUnitPrice || 0) - lineData.unitCost;
      const grossMargin = formatPercent(profit / (lineData.recommendedUnitPrice || 0));
      const lastPrice = lineData.lastUnitPrice;

      return [
        {
          label: 'Recommended Price',
          value: `${formatUSD(lineData.recommendedUnitPrice, true)}`,
        },
        {
          label: 'Gross Margin',
          value: grossMargin,
        },
        {
          label: 'Quantity Available',
          value: formatNumber(lineData.qtyOnHandAll),
        },
        {
          label: 'Last Price',
          value: lastPrice ? `${formatUSD(lastPrice, true)}` : '-',
        },
        {
          label: 'Last Qty',
          value: lineData.lastQtyOrdered ? formatNumber(lineData.lastQtyOrdered) : '-',
        },
        {
          label: 'Last Date',
          value: formatDate(lineData.lastOrderDate || ''),
        },
      ];
    }, [lineData]);

    if (error) {
      return (
        <ItemDetailsWrapper>
          <div>There was an error processing your request</div>
        </ItemDetailsWrapper>
      );
    }

    if (isLoading || !lineData) {
      return (
        <ItemDetailsWrapper>
          <Loader />
        </ItemDetailsWrapper>
      );
    }

    const { unitCost, stats: lineStats } = lineData;
    const { xys } = lineStats;

    return (
      <ItemDetailsWrapper>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Row gutter={24} style={{ marginBottom: '3rem' }}>
              <Col span={24}>
                <Typography type="large" style={{ textAlign: 'left', marginBottom: '2rem' }}>
                  Summary Stats
                </Typography>
                <StatsGroup data={stats} />
              </Col>
              {/* <Col span={12}>{history && history.length > 0 && <HistoryTable history={history} />}</Col> */}
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <Typography type="large" style={{ textAlign: 'left', marginBottom: '2rem' }}>
                  Price Popularity
                </Typography>
                <div style={{ height: '318px' }}>
                  <PriceChart data={xys} costAll={unitCost} />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Typography type="large" style={{ textAlign: 'left', marginBottom: '2rem' }}>
                  Unit of Measure
                </Typography>
                <UOMTable invMastUid={itemInvMastUid} />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <SalesTable itemId={itemInvMastUid} customerId={customerId} />
              </Col>
            </Row>
          </>
        )}
      </ItemDetailsWrapper>
    );
  },
);
