import styled from "styled-components";
import { Typography as TypographyComponent } from "components/Typography";
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Value = styled(TypographyComponent).attrs({
  weight: "500"
})`
  margin-top: 0px;
  margin-bottom: 5px;
`;
export const Label = styled(TypographyComponent).attrs({
  weight: "400"
})`
  margin-top: 5px;
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.gray};
`;
