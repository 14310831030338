import React from 'react';

import { Form } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FormItemProps } from 'antd/lib/form/FormItem';
import moment, { Moment } from 'moment';

import { DatePicker } from 'components/DatePicker';

export interface Props extends FormItemProps {
  name: string;
  size?: SizeType;
  defaultValue?: Moment;
}

const isDateDisabled = (date: Moment) =>
  // Can not select days before today
  date && date < moment().startOf('day');

export const DatePickerFormItem = ({ label, name, rules, ...otherProps }: Props): JSX.Element => (
  <Form.Item name={name} label={label} rules={rules}>
    <DatePicker disabledDate={isDateDisabled} {...otherProps} />
  </Form.Item>
);
