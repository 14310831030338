import React from 'react';

import { useHistory } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { Button } from 'components/Button';
import { Container } from 'components/Layout/Container';
import { PageHeader } from 'components/PageHeader';
import { Tasks as TasksComponent } from 'components/Tasks';

import { routes } from 'utils/routes';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

export const TasksListPage = () => {
  const history = useHistory();

  return (
    <Container>
      <PageHeader
        title="Tasks"
        noColon
        headerActions={
          <Button
            type="primary"
            onClick={() => {
              track(TrackEvent.Nav_CtaClick, {
                ctaName: CtaName.NewTask,
                ctaType: CtaType.Button,
              });
              history.push(routes.tasks.taskNew());
            }}
          >
            <PlusOutlined /> New Task
          </Button>
        }
      />
      <TasksComponent />
    </Container>
  );
};
