import styled from "styled-components";
export const Container = styled.div`
  width: 100vw;
`;
export const Splitter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.isDesktopView ? `${props.theme.primaryNavigationHeight}px` : 0};
`;
export const ContentWrapper = styled.div`
  padding-top: ${(props) => props.isDesktopView ? `${props.theme.tertiaryNavigationHeight}px` : 0};
  width: 100%;
`;
export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: ${(props) => props.isDesktopView ? `${props.theme.secondaryNavigationWidth}px` : "0"};
`;
