import React, { ReactChildren, ReactChild } from 'react';

import { Table as AntTable, Radio } from 'antd';
import { TablePaginationConfig, TableProps as AntTableProps } from 'antd/lib/table';
import { ColumnType, GetRowKey } from 'antd/lib/table/interface';

import { ResponsiveTable } from 'components/ResponsiveTable';

import * as Styled from './Table.style';

export const DEFAULT_PAGE_SIZE = 20;

// TODO: refactor props
// omitting 'title' because that type is different from antd Table
export interface TableProps extends Omit<AntTableProps<any>, 'title'> {
  isPercent?: boolean;
  id?: string;
  setIsPercent?: (value: boolean) => void;
  title?: string;
  data: any[];
  isLoading?: boolean;
  rowKey?: string | GetRowKey<any>;
  rowSelection?: FIXME;
  size?: FIXME;
  columns: ColumnType<FIXME>[];
  children?: ReactChildren | ReactChild;
  summary?: () => FIXME;
  bordered?: boolean;
  pagination?: false | TablePaginationConfig;
  responsive?: boolean;
  onRow?: FIXME;
  expandable?: FIXME;
  footer?: FIXME;
}

export const Table = (props: TableProps) => {
  const {
    id,
    bordered,
    columns,
    children,
    data,
    rowKey,
    isLoading,
    onRow,
    rowSelection,
    summary,
    size = 'small',
    title,
    isPercent,
    setIsPercent,
    responsive = true,
    expandable,
    footer,
    ...others
  } = props;
  let { pagination } = props;
  const percentSwitcher = setIsPercent ? (
    <Styled.RadioGroup
      onChange={({ target: { value } }) => {
        if (value === 'raw') {
          setIsPercent(false);
        } else {
          setIsPercent(true);
        }
      }}
      value={isPercent ? 'pct' : 'raw'}
    >
      <Radio.Button value="raw">Dollar View</Radio.Button>
      <Radio.Button value="pct">Percentage View</Radio.Button>
    </Styled.RadioGroup>
  ) : null;

  // default is simple pagination
  pagination = pagination === false ? false : { simple: true, pageSize: DEFAULT_PAGE_SIZE, ...pagination };

  if (pagination && data?.length <= (pagination.pageSize || DEFAULT_PAGE_SIZE)) {
    // don't show pagination if only one page to be displayed
    pagination = false;
  }

  return (
    <>
      {title && <Styled.Title type="h3">{title}</Styled.Title>}
      {percentSwitcher}
      {children}
      {responsive && (
        <ResponsiveTable
          id={id}
          columns={columns}
          rowSelection={rowSelection}
          size={size}
          dataSource={data}
          rowKey={rowKey}
          loading={isLoading}
          onRow={onRow}
          summary={summary}
          bordered={bordered}
          pagination={pagination}
          expandable={expandable}
          showSorterTooltip={false}
          footer={footer}
          {...others}
        />
      )}
      {!responsive && (
        <AntTable
          id={id}
          columns={columns}
          rowSelection={rowSelection}
          size={size}
          dataSource={data}
          rowKey={rowKey}
          loading={isLoading}
          onRow={onRow}
          summary={summary}
          bordered={bordered}
          pagination={pagination}
          expandable={expandable}
          footer={footer}
          {...others}
        />
      )}
    </>
  );
};
