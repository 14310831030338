import React, { useMemo } from 'react';

import { CheckSquareTwoTone, CloseSquareTwoTone } from '@ant-design/icons';
import { Form, Statistic, Divider } from 'antd';

import { Button } from 'components/Button';
import { EntityDescriptions } from 'components/Descriptions';
import { defaultFormTailLayout } from 'components/FormItems';
import { Table } from 'components/Table';
import { Tag } from 'components/Tag';

import { formatUSD, formatDate } from 'utils/formatting';
import { sortableNumberColumn, sortableStringColumn, sortableDollarWithCentsColumn } from 'utils/tables';

import { RECURRENCY_GREEN, RECURRENCY_RED, UNITS } from 'constants/styles';

export const OrderSubmit = ({
  lineItems,
  setCurrentStep,
  orderHeader,
}: {
  lineItems: FIXME;
  setCurrentStep: FIXME;
  orderHeader: FIXME;
}) => {
  const [form] = Form.useForm();

  const totalPrice = (lineItems || []).reduce((price: FIXME, item: FIXME) => {
    if (!isNaN(item.price)) {
      return Number(item.price) * Number(item.quantity) + price;
    }
    return price;
  }, 0);

  const columns = useMemo(
    () => [
      {
        title: 'Item ID',
        dataIndex: 'foreignId',
      },
      {
        title: 'Item Name',
        dataIndex: 'name',
      },
      sortableNumberColumn({
        title: 'Qty Ordered',
        dataIndex: 'quantity',
      }),
      sortableStringColumn({
        title: 'UOM',
        dataIndex: 'uom',
      }),
      sortableDollarWithCentsColumn({
        title: 'Unit Price',
        dataIndex: 'price',
      }),
    ],
    [],
  );

  const descriptions1 = useMemo(
    () => [
      {
        label: 'Approved',
        render: orderHeader.approved ? (
          <CheckSquareTwoTone style={{ fontSize: UNITS.LG, marginRight: '1px' }} twoToneColor={RECURRENCY_GREEN} />
        ) : (
          <CloseSquareTwoTone style={{ fontSize: UNITS.LG, marginRight: '1px' }} twoToneColor={RECURRENCY_RED} />
        ),
      },
      orderHeader.type && {
        label: 'Type',
        render: <Tag color="green">Purchase Order</Tag>,
      },
      orderHeader.date && { label: 'Order Date', render: formatDate(orderHeader.date) },
    ],
    [orderHeader.approved, orderHeader.date, orderHeader.type],
  );

  const descriptions2 = useMemo(
    () => [
      orderHeader.vendor && { label: 'Vendor', render: orderHeader.vendor, span: 3 },
      orderHeader.vendor && { label: 'Supplier', render: orderHeader.vendor, span: 2 },
      orderHeader.location && { label: 'Ship To', render: orderHeader.location, span: 3 },
      orderHeader.pono && { label: 'PO No', render: orderHeader.pono, span: 2 },
      orderHeader.comments && { label: 'Comments', render: orderHeader.comments, span: 3 },
    ],
    [orderHeader.vendor, orderHeader.location, orderHeader.pono, orderHeader.comments],
  );

  return (
    <Form name="orderSubmitForm" form={form}>
      <EntityDescriptions descriptions={descriptions1} />
      <EntityDescriptions bordered descriptions={descriptions2} />
      <Divider />
      <Table columns={columns} size="small" data={lineItems} rowKey="id" />

      <Form.Item {...defaultFormTailLayout}>
        <Statistic title="Final price" value={formatUSD(totalPrice, true)} />
      </Form.Item>

      <Form.Item {...defaultFormTailLayout}>
        <Button onClick={() => setCurrentStep(1)} style={{ marginRight: 10 }}>
          Previous
        </Button>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
