import React from 'react';

import { Container } from 'components/Layout/Container';
import { CenteredError } from 'components/Loaders';
import { UserTable } from 'components/Table/UserTable';
import { PageHeading } from 'components/Typography';

import { useApi } from 'hooks/useApi';

const MAX_RECORDS = 1000;

export const UserListPage = () => {
  const { data: userData, error, isLoading } = useApi().users().getAllUsers(MAX_RECORDS, 0);

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container>
      <PageHeading style={{ marginTop: '24px', marginBottom: '24px' }}>Users</PageHeading>
      <UserTable users={userData?.items ?? []} isLoading={isLoading} />
    </Container>
  );
};
