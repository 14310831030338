import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { ItemDetail } from 'components/ItemDetail';
import { Container } from 'components/Layout/Container';

import { useLegacyApi } from 'hooks/useApi';

import { usePathParams, IdPathParams, encodeLegacyApiItemIdParam } from 'utils/routes';

export const ItemDetailsPage = () => {
  const history = useHistory();
  const { id } = usePathParams<IdPathParams>();

  const { data, isLoading, error } = useLegacyApi(`/v3/items/item/${encodeLegacyApiItemIdParam(id)}`);
  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <ItemDetail id={id} data={data} isLoading={isLoading} error={error} onBack={onBack} />
    </Container>
  );
};
