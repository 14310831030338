import React from 'react';

import { useHistory } from 'react-router-dom';

import { Divider } from 'antd';

import { EntityDescriptions } from 'components/Descriptions';
import { Container } from 'components/Layout/Container';
import { ButtonLink } from 'components/Links';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';

import { useLegacyApi } from 'hooks/useApi';

import { formatDate, formatUSD } from 'utils/formatting';
import { routes, encodeLegacyApiParam, usePathParams, IdPathParams } from 'utils/routes';

import { AddressBar } from './AddressBar';
import { Lines } from './Lines';

interface Order {
  poNo: string;
  orderDate: any;
  vendorId: string;
  vendorName: string;
  supplierId: string;
  supplierName: string;
  complete: string;
  purchaseValue: number;
  linesCount: number;
  lines: [any];
}

export const PurchaseOrderDetailsView = ({ order }: { order: Order }) => {
  const descriptions = [
    order.poNo && { label: 'PO No', render: order.poNo },
    order.orderDate && { label: 'Order date', render: formatDate(order.orderDate) },
    order.purchaseValue && { label: 'Complete', render: order.complete ? 'Yes' : 'No' },
    order.vendorName && {
      label: 'Vendor',
      render: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 10 }}>{`${order.vendorName} (#${order.vendorId})`}</div>
          <div>{ButtonLink(routes.purchasing.vendorDetails(order.vendorId))}</div>
        </div>
      ),
      span: 2,
    },
    order.supplierName && {
      label: 'Supplier',
      render: `${order.supplierName} (#${order.supplierId})`,
    },
    order.purchaseValue && { label: 'Purchase Value', render: formatUSD(order.purchaseValue) },
    order.purchaseValue && { label: 'Line Count', render: order.linesCount },
  ];

  return (
    <>
      <EntityDescriptions bordered descriptions={descriptions} />
      <Divider />
      <AddressBar hdr={order} />
      <Divider />
      <Lines lines={order.lines} />
    </>
  );
};

export const PurchaseOrderDetailsPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const history = useHistory();
  const { data, error, isLoading } = useLegacyApi(`/v3/purchase-orders/${encodeLegacyApiParam(id)}`);

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !data) {
    return <CenteredLoader />;
  }

  if (Object.keys(data).length === 0) {
    return <NoDataMessage />;
  }

  const order: Order = data;

  const typeLabel = data?.complete ? 'Purchase Order' : 'Open Purchase Order';

  return (
    <Container>
      <PageHeader onBack={() => history.goBack()} prefix={`${typeLabel} #`} title={id} copyable noColon />
      <PurchaseOrderDetailsView order={order} />
    </Container>
  );
};
