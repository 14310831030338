import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { TenantFeature } from '@recurrency/core-api';
import { ColumnType } from 'antd/lib/table';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { Button } from 'components/Button';
import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { ButtonLink } from 'components/Links';
import { SearchFrame } from 'components/Search/SearchFrame';
import { Tooltip } from 'components/Tooltip';

import { useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { IndexName } from 'utils/algolia';
import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';
import { getLocalStorageItem, LocalStorageKey, removeLocalStorageItem, useLocalStorage } from 'utils/localStorage';
import { useHashState } from 'utils/routes';
import { sortableNumberColumn } from 'utils/tables';
import { priceUnitConverter, qtyUnitConverter } from 'utils/units';

import { AlgoliaInventoryItem } from 'types/algolia-collections';
import { SearchFrameHashState } from 'types/hash-state';
import { SalesRepLocationResponse } from 'types/legacy-api';

export const ItemListPage = ({ detailsPageLinkFn }: { detailsPageLinkFn: (id: string) => string }) => {
  const [hashState] = useHashState<SearchFrameHashState>();
  const { activeTenant, activeUser } = useGlobalApp();
  const { data: salesRepLocationData } = useLegacyApi<SalesRepLocationResponse>('/v3/salesrep');

  // show stock and MAC for salesrep's default location
  const salesRepLocation =
    salesRepLocationData && salesRepLocationData.taker !== 'ADMIN'
      ? `${salesRepLocationData.locationId}: ${salesRepLocationData.locationName}`
      : '';

  const stockLocationsToCompare = hashState.where?.in_stock_locations || [];
  if (salesRepLocation && !stockLocationsToCompare.includes(salesRepLocation)) {
    stockLocationsToCompare.unshift(salesRepLocation);
  }
  const hasStockLocationsToCompare = stockLocationsToCompare.length > 0;

  const columns: ColumnType<AlgoliaInventoryItem>[] = [
    {
      title: 'ID',
      dataIndex: 'item_id',
      render: (id: string) => <Link to={detailsPageLinkFn(id)}>{id}</Link>,
    },
    {
      title: 'Item',
      dataIndex: 'item_desc',
    },
    // hide 'Product Group' column to save space when comparing stock by locations
    hasStockLocationsToCompare
      ? null
      : {
          title: 'Product Group',
          dataIndex: 'pg',
        },
    ...stockLocationsToCompare.map((locationIdNameStr) => ({
      title: `${locationIdNameStr} MAC`,
      dataIndex: 'mac_by_location',
      render: (macByLocation: Obj<number>, item: AlgoliaInventoryItem) =>
        formatUSD(priceUnitConverter(macByLocation[locationIdNameStr], item.unit_size), true),
      align: 'right' as const,
    })),
    ...stockLocationsToCompare.map((locationIdNameStr) => ({
      title: `${locationIdNameStr} Stock`,
      dataIndex: 'stock_by_location',
      render: (stockByLocation: Obj<number>, item: AlgoliaInventoryItem) =>
        formatNumber(qtyUnitConverter(stockByLocation[locationIdNameStr], item.unit_size)),
      align: 'right' as const,
    })),
    sortableNumberColumn({
      title: 'Company Stock',
      dataIndex: 'stock',
    }),
    {
      title: 'UOM',
      dataIndex: 'uom',
    },
    {
      title: '',
      dataIndex: 'item_id',
      render: (itemId: string) => ButtonLink(detailsPageLinkFn(itemId)),
    },
  ].filter(truthy);

  const [selectedItemIdNameObjs, setSelectedItemIdNameObjs] = useLocalStorage(
    LocalStorageKey.SelectedItemsForNewQuote,
    [],
  );
  const selectedItemIds = useMemo(() => selectedItemIdNameObjs.map((item) => item.foreignId), [selectedItemIdNameObjs]);
  const newQuoteBtnEnabled = selectedItemIdNameObjs.length > 0;

  const handleTableItemSelect = (selectedItem: AlgoliaInventoryItem, selected: boolean) => {
    if (selected && !selectedItemIds.includes(selectedItem.item_id)) {
      setSelectedItemIdNameObjs([
        ...selectedItemIdNameObjs,
        { foreignId: selectedItem.item_id, name: selectedItem.item_desc },
      ]);
    } else if (!selected && selectedItemIds.includes(selectedItem.item_id)) {
      setSelectedItemIdNameObjs(selectedItemIdNameObjs.filter((item) => item.foreignId !== selectedItem.item_id));
    }
  };

  return (
    <SearchFrame<AlgoliaInventoryItem>
      title="Inventory"
      indexName={IndexName.Items}
      queryPlaceholder="Search items by ID, description, product group, or keywords..."
      columns={columns}
      valueFacets={[
        {
          title: 'Product Groups',
          field: 'pg',
          queryPlaceholder: 'Filter by product group...',
        },
        {
          title: 'In-Stock Locations',
          field: 'in_stock_locations',
          queryPlaceholder: 'Filter by locations...',
        },
      ]}
      ctaChild={
        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateQuote) && (
          <div style={{ display: 'flex', gap: 8 }}>
            <Tooltip
              title={
                newQuoteBtnEnabled
                  ? `Selected Items:\n${selectedItemIdNameObjs
                      .map((item, idx) => `  ${idx + 1}. ${joinIdNameObj(item)}`)
                      .join('\n')}`
                  : "Select at-least one item to enable 'New Quote' button"
              }
              overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}
            >
              <NewQuoteButton
                props={{
                  onClick: () => {
                    removeLocalStorageItem(LocalStorageKey.SelectedItemsForNewQuote);
                  },
                  disabled: !newQuoteBtnEnabled,
                }}
                hashState={{ items: getLocalStorageItem(LocalStorageKey.SelectedItemsForNewQuote) || undefined }}
                showItemCount
              />
            </Tooltip>
            {newQuoteBtnEnabled && (
              <Tooltip title="Clear selected items">
                <Button onClick={() => setSelectedItemIdNameObjs([])}>Clear</Button>
              </Tooltip>
            )}
          </div>
        )
      }
      tableRowKey="item_id"
      tableRowSelection={
        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateQuote)
          ? {
              selectedRowKeys: selectedItemIds,
              onSelect: handleTableItemSelect,
              hideSelectAll: true,
            }
          : undefined
      }
    />
  );
};
