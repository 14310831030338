import { Row } from "antd";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { media } from "utils/responsive";
export const Container = styled(Row).attrs({
  gutter: 0
})`
  display: flex;
  height: calc(100vh - ${(props) => props.theme.primaryNavigationHeight + props.theme.tertiaryNavigationHeight}px);
`;
export const LeftColumn = styled.div`
  display: flex;
  max-width: 230px;

  ${media.md`
    max-width: 230px;
  `};
  ${media.lg`
    max-width: 250px;
  `};
  ${media.xl`
    max-width: 350px;
  `};

  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.colors.gray300};
`;
export const LeftColumnTitle = styled.h1`
  flex: 0.5;
  font-size: 20px;
  padding: ${(props) => props.theme.units.XL}px;
  max-height: 85px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
export const TabContainer = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: space-evenly;
`;
export const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray600};
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  ${(props) => props.isSelected ? `border-bottom: 2px solid ${props.theme.colors.blue}` : ""};
`;
export const List = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 7;
`;
export const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.theme.units.XXL * 2}px;
`;
export const Input = styled.input`
  border: none;
  font-size: 15px;
  flex: 1;
  padding-left: ${(props) => props.theme.units.LG}px;
  padding-right: ${(props) => props.theme.units.LG}px;
  &:focus {
    outline: none;
  }
`;
export const InputContainer = styled.div`
  padding: ${(props) => props.theme.units.SM}px ${(props) => props.theme.units.LG}px
    ${(props) => props.theme.units.SM}px ${(props) => props.theme.units.LG}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  border-top: 1px solid ${(props) => props.theme.colors.gray300};
`;
export const ListItem = styled.div`
  width: 230px;
  height: 160px;

  padding: 8px 16px 8px 8px;

  ${media.md`
    width: 230px;
  `};
  ${media.lg`
    padding: 16px 32px 16px 16px;
    width: 250px;
  `};
  ${media.xl`
    padding: 24px 36px 24px 24px;
    width: 350px;
  `};

  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  ${(props) => props.isSelected ? `background-color: ${props.theme.colors.blue100}` : ""};

  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};
  }
`;
export const ItemId = styled(Typography).attrs({
  type: "subtitle"
})`
  color: ${(props) => props.theme.colors.gray600};
  margin: 0;
`;
export const ItemDescription = styled(Typography).attrs({
  type: "middle"
})`
  color: ${(props) => props.theme.colors.gray900};
  margin: 0;
`;
export const ItemExtendedDescription = styled(Typography).attrs({
  type: "small"
})`
  color: ${(props) => props.theme.colors.gray600};
  margin: 0;
`;
export const ItemStats = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Stat = styled.div`
  margin: 8px 0;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 20px;
  color: ${(props) => props.direction === "up" ? props.theme.colors.blue500 : props.theme.colors.red700};
  background-color: ${(props) => props.direction === "up" ? props.theme.colors.blue100 : props.theme.colors.red100};

  font-size: 10px;

  ${media.xl`
    font-size: 14px;
  `}
`;
export const StatLabel = styled.div`
  color: ${(props) => props.theme.colors.gray500};
  font-size: 9px;

  ${media.xl`
    font-size: 12px;
  `}
`;
export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  height: 100%;
  overflow-y: auto;
`;
export const RightColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.units.XL}px 0;
`;
export const RightColumnTitle = styled.div`
  flex: 1;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
export const ChartContainer = styled.div`
  height: 330px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
export const ChartOptionContainer = styled.div`
  margin: 8px 0;
`;
export const DetailSection = styled.div`
  margin: 20px 0;
`;
export const SectionTitle = styled(Typography).attrs({
  type: "large",
  align: "left",
  weight: "bold"
})`
  margin-bottom: 1rem;
`;
export const ChartWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
export const ActionWrapper = styled.div`
  margin-bottom: 8px;
`;
