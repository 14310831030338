// /**
//  * This module encapsulates data fetching needs for various table entities,
//  * with an uniform UseAsyncTableResponse interface.
//  */

import { useState } from 'react';

import { AxiosError } from 'axios';

import { DEFAULT_PAGE_SIZE } from 'components/Table';

import { useLegacyApi } from 'hooks/useApi';

/// interfaces ///

export interface LegacyApiItemsData<ItemT> {
  items: ItemT[];
  totalCount: number;
}

export interface UseAsyncTableProps<ItemT> {
  isLoading: boolean;
  isReloading: boolean;
  error: AxiosError | undefined;
  items: ItemT[];
  totalCount: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
}

/// util functions ///

export function useLegacyApiTableProps<ItemT>({
  apiPath,
  queryParams,
  loadCount,
  mapItemFn,
}: {
  apiPath: string;
  queryParams?: Obj<string | number | boolean | undefined>;
  mapItemFn?: (item: Any) => ItemT;
  /**
   * load {loadCount} entries and paginate on frontend rather than making new api calls.
   * we should avoid this pattern whenever possible as it shows an incomplete picture.
   * This is only used when getting totalCount is too expensive to compute on the api.
   */
  loadCount?: number;
}): UseAsyncTableProps<ItemT> {
  const [page, setPage] = useState(0);
  const pageSize = DEFAULT_PAGE_SIZE;

  const { data, isLoading, isReloading, error } = useLegacyApi<LegacyApiItemsData<ItemT>>(apiPath, {
    offset: loadCount ? 0 : page * pageSize,
    limit: loadCount || pageSize,
    ...queryParams,
  });

  const { items, totalCount } = data || { items: [], totalCount: 0 };

  return {
    isLoading,
    isReloading,
    error,
    items: mapItemFn ? items.map(mapItemFn) : items,
    totalCount: loadCount ? Math.min(loadCount, totalCount) : totalCount,
    page,
    pageSize,
    setPage,
  };
}
