/** document.createElement and set props */
export function createElem(tagName: string, props: Obj): HTMLElement {
  const elem: any = document.createElement(tagName);
  for (const [key, val] of Object.entries(props)) {
    elem[key] = val;
  }
  return elem;
}

/** wait for element matched by selector to be present on DOM */
export function waitForSelector(selector: string): Promise<Element> {
  return new Promise((resolve) => {
    const pollForSelector = () => {
      const el = document.querySelector(selector);
      if (el) {
        resolve(el);
      } else {
        setTimeout(pollForSelector, 200);
      }
    };
    pollForSelector();
  });
}

export function insertAsyncScript(props: Obj) {
  const scriptElem = createElem('script', {
    type: 'text/javascript',
    async: true,
    ...props,
  });
  document.head.appendChild(scriptElem);
}
