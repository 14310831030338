import { Spin } from "antd";
import styled from "styled-components";
export const ItemDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;
export const Loader = styled(Spin)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;
