import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { Table } from 'components/Table';
import { Tag } from 'components/Tag';

import { routes } from 'utils/routes';
import { idColumn, sortableNumberColumn, sortableStringColumn, sortableDollarWithCentsColumn } from 'utils/tables';

import { OrderLine } from 'types/legacy-api';

export const OrderLinesTable = ({ orderLines }: { orderLines: OrderLine[] }) => {
  const orderLineColumns: ColumnType<OrderLine>[] = [
    idColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (itemId: string) => <Link to={routes.sales.itemDetails(itemId)}>{itemId}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: ['item', 'itemDesc'],
    }),
    sortableStringColumn({
      title: 'Status',
      dataIndex: ['complete'],
      render: (complete: string) => (
        <Tag color={complete === 'Y' ? 'green' : 'blue'}>{complete === 'Y' ? 'Completed' : 'Open'}</Tag>
      ),
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'qtyOrdered',
    }),
    sortableNumberColumn({
      title: 'Qty Allocated',
      dataIndex: 'qtyAllocated',
    }),
    sortableNumberColumn({
      title: 'Qty Canceled',
      dataIndex: 'qtyCanceled',
    }),
    sortableNumberColumn({
      title: 'Qty Invoiced',
      dataIndex: 'qtyInvoiced',
    }),
    sortableNumberColumn({
      title: 'Qty Open',
      dataIndex: 'qtyOpen',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'salesCost',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Price',
      dataIndex: 'extendedPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Margin',
      dataIndex: 'totalGrossMargin',
    }),
  ];

  return <Table columns={orderLineColumns} data={orderLines} />;
};
