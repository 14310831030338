import React from 'react';

import { Form } from 'antd';

import { isAdmin } from 'contexts/Auth0Context';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useBranchesSelectProps,
  useCompaniesSelectProps,
  useFreightsSelectProps,
  useLocationsSelectProps,
  useSalesRepsSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { CheckboxFormItem, InputFormItem } from 'components/FormItems';
import { AddressFormField, AddressFormItem } from 'components/FormItems/AddressFormItem';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { joinIfIdNameObj, splitIfIdNameStr } from 'utils/formatting';

import { ProspectHashState } from 'types/hash-state';

export const ProspectCustomerStep = ({
  prospectState,
  onProspectStateChange,
}: {
  prospectState: ProspectHashState;
  onProspectStateChange: (newState: ProspectHashState) => void;
}) => {
  const { activeTenant, activeRole } = useGlobalApp();

  const defaultCompany = activeTenant.defaultData.prospect.company;
  const companiesSelectProps = useCompaniesSelectProps();
  const salesRepsSelectProps = useSalesRepsSelectProps();
  const locationsSelectProps = useLocationsSelectProps();
  const branchesSelectProps = useBranchesSelectProps();
  const freightsSelectProps = useFreightsSelectProps({ companyId: splitIfIdNameStr(prospectState.company)?.foreignId });
  if (!prospectState.company) {
    if (defaultCompany) {
      // Default to set default if one exists
      onProspectStateChange({ company: joinIfIdNameObj(defaultCompany) });
    } else if (companiesSelectProps.options[0]?.value) {
      // Otherwise use the first value in the list
      onProspectStateChange({ company: companiesSelectProps.options[0]?.value });
    }
  }
  if (prospectState.makeShipTo === undefined) {
    // Form should default to making a ship to
    onProspectStateChange({ makeShipTo: true });
  }
  if (!isAdmin(activeRole.foreignId, activeRole.name) && !prospectState.salesRep) {
    // If the user is not an admin, use the sales rep id of the current user
    onProspectStateChange({ salesRep: joinIfIdNameObj(activeRole) });
  }

  return (
    <>
      <Form.Item
        name="company"
        label="Company"
        rules={[{ required: true, message: 'Please add a company.' }]}
        hidden={companiesSelectProps.options.length <= 1}
      >
        <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" />
      </Form.Item>
      {companiesSelectProps.options.length > 1 && <br />}
      {isAdmin(activeRole.foreignId, activeRole.name) && (
        <Form.Item
          name="salesRep"
          label="Assigned Sales Rep"
          rules={[{ required: true, message: 'Please assign a sales rep.' }]}
        >
          <AsyncSelect selectProps={salesRepsSelectProps} entityPlural="Sales Reps" />
        </Form.Item>
      )}
      <InputFormItem
        label="Customer Name"
        name="customerName"
        rules={[
          { required: true, message: 'Please add a customer name.' },
          { max: 255, message: 'Customer name cannot be more than 255 characters' },
        ]}
      />
      <InputFormItem
        label="Central Phone"
        name="customerPhone"
        rules={[
          { required: true, message: 'Please add a phone number.' },
          { max: 20, message: 'Phone number cannot be more than 20 characters' },
        ]}
      />
      <InputFormItem
        label="Email"
        name="customerEmail"
        rules={[
          { type: 'email', message: 'Must be an email' },
          { max: 255, message: 'Email cannot be more than 255 characters' },
        ]}
      />
      <InputFormItem
        label="Central Fax"
        name="customerFax"
        rules={[{ max: 20, message: 'Fax number cannot be more than 20 characters' }]}
      />
      <Typography type="middle">Physical Address</Typography>
      {/* Ship to will be always required until we figure out how to properly deal with default branch */}
      {/* <CheckboxFormItem label="New Ship To" name="makeShipTo" /> */}
      {prospectState.makeShipTo && (
        <>
          <Form.Item
            name="shipToDefaultBranch"
            label="Ship To Default Branch"
            rules={[{ required: true, message: 'Please add a default branch.' }]}
          >
            <AsyncSelect selectProps={branchesSelectProps} entityPlural="branches" />
          </Form.Item>
          <Form.Item
            name="shipToPreferredLocation"
            label="Ship To Preferred Location"
            rules={[{ required: true, message: 'Please add a preferred location.' }]}
          >
            <AsyncSelect selectProps={locationsSelectProps} entityPlural="locations" />
          </Form.Item>
          <Form.Item
            name="shipToDefaultFreight"
            label="Ship To Default Freight"
            rules={[{ required: true, message: 'Please add a default freight.' }]}
          >
            <AsyncSelect selectProps={freightsSelectProps} entityPlural="freights" />
          </Form.Item>
        </>
      )}
      <AddressFormItem
        formNamePrefix="physical"
        // zipcode is required when making a shipto
        requiredFields={prospectState.makeShipTo ? [AddressFormField.zipcode] : undefined}
      />
      <Typography type="middle">Mailing Address</Typography>
      <CheckboxFormItem label="Same as physical" name="addressesSame" />
      {!prospectState.addressesSame && <AddressFormItem formNamePrefix="mailing" />}
    </>
  );
};
