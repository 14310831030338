import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  idColumn,
  sortableNumberColumn,
  sortableStringColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortablePercentColumn,
  // humanDateColumn,
} from 'utils/tables';

export const ItemSalesHistory = ({ customerId }: { customerId: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    // humanDateColumn({
    //   title: 'Timeline',
    //   dataIndex: 'orderDate',
    // }),
    sortableStringColumn({
      title: 'Order No',
      dataIndex: 'orderNo',
      render: (id: string) => <Link to={routes.orders.orderDetails(id)}>{id}</Link>,
    }),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: 'orderDate',
      defaultSortOrder: 'descend',
    }),
    idColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (itemId: string) => <Link to={routes.sales.itemDetails(itemId)}>{itemId}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
    }),
    sortableNumberColumn({
      title: 'Qty Shipped',
      dataIndex: 'qtyShipped',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Price',
      dataIndex: 'extendedPriceHome',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Cost',
      dataIndex: 'cogsAmountHome',
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'gmPercent',
    }),
    {
      render: ({ itemId }) => ButtonLink(routes.sales.itemDetails(itemId)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/sales-history`,
      })}
      columns={columns}
    />
  );
};
