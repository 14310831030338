import React from 'react';

import { useHistory } from 'react-router';

import { Button } from 'components/Button';

import { oldToNewRoutePath, routes } from 'utils/routes';

export const NotFoundPage = () => {
  const history = useHistory();

  // if this is an old route path, replace with new route path without having to do a page refresh
  const curPath = history.location.pathname;
  const newPath = oldToNewRoutePath(curPath);
  if (newPath) {
    history.replace(newPath + history.location.hash);
    return null;
  }

  // console.error so e2e tests will fail if they encounter a 404 page
  console.error(`NotFound: no routes matching '${curPath}'`);
  return (
    <div style={{ height: '500px', textAlign: 'center' }}>
      <h1 style={{ fontSize: '80px' }}>404</h1>
      <h1>Sorry, page not found.</h1>
      <br />
      <Button type="primary" onClick={() => history.push(routes.home())}>
        Return to Homepage
      </Button>
    </div>
  );
};
