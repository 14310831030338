import React, { useState, useCallback } from 'react';

import { Row, Col, Form, Divider } from 'antd';
import { useDebounce } from 'use-debounce';

import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { InputFormItem, AlgoliaSelectFormItem, SelectFormItem } from 'components/FormItems';
import { Container } from 'components/Layout/Container';
import { PageHeading } from 'components/Typography/Typography';

import { IndexName } from 'utils/algolia';
import { truthy } from 'utils/boolean';
import { splitIfIdNameStr } from 'utils/formatting';
import { useHashState } from 'utils/routes';

import { AlgoliaCustomer, AlgoliaInventoryItem } from 'types/algolia-collections';
import { PricingHashState } from 'types/hash-state';

import { ItemInfo } from './ItemInfo';

interface ItemOption {
  /** {foreignId} * */
  key: string | undefined;
  /** {foreignId}: {name} * */
  value: string | undefined;
  itemInvMastUid: string | undefined;
}

interface CustomerOption {
  /** {foreignId} * */
  key: string | undefined;
  /** {foreignId}: {name} * */
  value: string | undefined;
}

export const PricingPage = () => {
  const [hashState, updateHashState] = useHashState<PricingHashState>();
  const { customer: customerIdNameStr, item: itemIdNameStr, itemInvMastUid, unitOfMeasure: uomInit = '' } = hashState;
  const [form] = Form.useForm();

  const initCustomer = {
    key: splitIfIdNameStr(customerIdNameStr)?.foreignId,
    value: customerIdNameStr,
  };

  const initItem = {
    key: splitIfIdNameStr(itemIdNameStr)?.foreignId,
    value: itemIdNameStr,
    itemInvMastUid,
  };

  const [customer, setCustomer] = useState<CustomerOption>(initCustomer);
  const [item, setItem] = useState<ItemOption>(initItem);
  const [uoms, setUOMs] = useState<Array<{ value: string }>>([{ value: uomInit }]);
  const [unitOfMeasure, setUOM] = useState(uomInit);
  const [qty, setQty] = useState(1);

  const [debouncedQty] = useDebounce(qty, 500); // debounce qty event after 500ms
  const [debouncedUOM] = useDebounce(unitOfMeasure, 500); // debounce qty event after 500ms

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gm, setGm] = useState('');

  const handleChangeQty = useCallback((event) => {
    setQty(Math.floor(Number(event.target.value)));
  }, []);

  const handleSelectCustomer = useCallback(
    (option: CustomerOption) => {
      setCustomer(option);
      updateHashState({ customer: option.value });
    },
    [updateHashState],
  );

  const handleSelectItem = useCallback(
    (option: ItemOption) => {
      setItem(option);
      updateHashState({ item: option.value, itemInvMastUid: option.itemInvMastUid });
    },
    [updateHashState],
  );

  const handleSelectUOM = useCallback(
    (unitOfMeasure: string) => {
      setUOM(unitOfMeasure);
      updateHashState({ unitOfMeasure });
    },
    [updateHashState],
  );

  const handleGetQtyValue = useCallback((event) => Math.floor(Number(event.target.value)), []);

  return (
    <Container style={{ paddingTop: '30px' }}>
      <Form layout="vertical" name="pricingForm" form={form} onFinishFailed={console.error}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <PageHeading>Pricing & Availability</PageHeading>
          <NewQuoteButton
            props={{ disabled: !(itemIdNameStr && customerIdNameStr) }}
            hashState={{
              customer: customerIdNameStr,
              items: [splitIfIdNameStr(itemIdNameStr)].filter(truthy),
            }}
          />
        </div>
        <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }}>
          <Col xs={24} sm={24} md={12} lg={6}>
            <AlgoliaSelectFormItem<AlgoliaCustomer>
              label="Customer"
              name="customer"
              initialValue={customer?.value}
              indexName={IndexName.Customers}
              rules={[{ required: true, message: 'Please add a customer.' }]}
              mapHitFn={(hit) => ({
                key: hit.customer_id,
                value: `${hit.customer_id}: ${hit.customer_name}`,
              })}
              setFieldsValue={form.setFieldsValue}
              postValueChange={(value, option: CustomerOption) => {
                handleSelectCustomer(option);
              }}
              salesRepRefined
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <AlgoliaSelectFormItem<AlgoliaInventoryItem>
              label="Item"
              name="item"
              initialValue={item?.value}
              indexName={IndexName.Items}
              mapHitFn={(hit) => ({
                key: hit.item_id,
                value: `${hit.item_id}: ${hit.item_desc}`,
                itemInvMastUid: hit.inv_mast_uid,
              })}
              rules={[{ required: true, message: 'Please add an item.' }]}
              setFieldsValue={form.setFieldsValue}
              postValueChange={(value, option: ItemOption) => {
                handleSelectItem(option);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <SelectFormItem
              label="Unit of Measure"
              name="unitOfMeasure"
              disabled={!(customer && item)}
              options={uoms}
              initialValue={uomInit}
              value={unitOfMeasure}
              setFieldsValue={form.setFieldsValue}
              onChange={handleSelectUOM}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <InputFormItem
              label="Quantity"
              type="number"
              defaultValue={1}
              disabled={!(customer && item)}
              name="quantity"
              getValueFromEvent={handleGetQtyValue}
              postValueChange={handleChangeQty}
              rules={[
                {
                  required: Boolean(item),
                  type: 'number',
                  min: 1,
                  message: 'Please add a positive quantity.',
                },
              ]}
            />
          </Col>
        </Row>
        <Divider />
        {customer && item && customer.key && item.itemInvMastUid && (
          <ItemInfo
            customerId={customer.key}
            itemInvMastUid={item.itemInvMastUid}
            unitOfMeasure={debouncedUOM}
            qty={debouncedQty}
            setUOMs={setUOMs}
            setUOM={handleSelectUOM}
            setGm={setGm}
            setFieldsValue={form.setFieldsValue}
            priceFieldName="price"
            tableTab={hashState.tab}
          />
        )}
      </Form>
    </Container>
  );
};
