import React, { useEffect, useState } from 'react';

import { Statistic, Row, Col, Grid } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import styled from 'styled-components';

import { formatUSD, formatPercent, dashIfEmpty } from 'utils/formatting';

import { UNITS } from 'constants/styles';

const StyledRow = styled(Row)`
  margin-top: 1rem;
`;

const Stat = ({ title, value }: { title: string; value: valueType }) => (
  <Statistic
    title={
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{title}</div>
      </div>
    }
    value={value}
    valueStyle={{ fontSize: UNITS.LG }}
  />
);

export const StatsBar = ({ stats }: { stats: FIXME }) => {
  const screens = Grid.useBreakpoint();
  const [span, setSpan] = useState(4);

  useEffect(() => {
    if (Object.keys(screens).length > 0) {
      setSpan(screens.lg ? 8 : 16);
    }
  }, [screens, setSpan]);

  const bar = [
    {
      topTitle: 'Sales YTD: ',
      topVal: stats.salesYtd,
      bottomTitle: 'Sales LYTD: ',
      bottomVal: stats.salesPytd,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatUSD(amt),
    },
    {
      topTitle: 'GM $ YTD: ',
      topVal: stats.gmYtd,
      bottomTitle: 'GM $ LYTD: ',
      bottomVal: stats.gmPytd,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string) => dashIfEmpty(formatUSD(amt), '$0'),
    },
    {
      topTitle: 'GM % YTD: ',
      topVal: stats.salesYtd > 0 ? stats.gmYtd / stats.salesYtd : 0,
      bottomTitle: 'GM % LYTD: ',
      bottomVal: stats.salesPytd > 0 ? stats.gmPytd / stats.salesPytd : 0,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string) => dashIfEmpty(formatPercent(Number(amt)), '0%'),
    },
  ];

  return (
    <StyledRow gutter={UNITS.XL}>
      {(stats ? bar : []).map((item, index) => (
        <Col span={span} key={index}>
          <Stat title={item.topTitle} value={item.render(item.topVal)} />
          <Stat title={item.bottomTitle} value={item.render(item.bottomVal)} />
        </Col>
      ))}
    </StyledRow>
  );
};
