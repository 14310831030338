import React, { useState } from 'react';

import { Slider as AntSlider } from 'antd';

import { formatUSDAbbreviated } from 'utils/formatting';

export type NumMinMax = {
  min: number;
  max: number;
};

export interface RangeSliderProps {
  range?: NumMinMax;
  selectedValue?: NumMinMax;
  labelFormatFn?: (value: number) => string;
  /** returns undefined when the selected value is same as range i.e no range is selected */
  onSelectedValueChange?: (value: NumMinMax | undefined) => void;
}

export const RangeSlider = ({ selectedValue, range, onSelectedValueChange, labelFormatFn }: RangeSliderProps) => {
  let { min: rangeMin = 0, max: rangeMax = 0 } = range || {};
  // clamp range so it always starts or ends with zero
  rangeMin = Math.min(rangeMin, 0);
  rangeMax = Math.max(0, rangeMax);
  // we add +1 on max, and -1 on min, and round all values because algolia facet stats return integers that ignore decimals
  // see https://github.com/recurrency/frontend/issues/1698
  rangeMin = rangeMin && Math.round(rangeMin - 1);
  rangeMax = rangeMax && Math.round(rangeMax + 1);

  const isRangeZero = !!(rangeMin === 0 && rangeMax === 0);

  // undefined implies take value from props, otherwise use internal state as user is dragging
  // new values are applied once the user has finished dragging so UX doesn't flash
  const [valueMinMax, setValueMinMax] = useState<[number, number] | undefined>(undefined);
  const curValueMinMax = valueMinMax ?? [selectedValue?.min ?? rangeMin, selectedValue?.max ?? rangeMax];

  const _labelFormatFn = labelFormatFn || formatUSDAbbreviated;

  const handleSliderAfterChange = ([min, max]: [number, number]) => {
    const newValue = min === rangeMin && max === rangeMax ? undefined : { min, max };
    onSelectedValueChange?.(newValue);
    setValueMinMax(undefined);
  };

  const marks = {
    [rangeMin]: {
      style: { transform: 'translateX(-5px)' },
      label: _labelFormatFn(rangeMin),
    },
    [rangeMax]: {
      style: { transform: 'translateX(calc(-100% + 5px))' },
      label: _labelFormatFn(rangeMax),
    },
  };

  return isRangeZero ? (
    <AntSlider min={0} max={0} disabled marks={{ 0: { label: '-' } }} />
  ) : (
    <AntSlider
      range
      min={rangeMin}
      max={rangeMax}
      marks={marks}
      value={curValueMinMax}
      onChange={setValueMinMax}
      onAfterChange={handleSliderAfterChange}
      // @ts-expect-error incorrect type for tipFormatter
      tipFormatter={_labelFormatFn}
    />
  );
};
