import React from 'react';

import { Link } from 'react-router-dom';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  idColumn,
  sortableStringColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
} from 'utils/tables';

export const POTable = ({ invMastUid }: { invMastUid: string | undefined }) => {
  const columns = [
    idColumn({
      title: 'PO No',
      dataIndex: 'poNo',
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: FIXME) => `${record.locationId}: ${record.locationName}`,
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'unitQuantity',
    }),
    sortableNumberColumn({
      title: 'Qty Received',
      dataIndex: 'qtyReceived',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitPriceDisplay',
    }),
    sortableDateColumn({
      title: 'Date Ordered',
      dataIndex: 'orderDate',
    }),
    sortableDateColumn({
      title: 'Date Due',
      dataIndex: 'dateDue',
      defaultSortOrder: 'ascend',
    }),
    {
      render: ({ poNo }: { poNo: FIXME }) => ButtonLink(routes.purchasing.purchaseOrderDetails(poNo)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(invMastUid || '')}/purchase-orders`,
      })}
      columns={columns}
    />
  );
};
