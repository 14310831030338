import React, { useState } from 'react';

import { CenteredError } from 'components/Loaders';
import { SearchInput } from 'components/Search/SearchInput';

import { useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { IndexName, searchAlgoliaIndex } from 'utils/algolia';
import { useHashState } from 'utils/routes';

import { AlgoliaInventoryItem } from 'types/algolia-collections';
import { PurchasingForecastsHashState } from 'types/hash-state';

import { ForecastDetails } from './ForecastDetails';
import { ForecastList } from './ForecastList';
import { Container, LeftColumn, LeftColumnTitle, InputContainer } from './ForecastsPage.style';

export const ForecastsPage = () => {
  const { activeTenant, searchClient } = useGlobalApp();
  const [hashState, updateHashState] = useHashState<PurchasingForecastsHashState>();
  const [selectedItem, setSelectedItem] = useState<AlgoliaInventoryItem | null>(null);

  const {
    data: algoliaResponse,
    isLoading: isHitsLoading,
    error,
  } = usePromise(
    () =>
      searchAlgoliaIndex<AlgoliaInventoryItem>(searchClient, {
        indexName: IndexName.Items,
        tenantId: activeTenant.id,
        query: hashState.query,
      }),
    [hashState.query],
  );

  // hardcode id = 1000 for mock data
  const { data: detailData, isLoading: isDetailLoading } = useLegacyApi('/purchasing/forecasts/1000', {
    required: 'True',
  });

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container>
      <LeftColumn>
        <LeftColumnTitle>Forecasts</LeftColumnTitle>
        <InputContainer>
          <SearchInput
            placeholder="Search items..."
            query={hashState.query}
            onQueryChange={(query) => updateHashState({ query })}
          />
        </InputContainer>
        <ForecastList
          isLoading={isHitsLoading}
          hits={algoliaResponse?.hits || []}
          selectedId={hashState.itemId}
          onItemSelect={(itemId: string, item: AlgoliaInventoryItem) => {
            updateHashState({ itemId });
            setSelectedItem(item);
          }}
        />
      </LeftColumn>
      {selectedItem && !isDetailLoading && detailData && <ForecastDetails data={selectedItem} details={detailData} />}
    </Container>
  );
};
