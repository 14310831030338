import { ButtonLink } from 'components/Links';

import {
  sortableStringColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarColumn,
  idColumn,
} from 'utils/tables';

import { PropertyMap, ForecastType } from './types';

type Tab = {
  id: ForecastType;
  label: string;
};

export const TABS: Tab[] = [
  { id: 'requirements', label: 'Requirements' },
  { id: 'items', label: 'Items' },
];

export enum ChartOption {
  DAILY,
  WEEKLY,
  MONTHLY,
}

export const chartOptions = [
  {
    label: 'Daily',
    value: ChartOption.DAILY,
  },
  {
    label: 'Weekly',
    value: ChartOption.WEEKLY,
  },
  {
    label: 'Monthly',
    value: ChartOption.MONTHLY,
  },
];

export const FORECAST_PROPERTY_MAP: PropertyMap = {
  requirements: {
    id: 'itemId',
    description: 'itemDesc',
    extendedDescription: 'itemExtDesc',
  },
  items: {
    id: 'itemId',
    description: 'itemDesc',
    extendedDescription: 'itemExtDesc',
  },
};

export const poColumns = [
  idColumn({
    title: 'PO No',
    dataIndex: 'poNo',
  }),
  idColumn({
    title: 'Location',
    dataIndex: 'locationId',
    render: (_, record: FIXME) => `${record.locationId}: ${record.locationName}`,
  }),
  sortableNumberColumn({
    title: 'Qty. Ordered',
    dataIndex: 'qtyOrdered',
  }),
  sortableNumberColumn({
    title: 'Qty. Received',
    dataIndex: 'qtyReceived',
  }),
  sortableStringColumn({ title: 'UOM', dataIndex: 'uom' }),
  sortableDollarColumn({
    title: 'Unit Cost',
    dataIndex: 'unitCost',
  }),
  sortableDateColumn({
    title: 'Date Ordered',
    dataIndex: 'dateOrdered',
  }),
  sortableDateColumn({
    title: 'Date Due',
    dataIndex: 'dueDate',
  }),
];

export const purchaseColumns = [
  idColumn({
    title: 'Supplier ID',
    dataIndex: 'supplierId',
  }),
  sortableStringColumn({ title: 'Supplier Name', dataIndex: 'supplierName' }),
  sortableStringColumn({
    title: 'Location',
    dataIndex: 'locationId',
    render: (_, record: FIXME) => `${record.locationId}: ${record.locationName}`,
  }),
  sortableNumberColumn({
    title: 'Target Qty',
    dataIndex: 'targetQty',
  }),
  sortableStringColumn({ title: 'Target UOM', dataIndex: 'targetUom' }),
  sortableDollarColumn({
    title: 'Target Value',
    dataIndex: 'targetValue',
  }),
  {
    render: () => ButtonLink('#'),
  },
];
