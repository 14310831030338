import React from 'react';

import { InputFormItem } from '..';

// TODO: use googlesearch api for better address autocomplete
// see https://github.com/recurrency/frontend/issues/1412

export enum AddressFormField {
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  state = 'state',
  zipcode = 'zipcode',
}

export const AddressFormItem = ({
  formNamePrefix,
  requiredFields,
}: {
  formNamePrefix?: string;
  requiredFields?: AddressFormField[];
}): JSX.Element => (
  <>
    <InputFormItem
      label="Street Address"
      name={formNamePrefix ? `${formNamePrefix}-address1` : 'address1'}
      rules={[
        {
          required: requiredFields && requiredFields.includes(AddressFormField.address1),
          message: 'Please input an street address',
        },
        { max: 50, message: 'Street address cannot be more than 50 characters' },
      ]}
    />
    <InputFormItem
      label="Address Line 2"
      name={formNamePrefix ? `${formNamePrefix}-address2` : 'address2'}
      rules={[
        {
          required: requiredFields && requiredFields.includes(AddressFormField.address2),
          message: 'Please input an address line 2',
        },
        { max: 50, message: 'Address line 2 cannot be more than 50 characters' },
      ]}
    />
    <InputFormItem
      label="City"
      name={formNamePrefix ? `${formNamePrefix}-city` : 'city'}
      rules={[
        {
          required: requiredFields && requiredFields.includes(AddressFormField.city),
          message: 'Please input a city',
        },
        { max: 50, message: 'City cannot be more than 50 characters' },
      ]}
    />
    <InputFormItem
      label="State"
      name={formNamePrefix ? `${formNamePrefix}-state` : 'state'}
      rules={[
        {
          required: requiredFields && requiredFields.includes(AddressFormField.state),
          message: 'Please input a state',
        },
        { max: 50, message: 'State cannot be more than 50 characters' },
      ]}
    />
    <InputFormItem
      label="ZIP Code"
      name={formNamePrefix ? `${formNamePrefix}-zipcode` : 'zipcode'}
      rules={[
        // zip code validation with regex
        { pattern: new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/, 'i'), message: 'Please input a valid zip code' },
        {
          required: requiredFields && requiredFields.includes(AddressFormField.zipcode),
          message: 'Please input a zipcode',
        },
      ]}
    />
  </>
);
