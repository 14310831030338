import React from 'react';

import { Divider } from 'antd';

import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { EntityDescriptions } from 'components/Descriptions';
import { Container } from 'components/Layout/Container';
import { linkableAddress } from 'components/Links';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { Tooltip } from 'components/Tooltip';

import { formatDate, formatAddress, joinIdNameObj } from 'utils/formatting';

import { StatsBar } from './StatsBar';
import { getTabs } from './Tabs/getTabs';

export type Props = {
  id?: string;
  isLoading: boolean;
  error?: Error;
  customer: FIXME;
  stats: FIXME;
  onBack?: () => void;
  headerVisible?: boolean;
};

export const CustomerDetail = (props: Props) => {
  const { id, isLoading, error, customer, stats, headerVisible = true, onBack } = props;

  if (error) {
    return <CenteredError error={error} />;
  }

  if (!id || isLoading || !customer) {
    return <CenteredLoader />;
  }

  if (Object.keys(customer).length === 0) {
    return <NoDataMessage />;
  }

  const { billingAddress } = customer || {};

  const { physAddress1: street, physCity: city, physState: state, physPostalCode: zip } = billingAddress || {};

  const descriptions = [
    street &&
      city &&
      state &&
      zip && {
        label: 'Primary Address',
        render: (
          <Tooltip title="Primary Address" placement="right">
            <span>{linkableAddress(formatAddress(street, city, state, zip))}</span>
          </Tooltip>
        ),
        span: 2,
      },
    customer?.customerType && {
      label: 'Customer Type',
      render: customer.customerType,
    },
    billingAddress?.centralPhoneNumber && {
      label: 'Central Phone',
      render: billingAddress.centralPhoneNumber,
    },
    billingAddress?.emailAddress && {
      label: 'Central Email',
      render: billingAddress.emailAddress,
    },
    billingAddress?.centralFaxNumber && {
      label: 'Central Fax',
      render: billingAddress.centralFaxNumber,
    },
    customer?.creditStatus && {
      label: 'Credit Status',
      render: customer.creditStatus,
    },
    customer?.dateAcctOpened && {
      label: 'Date Account Opened',
      render: formatDate(customer.dateAcctOpened),
    },
    customer?.salesRepresentatives && {
      label: 'Sales Rep(s)',
      render: customer.salesRepresentatives
        .map(
          ({ salesrepId, firstName, lastName }: { salesrepId: string; firstName: string; lastName: string }) =>
            `${salesrepId}: ${firstName} ${lastName}`,
        )
        .join(', '),
    },
  ].filter(Boolean);

  const tabs = getTabs(customer);

  return (
    <Container>
      {headerVisible && (
        <PageHeader
          prefix="Customer #"
          title={customer?.customerId}
          subTitle={customer?.customerName}
          headerActions={
            <NewQuoteButton
              hashState={{
                customer: joinIdNameObj({
                  foreignId: customer.customerId,
                  name: customer.customerName,
                }),
              }}
            />
          }
          onBack={onBack}
          copyable
        />
      )}
      <EntityDescriptions descriptions={descriptions} />
      <Divider>Statistics</Divider>
      <StatsBar stats={stats && stats.items?.length > 0 ? stats.items[0] : {}} />
      <Divider>Details</Divider>
      <NavTabs tabs={tabs} />
    </Container>
  );
};
