import React from 'react';

import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import { Button } from 'components/Button';
import { SmallLoader } from 'components/Loaders';
import { Typography } from 'components/Typography';

import { useLegacyApi } from 'hooks/useApi';

import { formatDate, formatPercent, formatUSD, splitIfIdNameStr } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { OrderType, RecommendedItemType, track, TrackEvent } from 'utils/track';

import { QuoteEditHashState, QuoteLineItem } from 'types/hash-state';
import {
  RecommendedNewItemsResponse,
  RecommendedOrderedOnceItemsResponse,
  RecommendedReorderItemsResponse,
  RecommendedReorderLineItem,
} from 'types/legacy-api';

import * as Styled from './QuoteLineItemRecommendations.style';

export function QuoteLineItemRecommendations({
  quoteState,
  orderType,
  onLineItemsChange,
}: {
  quoteState: QuoteEditHashState;
  orderType: OrderType;
  onLineItemsChange: (lineItems: QuoteLineItem[]) => void;
}) {
  const customerId = splitIfIdNameStr(quoteState.customer)?.foreignId;
  const shipToId = splitIfIdNameStr(quoteState.shipTo)?.foreignId;
  const locationId = splitIfIdNameStr(quoteState.location)?.foreignId;
  const lineItems = quoteState.items || [];
  const numRecItems = 5;

  if (!customerId || !shipToId || !locationId) {
    return null;
  }

  const handleRecommendedItemAdd = (item: QuoteLineItem) => {
    // if the last item is blank item (created view add new line), then replace with recommendation
    // otherwise add to line items array
    if (lineItems.length > 0 && !lineItems[lineItems.length - 1].foreignId) {
      lineItems[lineItems.length - 1] = item;
      onLineItemsChange(lineItems);
    } else {
      onLineItemsChange([...lineItems, item]);
    }
  };

  return (
    <Styled.Container>
      <Styled.Box>
        <Styled.Header>
          <Typography>Recommended Items</Typography>
        </Styled.Header>
        <Collapse defaultActiveKey={['reorders']} ghost accordion>
          <Collapse.Panel header={<Styled.RecItemsHeaderTitle>Reorders</Styled.RecItemsHeaderTitle>} key="reorders">
            <RecReorderItemsList
              customerId={customerId}
              shipToId={shipToId}
              locationId={locationId}
              lineItems={lineItems}
              numRecItems={numRecItems}
              orderType={orderType}
              onRecommendedItemAdd={handleRecommendedItemAdd}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={<Styled.RecItemsHeaderTitle>Ordered Once</Styled.RecItemsHeaderTitle>}
            key="orderedOnce"
          >
            <RecOrderedOnceItemsList
              customerId={customerId}
              shipToId={shipToId}
              locationId={locationId}
              lineItems={lineItems}
              numRecItems={numRecItems}
              orderType={orderType}
              onRecommendedItemAdd={handleRecommendedItemAdd}
            />
          </Collapse.Panel>
          <Collapse.Panel header={<Styled.RecItemsHeaderTitle>New</Styled.RecItemsHeaderTitle>} key="new">
            <RecNewItemsList
              customerId={customerId}
              lineItems={lineItems}
              numRecItems={numRecItems}
              orderType={orderType}
              onRecommendedItemAdd={handleRecommendedItemAdd}
            />
          </Collapse.Panel>
        </Collapse>
      </Styled.Box>
    </Styled.Container>
  );
}

function RecItemButton({
  lineItems,
  recItem,
  recommendedItemType,
  orderType,
  onRecommendedItemAdd,
}: {
  recItem: RecommendedReorderLineItem;
  recommendedItemType: RecommendedItemType;
  lineItems: QuoteLineItem[];
  orderType: OrderType;
  onRecommendedItemAdd: (item: QuoteLineItem) => void;
}) {
  return lineItems.some((item) => item.foreignId === recItem.itemId) ? (
    <Button size="small" disabled>
      <CheckCircleOutlined style={{ color: 'green' }} />
    </Button>
  ) : (
    <Button
      size="small"
      onClick={() => {
        track(TrackEvent.Quotes_EditQuote_AddRecommendedItem, {
          recommendedItemType,
          orderType,
        });
        onRecommendedItemAdd({
          foreignId: recItem.itemId,
          name: recItem.itemDesc,
          description: recItem.extendedDesc ?? undefined,
          quantity: recItem?.lastQty,
          price: recItem?.lastUnitPrice,
          unitOfMeasure: recItem?.lastUnitOfMeasure,
        });
      }}
    >
      <PlusOutlined />
    </Button>
  );
}

function RecNewItemsList({
  customerId,
  numRecItems,
  lineItems,
  orderType,
  onRecommendedItemAdd,
}: {
  customerId: string;
  numRecItems: number;
  lineItems: QuoteLineItem[];
  orderType: OrderType;
  onRecommendedItemAdd: (item: QuoteLineItem) => void;
}) {
  const { data, isLoading, error } = useLegacyApi<RecommendedNewItemsResponse>(
    `/v3/customers/${encodeLegacyApiParam(customerId)}/recommendations/items`,
    {
      limit: numRecItems,
    },
  );

  if (isLoading) {
    return <SmallLoader />;
  }

  if (error || data?.items.length === 0) {
    return <div>No new item recommendations found.</div>;
  }

  return (
    <div>
      {data?.items.map((recItem) => (
        <Styled.RecBox key={recItem.itemId}>
          <RecItemButton
            // casting to RecommendedReorderLineItem since we optionally take lastQty and lastPrice if available
            recItem={recItem as unknown as RecommendedReorderLineItem}
            recommendedItemType={RecommendedItemType.New}
            lineItems={lineItems}
            orderType={orderType}
            onRecommendedItemAdd={onRecommendedItemAdd}
          />
          <Styled.RecBody>
            <Styled.RecTitle>
              {recItem.itemId}: {recItem.itemDesc}
              {recItem.extendedDesc && ` - ${recItem.extendedDesc}`}
            </Styled.RecTitle>
            <Styled.RecSubtitle>{formatPercent(recItem.rank)} Match</Styled.RecSubtitle>
          </Styled.RecBody>
        </Styled.RecBox>
      ))}
    </div>
  );
}

function RecReorderItemsList({
  customerId,
  shipToId,
  locationId,
  numRecItems,
  lineItems,
  orderType,
  onRecommendedItemAdd,
}: {
  customerId: string;
  shipToId: string;
  locationId: string;
  numRecItems: number;
  lineItems: QuoteLineItem[];
  orderType: OrderType;
  onRecommendedItemAdd: (item: QuoteLineItem) => void;
}) {
  const { data, isLoading, error } = useLegacyApi<RecommendedReorderItemsResponse>(
    `/v3/customers/${encodeLegacyApiParam(customerId)}/items/reorders`,
    {
      limit: numRecItems,
      ship_to_id: shipToId,
      location_id: locationId,
    },
  );

  if (isLoading) {
    return <SmallLoader />;
  }

  if (error || data?.items.length === 0) {
    return <div>No reorder recommendations found.</div>;
  }

  return (
    <div>
      {data?.items.map((recItem) => (
        <Styled.RecBox key={recItem.itemId}>
          <RecItemButton
            recItem={recItem}
            recommendedItemType={RecommendedItemType.Reorders}
            lineItems={lineItems}
            orderType={orderType}
            onRecommendedItemAdd={onRecommendedItemAdd}
          />
          <Styled.RecBody>
            <Styled.RecTitle>
              {recItem.itemId}: {recItem.itemDesc}
              {recItem.extendedDesc && ` - ${recItem.extendedDesc}`}
            </Styled.RecTitle>
            <Styled.RecSubtitle>
              Ordered {recItem.lastQty} {recItem.lastUnitOfMeasure} on {formatDate(recItem.lastOrderDate)} for{' '}
              {formatUSD(recItem.lastUnitPrice, true)} per {recItem.lastUnitOfMeasure}
            </Styled.RecSubtitle>
          </Styled.RecBody>
        </Styled.RecBox>
      ))}
    </div>
  );
}
function RecOrderedOnceItemsList({
  customerId,
  shipToId,
  locationId,
  numRecItems,
  lineItems,
  orderType,
  onRecommendedItemAdd,
}: {
  customerId: string;
  shipToId: string;
  locationId: string;
  numRecItems: number;
  lineItems: QuoteLineItem[];
  orderType: OrderType;
  onRecommendedItemAdd: (item: QuoteLineItem) => void;
}) {
  const { data, isLoading, error } = useLegacyApi<RecommendedOrderedOnceItemsResponse>(
    `/v3/customers/${encodeLegacyApiParam(customerId)}/items/ordered-once`,
    {
      limit: numRecItems,
      ship_to_id: shipToId,
      location_id: locationId,
    },
  );

  if (isLoading) {
    return <SmallLoader />;
  }

  if (error || data?.items.length === 0) {
    return <div>No single order recommendations found.</div>;
  }

  return (
    <div>
      {data?.items.map((recItem) => (
        <Styled.RecBox key={recItem.itemId}>
          <RecItemButton
            recItem={recItem}
            recommendedItemType={RecommendedItemType.OrderedOnce}
            lineItems={lineItems}
            orderType={orderType}
            onRecommendedItemAdd={onRecommendedItemAdd}
          />
          <Styled.RecBody>
            <Styled.RecTitle>
              {recItem.itemId}: {recItem.itemDesc}
              {recItem.extendedDesc && ` - ${recItem.extendedDesc}`}
            </Styled.RecTitle>
            <Styled.RecSubtitle>
              Ordered {recItem.lastQty} {recItem.lastUnitOfMeasure} on {formatDate(recItem.lastOrderDate)} for{' '}
              {formatUSD(recItem.lastUnitPrice, true)} per {recItem.lastUnitOfMeasure}
            </Styled.RecSubtitle>
          </Styled.RecBody>
        </Styled.RecBox>
      ))}
    </div>
  );
}
