import { useEffect, useState } from 'react';

import { StateStore } from 'utils/stateStore';

/** helper hook to auto observe/unobserve for state changes in store */
export function useStore<StateT>(store: StateStore<StateT>) {
  // react re-renders when state is chaned via setState
  // observe when component is mounted, unobserve when unmounted
  // when store state changes, it is synced to component's local state
  const [state, setState] = useState<StateT>(store.state);

  useEffect(() => {
    // if store instance changes, sync state to the latest instance
    setState(store.state);
    return store.observe(setState);
  }, [store]);
  return state;
}
