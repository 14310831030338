import React from 'react';

import { Items } from './Items';
import { POLines } from './POLines';
import { PurchaseOrders } from './PurchaseOrders';
import { Suppliers } from './Suppliers';

export const getTabs = (vendorId: string | undefined) => [
  { header: 'Items', content: <Items vendorId={vendorId} /> },
  { header: 'Suppliers', content: <Suppliers vendorId={vendorId} /> },
  { header: 'Open POs', content: <PurchaseOrders vendorId={vendorId} open /> },
  { header: 'Open PO Lines', content: <POLines vendorId={vendorId} open /> },
  { header: 'All POs', content: <PurchaseOrders vendorId={vendorId} /> },
  { header: 'All PO Lines', content: <POLines vendorId={vendorId} /> },
];
