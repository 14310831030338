import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { isAdmin } from 'contexts/Auth0Context';

import { NewProspectButton } from 'components/Button/NewProspectButton';
import { ButtonLink } from 'components/Links';
import { SearchFrame } from 'components/Search/SearchFrame';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { IndexName } from 'utils/algolia';
import { routes } from 'utils/routes';

import { AlgoliaCustomer } from 'types/algolia-collections';

export const CustomerListPage = () => {
  const { activeRole } = useGlobalApp();
  const isUserAdminRole = isAdmin(activeRole.foreignId, activeRole.name);

  const defaultColumns: ColumnType<AlgoliaCustomer>[] = [
    {
      title: 'ID',
      dataIndex: 'customer_id',
      render: (id: string) => <Link to={routes.sales.customerDetails(id)}>{id}</Link>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
    },
    { title: 'Address', dataIndex: 'phys_address1' },
    {
      title: 'City',
      dataIndex: 'phys_city',
    },
    {
      title: 'State',
      dataIndex: 'phys_state',
    },
    {
      title: 'ZIP',
      dataIndex: 'phys_postal_code',
    },
  ];

  const adminColumns: ColumnType<AlgoliaCustomer>[] = [
    {
      title: 'Primary Rep',
      dataIndex: 'salesrep_fullname',
    },
  ];

  const endColumns: ColumnType<AlgoliaCustomer>[] = [
    {
      title: '',
      dataIndex: 'customer_id',
      render: (customerId: string) => ButtonLink(routes.sales.customerDetails(customerId)),
    },
  ];

  const columns = isUserAdminRole
    ? [...defaultColumns, ...adminColumns, ...endColumns]
    : [...defaultColumns, ...endColumns];

  return (
    <SearchFrame<AlgoliaCustomer>
      indexName={IndexName.Customers}
      title="Customers"
      queryPlaceholder="Search customers by ID or name..."
      columns={columns}
      valueFacets={[
        {
          title: 'Sales Rep',
          queryPlaceholder: 'Filter by sales rep...',
          ...(isUserAdminRole
            ? {
                field: 'salesreps',
              }
            : {
                hidden: true,
                field: 'salesrep_ids',
                defaultValue: activeRole.foreignId,
              }),
        },
        {
          title: 'City',
          queryPlaceholder: 'Filter by city...',
          field: 'phys_city',
        },
        {
          title: 'State',
          queryPlaceholder: 'Filter by state...',
          field: 'phys_state',
        },
      ]}
      ctaChild={<NewProspectButton />}
    />
  );
};
