import { useMemo } from 'react';

import { useLegacyApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { isStringEqual, formatUSD, formatNumber } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { qtyUnitConverter } from 'utils/units';

import { SalesRepLocationResponse } from 'types/legacy-api';

import { NULLIFY_API_CALL } from './useApi';

export const useMemoizedItemStats = (data: FIXME) => {
  const { data: locationData } = useLegacyApi<SalesRepLocationResponse>('/v3/salesrep');
  const { data: availability } = useLegacyApi(
    data && data.invMastUid
      ? `/v3/items/${encodeLegacyApiParam(data.invMastUid || '')}/availability`
      : NULLIFY_API_CALL,
    {},
  );

  return useMemo(() => {
    if (!availability || !locationData) {
      return [];
    }

    const { locationId } = locationData;

    let totalStock = 0;
    let locationQtyInfo = { locationName: '', qtyAvailable: 0, movingAverageCost: 0 };
    let defaultUnit = '';

    for (const location of availability.items) {
      const qtyAvailable = qtyUnitConverter(location.qtyOnHand - location.qtyAllocated, location.defaultUnitSize);
      totalStock += qtyAvailable;
      if (isStringEqual(locationId, location.locationId)) {
        locationQtyInfo = { ...location, qtyAvailable };
      }
      if (defaultUnit === '') {
        defaultUnit = location.defaultSellingUnit;
      }
    }

    const stats: Array<{ label: string; value: string }> = [
      {
        label: 'Company Stock',
        value: `${formatNumber(totalStock)} ${defaultUnit}`,
      },
      locationQtyInfo.locationName
        ? {
            label: `${locationQtyInfo.locationName} Stock`,
            value: `${formatNumber(locationQtyInfo.qtyAvailable)} ${defaultUnit}`,
          }
        : null,
      locationQtyInfo.locationName
        ? {
            label: `${locationQtyInfo.locationName} MAC`,
            value: formatUSD(locationQtyInfo.movingAverageCost, true),
          }
        : null,
    ].filter(truthy);

    return stats;
  }, [availability, locationData]);
};
