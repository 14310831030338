import React, { useCallback, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';

import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { EntityDescriptions } from 'components/Descriptions';
import { DEFAULT_PAGE_SIZE, Table } from 'components/Table';
import { Typography } from 'components/Typography';
import { PageHeading } from 'components/Typography/Typography';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinIdNameObj, formatDate, formatNumber, formatUSD, joinIfIdNameObj } from 'utils/formatting';
import { useHashState } from 'utils/routes';

import { QuotesListHashState } from 'types/hash-state';

import { QuoteWithTotalInfo } from '../types';
import * as Styled from './QuoteListPage.style';
import { getQuoteListColumns, quoteItemColumns } from './tableColumns';

export const QuoteListPage = () => {
  const [hashState, updateHashState] = useHashState<QuotesListHashState>();
  // antd table uses 1 based page size
  const { page = 1 } = hashState;
  const pageSize = DEFAULT_PAGE_SIZE;
  const { activeTenant, activeUser } = useGlobalApp();
  const { data, isLoading, reload } = useApi()
    .quotes()
    .getAllQuotes(activeTenant?.id, pageSize, (page - 1) * pageSize);

  const quotes: QuoteWithTotalInfo[] = useMemo(() => {
    if (data?.items) {
      return data.items.map((item) => ({
        ...item,
        totalLines: item?.metadata?.items?.length,
        totalPrice: item?.metadata?.items?.reduce((total, metaItem) => metaItem.quantity * metaItem.price + total, 0),
      }));
    }
    return [];
  }, [data]);
  const totalCount = data?.totalCount ?? 0;

  const expandable = useMemo(
    () => ({
      expandedRowRender: (record: QuoteWithTotalInfo) => {
        const { date, metadata, purchaseOrder, description } = record;
        const { items: quoteItems } = metadata ?? [];
        const descriptions = [
          { label: 'Required Date', render: formatDate(date) },
          {
            label: 'Company',
            render: metadata?.company ? joinIdNameObj(metadata?.company) : '',
          },
          { label: 'Freight Type', render: joinIfIdNameObj(metadata?.freightType) || '' },
          {
            label: 'Carrier',
            render: metadata?.carrier ? joinIdNameObj(metadata?.carrier) : '',
          },
          { label: 'PO No', render: purchaseOrder },
          { label: 'Comments', render: description, span: 3 },
        ];

        return (
          <Styled.ItemContent>
            <EntityDescriptions descriptions={descriptions} />
            {!quoteItems || !quoteItems.length ? (
              <Typography>No Items</Typography>
            ) : (
              <Styled.QuoteTable
                columns={quoteItemColumns}
                data={quoteItems}
                rowKey="foreignId"
                size="small"
                pagination={false}
                responsive
                footer={() => (
                  <Styled.FooterContent>
                    <Styled.FooterLabel>Total Lines: {formatNumber(record.totalLines)}</Styled.FooterLabel>
                    <Styled.FooterLabel>Subtotal: {formatUSD(record.totalPrice, true)}</Styled.FooterLabel>
                  </Styled.FooterContent>
                )}
              />
            )}
          </Styled.ItemContent>
        );
      },
    }),
    [],
  );

  const postDelete = useCallback(
    async (id: string) => {
      try {
        await api().quotes().deleteQuote(id, {});
        message.success('Deleted quote.');
        reload();
      } catch (err) {
        captureAndShowError(err, 'deleting quote');
      }
    },
    [reload],
  );

  const onDelete = useCallback(
    (id: string) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this quote?',
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be undone.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          postDelete(id);
        },
        onCancel() {},
      });
    },
    [postDelete],
  );

  return (
    <Styled.Container>
      <Styled.Header>
        <PageHeading>Quotes</PageHeading>
        <NewQuoteButton />
      </Styled.Header>
      <Styled.Content>
        <Table
          loading={isLoading || !data}
          columns={getQuoteListColumns(onDelete, activeTenant, activeUser)}
          data={quotes}
          rowKey="id"
          size="small"
          pagination={
            totalCount > pageSize && {
              current: page,
              pageSize,
              total: totalCount,
              onChange: (newPage: number) => updateHashState({ page: newPage }),
              simple: true,
            }
          }
          expandable={expandable}
        />
      </Styled.Content>
    </Styled.Container>
  );
};
