import React, { useEffect, useState } from 'react';

import { Statistic, Row, Col, Grid } from 'antd';
import styled from 'styled-components';

import { formatUSD, formatPercent } from 'utils/formatting';

import { UNITS } from 'constants/styles';

import { StatsBarData } from './Performance/types';
import { Tooltip } from './Tooltip';

const StyledRow = styled(Row)`
  margin-top: 1rem;
`;

const Stat = ({ title, value }: { title: string | React.ReactNode; value: string }) => (
  <Statistic title={title} value={value} valueStyle={{ fontSize: UNITS.LG }} />
);

export const StatsBar = ({ statsbarData }: { statsbarData: StatsBarData }) => {
  const screens = Grid.useBreakpoint();
  const [span, setSpan] = useState(4);

  useEffect(() => {
    if (Object.keys(screens).length > 0) {
      setSpan(screens.lg ? 4 : 8);
    }
  }, [screens, setSpan]);

  const bar = [
    {
      topTitle: <Tooltip title="Sales Month to Date">Sales MTD: </Tooltip>,
      topVal: statsbarData.salesPtd,
      bottomTitle: <Tooltip title="Sales Last Month to Date">Sales LMTD: </Tooltip>,
      bottomVal: statsbarData.salesLptd,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatUSD(amt),
    },
    {
      topTitle: <Tooltip title="Gross Margin Month to Date">GM MTD: </Tooltip>,
      topVal: statsbarData.gmPtd,
      bottomTitle: <Tooltip title="Gross Margin Last Month to Date">GM LMTD: </Tooltip>,
      bottomVal: statsbarData.gmLptd,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatUSD(amt),
    },
    {
      topTitle: <Tooltip title="Gross Margin Percentage Month to Date">GM % MTD: </Tooltip>,
      topVal: statsbarData.salesPtd > 0 ? statsbarData.gmPtd / statsbarData.salesPtd : 0,
      bottomTitle: <Tooltip title="Gross Margin Percentage Last Month to Date">GM % LMTD: </Tooltip>,
      bottomVal: statsbarData.salesLptd > 0 ? statsbarData.gmLptd / statsbarData.salesLptd : 0,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatPercent(Number(amt)),
    },
    {
      topTitle: <Tooltip title="Sales Year to Date">Sales YTD: </Tooltip>,
      topVal: statsbarData.salesYtd,
      bottomTitle: <Tooltip title="Sales Last Year to Date">Sales LYTD: </Tooltip>,
      bottomVal: statsbarData.salesPytd,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatUSD(amt),
    },
    {
      topTitle: <Tooltip title="Gross Margin Year to Date">GM YTD: </Tooltip>,
      topVal: statsbarData.gmYtd,
      bottomTitle: <Tooltip title="Gross Margin Last Year to Date">GM LYTD: </Tooltip>,
      bottomVal: statsbarData.gmPytd,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatUSD(amt),
    },
    {
      topTitle: <Tooltip title="Gross Margin Percentage Year to Date">GM % YTD: </Tooltip>,
      topVal: statsbarData.salesYtd > 0 ? statsbarData.gmYtd / statsbarData.salesYtd : 0,
      bottomTitle: <Tooltip title="Gross Margin Percentage Last Year to Date">GM % LYTD: </Tooltip>,
      bottomVal: statsbarData.salesPytd > 0 ? statsbarData.gmPytd / statsbarData.salesPytd : 0,
      upColor: (t: number, b: number) => t < b,
      render: (amt: string | number) => formatPercent(Number(amt)),
    },
  ];

  return (
    <StyledRow gutter={UNITS.XL}>
      {bar.map((item, index) => (
        <Col span={span} key={index}>
          <Stat title={item.topTitle} value={item?.render(item.topVal)} />
          <Stat title={item.bottomTitle} value={item?.render(item.bottomVal)} />
        </Col>
      ))}
    </StyledRow>
  );
};
