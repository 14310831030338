import React from 'react';

import * as Sentry from '@sentry/react';

import { CenteredError } from 'components/Loaders';

export const ErrorBoundary = ({ children }: { children: JSX.Element }) => (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, eventId }) => (
      <CenteredError error={error} componentStack={componentStack || ''} sentryEventId={eventId} />
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);
