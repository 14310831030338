import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { formatUSD } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableBooleanColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortableNumberColumn,
} from 'utils/tables';
import { priceUnitConverter } from 'utils/units';

export const locationTableColumns: ColumnType<FIXME>[] = [
  {
    title: 'Location ID',
    dataIndex: 'locationId',
    sorter: (a, b) => Number(a.locationId) - Number(b.locationId),
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    sorter: (a, b) => a.locationName.localeCompare(b.locationName),
    sortDirections: ['descend', 'ascend'],
  },
  sortableNumberColumn({
    title: 'Qty on Hand',
    dataIndex: 'qtyOnHand',
  }),
  sortableNumberColumn({
    title: 'Qty Ordered',
    dataIndex: 'orderQuantity',
  }),
  sortableNumberColumn({
    title: 'Qty B/O',
    dataIndex: 'qtyBackordered',
  }),
  sortableNumberColumn({
    title: 'Qty in Process',
    dataIndex: 'qtyInProcess',
  }),
  sortableNumberColumn({
    title: 'Qty in Transit',
    dataIndex: 'qtyInTransit',
  }),
  sortableDollarWithCentsColumn({
    title: 'Moving Avg Cost',
    dataIndex: 'movingAverageCost',
    render: (movingAverageCost: number, record: FIXME) =>
      formatUSD(priceUnitConverter(movingAverageCost, record.defaultUnitSize), true),
  }),
];

export const customerQuotesTableColumns: ColumnType<FIXME>[] = [
  sortableDateColumn({
    title: 'Order Date',
    dataIndex: 'orderDate',
  }),
  {
    title: 'Order No',
    dataIndex: 'orderNo',
    sorter: (a, b) => Number(a.orderNo) - Number(b.orderNo),
    sortDirections: ['descend', 'ascend'],
    render: (orderNo: string) => <Link to={routes.orders.orderDetails(orderNo)}>{orderNo}</Link>,
  },
  {
    title: 'Customer ID',
    dataIndex: 'customerId',
    sorter: (a, b) => Number(a.customerId) - Number(b.customerId),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Customer',
    dataIndex: 'customerName',
    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    sortDirections: ['descend', 'ascend'],
  },
  sortableNumberColumn({
    title: 'Quantity',
    dataIndex: 'qtyOrdered',
  }),
  {
    title: 'UOM',
    dataIndex: 'unitOfMeasure',
    sorter: (a, b) => a.unitOfMeasure.localeCompare(b.unitOfMeasure),
    sortDirections: ['descend', 'ascend'],
    align: 'right',
  },
  sortableDollarWithCentsColumn({
    title: 'Unit Price',
    dataIndex: 'unitPrice',
  }),
  sortableBooleanColumn({
    title: 'Complete',
    dataIndex: 'complete',
  }),
];
