import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  sortableStringColumn,
  idColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortablePercentColumn,
} from 'utils/tables';

export const OpenOrderLines = ({ invMastUid }: { invMastUid: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Order No',
      dataIndex: 'orderNo',
      render: (orderNo: string) => <Link to={routes.orders.orderDetails(orderNo)}>{orderNo}</Link>,
    }),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: 'orderDate',
      defaultSortOrder: 'descend',
    }),
    idColumn({
      title: 'Customer ID',
      dataIndex: 'customerId',
      render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customerName',
    }),
    idColumn({
      title: 'Ship To ID',
      dataIndex: 'addressId',
    }),
    sortableStringColumn({
      title: 'Ship To',
      dataIndex: 'ship2Name',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'qtyOrdered',
    }),
    sortableNumberColumn({
      title: 'Qty Open',
      dataIndex: 'qtyOpen',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'salesCost',
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'gmPct',
    }),
    {
      render: ({ orderNo }: { orderNo: string }) => ButtonLink(routes.orders.orderDetails(orderNo)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(invMastUid || '')}/orders-quotes`,
        queryParams: { type: 'order' },
      })}
      columns={columns}
    />
  );
};
