import styled from "styled-components";
export const ChartWrapper = styled.div`
  position: relative;

  .tooltip-classname {
    width: 100px;
    height: 80px;
    position: absolute;
    display: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    color: #131722;
    background-color: rgba(255, 255, 255, 1);
    text-align: left;
    z-index: 1000;
    top: 12px;
    left: 12px;
    pointer-events: none;
    border: 1px solid #3881f0;
    border-radius: 2px;
  }
`;
