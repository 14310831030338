import { Select as AntdSelect } from "antd";
import styled from "styled-components";
const heights = {
  small: "30px",
  middle: "40px",
  large: "50px"
};
const getHeights = (size = "middle") => heights[size];
export const Select = styled(AntdSelect)`
  &.ant-select {
    .ant-select-selector {
      border-radius: ${(props) => props.theme.border.radius};
      min-width: 50px;
      height: ${(props) => getHeights(props.size)} !important;
      .ant-select-selection-item,
      .ant-select-selection-search,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }
  }
  span.ant-select-selection-item {
    // spans always take width of content so this breaks flex layout (for all browsers)
    // we use a hack of width:1px and turn the padding into margin (so it doesn't overlap with dropdown arrow)
    width: 1px;
    padding-right: 0;
    margin-right: 21px;
  }
`;
export const TagSelect = styled(AntdSelect)`
  &.ant-select {
    .ant-select-selector {
      border-radius: ${(props) => props.theme.border.radius};
      min-width: 50px;
      height: ${(props) => getHeights(props.size)} !important;
      .ant-select-selection-item,
      .ant-select-selection-search,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }
  }
`;
