import React, { useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import {
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { AddUserRequest, UpdateTenantUserRequest } from '@recurrency/core-api';
import { PageHeader, Descriptions, Divider, Modal as antdModal, message, Dropdown, Menu } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { DescriptionsContainer } from 'components/DescriptionsContainer';
import { Input } from 'components/Input';
import { Container } from 'components/Layout/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { Select } from 'components/Select';
import { Table } from 'components/Table';
import { Typography } from 'components/Typography';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { formatDate } from 'utils/formatting';
import { routes, IdPathParams, usePathParams } from 'utils/routes';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';

const MAX_RECORDS = 1000;

enum UserTenantRoles {
  ADMIN = 'admin',
  MANAGER = 'manager',
  SALES = 'sales',
  PURCHASER = 'purchaser',
}

let roleIncrement = 0;
const getKey = () => roleIncrement++;
const getDefaultRole = () => ({
  key: getKey(),
  name: Object.values(UserTenantRoles)[0],
  foreignId: '',
});
const assignKeys = (roles: FIXME[]) => roles.map((r) => ({ ...r, key: getKey() }));

export const UserDetailsPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const history = useHistory();

  const { id } = usePathParams<IdPathParams>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTenantLocked, setIsTenantLocked] = useState(false);
  const [tenant, setTenant] = useState({ name: activeTenant?.name, id: activeTenant?.id });
  const [tenantQuery, setTenantQuery] = useState('');
  const [roles, setRoles] = useState([getDefaultRole()]);

  const {
    data,
    error: userError,
    isLoading: userIsLoading,
    reload: userReload,
  } = useApi().users().getUserById(id, true);
  // incorrect core-api return type
  const user = data as FIXME;

  const {
    data: data2,
    error: tenantError,
    isLoading: tenantIsLoading,
  } = useApi().tenants().getAllTenants(MAX_RECORDS, 0);
  // incorrect core-api return type
  const tenantData = data2 as FIXME;

  const error = userError || tenantError;
  const isLoading = userIsLoading || tenantIsLoading;

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !user) {
    return <CenteredLoader />;
  }

  const columns: ColumnType<FIXME>[] = [
    sortableNumberColumn({
      title: 'Tenant ID',
      dataIndex: 'id',
      render: (id: string) => <Link to={routes.internal.tenantDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    {
      title: 'Role(s)',
      dataIndex: ['tenantUser', 'roles'],
      render: (roles: FIXME[]) =>
        roles.map((role) => `${role.name || '(no name)'}:${role.foreignId || '(no foreignId)'}`).join(', '),
    },
    {
      align: 'right',
      render: (record: FIXME) => (
        <div>
          <Button onClick={() => onEdit(record)}>
            <EditOutlined /> Edit
          </Button>
          <Button danger onClick={() => onRemove(record.id)}>
            <MinusCircleOutlined /> Disconnect
          </Button>
        </div>
      ),
    },
  ];

  const onSubmit = async () => {
    try {
      if (!tenant.id) {
        throw new Error('No tenant ID found');
      }

      if (user?.tenants?.some((t: { id: string }) => t.id === tenant.id)) {
        const request: UpdateTenantUserRequest = {
          userId: user.id,
          tenantId: tenant.id,
          isActive: true,
          roles,
        };
        await api().tenants().putUpdateUser(tenant.id, user.id, request);
        message.success('User tenant updated.');
      } else {
        const request: AddUserRequest = {
          userId: user.id,
          tenantId: tenant.id,
          isActive: true,
          roles,
        };
        await api().tenants().addUser(tenant.id, request);
        message.success('User tenant added.');
      }

      closeModal();
      userReload();
    } catch (err) {
      captureAndShowError(err, `updating user`);
    }
  };

  const postRemove = async (tenantId: string) => {
    try {
      await api().tenants().removeUser(tenantId, user.id);
      message.success('Removed user from tenant.');
      setIsModalOpen(false);
      userReload();
    } catch (err) {
      captureAndShowError(err, `removing user from tenant`);
    }
  };

  const onRemove = (tenantId: string) => {
    antdModal.confirm({
      title: 'Are you sure you want to disconnect this user and tenant?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postRemove(tenantId);
      },
      onCancel() {},
    });
  };

  const postDelete = async () => {
    try {
      await api().users().deleteUser(user.id);
      message.success('Deleted user.');
      history.push(routes.internal.userList());
    } catch (err) {
      captureAndShowError(err, `deleting user`);
    }
  };

  const onDelete = () => {
    if (user.id === activeUser?.id) {
      message.error('Cannot delete currently logged in user.');
    } else {
      antdModal.confirm({
        title: 'Are you sure you want to delete this user?',
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be undone.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          postDelete();
        },
        onCancel() {},
      });
    }
  };

  const postChangePassword = async () => {
    try {
      await api().users().sendChangePassword({ id: user.id });
      message.success('Sent change password email!');
    } catch (err) {
      captureAndShowError(err, `sending change password email`);
    }
  };

  const onChangePassword = () => {
    antdModal.confirm({
      title: 'Are you sure you want to send a change password email?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postChangePassword();
      },
      onCancel() {},
    });
  };

  const onEdit = async (record: FIXME) => {
    setTenant({ name: record.name, id: record.id });
    setRoles(assignKeys(record.tenantUser?.roles));
    setIsTenantLocked(true);
    setIsModalOpen(true);
  };

  const addToTenant = () => {
    setIsModalOpen(true);
  };

  const updateRoles = ({ index, name, foreignId }: { index: number; name?: UserTenantRoles; foreignId?: string }) => {
    const existingRoles = [...roles];
    if (name) {
      existingRoles[index].name = name;
    }
    if (foreignId || foreignId === '') {
      existingRoles[index].foreignId = foreignId;
    }
    setRoles(existingRoles);
  };

  const addRole = () => {
    const existingRoles = [...roles];
    existingRoles.push(getDefaultRole());
    setRoles(existingRoles);
  };

  const removeRole = (index: number) => {
    if (roles.length > 1) {
      const existingRoles = [...roles];
      existingRoles.splice(index, 1);
      setRoles(existingRoles);
    } else {
      message.error('User must have at least one role per tenant');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsTenantLocked(false);
    setTenant({ name: activeTenant?.name, id: activeTenant?.id });
    setTenantQuery('');
    setRoles([getDefaultRole()]);
  };

  return (
    <Container>
      <PageHeader
        title={`#${user.id}: ${user.firstName} ${user.lastName}`}
        subTitle={user.email}
        onBack={() => history.push(routes.internal.userList())}
        extra={[
          <Button id="userdetails_changePassword" key="0" onClick={onChangePassword}>
            Send Change Password Email
          </Button>,
          <Button id="userdetails_delete" key="0" danger onClick={onDelete}>
            Delete
          </Button>,
          <Button id="userdetails_edit" key="1" onClick={() => history.push(routes.internal.userEdit(user.id))}>
            Edit
          </Button>,
        ]}
      >
        <DescriptionsContainer size="middle" column={2} bordered>
          <Descriptions.Item label="Last Login">{formatDate(user.lastLogin)}</Descriptions.Item>
          <Descriptions.Item label="Email Verified">{user.emailVerified ? 'Yes' : 'No'}</Descriptions.Item>
          <Descriptions.Item label="Created At">{formatDate(user.createdAt)}</Descriptions.Item>
          <Descriptions.Item label="Updated At">{formatDate(user.updatedAt)}</Descriptions.Item>
          <Descriptions.Item label="Foreign ID">{user.foreignId}</Descriptions.Item>
          <Descriptions.Item label="UID">{user.id}</Descriptions.Item>
          <Descriptions.Item label="Timezone">{user.timezone ? user.timezone : 'None listed.'}</Descriptions.Item>
        </DescriptionsContainer>
        <Table title="Tenants" columns={columns} data={user && user.tenants} rowKey="id" isLoading={isLoading}>
          <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Button type="primary" onClick={addToTenant}>
                <PlusOutlined /> Add to Tenant
              </Button>
            </div>
          </div>
        </Table>
        <Modal
          visible={isModalOpen}
          title="Manage Tenant/User Connection"
          onCancel={closeModal}
          footer={
            <div>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type="primary" onClick={onSubmit} disabled={!tenant.id}>
                Save Changes
              </Button>
            </div>
          }
          centered
        >
          <div>
            <div>Add user to</div>
            {isTenantLocked ? (
              <div>
                <Input disabled value={tenant.name} />
              </div>
            ) : (
              <Select
                style={{ display: 'block' }}
                placeholder="Search for a tenant..."
                onSelect={(value, option) => {
                  setTenant({
                    name: value,
                    id: option.id,
                  });
                }}
                onSearch={(value) => {
                  if (value && value.length > 0) {
                    setTenantQuery(value.toLowerCase());
                  }
                }}
                options={
                  tenantData
                    ? tenantData.items
                        .map((record: { id: number; name: string }) => ({
                          id: record.id,
                          value: record.name,
                        }))
                        .filter((record: { value: string }) => record.value.toLowerCase().includes(tenantQuery))
                    : []
                }
                defaultValue={tenant.name}
              />
            )}
            <br />
            <div>with role(s)</div>
            {roles.map((roleObj, index) => (
              <div key={roleObj.key}>
                <Button danger onClick={() => removeRole(index)}>
                  <MinusCircleOutlined />
                </Button>
                <Dropdown
                  overlay={
                    // @ts-expect-error (converted from ts-ignore)
                    <Menu onClick={({ key: name }) => updateRoles({ index, name })}>
                      {Object.values(UserTenantRoles).map((roleString: string) => (
                        <Menu.Item key={roleString}>{roleString}</Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button>
                    {roleObj.name} <DownOutlined />
                  </Button>
                </Dropdown>
                <Input
                  style={{ width: 'auto' }}
                  onChange={(s) => updateRoles({ index, foreignId: s.target.value })}
                  placeholder="Foreign ID"
                  defaultValue={roleObj.foreignId}
                />
              </div>
            ))}
            <br />
            <Button onClick={addRole}>
              <PlusOutlined /> Add Role
            </Button>
            <Typography type="small">
              Note: Though we support multiple roles in the database, the frontend has not yet been updated accordingly.
              Only the first role will dictate a user's permissions for now.
            </Typography>
          </div>
        </Modal>
        <Divider />
      </PageHeader>
    </Container>
  );
};
