import React from 'react';

import { Link } from 'react-router-dom';

import { Tenant } from '@recurrency/core-api';
import { message } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { QuoteWithTotalInfo, QuoteStatusType } from 'pages/orders/quotes/types';

import { Button } from 'components/Button';
import { Tag } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';

import { ExtendedUser } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinAnd } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableStringColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  idColumn,
  combinedIdStringColumn,
  strFieldSorter,
} from 'utils/tables';

import { history } from 'settings/history';
import { colors } from 'settings/theme';

const sendPDFEmail = async (quoteId: string) => {
  try {
    await api().quotes().updatePartialQuote(quoteId, { sendEmail: true });
    message.success('PDF email sent, check your inbox shortly.');
  } catch (err) {
    captureAndShowError(err, 'sending PDF email');
  }
};

export const getQuoteListColumns = (
  deleteQuote: (id: string) => void,
  activeTenant: Tenant,
  activeUser: ExtendedUser,
) => {
  const quoteCopyEmailsTo = activeTenant?.tenantUser?.settings?.quote?.copyEmailsTo ?? [];

  const columns: ColumnType<QuoteWithTotalInfo>[] = [
    idColumn({
      title: 'Quote No',
      sorter: strFieldSorter('foreignId'),
      // quotes only have foreignId when they are submitted for export
      render: (quote: QuoteWithTotalInfo) =>
        quote.foreignId ? (
          quote.foreignId
        ) : quote.status === QuoteStatusType.Draft ? (
          <span style={{ color: colors.gray500 }}>Not Submitted</span>
        ) : (
          <span style={{ color: colors.gray500 }}>Processing...</span>
        ),
    }),
    {
      title: <Tooltip title="Whether the quote has been submitted to your ERP">Submitted</Tooltip>,
      dataIndex: 'status',
      render: (t: QuoteStatusType) => {
        if (t === QuoteStatusType.Submitted) {
          return <Tag color="green">Submitted</Tag>;
        }
        if (t === QuoteStatusType.Draft) {
          return <Tag color="blue">Draft</Tag>;
        }
        return '';
      },
      defaultFilteredValue: ['submitted', 'draft'],
      filters: [
        { text: 'Submitted', value: 'submitted' },
        { text: 'Draft', value: 'draft' },
      ],
      // @ts-expect-error antd's onFilter typing is incorrect, string is the correct type here
      onFilter: (value: string, quote: QuoteWithTotalInfo) => quote.status === value,
    },
    // TODO: add exported ID once exporting is happening + export status if failed
    combinedIdStringColumn({
      title: 'Customer',
      dataIndex: ['metadata', 'customer'],
      render: ({ foreignId, name }: { foreignId: string; name: string }) =>
        foreignId ? (
          <Link to={routes.sales.customerDetails(foreignId)}>
            {foreignId}: {name}
          </Link>
        ) : (
          ''
        ),
    }),
    combinedIdStringColumn({ title: 'Ship To', dataIndex: ['metadata', 'shipTo'] }),
    combinedIdStringColumn({ title: 'Contact', dataIndex: ['metadata', 'contact'] }),
    sortableDollarWithCentsColumn({ title: 'Total Value', dataIndex: 'totalPrice' }),
    sortableDateColumn({
      title: 'Last Update',
      dataIndex: 'updatedAt',
    }),
    {
      title: 'Actions',
      dataIndex: '',
      render: (record: QuoteWithTotalInfo) => (
        <div style={{ display: 'flex', gap: 8 }}>
          <Tooltip
            title={
              record.totalLines > 0
                ? `Send PDF to ${joinAnd([
                    activeUser.email,
                    ...quoteCopyEmailsTo,
                  ])}. This will not send an email to the customer.`
                : 'Add at least one line to send PDF to your email.'
            }
          >
            <Button disabled={record.totalLines === 0} onClick={() => sendPDFEmail(record.id)}>
              Email PDF
            </Button>
          </Tooltip>
          <Button
            disabled={record.status === QuoteStatusType.Submitted}
            onClick={() => history.push(routes.orders.quoteEdit(record.id))}
          >
            Edit
          </Button>
          <Button disabled={record.status === QuoteStatusType.Submitted} danger onClick={() => deleteQuote(record.id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];
  return columns;
};

export const quoteItemColumns = [
  idColumn({
    title: 'Item ID',
    dataIndex: 'foreignId',
    render: (itemId: string) => <Link to={routes.sales.itemDetails(itemId)}>{itemId}</Link>,
  }),
  sortableStringColumn({ title: 'Name', dataIndex: 'name' }),
  sortableNumberColumn({ title: 'Qty', dataIndex: 'quantity' }),
  sortableStringColumn({ title: 'UOM', dataIndex: 'unitOfMeasure' }),
  sortableDollarWithCentsColumn({ title: 'Price', dataIndex: 'price' }),
];
