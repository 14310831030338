import React from 'react';

import { useHistory } from 'react-router-dom';

import { Form, message, PageHeader } from 'antd';

import { Button } from 'components/Button';
import { defaultFormTailLayout, InputFormItem, responsiveFormLayout } from 'components/FormItems';
import { Container } from 'components/Layout/Container';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes } from 'utils/routes';

export const TenantNewPage = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const onSubmit = async (data: any) => {
    try {
      const request = {
        ...data,
        metadata: {
          legacyId: data.legacyId,
        },
      };
      const response: any = await api().tenants().createTenant(request);
      message.success('New tenant created.');
      if (response?.data?.id) {
        history.push(routes.internal.tenantDetails(response.data.id));
      } else {
        history.push(routes.internal.tenantList());
      }
    } catch (err) {
      captureAndShowError(err, `creating tenant`);
    }
  };

  return (
    <Container>
      <PageHeader title="New Tenant" onBack={() => history.push(routes.internal.tenantList())} />
      <Form.Provider>
        <Form {...responsiveFormLayout} name="taskForm" form={form} onFinish={onSubmit} onError={console.error}>
          <InputFormItem label="Name" name="name" rules={[{ required: true, message: 'Please add a name.' }]} />

          <InputFormItem
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: 'Please add a company name.' }]}
          />

          <InputFormItem
            label="Algolia Key (AIS)"
            name="ais"
            rules={[{ required: true, message: 'Please add an Algolia key.' }]}
          />

          <InputFormItem label="Slug" name="slug" rules={[{ required: true, message: 'Please add a slug.' }]} />

          <InputFormItem label="Legacy ID" name="legacyId" />

          <Form.Item {...defaultFormTailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Container>
  );
};
