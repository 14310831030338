import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { ForecastSparkline } from 'components/Charts/ForecastSparkline/ForecastSparkline';
import { RadioGroup } from 'components/Radio';
import { SearchFrame } from 'components/Search/SearchFrame';

import { IndexName } from 'utils/algolia';
import { truthy } from 'utils/boolean';
import { formatNumber } from 'utils/formatting';
import { objRemoveUndefinedValues } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { sortableNumberColumn } from 'utils/tables';

import { RECURRENCY_GREEN } from 'constants/styles';

import { RECURRENCY_RED } from 'settings/theme/colors';

import { AlgoliaForecast } from 'types/algolia-collections';
import { SearchFrameHashState } from 'types/hash-state';

export const TimeHorizon: Record<string, number> = {
  '1 Month': 1,
  '3 Month': 3,
  '6 Month': 6,
  '12 Month': 12,
};
const timeHorizons = Object.keys(TimeHorizon).filter((value) => typeof value === 'string');

export const ForecastListPage = () => {
  const [hashState, updateHashState] = useHashState<SearchFrameHashState>();
  const renderNumber = (num: number) =>
    num !== undefined ? num !== null && formatNumber(num) : { props: { colSpan: 0 } };
  const renderNumberItalics = (num: number) =>
    num !== undefined ? num !== null && <em>{formatNumber(num)}</em> : { props: { colSpan: 0 } };

  const [showSparkline, setShowSparkline] = useState(true);

  const selectedTimeHorizon = hashState.extFilter?.timeHorizon?.[0] ?? Object.keys(TimeHorizon)[2];

  const columns: ColumnType<AlgoliaForecast>[] = [
    {
      title: 'Item ID',
      dataIndex: 'item_id',
      render: (id?: string) =>
        id && (
          <Link to={routes.sales.itemDetails(id)} style={{ whiteSpace: 'nowrap' }}>
            {id}
          </Link>
        ),
    },
    {
      title: 'Item',
      dataIndex: 'item_desc',
    },
    {
      title: 'UOM',
      dataIndex: 'unit_of_measure',
    },
    showSparkline && {
      title: 'Demand',
      // Use past 12 months worth of data
      render: (forecast: AlgoliaForecast) =>
        !forecast.item_id ? null : (
          <ForecastSparkline
            historicalData={forecast.historical_dates.map((_, i) => ({
              date: forecast.historical_dates[i],
              value: forecast.historical_demand[i],
              uom: forecast.unit_of_measure,
              type: 'historical',
            }))}
            forecastData={forecast.forecast_dates.slice(0, TimeHorizon[selectedTimeHorizon]).map((_, i) => ({
              date: forecast.forecast_dates[i],
              value: forecast.forecast_demand[i],
              uom: forecast.unit_of_measure,
              type: 'forecast',
            }))}
          />
        ),
    },

    !showSparkline && {
      title: 'Historical Demand',
      // Use past 12 months worth of data
      children: Array.from(Array(12).keys()).map((month) => ({
        // TODO: use dates sent by backend
        title: moment()
          .subtract(12 - month, 'month')
          .format('MMM YYYY'),
        dataIndex: ['historical_demand', month],
        align: 'right' as const,
        render: renderNumber,
      })),
    },
    !showSparkline && {
      title: <em>Forecasted Demand</em>,
      // Use next 6 months worth of data
      children: Array.from(Array(TimeHorizon[selectedTimeHorizon]).keys()).map((month) => ({
        title: <em>{moment().add(month, 'month').format('MMM YYYY')}</em>,
        dataIndex: ['forecast_demand', month],
        align: 'right' as const,
        render: renderNumberItalics,
      })),
    },

    {
      title: <em>Total {selectedTimeHorizon} Forecast</em>,
      dataIndex: `M${TimeHorizon[selectedTimeHorizon]}_total_forecast`,
      render: renderNumberItalics,
      align: 'right' as const,
    },
    {
      title: 'Location Stock',
      dataIndex: 'location_stock',
      render: renderNumber,
      align: 'right' as const,
    },
    {
      title: 'Incoming Stock',
      dataIndex: 'location_incoming_stock',
      render: renderNumber,
      align: 'right' as const,
    },
    sortableNumberColumn({
      title: `${selectedTimeHorizon} Net Difference`,
      dataIndex: `M${TimeHorizon[selectedTimeHorizon]}_net_difference`,
      render: (netDifference: number) =>
        netDifference !== undefined
          ? netDifference !== null && (
              <div
                style={{
                  color: netDifference < 0 ? RECURRENCY_RED : RECURRENCY_GREEN,
                }}
              >
                {formatNumber(netDifference)}
              </div>
            )
          : { props: { colSpan: 0 } },
    }),
    sortableNumberColumn({
      title: 'Months of Stock',
      dataIndex: 'months_of_stock',
      render: renderNumber,
    }),
  ].filter(truthy);

  return (
    <SearchFrame<AlgoliaForecast>
      title="Forecasts"
      indexName={IndexName.Forecasts}
      queryPlaceholder="Search forecasts by ID, description, product group, or keywords..."
      columns={columns}
      ctaChild={
        <RadioGroup
          onChange={({ target: { value } }) => {
            if (value === 'sparkline') {
              setShowSparkline(true);
            } else {
              setShowSparkline(false);
            }
          }}
          value={showSparkline ? 'sparkline' : 'numerical'}
        >
          <Radio.Button value="sparkline">Sparkline View</Radio.Button>
          <Radio.Button value="numerical">Numerical View</Radio.Button>
        </RadioGroup>
      }
      externalFacets={[
        {
          title: 'Time Horizon',
          field: 'timeHorizon',
          filterType: 'single',
          defaultValue: '6 Month',
          options: timeHorizons,
          showItemCount: false,
          onSelect: (oldSelection) => {
            // Reset the net difference filter is used when switching time horizons
            updateHashState({
              whereNum: objRemoveUndefinedValues({
                ...hashState.whereNum,
                [`M${TimeHorizon[oldSelection[0]]}_net_difference`]: undefined,
              }),
              sortBy: { field: 'months_of_stock', order: 'asc' },
              page: 0,
            });
          },
        },
      ]}
      valueFacets={[
        {
          title: 'Locations',
          field: 'location',
          filterType: 'single',
          defaultValue: true,
          queryPlaceholder: 'Filter by locations...',
        },
        {
          title: 'Vendors',
          field: 'vendors',
          queryPlaceholder: 'Filter by vendor...',
        },
        {
          title: 'Product Groups',
          field: 'product_group',
          queryPlaceholder: 'Filter by product group...',
        },
        {
          title: 'Only Show Items With Demand',
          field: 'has_demand',
          capitalizeItemLabels: true,
          defaultValue: 'true',
          filterType: 'single',
        },
      ]}
      numericFacets={[
        {
          title: `${selectedTimeHorizon} Net Difference`,
          field: `M${TimeHorizon[selectedTimeHorizon]}_net_difference`,
          labelFormatFn: formatNumber,
        },
      ]}
      tableRowKey="objectID"
    />
  );
};
