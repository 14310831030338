import { Typography } from "antd";
import styled from "styled-components";
export const Title = styled(Typography.Title)`
  margin-top: 2rem;
  text-align: center;
`;
export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
