import { monthTicks } from 'components/Performance/data';

export const getLastTwoYears = () => {
  const d = new Date();
  const thisYear = d.getFullYear().toString();
  const lastYear = (d.getFullYear() - 1).toString();
  return [lastYear, thisYear];
};

export function monthNumFromName(monthName: string): number {
  const months: { [month: string]: number } = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    // no may because it is the same as abbreviation
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11,
  };
  return months[monthName.toLowerCase()];
}

/**
 *
 * @returns Month names from the beginning of the year to the current month, inclusive
 */
export function getElapsedMonths(): string[] {
  const date = new Date();
  const currentMonth = date.getMonth();
  return monthTicks.filter((month) => monthNumFromName(month) <= currentMonth);
}
