import React from 'react';

import { Radio } from 'antd';

import { CenteredError } from 'components/Loaders';
import { RadioGroup } from 'components/Radio';
import { ResponsiveTable, Props as TableProps } from 'components/ResponsiveTable';

import { UseAsyncTableProps } from './useAsyncTableProps';

interface AsyncTableProps<ItemT = any> extends TableProps {
  /** hook passed from useTableProps util */
  tableProps: UseAsyncTableProps<ItemT>;
  /** function to toggle percent value */
  setIsPercent?: (isPercent: boolean) => void;
  /** whether to display percents or raw values */
  isPercent?: boolean;
}

export function AsyncTable<ItemT>({ tableProps, setIsPercent, isPercent, ...otherProps }: AsyncTableProps<ItemT>) {
  const { isLoading, isReloading, items, totalCount, error, pageSize, setPage } = tableProps;
  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <>
      {setIsPercent && (
        <RadioGroup
          onChange={({ target: { value } }) => {
            if (value === 'raw') {
              setIsPercent(false);
            } else {
              setIsPercent(true);
            }
          }}
          value={isPercent ? 'pct' : 'raw'}
          style={{ marginBottom: '1rem' }}
        >
          <Radio.Button value="raw">Dollar View</Radio.Button>
          <Radio.Button value="pct">Percentage View</Radio.Button>
        </RadioGroup>
      )}
      <ResponsiveTable
        dataSource={items}
        // when isReloading, table will show existing data + spinny on top
        loading={isLoading || isReloading}
        pagination={
          totalCount > pageSize && {
            onChange: (oneIndexedPageNum: number) => setPage(oneIndexedPageNum - 1),
            pageSize,
            simple: true,
            total: totalCount,
          }
        }
        size="small"
        {...otherProps}
      />
    </>
  );
}
