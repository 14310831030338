import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';
import { CompareFn, SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';
import { DataIndex } from 'rc-table/lib/interface';

import { ButtonLink } from 'components/Links';

import {
  formatUSD,
  formatDate,
  formatPercent,
  capitalize,
  getPercentColor,
  formatNumber,
  formatId,
  formatHumanDate,
} from 'utils/formatting';
import { objGet } from 'utils/object';
import { routes } from 'utils/routes';

import { priceUnitConverter, qtyUnitConverter } from './units';

interface ColumnTypeNoKey<ObjT> extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  render?: (val: ObjT, obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnType1Key<ObjT, Key1T extends keyof ObjT, T = Key1T>
  extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  dataIndex: T;
  render?: (val: ObjT[Key1T], obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnType2Keys<ObjT, Key1T extends keyof ObjT, Key2T extends keyof ObjT[Key1T], T = [Key1T, Key2T]>
  extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  dataIndex: T;
  render?: (val: ObjT[Key1T][Key2T], obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnType3Keys<
  ObjT,
  Key1T extends keyof ObjT,
  Key2T extends keyof ObjT[Key1T],
  Key3T extends keyof ObjT[Key1T][Key2T],
  T = [Key1T, Key2T, Key3T],
> extends Omit<ColumnType<ObjT>, 'dataIndex' | 'render'> {
  dataIndex: T;
  render?: (val: ObjT[Key1T][Key2T][Key3T], obj: ObjT, index: number) => React.ReactNode;
}

interface ColumnTypeGeneric<ObjT> extends ColumnType<ObjT> {
  dataIndex?: string | Readonly<string[]>;
  render?: (val: Any, obj: ObjT, index: number) => React.ReactNode;
}

export function typedColumn<ObjT extends Obj, Key1T extends keyof ObjT>(
  config: ColumnType1Key<ObjT, Key1T>,
): ColumnType<ObjT>;
export function typedColumn<ObjT extends Obj, Key1T extends keyof ObjT, Key2T extends keyof ObjT>(
  config: ColumnType2Keys<ObjT, Key1T, Key2T>,
): ColumnType<ObjT>;
export function typedColumn<
  ObjT extends Obj,
  Key1T extends keyof ObjT,
  Key2T extends keyof ObjT[Key1T],
  Key3T extends keyof ObjT[Key1T][Key2T],
>(config: ColumnType3Keys<ObjT, Key1T, Key2T, Key3T>): ColumnType<ObjT>;
export function typedColumn<ObjT extends Obj>(config: ColumnTypeNoKey<ObjT>): ColumnType<ObjT>;
export function typedColumn<ObjT>(config: ColumnTypeGeneric<ObjT>): ColumnType<ObjT> {
  return config;
}

// usually ant tables go from (descend -> ascend -> no sort), this makes it (descend -> ascend) toggle
export const sortDirections = ['descend' as const, 'ascend' as const, 'descend' as const];

/**
 * Use-case: sortable table column with empty values. Typically you don't want the empty values on top,
 * so this function will always sort them to the end of the list for either ascending or descending.
 */
export const falseyAlwaysLast =
  (sortFn: CompareFn<Obj>, dataIndex: DataIndex | undefined): CompareFn<Obj> =>
  (aObj: Obj, bObj: Obj, sortOrder?: SortOrder) => {
    const a = objGet(aObj, dataIndex);
    const b = objGet(bObj, dataIndex);

    // If both falsey, they count as equal
    // NOTE: Special exception, zeroes should sort normally
    if (!a && !b && a !== 0 && b !== 0) {
      return 0;
    }
    if (!a && a !== 0) {
      return sortOrder === 'descend' ? -1 : 1;
    }
    if (!b && b !== 0) {
      return sortOrder === 'descend' ? 1 : -1;
    }

    return sortFn(aObj, bObj, sortOrder);
  };

/** function that returns a sorter function that sorts off the field given by dataIndex */
export const strFieldSorter =
  (dataIndex: DataIndex | undefined) =>
  (a: Obj, b: Obj): number =>
    (objGet(a, dataIndex) || '').localeCompare(objGet(b, dataIndex) || '', undefined, {
      // This keyword makes the comparison case-insensitive
      sensitivity: 'base',
    });

export const numFieldSorter =
  (dataIndex: DataIndex | undefined) =>
  (a: Obj, b: Obj): number =>
    Number(objGet(a, dataIndex)) - Number(objGet(b, dataIndex));

export const sortableStringColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  sorter: falseyAlwaysLast(strFieldSorter(dataIndex), dataIndex),
  sortDirections,
  ...rest,
});

export const sortableNumberColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (num: number) => formatNumber(Number(num)),
  sorter: numFieldSorter(dataIndex),
  sortDirections,
  align: 'right' as const,
  ...rest,
});

export const sortableDollarColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (amt: number) => formatUSD(amt),
  sorter: numFieldSorter(dataIndex),
  sortDirections,
  align: 'right' as const,
  ...rest,
});

export const sortableDollarWithCentsColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (amt: number) => formatUSD(amt, true),
  sorter: numFieldSorter(dataIndex),
  sortDirections,
  align: 'right' as const,
  ...rest,
});

export const humanDateColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: formatHumanDate,
  ...rest,
});

export const sortableDateColumn = ({
  dataIndex,
  title,
  withTimestamp = false,
  ...rest
}: { withTimestamp?: boolean } & ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (date: string) => formatDate(date, withTimestamp),
  sorter: (a: Obj, b: Obj) => {
    const aNotValid = !objGet(a, dataIndex) || !moment(objGet(a, dataIndex)).isValid();
    const bNotValid = !objGet(b, dataIndex) || !moment(objGet(b, dataIndex)).isValid();

    if (aNotValid && bNotValid) {
      return 0;
    }
    if (aNotValid) {
      return -1;
    }
    if (bNotValid) {
      return 1;
    }
    return objGet(a, dataIndex)?.localeCompare(objGet(b, dataIndex));
  },
  sortDirections,
  ...rest,
});

export const sortablePercentColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (val: number) => formatPercent(val),
  sorter: numFieldSorter(dataIndex),
  sortDirections,
  align: 'right' as const,
  ...rest,
});

export const booleanColumn = ({ dataIndex, title }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (val: boolean) => (val ? `Yes` : `No`),
});

export const sortableBooleanColumn = ({ dataIndex, title }: ColumnType<any>): ColumnType<any> => ({
  ...booleanColumn({ dataIndex, title }),
  sorter: (a: Obj, b: Obj) => (objGet(a, dataIndex) ? 1 : 0) - (objGet(b, dataIndex) ? 1 : 0),
  sortDirections,
});

export const idColumn = ({ title, dataIndex, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  render: (id: string) => formatId(id),
  sorter: strFieldSorter(dataIndex),
  sortDirections,
  ...rest,
});

export const combinedIdStringColumn = ({ dataIndex, title, ...rest }: ColumnType<any>): ColumnType<any> => ({
  title,
  dataIndex,
  sorter: strFieldSorter('foreignId'),
  sortDirections,
  render: (value: { foreignId: string; name: string } | undefined) => {
    if (value) {
      return `${value.foreignId}: ${value.name}`;
    }
    return '';
  },
  ...rest,
});

export const percentChangeColumn = ({
  dividend,
  divisor,
  title,
}: {
  dividend: (row: any) => number;
  divisor: (row: any) => number;
} & Omit<ColumnType<any>, 'dataIndex'>): ColumnType<any> => ({
  title: title || '∆%',
  align: 'right' as const,
  render: (row: FIXME) => {
    if (!divisor(row) || divisor(row) === 0) {
      return '-';
    }

    const percent = formatPercent(dividend(row) / divisor(row));

    return <span style={{ color: getPercentColor(percent) }}>{percent}</span>;
  },
});

const beginningColumns = (type: string): ColumnType<any>[] => [
  sortableDateColumn({
    title: `${capitalize(type)} Date`,
    dataIndex: 'orderDate',
  }),
  idColumn({
    title: 'Customer ID',
    dataIndex: 'customerId',
    render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
  }),
  sortableStringColumn({ title: 'Customer', dataIndex: 'customerName' }),
];

const orderOrQuoteColumns = (type: string): ColumnType<any>[] =>
  type === 'order'
    ? [
        sortableDollarColumn({
          title: 'Total Amount',
          dataIndex: 'totalValue',
        }),
        sortableDollarColumn({
          title: 'Open Amount',
          dataIndex: 'openValue',
        }),
        sortableDollarColumn({
          title: 'Total GM',
          dataIndex: 'gm',
        }),
        sortableDollarColumn({
          title: 'Open GM',
          dataIndex: 'openGm',
        }),
        percentChangeColumn({
          title: 'GM%',
          dividend: (item: Obj) => item.gm,
          divisor: (item: Obj) => item.totalValue,
        }),
      ]
    : [
        sortableDollarColumn({
          title: `${type === 'quote' ? capitalize(type) : 'Total'} Amount`,
          dataIndex: 'quoteValue',
        }),
        sortableDollarColumn({
          title: 'Quote GM',
          dataIndex: 'quoteGm',
        }),
        percentChangeColumn({
          title: 'GM%',
          dividend: (item: Obj) => item.quoteGm,
          divisor: (item: Obj) => item.quoteValue,
        }),
      ];

const endColumns = (): ColumnType<any>[] => [
  sortableNumberColumn({
    title: 'Item Count',
    dataIndex: 'itemCount',
    responsive: ['lg' as const, 'xl' as const, 'xxl' as const],
  }),
];

export const orderAndQuoteColumns = ({ type }: { type: string }): ColumnType<any>[] => [
  ...beginningColumns(type),
  ...orderOrQuoteColumns(type),
  ...endColumns(),
];

export const salesAndGMColumns = ({ isPercent }: { isPercent: boolean }): ColumnType<any>[] => [
  sortableDollarColumn({
    title: 'Sales YTD',
    dataIndex: 'salesYtd',
    defaultSortOrder: 'descend',
  }),
  sortableDollarColumn({
    title: 'Sales LYTD',
    dataIndex: 'salesPytd',
    responsive: ['lg' as const, 'xl' as const, 'xxl' as const],
  }),
  isPercent
    ? {
        title: '∆%',
        dataIndex: 'salesDeltaPct',
        render: (pct: number) => {
          const formatted = formatPercent(pct);

          return <span style={{ color: getPercentColor(formatted) }}>{formatted}</span>;
        },
        sorter: (a: Obj, b: Obj) => a.salesDeltaPct - b.salesDeltaPct,
        sortDirections,
      }
    : {
        title: '∆ Sales',
        dataIndex: 'salesDeltaRaw',
        render: (amt: string) => <span style={{ color: getPercentColor(amt) }}>{formatUSD(amt)}</span>,
        sorter: (a: Obj, b: Obj) => a.salesDeltaRaw - b.salesDeltaRaw,
        sortDirections,
      },
  sortableDollarColumn({
    title: 'GM YTD',
    dataIndex: 'gmYtd',
  }),
  sortableDollarColumn({
    title: 'GM LYTD',
    dataIndex: 'gmPytd',
    responsive: ['lg' as const, 'xl' as const, 'xxl' as const],
  }),
  isPercent
    ? {
        title: '∆%',
        dataIndex: 'gmDeltaPct',
        render: (pct: number) => {
          const formatted = formatPercent(pct);

          return <span style={{ color: getPercentColor(formatted) }}>{formatted}</span>;
        },
        sorter: (a: Obj, b: Obj) => a.gmDeltaPct - b.gmDeltaPct,
        sortDirections,
      }
    : {
        title: '∆ GM',
        dataIndex: 'gmDeltaRaw',
        render: (amt: string) => <span style={{ color: getPercentColor(amt) }}>{formatUSD(amt)}</span>,
        sorter: (a: Obj, b: Obj) => a.gmDeltaRaw - b.gmDeltaRaw,
        sortDirections,
      },
];

export const salesTableColumns = [
  sortableNumberColumn({
    title: 'Order No',
    dataIndex: 'orderNo',
    render: (orderNo?: string) =>
      orderNo ? <Link to={routes.orders.orderDetails(orderNo)}>{formatId(orderNo)}</Link> : null,
  }),
  sortableDateColumn({
    title: 'Order Date',
    dataIndex: 'orderDate',
  }),
  idColumn({
    title: 'Location',
    dataIndex: 'salesLocationId',
    render: (_, record: FIXME) => `${record.salesLocationId}: ${record.locationName}`,
  }),
  sortableStringColumn({
    title: 'Customer ID',
    dataIndex: 'customerId',
    render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
  }),
  sortableStringColumn({
    title: 'Customer',
    dataIndex: 'customerName',
  }),
  sortableNumberColumn({
    title: 'Quantity',
    dataIndex: 'qtyShipped',
    render: (qtyShipped: number, record: FIXME) => qtyUnitConverter(qtyShipped, record.salesUnitSize),
  }),
  sortableStringColumn({
    title: 'UOM',
    dataIndex: 'unitOfMeasure',
  }),
  sortableDollarWithCentsColumn({
    title: 'Unit Price',
    dataIndex: 'unitPrice',
  }),
  sortableDollarWithCentsColumn({
    title: 'Unit Cost',
    dataIndex: 'unitCost',
    render: (unitCost: number, record: FIXME) => formatUSD(priceUnitConverter(unitCost, record.salesUnitSize), true),
  }),
  sortableDollarWithCentsColumn({
    title: 'Ext. Price',
    dataIndex: 'extendedPriceHome',
  }),
  sortablePercentColumn({
    title: 'GM%',
    dataIndex: 'gmPercent',
  }),
  {
    render: ({ orderNo }: { orderNo: string }) => (orderNo ? ButtonLink(routes.orders.orderDetails(orderNo)) : null),
  },
];

export const getProductGroupSearchRoute = (productGroupId: string, productGroupDesc: string): string =>
  routes.sales.itemList({ where: { pg: [`${productGroupId}: ${productGroupDesc}`] } });
