import React from 'react';

import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import { Auth0Provider } from 'contexts/Auth0Context';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { AppVersionPoller } from 'components/Utilities/AppVersionPoller';

import { perfMark, PerfMarkName } from 'utils/perfMeasure';

import { authConfig } from 'settings/authConfig';
import { history } from 'settings/history';
import { registerSentry } from 'settings/sentry';
import { theme } from 'settings/theme';

import { App } from './App';
import { extScriptsInit } from './extScriptsInit';
import './index.less';

function init() {
  const { protocol, hostname, pathname, search, hash } = window.location;
  // redirect .ai -> .com
  if (hostname === 'app.recurrency.ai' || hostname === 'app-staging.recurrency.ai') {
    window.location.replace(`https://${hostname.replace('.ai', '.com')}${pathname}${search}${hash}`);
    return;
  }

  // force https
  if (protocol === 'http:' && hostname !== 'localhost') {
    window.location.replace(`https://${hostname}${pathname}${search}${hash}`);
    return;
  }

  perfMark(PerfMarkName.AppInit);
  registerSentry();

  // only initialize 3rd party tracking script if browser isn't ChromeHeadless
  // @see https://stackoverflow.com/a/54306469
  if (!window.navigator.webdriver) {
    extScriptsInit();
  }

  ReactDOM.render(
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={authConfig.domain}
          client_id={authConfig.clientId}
          redirect_uri={window.location.origin}
          scope="openid profile email recurrency:user recurrency:admin"
          cacheLocation="localstorage"
          audience={authConfig.audience}
        >
          <AppVersionPoller>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </AppVersionPoller>
        </Auth0Provider>
      </ThemeProvider>
    </Router>,
    document.getElementById('root'),
  );
}

init();
