import React from 'react';

import { Typography, Table as AntTable } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { CenteredLoader } from 'components/Loaders';
import { NoDataMessage } from 'components/NoDataMessage';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';

import { getElapsedMonths, getLastTwoYears } from 'utils/date';
import { formatPercent, formatUSD } from 'utils/formatting';
import { percentChangeColumn } from 'utils/tables';

import { RECURRENCY_GREEN, RECURRENCY_RED } from 'constants/styles';

const summaryAlign = 'right';

export const PerformanceTable = ({ dashboardData }: { dashboardData: FIXME }) => {
  const lastTwoYears = getLastTwoYears();
  const [lastYear, thisYear] = lastTwoYears;

  const dataKeys = ['lineSales', 'lineGm'];

  if (!dashboardData) {
    return <CenteredLoader />;
  }

  if (Object.keys(dashboardData).length === 0) {
    return <NoDataMessage />;
  }

  const { stats } = dashboardData;

  const combinedData = getElapsedMonths().map((monthTick) => ({
    date: monthTick,
    ...lastTwoYears.reduce(
      (yearAcc, yearCur) => ({
        ...yearAcc,
        ...dataKeys.reduce(
          (dataAcc, dataCur) => ({
            ...dataAcc,
            [`${dataCur}_${yearCur}`]: dashboardData?.[yearCur]?.[monthTick.toLowerCase()]?.[dataCur] || 0,
          }),
          {},
        ),
      }),
      {},
    ),
  }));

  const dataFilter = (d: any) => d[`lineSales_${thisYear}`] !== undefined && d[`lineGm_${thisYear}`] !== undefined;

  const finalData = combinedData.filter(dataFilter);

  const columns: ColumnType<FIXME>[] = [
    { title: 'Month', dataIndex: 'date' },
    {
      title: `Sales ${thisYear}`,
      dataIndex: `lineSales_${thisYear}`,
      render: (amt: any) => formatUSD(amt),
      align: 'right',
    },
    {
      title: `Sales ${lastYear}`,
      dataIndex: `lineSales_${lastYear}`,
      render: (amt: any) => formatUSD(amt),
      align: 'right',
    },
    percentChangeColumn({
      dividend: (sales: any) => sales[`lineSales_${thisYear}`] - sales[`lineSales_${lastYear}`],
      divisor: (sales: any) => sales[`lineSales_${lastYear}`],
    }),
    {
      title: <Tooltip title={`Gross Margin in ${thisYear}`} children={`GM ${thisYear}`} />,
      dataIndex: `lineGm_${thisYear}`,
      render: (amt: any) => formatUSD(amt),
      align: 'right',
    },
    {
      title: <Tooltip title={`Gross Margin in ${lastYear}`} children={`GM ${lastYear}`} />,
      dataIndex: `lineGm_${lastYear}`,
      render: (amt: any) => formatUSD(amt),
      align: 'right',
    },
    percentChangeColumn({
      dividend: (gm: any) => gm[`lineGm_${thisYear}`] - gm[`lineGm_${lastYear}`],
      divisor: (gm: any) => gm[`lineGm_${lastYear}`],
    }),
    percentChangeColumn({
      title: <Tooltip title={`Gross Margin Percentage in ${thisYear}`} children={`GM% ${thisYear}`} />,
      dividend: (item: any) => item[`lineGm_${thisYear}`],
      divisor: (item: any) => item[`lineSales_${thisYear}`],
    }),
    percentChangeColumn({
      title: <Tooltip title={`Gross Margin Percentage in ${lastYear}`} children={`GM% ${lastYear}`} />,
      dividend: (item: any) => item[`lineGm_${lastYear}`],
      divisor: (item: any) => item[`lineSales_${lastYear}`],
    }),
  ];

  const getNumberColor = (num: any) => (num > 0 ? RECURRENCY_GREEN : RECURRENCY_RED);

  return (
    <>
      <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '2rem' }}>
        Performance
      </Typography.Title>
      <Table
        columns={columns}
        data={finalData}
        size="small"
        bordered
        pagination={false}
        rowKey="date"
        summary={() => (
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell index={0} colSpan={1}>
              <b>Totals</b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>{formatUSD(stats.salesYtd)}</b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>{formatUSD(stats.salesPytd)}</b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>
                <span style={{ color: getNumberColor(stats.salesDeltaPct) }}>{formatPercent(stats.salesDeltaPct)}</span>
              </b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>{formatUSD(stats.gmYtd)}</b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>{formatUSD(stats.gmPytd)}</b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>
                <span style={{ color: getNumberColor(stats.gmDeltaPct) }}>{formatPercent(stats.gmDeltaPct)}</span>
              </b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>
                <span style={{ color: getNumberColor(stats.gmYtd / stats.salesYtd) }}>
                  {formatPercent(stats.gmYtd / stats.salesYtd)}
                </span>
              </b>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={1} align={summaryAlign}>
              <b>
                <span style={{ color: getNumberColor(stats.gmPytd / stats.salesPytd) }}>
                  {formatPercent(stats.gmPytd / stats.salesPytd)}
                </span>
              </b>
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
        )}
      />
    </>
  );
};
