import React from 'react';

import { DatePickerProps } from 'antd';
import moment, { Moment } from 'moment';

import { Button } from 'components/Button';
import { DatePicker } from 'components/DatePicker';

export interface QuickButton {
  name: string;
  value: Moment;
}

const isDateDisabled = (date: Moment) =>
  // Can not select days before today
  date && date < moment().startOf('day');

/**
 * IsoDatePicker takes date value as ISO 8601 strings, and returns date changes as strings
 */
export function IsoDatePicker({
  value,
  onChange,
  quickButtons,
  ...otherProps
}: {
  value?: string;
  onChange?: (newValue: string) => void;
  quickButtons?: QuickButton[];
} & DatePickerProps) {
  return (
    <>
      <DatePicker
        disabledDate={isDateDisabled}
        value={moment(value)}
        onChange={(newValue) => {
          if (newValue && onChange) {
            onChange(newValue.toISOString());
          }
        }}
        {...otherProps}
      />
      {quickButtons &&
        quickButtons.map((qb) => (
          <Button style={{ marginLeft: '10px' }} key={qb.name} onClick={() => onChange?.(qb.value.toISOString())}>
            {qb.name}
          </Button>
        ))}
    </>
  );
}
