import React from 'react';

import { formatUSD, formatPercent } from 'utils/formatting';

import {
  ListItem,
  ItemId,
  ItemDescription,
  ItemExtendedDescription,
  ItemStats,
  Stat,
  StatContainer,
  StatLabel,
} from './ForecastsPage.style';

const getDirection = (value: number) => (value >= 0 ? 'up' : 'down');

const getDirectionIcon = (value: number) => (value >= 0 ? '↑' : '↓');

const trimString = (str?: string, limit?: number) => {
  if (!str || !limit) return '';

  if (str.length > limit) {
    return `${str.substring(0, limit)}...`;
  }

  return str;
};

export const ForecastListItem = ({
  id,
  description,
  extendedDescription,
  isSelected,
  onClick,
  salesYtd,
  gmYtd,
  gmPct,
}: {
  id: string;
  description: string;
  extendedDescription?: string;
  isSelected: boolean;
  onClick: (event: any) => void;
  salesYtd: number;
  gmYtd: number;
  gmPct: number;
}) => (
  <ListItem onClick={onClick} isSelected={isSelected}>
    <ItemId>{id}</ItemId>
    <ItemDescription>{trimString(description, 30)}</ItemDescription>
    <ItemExtendedDescription>{trimString(extendedDescription, 50)}</ItemExtendedDescription>
    <ItemStats>
      <StatContainer>
        <Stat direction={getDirection(salesYtd)}>
          {formatUSD(salesYtd)} {getDirectionIcon(salesYtd)}
        </Stat>
        <StatLabel>Sales YTD</StatLabel>
      </StatContainer>
      <StatContainer>
        <Stat direction={getDirection(gmYtd)}>
          {formatUSD(gmYtd)} {getDirectionIcon(gmYtd)}
        </Stat>
        <StatLabel>GM YTD</StatLabel>
      </StatContainer>
      <StatContainer>
        <Stat direction={getDirection(gmPct)}>
          {formatPercent(gmPct)} {getDirectionIcon(gmPct)}
        </Stat>
        <StatLabel>GM %</StatLabel>
      </StatContainer>
    </ItemStats>
  </ListItem>
);
