import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { ButtonLink } from 'components/Links';
import { CenteredError } from 'components/Loaders';
import { Table } from 'components/Table';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableDateColumn, humanDateColumn, sortableStringColumn } from 'utils/tables';

import { RECURRENCY_RED } from 'constants/styles';

export const LinkedTasks = ({ customerId }: { customerId: string | undefined }) => {
  const { activeTenant } = useGlobalApp();

  const { data, isLoading, error } = useApi()
    .tasks()
    .searchTasks({
      tenantId: activeTenant?.id,
      customer: customerId
        ? {
            foreignId: customerId,
          }
        : undefined,
    });

  if (error) {
    return <CenteredError error={error} />;
  }

  const { items } = data || { items: [] };

  const today = moment().format('YYYY-MM-DD');

  const columns: ColumnType<FIXME>[] = [
    sortableStringColumn({
      title: 'Task',
      dataIndex: 'title',
      render: (title: string, { id }: { id: string }) => <Link to={routes.tasks.taskDetails(id)}>{title}</Link>,
    }),
    humanDateColumn({
      title: 'Due',
      dataIndex: 'dueAt',
    }),
    sortableDateColumn({
      title: 'Deadline',
      dataIndex: 'dueAt',
      render: (date: any) => {
        const formatted = formatDate(date);

        if (moment(today).isSame(formatted)) {
          return <div style={{ color: RECURRENCY_RED }}>{formatted}</div>;
        }

        return formatted;
      },
      defaultSortOrder: 'ascend',
    }),
    {
      title: 'Completed',
      dataIndex: 'status',
      render: (status: string) => (status === 'completed' ? 'YES' : 'NO'),
    },
    {
      render: ({ id }: { id: string }) => ButtonLink(routes.tasks.taskDetails(id)),
    },
  ];

  return <Table loading={isLoading} columns={columns} data={items} rowKey="id" />;
};
