import React, { useEffect } from 'react';

import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

import { Select, SelectProps, SelectOption } from 'components/Select';

import { addArticle } from 'utils/formatting';

// Much of this interface comes from FormItems/AutoComplete - we'll need to re-work it in the future.
export interface SelectFormItemProps extends FormItemProps, Omit<SelectProps, 'children'> {
  label: string;
  name: string | number;
  isLoading?: boolean;
  initialValue?: string | string[];
  disabled?: boolean;
  options?: SelectOption[];
  customHandlers?: {
    [key: string]: () => void;
  };
  placeholder?: string;
  setFieldsValue?: (value: any) => void;
}

export const SelectFormItem = ({
  label,
  name,
  rules,
  disabled = false,
  options = [],
  isLoading = false,
  customHandlers,
  setFieldsValue = () => {},
  initialValue,
  placeholder,
  size = 'middle',
  hidden = false,
  ...otherProps
}: SelectFormItemProps): JSX.Element => {
  useEffect(() => {
    if (initialValue) {
      setFieldsValue({ [name]: initialValue });
    }
    // we want this to run once
    // eslint-disable-next-line
  }, []);

  return (
    <Form.Item label={label} name={name} rules={rules} hidden={hidden}>
      <Select
        isLoading={isLoading}
        showSearch
        options={options}
        disabled={disabled}
        // @ts-expect-error option isn't React.Node
        filterOption={(inputValue, option: SelectOption) =>
          // TODO: Ensure all selects always pass options as {label, value}
          (typeof option.label === 'string' ? option.label : String(option.value))
            .normalize()
            .toLowerCase()
            .includes(`${inputValue}`.normalize().toLowerCase())
        }
        onSelect={(value) => {
          if (customHandlers && customHandlers[value]) {
            customHandlers[value]();
          } else {
            setFieldsValue({
              [name]: value,
            });
          }
        }}
        placeholder={placeholder || `Search for ${addArticle(label)}...`}
        size={size}
        {...otherProps}
      />
    </Form.Item>
  );
};
