import React from 'react';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { NavModule, NavSubModule } from '../types';
import * as Styled from './TertiaryNavigation.style';

export interface TertiaryNavigationProps {
  isDesktopView: boolean;
  curNavModule: NavModule | undefined;
  onSubModuleSelect: (navSubModule: NavSubModule) => void;
  curSubModuleSlug: string | undefined;
  isHamburgerOpen: boolean;
  toggleHamburger: () => void;
}

export const TertiaryNavigation = ({
  isDesktopView,
  curNavModule,
  onSubModuleSelect,
  curSubModuleSlug,
  isHamburgerOpen,
  toggleHamburger,
}: TertiaryNavigationProps) => {
  const { activeUser, activeTenant } = useGlobalApp();
  return (
    <Styled.Container isDesktopView={isDesktopView} data-test-id="tertiaryNavContainer">
      <Styled.Content>
        {!isDesktopView && (
          <Styled.HamburgerWrapper>
            <Styled.Hamburger toggled={isHamburgerOpen} toggle={toggleHamburger} />
          </Styled.HamburgerWrapper>
        )}
        {(curNavModule?.subModules || []).map((subModule) =>
          !(
            subModule?.featureFlag === undefined ||
            shouldShowFeatureFlag(activeTenant, activeUser, subModule.featureFlag)
          ) ? null : (
            <Styled.SubItem
              key={subModule.slug}
              selected={subModule.slug === curSubModuleSlug || subModule.entitySlug === curSubModuleSlug}
              onClick={(ev) => {
                // allow ctrl/cmd+click to open in new tab
                if (!ev.ctrlKey && !ev.metaKey) {
                  // route internally (href shown for accessibility)
                  ev.preventDefault();
                  onSubModuleSelect(subModule);
                }
              }}
              href={subModule.routeFn()}
            >
              {subModule.label}
            </Styled.SubItem>
          ),
        )}
        <div>&nbsp;</div>
      </Styled.Content>
    </Styled.Container>
  );
};
