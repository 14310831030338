import React from 'react';

import { SelectProps } from 'antd/lib/select';

import { UseAsyncSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';

import { SmallLoader } from '../Loaders';
import { Select } from '../Select';

interface AsyncSelectProps extends SelectProps<any> {
  selectProps: UseAsyncSelectProps | (() => UseAsyncSelectProps);
  entityPlural: string;
}

export function AsyncSelect({ selectProps, entityPlural, placeholder, onSelect, ...otherProps }: AsyncSelectProps) {
  // selectProps could be passed as a hook function, resolve to obj
  const selectPropsObj = typeof selectProps === 'function' ? selectProps() : selectProps;

  return (
    <Select
      loading={selectPropsObj.isLoading}
      options={selectPropsObj.options}
      placeholder={placeholder || `Search for ${entityPlural}...`}
      notFoundContent={selectPropsObj.isLoading ? <SmallLoader /> : `No ${entityPlural} found`}
      showSearch
      filterOption={false}
      onSearch={selectPropsObj.setSearchQuery}
      onSelect={(value, option) => {
        // clear search query after user makes a selection
        selectPropsObj.setSearchQuery('');
        selectPropsObj.onSelect?.(value);
        onSelect?.(value, option);
      }}
      {...otherProps}
    />
  );
}
