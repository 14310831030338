import React from 'react';

import { Col, Row } from 'antd';

export interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const SplitPage = (props: Props): JSX.Element => {
  const { left, right } = props;
  return (
    <Row gutter={24}>
      <Col xs={24} lg={18}>
        {left}
      </Col>
      <Col xs={24} lg={6}>
        {right}
      </Col>
    </Row>
  );
};
