import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

// ignoring eslint import/order rules so we can keep imports organized by category
/* eslint-disable import/order */
import { NotFoundPage } from 'pages/NotFoundPage';

import { DashboardPage } from 'pages/sales/DashboardPage';

// Admin pages
import { SettingsPage } from 'pages/admin/SettingsPage';

// Customer Pages
import { CustomerListPage } from 'pages/sales/customers/CustomerListPage';
import { ProspectNewPage } from 'pages/sales/customers/ProspectNewPage/ProspectNewPage';
import { CustomerDetailsPage } from 'pages/sales/customers/CustomerDetailsPage';

// Order Pages
import { OrderListPage } from 'pages/orders/orders/OrderListPage';
import { OrderDetailsPage } from 'pages/orders/orders/OrderDetailsPage';

// Quote Page
import { QuoteListPage } from 'pages/orders/quotes/QuoteListPage';
import { QuoteEditPage } from 'pages/orders/quotes/QuoteEditPage';
import { RecommendedQuotesPage } from 'pages/orders/RecommendedQuotesPage';

// Item Pages
import { SalesItemListPage } from 'pages/sales/SalesItemListPage';
import { PurchasingItemListPage } from 'pages/purchasing/PurchasingItemListPage';
import { ItemDetailsPage } from 'pages/items/ItemDetailsPage';

// Task Pages
import { TasksListPage } from 'pages/tasks/TasksListPage';
import { TaskNewPage } from 'pages/tasks/TaskNewPage';
import { TaskEditPage } from 'pages/tasks/TaskEditPage';
import { TaskDetailsPage } from 'pages/tasks/TaskDetailsPage';

// Vendor Pages
import { VendorListPage } from 'pages/purchasing/vendors/VendorListPage';
import { VendorDetailsPage } from 'pages/purchasing/vendors/VendorDetailsPage';

// Purchase Order Pages
import { PurchaseOrderListPage } from 'pages/purchasing/purchaseOrders/PurchaseOrderListPage';
import { PurchaseOrderDetailsPage } from 'pages/purchasing/purchaseOrders/PurchaseOrderDetailsPage';
import { PurchaseOrderNewPage } from 'pages/purchasing/purchaseOrders/PurchaseOrderNewPage';

// Forecasting pages
import { ForecastsPage } from 'pages/purchasing/ForecastsPage';
import { ForecastListPage } from 'pages/purchasing/ForecastsPage/ForecastListPage';

// Search pages
import { PricingPage } from 'pages/sales/PricingPage';

// Account pages
import { EditDetailsPage } from 'pages/account/EditDetailsPage';

// Internal pages
import { TenantDetailsPage } from 'pages/internal/tenants/TenantDetailsPage';
import { TenantEditPage } from 'pages/internal/tenants/TenantEditPage';
import { TenantListPage } from 'pages/internal/tenants/TenantListPage';
import { TenantNewPage } from 'pages/internal/tenants/TenantNewPage';
import { UserDetailsPage } from 'pages/internal/users/UserDetailsPage';
import { UserEditPage } from 'pages/internal/users/UserEditPage';
import { UserListPage } from 'pages/internal/users/UserListPage';
import { UserNewPage } from 'pages/internal/users/UserNewPage';
import { makePath, routePaths } from 'utils/routes';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { isTenantIMS } from 'utils/tenants';
/* eslint-enable import/order */

export function AppRoutes() {
  const { activeTenant } = useGlobalApp();
  return (
    <>
      {/* prettier-ignore */}
      <Switch>
        <Route exact path={routePaths.home} children={<Redirect to={makePath(routePaths.sales.dashboard)} />} />
        <Route exact path={routePaths.sales.dashboard} component={DashboardPage} />
        <Route exact path={routePaths.sales.customerList} component={CustomerListPage} />
        <Route exact path={routePaths.sales.customerDetails} component={CustomerDetailsPage} />
        <Route exact path={routePaths.sales.customerNew} component={ProspectNewPage} />
        <Route exact path={routePaths.sales.itemList} component={SalesItemListPage} />
        <Route exact path={routePaths.sales.itemDetails} component={ItemDetailsPage} />
        <Route exact path={routePaths.sales.pricing} component={PricingPage} />

        <Route exact path={routePaths.orders.orderList} component={OrderListPage} />
        <Route exact path={routePaths.orders.orderDetails} component={OrderDetailsPage} />
        <Route exact path={routePaths.orders.orderNew} component={QuoteEditPage} />
        <Route exact path={routePaths.orders.quoteList} component={QuoteListPage} />
        <Route exact path={routePaths.orders.quoteNew} component={QuoteEditPage} />
        <Route exact path={routePaths.orders.quoteEdit} component={QuoteEditPage} />
        <Route exact path={routePaths.orders.recommendedQuotes} component={RecommendedQuotesPage} />

        <Route exact path={routePaths.purchasing.purchaseOrderList} component={PurchaseOrderListPage} />
        <Route exact path={routePaths.purchasing.purchaseOrderDetails} component={PurchaseOrderDetailsPage} />
        <Route exact path={routePaths.purchasing.purchaseOrderNew} component={PurchaseOrderNewPage} />
        <Route exact path={routePaths.purchasing.vendorList} component={VendorListPage} />
        <Route exact path={routePaths.purchasing.vendorDetails} component={VendorDetailsPage} />
        <Route exact path={routePaths.purchasing.itemList} component={PurchasingItemListPage} />
        <Route exact path={routePaths.purchasing.itemDetails} component={ItemDetailsPage} />
        <Route exact path={routePaths.purchasing.forecasts} component={isTenantIMS(activeTenant.id) ? ForecastListPage : ForecastsPage} />

        <Route exact path={routePaths.tasks.taskList} component={TasksListPage} />
        <Route exact path={routePaths.tasks.taskDetails} component={TaskDetailsPage} />
        <Route exact path={routePaths.tasks.taskEdit} component={TaskEditPage} />
        <Route exact path={routePaths.tasks.taskNew} component={TaskNewPage} />

        <Route exact path={routePaths.admin.settings} component={SettingsPage} />

        <Route exact path={routePaths.internal.userList} component={UserListPage} />
        <Route exact path={routePaths.internal.userDetails} component={UserDetailsPage} />
        <Route exact path={routePaths.internal.userNew} component={UserNewPage} />
        <Route exact path={routePaths.internal.userEdit} component={UserEditPage} />
        <Route exact path={routePaths.internal.tenantList} component={TenantListPage} />
        <Route exact path={routePaths.internal.tenantDetails} component={TenantDetailsPage} />
        <Route exact path={routePaths.internal.tenantEdit} component={TenantEditPage} />
        <Route exact path={routePaths.internal.tenantNew} component={TenantNewPage} />

        <Route exact path={routePaths.account.accountEdit} component={EditDetailsPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}
