import React, { useState } from 'react';

import { Checkbox, Radio } from 'antd';

import { SearchInput } from 'components/Search/SearchInput';
import { Tag } from 'components/Tag';
import { Typography } from 'components/Typography';

import { usePromise } from 'hooks/usePromise';

import { MAX_VALUES_PER_FACET } from 'utils/algolia';
import { formatNumber } from 'utils/formatting';

import { colors } from 'settings/theme/colors';

import * as Styled from './FacetValuesList.style';

interface FacetItem {
  value: string;
  count: number;
}

export const ValuesFilterList = ({
  valueCounts,
  selectedValues,
  onSelectedValuesChange,
  queryPlaceholder,
  capitalizeItemLabels = false,
  filterType,
  searchFacetValuesFn,
  showItemCount = true,
}: {
  valueCounts: Obj<number>;
  selectedValues: string[];
  onSelectedValuesChange: (values: string[] | undefined) => void;
  queryPlaceholder?: string;
  capitalizeItemLabels?: boolean;
  filterType?: 'single' | 'multiple';
  showItemCount?: boolean;
  /** a function that returns matching facet values */
  searchFacetValuesFn?: (query: string) => Promise<FacetItem[]>;
}) => {
  const facetItems: FacetItem[] = Object.entries(valueCounts).map(([value, count]) => ({
    value,
    count,
  }));
  const isRadioInput = filterType === 'single';
  const FilterInputComponent = isRadioInput ? Radio : Checkbox;
  const isItemSelected = (item: FacetItem) => selectedValues.includes(item.value);
  const [query, setQuery] = useState('');

  // if query is given, search algolia for matching facets
  const { data: matchingFacetItems = [] } = usePromise(
    () => (query && searchFacetValuesFn ? searchFacetValuesFn(query) : Promise.resolve(facetItems)),
    // valueCounts in deps (so when search results change, search is re-evaluated)
    [query, valueCounts],
  );

  return (
    <>
      {queryPlaceholder && (
        <SearchInput
          placeholder={queryPlaceholder}
          query={query}
          onQueryChange={setQuery}
          style={{ marginBottom: '8px' }}
        />
      )}
      <Styled.ResultItemsContainer>
        {matchingFacetItems
          .sort((a, b) => b.count - a.count)
          .map((item) => (
            <div key={item.value} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FilterInputComponent
                checked={isItemSelected(item)}
                onChange={() => {
                  // prettier-ignore
                  const newSelectedValues = isItemSelected(item)
                  ? (isRadioInput ? [] : selectedValues.filter((val) => val !== item.value))
                  : (isRadioInput ? [item.value] : [...selectedValues, item.value]);

                  // remove filter (values = undefined) if there aren't any values
                  onSelectedValuesChange(newSelectedValues.length > 0 ? newSelectedValues : undefined);
                }}
                style={{
                  marginRight: 5,
                  marginTop: 5,
                  textTransform: capitalizeItemLabels ? 'capitalize' : undefined,
                }}
              >
                {item.value}
              </FilterInputComponent>
              {showItemCount && (
                <Tag style={{ marginRight: 5, marginTop: 5, height: '2em' }}>{formatNumber(item.count)}</Tag>
              )}
            </div>
          ))}
        {(matchingFacetItems.length === MAX_VALUES_PER_FACET || matchingFacetItems.length === 0) && (
          <Typography type="small" style={{ marginTop: '16px', color: colors.gray500 }}>
            {matchingFacetItems.length === MAX_VALUES_PER_FACET
              ? `Showing top ${MAX_VALUES_PER_FACET} results, search for more.`
              : 'No results found, try another search'}
          </Typography>
        )}
      </Styled.ResultItemsContainer>
    </>
  );
};
