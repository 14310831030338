import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { Table } from 'components/Table';

import { formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  idColumn,
  sortableNumberColumn,
  sortableStringColumn,
  sortableDollarWithCentsColumn,
  sortableBooleanColumn,
} from 'utils/tables';

export const Lines = ({ lines }: { lines: FIXME[] }) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (id: string) => <Link to={routes.purchasing.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'qtyOrdered',
    }),
    sortableNumberColumn({
      title: 'Qty Received',
      dataIndex: 'qtyReceived',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    { title: 'Due Date', dataIndex: 'dateDue', render: (dt: any) => formatDate(dt) },
    sortableBooleanColumn({
      title: 'Complete',
      dataIndex: 'complete',
    }),
    {
      render: ({ itemId }: { itemId: any }) => ButtonLink(routes.purchasing.itemDetails(itemId)),
    },
  ];
  return (
    <>
      <div style={{ overflowX: 'scroll' }}>
        <Table columns={columns} data={lines} rowKey="itemId" />
      </div>
    </>
  );
};
