import { captureException as sentryCaptureException } from '@sentry/react';
import { message } from 'antd';

/**
 * actionMessage - the action that the user was trying to do before the failure
 * Example: 'creating tasks' = 'An error occurred while creating tasks, our engineers have been notified'
 */
export const captureAndShowError = (
  err: any,
  actionMessage: string,
  additionalMessage?: string,
  messageDuration = 5,
) => {
  captureError(err);
  message.error(
    `An error occurred while ${actionMessage}, our engineers have been notified. ${additionalMessage || ''}`,
    messageDuration,
  );
};

export const captureError = (err: any) => {
  sentryCaptureException(err);
  console.error(err);
};
