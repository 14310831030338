import { useRef, useEffect } from 'react';

/**
 * Adds an event listener to a target and automatically removes it when the target or event changes
 */
export function useEventListener<EventT extends Event>(
  target: EventTarget | null,
  eventName: string,
  listener: (ev: EventT) => void,
  options?: EventListenerOptions,
) {
  // Update ref.current value if listener changes.
  // This allows our effect below to always get latest handler
  // without us needing to pass it in effect deps array
  // and potentially cause effect to re-run every render.
  const listenerRef = useRef<EventListener>();
  // @ts-expect-error EventT and Event subconstraints don't match
  listenerRef.current = listener;

  useEffect(() => {
    if (!target) return;
    // create wrapper that calls latest listener
    const eventListener = (event: Event) => {
      listenerRef.current?.(event);
    };
    target.addEventListener(eventName, eventListener, options);
    // eslint-disable-next-line consistent-return
    return () => {
      target.removeEventListener(eventName, eventListener, options);
    };
    // options is an object which will change for every render (we can skip it)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, eventName]);
}
