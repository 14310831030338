/* eslint-disable implicit-arrow-linebreak */

import { css } from 'styled-components';

import { objMapValues } from './object';

type MediaQueriesType = {
  [key: string]: string;
};

// SC Media breakpoints utility
const mediaQueries: MediaQueriesType = {
  xs: '(min-width: 0px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1600px)',
};

export const media = objMapValues(
  mediaQueries,
  (mediaQuery) =>
    (...args: Parameters<typeof css>) =>
      css`
        @media ${mediaQuery} {
          ${css(...args)};
        }
      `,
);

export function provideResponsiveShow({ showFrom }: { showFrom: string }) {
  return (
    showFrom &&
    css`
      display: none;
      ${media[showFrom]`
        display: block;
      `}
    `
  );
}

export function provideResponsiveHide({ hideFrom }: { hideFrom: string }) {
  return (
    hideFrom &&
    css`
      display: block;
      ${media[hideFrom]`
        display: none;
      `}
    `
  );
}

export const getIsPlatformMac = () => navigator.platform.toUpperCase().indexOf('MAC') >= 0;
