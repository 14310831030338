import { Colors } from './types';

export const RECURRENCY_BLUE = '#1EA4CC';
export const RECURRENCY_DARK_BLUE = '#1880A0';
export const RECURRENCY_LIGHT_BLUE = '#EBF5FF';
export const RECURRENCY_DARK_VIOLET = '#0D4454';
export const RECURRENCY_RED = '#DB3300';

export const GRAY_400 = '#D9D9D9';
export const RECURRENCY_LIGHT_GRAY = '#F0F0F0';
export const RECURRENCY_GRAY = '#595959';
export const RECURRENCY_DARK_GRAY = '#1F1F1F';

// TODO: organize color palette
export const colors: Colors = {
  blue: '#1EA4CC',
  blue100: '#F0F6FF',
  blue200: '#D6E6FF',
  blue500: '#5998F7',
  darkBlue: '#1880A0',
  lightBlue: '#EBF5FF',
  darkViolet: '#0D4454',
  red: '#DB3300',
  red100: '#FFF1F0',
  red700: '#CF1322',
  gray: '#595959',
  gray300: '#F0F0F0',
  gray400: '#D9D9D9',
  gray500: '#BFBFBF',
  gray600: '#595959',
  gray900: '#1F1F1F',
  lightGray: '#FAFAFA',
  darkGray: '#1F1F1F',
  border: '#F0F0F0',
  newBlueGray900: '#1E4158',
  newBlueGray200: '#E2F3F3',
  newBlueGray100: '#EDF8F8',
  newWhite: '#FFFFFF',
};
