import React from 'react';

import { useHistory } from 'react-router-dom';

import { CreateUserRequest } from '@recurrency/core-api';
import { Form, Input, message, PageHeader } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import { Button } from 'components/Button';
import { CheckboxFormItem, defaultFormTailLayout, InputFormItem, responsiveFormLayout } from 'components/FormItems';
import { Container } from 'components/Layout/Container';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes } from 'utils/routes';

export const UserNewPage = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const onSubmit = async (data: CreateUserRequest) => {
    try {
      const response: any = await api().users().createUser(data);
      message.success('New user created.');
      if (response?.data?.id) {
        history.push(routes.internal.userDetails(response.data.id));
      } else {
        history.push(routes.internal.userList());
      }
    } catch (err) {
      captureAndShowError(err, `creating user`);
    }
  };

  const passwordSegment = () => Math.random().toString(36).substr(2, 8);
  const shuffle = (v: string) =>
    v
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');
  const generateTempPassword = () => {
    const symLength = Math.ceil(Math.random() * 10);
    const symString = '!@#$<>%^&|*:()-+?';
    let symStr = '';
    for (let i = 0; i < symLength; i++) {
      symStr += symString.charAt(Math.floor(Math.random() * symString.length));
    }
    return shuffle(symStr + passwordSegment() + passwordSegment());
  };

  const tempPassword = generateTempPassword();

  return (
    <Container>
      <PageHeader title="New User" onBack={() => history.push(routes.internal.userList())} />
      <Form.Provider>
        <Form
          {...responsiveFormLayout}
          name="taskForm"
          form={form}
          onFinish={onSubmit}
          onError={console.error}
          initialValues={{ verifyEmail: false }}
        >
          <InputFormItem
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please add a first name.' }]}
          />

          <InputFormItem
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please add a last name.' }]}
          />

          <InputFormItem label="Email" name="email" rules={[{ required: true, message: 'Please add an email.' }]} />

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please add a temporary password.' }]}
            extra={
              <div>
                Suggested temporary password: <Paragraph copyable>{tempPassword}</Paragraph>
              </div>
            }
          >
            <Input.Password />
          </Form.Item>
          <CheckboxFormItem name="sendWelcomeEmail" label="Send Welcome Email" />

          <Form.Item {...defaultFormTailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Container>
  );
};
