import React from 'react';

import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { DetailsFrame } from 'components/Layout/DetailsFrame';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NoDataMessage } from 'components/NoDataMessage';
import { UOMTable } from 'components/UOMTable';

import { useMemoizedItemStats } from 'hooks/useMemoized';

import { Availability } from './Tabs/Availability';
import { ERPPricing } from './Tabs/ERPPricing';
import { OpenOrderLines } from './Tabs/OpenOrderLines';
import { OpenQuoteLines } from './Tabs/OpenQuoteLines';
import { OpenTransferLines } from './Tabs/OpenTransferLines';
import { POTable } from './Tabs/POTable';
import { RecentSales } from './Tabs/RecentSales';
import { RecommendedCustomers } from './Tabs/RecommendedCustomers';
import { TopCustomers } from './Tabs/TopCustomers';
import { TopShiptos } from './Tabs/TopShiptos';

export type Props = {
  id: string | undefined;
  data: FIXME;
  isLoading: boolean;
  error?: Error;
  onBack?: () => void;
  headerVisible?: boolean;
};

export const ItemDetail = ({ id, data, isLoading, error, headerVisible = true, onBack = () => {} }: Props) => {
  const stats = useMemoizedItemStats(data);

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !data) {
    return <CenteredLoader />;
  }

  if (Object.keys(data).length === 0) {
    return <NoDataMessage />;
  }

  const { itemDesc, extendedDesc, keywords, productGroup, defaultProductGroup, invMastUid } = data;

  const descriptions = [
    extendedDesc && { label: 'Extended Description', render: extendedDesc },
    keywords && { label: 'Keywords', render: keywords },
    productGroup && {
      label: 'Product Group',
      render: `${defaultProductGroup}: ${productGroup}`,
    },
  ].filter(Boolean);

  const tabs = [
    { header: 'Availability & Cost', content: <Availability invMastUid={invMastUid} /> },
    { header: 'Recent Sales', content: <RecentSales invMastUid={invMastUid} /> },
    {
      header: 'Top Customers',
      content: <TopCustomers itemId={invMastUid} />,
    },
    {
      header: 'Top Ship Tos',
      content: <TopShiptos itemId={invMastUid} />,
    },
    { header: 'Open Order Lines', content: <OpenOrderLines invMastUid={invMastUid} /> },
    { header: 'Open Quote Lines', content: <OpenQuoteLines invMastUid={invMastUid} /> },
    { header: 'Open Transfer Lines', content: <OpenTransferLines invMastUid={invMastUid} /> },
    {
      header: 'Recommended Customers',
      content: <RecommendedCustomers itemId={invMastUid} itemDesc={itemDesc} />,
    },
    { header: 'Open POs', content: <POTable invMastUid={invMastUid} /> },
    { header: 'UOMs', content: <UOMTable invMastUid={invMastUid} /> },
    { header: 'ERP Pricing', content: <ERPPricing invMastUid={invMastUid} /> },
  ];

  return (
    <DetailsFrame
      tabs={tabs}
      prefix="Item #"
      title={id}
      stats={stats}
      subTitle={`${itemDesc}`}
      showTitle={headerVisible}
      descriptions={descriptions}
      descriptionColumn={1}
      onBack={onBack}
      copyable
      headerActions={<NewQuoteButton hashState={{ items: [{ foreignId: id!, name: itemDesc }] }} />}
    />
  );
};
