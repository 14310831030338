import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { config } from 'settings/environment';

// let dialogShownTimeout: any;

const setTag = (event: Sentry.Event, key: string, value: string) => {
  if (!event.tags) {
    event.tags = {};
  }
  event.tags[key] = value;
};

export const registerSentry = () => {
  // initialize error tracking in production
  if (config.environment === 'production' && window.location.hostname !== 'localhost') {
    Sentry.init({
      dsn: 'https://c0b499e1dbf54228bed9b33ee32b207b@o422067.ingest.sentry.io/5343700',
      normalizeDepth: 10,
      beforeSend(event, hint) {
        if (hint?.originalException) {
          console.error(hint.originalException);

          // If event was an axios exception, add request id
          const exception = hint.originalException as AxiosError;
          if (exception.isAxiosError && exception?.response?.headers) {
            setTag(event, 'request_id', exception.response.headers['x-request-id']);
          }
        }

        if (config.appVersion) {
          setTag(event, 'version', config.appVersion);
        }

        // if fullstory has loaded, track the fullstory session url with the error
        if (window.FS?.getCurrentSessionURL) {
          setTag(event, 'fs_url', window.FS.getCurrentSessionURL(/* withTimestamp */ true));
        }

        return event;
      },
    });
  }
};
