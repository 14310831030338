import { useMemo } from 'react';

import { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { api, ApiFunction, legacyApiFetch } from 'utils/api';
import { objMapValues } from 'utils/object';

import { usePromise, UsePromiseResponse } from './usePromise';

/**
 * NULLIFY_API_CALL is a hack since hooks always have to be called in order,
 * but sometimes we want to conditionally call apis,
 * in which case we pass NULLIFY_API_CALL as first arg.
 * e.g load quote only when we have an id
 */
export const NULLIFY_API_CALL = 'NULLIFY_API_CALL';

export function useApi() {
  return {
    users() {
      return wrapUsePromise(api().users());
    },
    tenants() {
      return wrapUsePromise(api().tenants());
    },
    tasks() {
      return wrapUsePromise(api().tasks());
    },
    oAuth() {
      return wrapUsePromise(api().oAuth());
    },
    quotes() {
      return wrapUsePromise(api().quotes());
    },
    customers() {
      return wrapUsePromise(api().customers());
    },
  };
}

function wrapUsePromise<ApiFactoryT extends Obj<ApiFunction>>(
  factory: ApiFactoryT,
): {
  [K in keyof ApiFactoryT]: (
    ...args: Parameters<ApiFactoryT[K]>
  ) => UsePromiseResponse<PromiseType<ReturnType<ApiFactoryT[K]>>['data'], AxiosError>;
} {
  return objMapValues(
    factory,
    (apiFn: ApiFunction, fnName: string) =>
      (...args: any[]) =>
        usePromise(
          args[0] === NULLIFY_API_CALL ? () => Promise.resolve(null) : () => apiFn(...args).then((resp) => resp.data),
          args,
          {
            cacheKey: `${fnName}|${JSON.stringify(args)}`,
          },
        ),
  ) as any; // cast as any, to avoid ts nits (we already explicitly type return)
}

export function useLegacyApi<DataT = any>(
  apiPath: string,
  queryParams?: Obj<string | number | boolean>,
): UsePromiseResponse<DataT, AxiosError> {
  const result = usePromise(
    apiPath === NULLIFY_API_CALL
      ? () => Promise.resolve(null)
      : () => legacyApiFetch(apiPath, { method: 'GET', data: queryParams }).then((resp) => resp.data),
    [apiPath, queryParams],
    {
      cacheKey: queryParams ? `${apiPath}|${JSON.stringify(queryParams)}` : apiPath,
    },
  );

  // NOTE: auto camelCaseKeys is an interim hack, we need to enforce strong types for legacy api at some point
  // usePromise will return same object if apiPath hasn't changed
  // so we use useMemo to preserve object reference
  result.data = useMemo(() => (result.data ? camelcaseKeys(result.data, { deep: true }) : result.data), [result.data]);

  // @ts-expect-error legacy api isn't strongly typed, so we don't know the correct return type
  return result;
}

/**
 * throw if SWR response has error (will be caught by nearest error boundary)
 */
export function throwIfApiError<DataT = any>(
  response: UsePromiseResponse<DataT, AxiosError>,
): UsePromiseResponse<DataT, AxiosError> {
  if (response.error) {
    throw response.error;
  } else {
    return response;
  }
}
