import { DatePicker as AntdDatePicker } from "antd";
import styled from "styled-components";
const heights = {
  small: "30px",
  middle: "40px",
  large: "50px"
};
const getHeights = (size = "middle") => heights[size];
export const DatePicker = styled(AntdDatePicker)`
  border-radius: ${(props) => props.theme.border.radius};
  height: ${(props) => getHeights(props.size)};
`;
