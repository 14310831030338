import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { CustomerDetail } from 'components/CustomerDetail';
import { Container } from 'components/Layout/Container';

import { useLegacyApi } from 'hooks/useApi';

import { encodeLegacyApiParam, IdPathParams, usePathParams } from 'utils/routes';

export const CustomerDetailsPage = () => {
  const history = useHistory();
  const { id } = usePathParams<IdPathParams>();
  const { data: customerData, error } = useLegacyApi(`/v3/customers/${encodeLegacyApiParam(id)}`);
  const { data: statsData } = useLegacyApi(`/v3/customers/${encodeLegacyApiParam(id)}/stats`);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CustomerDetail
        id={id}
        isLoading={!customerData}
        error={error}
        customer={customerData}
        stats={statsData}
        onBack={onBack}
      />
    </Container>
  );
};
