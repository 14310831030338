import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { SearchFrame } from 'components/Search/SearchFrame';

import { IndexName } from 'utils/algolia';
import { formatPercent, formatUSD, getPercentColor } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableDollarColumn, sortableNumberColumn } from 'utils/tables';

import { AlgoliaVendor } from 'types/algolia-collections';

export const VendorListPage = () => {
  const columns: ColumnType<AlgoliaVendor>[] = [
    {
      title: 'ID',
      dataIndex: 'vendor_id',
      render: (id: string) => <Link to={routes.purchasing.vendorDetails(id)}>{id}</Link>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
    },
    sortableDollarColumn({
      title: 'Purchases YTD',
      dataIndex: 'purchases_ytd',
    }),
    sortableDollarColumn({
      title: 'Purchases LYTD',
      dataIndex: 'purchases_lytd',
    }),
    sortableDollarColumn({
      title: '∆ Purchases',
      dataIndex: 'purchases_delta_raw',
      align: 'right' as const,
      render: (amt: string) => <span style={{ color: getPercentColor(amt) }}>{formatUSD(amt)}</span>,
    }),
    sortableNumberColumn({
      title: '∆% Purchases',
      dataIndex: 'purchases_delta_pct',
      render: (pct: string) => {
        const formatted = formatPercent(pct, 0);
        return <span style={{ color: getPercentColor(formatted) }}>{formatted}</span>;
      },
    }),
    {
      title: '',
      dataIndex: 'vendor_id',
      render: (id: FIXME) => ButtonLink(routes.purchasing.vendorDetails(id)),
    },
  ];

  return (
    <SearchFrame<AlgoliaVendor>
      title="Vendors"
      indexName={IndexName.Vendors}
      queryPlaceholder="Search vendors by ID or name..."
      columns={columns}
      valueFacets={[
        {
          title: 'Product Group',
          field: 'product_group_desc',
          queryPlaceholder: 'Filter by product group...',
        },
      ]}
      numericFacets={[
        { title: 'Purchases YTD', field: 'purchases_ytd' },
        { title: 'Purchases LYTD', field: 'purchases_lytd' },
      ]}
    />
  );
};
