import React, { useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useEventListener } from 'hooks/useEventListener';

import { getIsPlatformMac } from 'utils/responsive';
import { TrackEvent, track } from 'utils/track';

import * as Styled from './GlobalSearch.style';
import { MenuCategory, MenuOption } from './types';
import { useGlobalSearch } from './useGlobalSearch';

const Category = ({ category }: { category: MenuCategory }) => <span>{category.label}</span>;

const Option = ({ option, isDesktopView }: { option: MenuOption; isDesktopView: boolean }) => (
  <Styled.ResultContainer to={option.href} isDesktopView={isDesktopView} data-track="Nav.SearchSelectOption">
    <Styled.ResultTitle>
      <Styled.ResultIcon>{option.icon}</Styled.ResultIcon>
      {option.labelHighlights
        ? option.labelHighlights.map((label, i) =>
            i % 2 ? (
              <Styled.HighlightedLabel key={i}>{label}</Styled.HighlightedLabel>
            ) : (
              <Styled.UnhighlightedLabel key={i}>{label}</Styled.UnhighlightedLabel>
            ),
          )
        : option.label}
    </Styled.ResultTitle>
    <Styled.ResultSubtitle isDesktopView={isDesktopView}>{option.subtext || ''}</Styled.ResultSubtitle>
  </Styled.ResultContainer>
);

export const GlobalSearch = ({ isDesktopView }: { isDesktopView: boolean }) => {
  const history = useHistory();
  const searchSelectRef = useRef<HTMLElement>();
  const isFocusedRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');

  const searchResults = useGlobalSearch(searchQuery);

  const options = searchQuery
    ? searchResults.map((category, categoryIdx) => ({
        label: <Category category={category} />,
        options: category.options.map((item, itemIdx) => ({
          value: `${categoryIdx}:${itemIdx}`,
          label: <Option option={item} isDesktopView={isDesktopView} />,
          href: item.href,
          categoryLabel: category.label,
          itemLabel: item.label,
          onSelect: item?.onSelect,
        })),
      }))
    : [];
  const numOptions = options.reduce((sum, category) => sum + category.options.length, 0);

  const isPlatformMac = getIsPlatformMac();
  const searchPromptStr = isDesktopView
    ? `Search customers, inventory, orders, navigation, etc. (${isPlatformMac ? '⌘' : 'Ctrl'}+k)`
    : 'Search';

  const onSelect = (_key: string, option: FIXME) => {
    if (option?.onSelect) {
      option.onSelect();
    }
    track(TrackEvent.Nav_GlobalSearch_OptionSelect, {
      searchQuery,
      searchQueryLength: searchQuery.length,
      categoryLabel: option.categoryLabel,
      optionLabel: option.itemLabel,
      optionHref: option.href,
      numOptions,
    });
    history.push(option.href);
    setSearchQuery('');
  };

  // focus search select when user presses cmd+k or ctrl+k, blur when user presses esc
  useEventListener(document, 'keydown', (ev: KeyboardEvent) => {
    if (ev.key === 'k' && (ev.metaKey || ev.ctrlKey)) {
      // Edge on windows steals ctrl+k for main location bar
      ev.stopPropagation();
      ev.preventDefault();
      if (!isFocusedRef.current) {
        track(TrackEvent.Nav_GlobalSearch_Focus, { interactType: 'KeyboardShortcut' });
      }
      searchSelectRef.current?.focus();
    } else if (ev.key === 'Escape') {
      searchSelectRef.current?.blur();
    }
  });

  return (
    <Styled.SearchSelect
      ref={searchSelectRef}
      data-track="Nav.SearchSelect"
      showSearch
      value={searchPromptStr}
      size="small"
      onSearch={setSearchQuery}
      onSelect={onSelect}
      onFocus={() => {
        isFocusedRef.current = true;
      }}
      onBlur={() => {
        isFocusedRef.current = false;
        setSearchQuery('');
        // NOTE: blur doesn't fire when user selects an option, only when user clicks outside search input
        track(TrackEvent.Nav_GlobalSearch_Abandon, {
          searchQuery,
          searchQueryLength: searchQuery.length,
          numOptions,
        });
      }}
      onMouseDown={() => {
        // using mousedown because it fires before focus
        if (!isFocusedRef.current) {
          track(TrackEvent.Nav_GlobalSearch_Focus, { interactType: 'Mouse' });
        }
      }}
      options={options}
      // only show no results if user entered search query
      // initial state is search select looks like an input
      notFoundContent={searchQuery.length ? 'No search results found, try another search' : null}
      filterOption={false}
    />
  );
};
