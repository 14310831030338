import React from 'react';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { linkablePhoneNumber, linkableEmail } from 'components/Links';

import { encodeLegacyApiParam } from 'utils/routes';
import { idColumn, sortableStringColumn } from 'utils/tables';

export const Contacts = ({ customerId }: { customerId: string | undefined }) => {
  const columns = [
    idColumn({
      title: 'Contact ID',
      dataIndex: 'contactId',
    }),
    sortableStringColumn({
      title: 'First',
      dataIndex: 'firstName',
    }),
    sortableStringColumn({
      title: 'Last',
      dataIndex: 'lastName',
    }),
    sortableStringColumn({
      title: 'Title',
      dataIndex: 'title',
    }),
    sortableStringColumn({
      title: 'Email',
      dataIndex: 'emailAddress',
      render: (email: any) => linkableEmail(email),
    }),
    sortableStringColumn({
      title: 'Phone',
      dataIndex: 'directPhone',
      render: (phone: any) => linkablePhoneNumber(phone),
    }),
    sortableStringColumn({
      title: 'Fax',
      dataIndex: 'directFax',
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/contacts`,
      })}
      columns={columns}
      rowKey="contactId"
    />
  );
};
