export enum PromiseState {
  Pending,
  Fulfilled,
  Rejected,
}

/**
 * Native promises have no way to unthen, uncatch, or see current state of promise.
 * StatefulPromise makes that possible.
 */
export class StatefulPromise<ValueT, ErrorT = Error> {
  public promise: Promise<ValueT>;
  public state: PromiseState;
  public value: ValueT | undefined;
  public error: ErrorT | undefined;
  public onValue: ((value: ValueT) => void) | undefined;
  public onError: ((error: ErrorT) => void) | undefined;

  constructor(promise: Promise<ValueT>) {
    this.promise = promise;
    this.state = PromiseState.Pending;
    this.promise
      .then((value: ValueT) => {
        this.state = PromiseState.Fulfilled;
        this.value = value;
        this.onValue?.(value);
      })
      .catch((error: ErrorT) => {
        this.state = PromiseState.Rejected;
        this.error = error;
        this.onError?.(error);
      });
  }
}
