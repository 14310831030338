import React from 'react';

import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { UpdatePartialUserRequest } from '@recurrency/core-api';
import { Form, message, PageHeader } from 'antd';

import { AsyncSelect } from 'components/AsyncSelect';
import { useTenantUsersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { InputFormItem, defaultFormTailLayout, SelectFormItem, responsiveFormLayout } from 'components/FormItems';
import { Container } from 'components/Layout/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { useApi } from 'hooks/useApi';
import { globalAppStore, useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { getTimezoneOptions } from 'utils/timezones';

import { UNITS, RECURRENCY_WARNING, RECURRENCY_SUCCESS } from 'constants/styles';

const tzOptions = getTimezoneOptions();

interface EditUserDetailsForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  timezone?: string;
  tenantId?: string;
  quoteCopyEmailsTo?: string[];
  orderCopyEmailsTo?: string[];
}
export function updatePartialRequestFromFormState(state: EditUserDetailsForm): UpdatePartialUserRequest {
  return {
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    timezone: state.timezone,
    tenantUserSettings: {
      // tenant id will always exist with form validation
      tenantId: state.tenantId!,
      settings: {
        quote: {
          copyEmailsTo: state.quoteCopyEmailsTo,
        },
        order: {
          copyEmailsTo: state.orderCopyEmailsTo,
        },
      },
    },
  };
}

export const EditDetailsPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const { data: user, isLoading, error } = useApi().users().getUserById(activeUser?.id, true);
  const tenantUsersSelectProps = useTenantUsersSelectProps();
  const [form] = Form.useForm<EditUserDetailsForm>();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !user) {
    return <CenteredLoader />;
  }

  const onSubmit = async (data: EditUserDetailsForm) => {
    const request = updatePartialRequestFromFormState(data);
    try {
      const { data: updatedUser } = await api().users().updatePartialUser(user.id, request);
      await globalAppStore.update({
        activeUser: { ...activeUser, fullName: `${updatedUser.firstName} ${updatedUser.lastName}` },
        activeTenant: {
          ...activeTenant,
          // Active tenant should always be in the users tenants
          tenantUser:
            updatedUser.tenants?.filter((tenant) => tenant.id === activeTenant.id)[0].tenantUser ??
            activeTenant.tenantUser,
        },
        tenants: updatedUser.tenants,
      });
      message.success('Account Details updated.');
    } catch (err) {
      captureAndShowError(err, `updating user`);
    }
  };

  return (
    <Container>
      <PageHeader
        title="Edit Account Settings"
        style={{ marginTop: '24px', marginBottom: '24px', textAlign: 'center' }}
      />
      <Form.Provider>
        <Form
          {...responsiveFormLayout}
          form={form}
          onFinish={onSubmit}
          onError={console.error}
          initialValues={{
            ...user,
            tenantId: activeTenant.id,
            quoteCopyEmailsTo: activeTenant.tenantUser.settings?.quote?.copyEmailsTo ?? [],
            orderCopyEmailsTo: activeTenant.tenantUser.settings?.order?.copyEmailsTo ?? [],
          }}
        >
          <InputFormItem
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please add a first name.' }]}
          />
          <InputFormItem
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please add a last name.' }]}
          />
          <Form.Item label="Email" style={{ marginBottom: '0px' }}>
            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(90% - 12px)',
                marginBottom: '0px',
              }}
            >
              <InputFormItem name="email" rules={[{ required: true, message: 'Please add an email.' }]} />
            </Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(10% + 1px)',
                marginLeft: '10px',
              }}
            >
              <Tooltip
                slow
                title={user?.emailVerified ? 'Email is verified' : 'Email is not verified'}
                placement="right"
              >
                {user?.emailVerified ? (
                  <CheckCircleOutlined style={{ fontSize: UNITS.XL, color: RECURRENCY_SUCCESS }} />
                ) : (
                  <WarningOutlined style={{ fontSize: UNITS.XL, color: RECURRENCY_WARNING, paddingTop: '2px' }} />
                )}
              </Tooltip>
            </Form.Item>
          </Form.Item>
          <SelectFormItem
            isLoading={false}
            initialValue=""
            label="Timezone"
            name="timezone"
            options={tzOptions}
            setFieldsValue={form.setFieldsValue}
          />
          {user?.tenants && user?.tenants?.length > 1 && (
            <Typography type="large" style={{ paddingBottom: '20px' }}>
              Tenant Specific Settings
            </Typography>
          )}
          <Form.Item
            name="tenantId"
            label="Tenant"
            rules={[{ required: true, message: 'Please choose a tenant.' }]}
            hidden={user?.tenants && user?.tenants?.length <= 1}
          >
            <AsyncSelect
              selectProps={tenantUsersSelectProps}
              entityPlural="tenants"
              onSelect={(tenantId) => {
                form.setFieldsValue({
                  quoteCopyEmailsTo:
                    user.tenants?.filter((tenant) => tenant.id === tenantId)[0].tenantUser.settings?.quote
                      ?.copyEmailsTo ?? [],
                  orderCopyEmailsTo:
                    user.tenants?.filter((tenant) => tenant.id === tenantId)[0].tenantUser.settings?.order
                      ?.copyEmailsTo ?? [],
                });
              }}
            />
          </Form.Item>
          <SelectFormItem
            label="Quote CC emails"
            name="quoteCopyEmailsTo"
            placeholder="Add emails separated by spaces or commas"
            rules={[
              {
                type: 'array',
                message: 'Must be a list',
                defaultField: { type: 'email', message: 'There can only be valid emails' },
              },
            ]}
            mode="tags"
            notFoundContent=""
            tokenSeparators={[',', ' ']}
          />
          <SelectFormItem
            label="Order CC emails"
            name="orderCopyEmailsTo"
            placeholder="Add emails separated by spaces or commas"
            rules={[
              {
                type: 'array',
                message: 'Must be a list',
                defaultField: { type: 'email', message: 'There can only be valid emails' },
              },
            ]}
            mode="tags"
            notFoundContent=""
            tokenSeparators={[',', ' ']}
          />
          <Form.Item {...defaultFormTailLayout}>
            <Button id="saveUserSettingsButton" type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Container>
  );
};
