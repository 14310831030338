import React from 'react';

import { NoResults } from 'components/Icons';
import { CenteredLoader } from 'components/Loaders';

import { AlgoliaInventoryItem } from 'types/algolia-collections';

import { ForecastListItem } from './ForecastListItem';
import { ItemDescription, EmptyListContainer, List } from './ForecastsPage.style';

export const ForecastList = ({
  hits,
  selectedId,
  onItemSelect,
  isLoading,
}: {
  isLoading: boolean;
  hits: AlgoliaInventoryItem[];
  selectedId?: string;
  onItemSelect?: (itemId: string, item: AlgoliaInventoryItem) => void;
}) => {
  if (isLoading) {
    return <CenteredLoader />;
  }

  if (!hits || hits.length === 0) {
    return (
      <EmptyListContainer>
        <NoResults />
        <ItemDescription>No Item available</ItemDescription>
      </EmptyListContainer>
    );
  }

  return (
    <List>
      {hits?.map((item: AlgoliaInventoryItem, index: number) => {
        const itemId = item.item_id;
        const itemDesc = item.item_desc;
        const extendedDesc = item.extended_desc;

        const isSelected = itemId === selectedId;
        // TODO: re-enable these with real data, not from Algolia
        const salesYtd = 0; // item.sales_ytd;
        const gmYtd = 0; // item.gm_ytd;
        const gmPct = 0; // item.gm_delta_pct;

        return (
          <ForecastListItem
            key={`${itemId}${index}`}
            id={itemId}
            description={itemDesc}
            extendedDescription={extendedDesc}
            onClick={() => onItemSelect?.(itemId, item)}
            isSelected={isSelected}
            salesYtd={salesYtd}
            gmYtd={gmYtd}
            gmPct={gmPct}
          />
        );
      })}
    </List>
  );
};
