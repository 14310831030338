import React from 'react';

import { TenantDefaultData, UpdateDefaultDataRequest } from '@recurrency/core-api';
import { message } from 'antd';

import { Container } from 'components/Layout/Container';
import { PageHeader } from 'components/PageHeader';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';

import { FormDefaultSettings } from './FormDefaultSettings';

export interface CompanyV3 {
  id: string;
  companyId: string;
  companyName: string;
}

export interface FreightTypeV3 {
  id: string;
  companyId: string;
  freightCd: string;
  freightCodeUid: string;
  freightDesc: string;
}

export interface CarrierV3 {
  id: string;
  foreignId: string;
  name: string;
}

export const SettingsPage = () => {
  const { activeTenant } = useGlobalApp();
  const onDefaultDataChange = async (newDefaultData: TenantDefaultData) => {
    const request: UpdateDefaultDataRequest = { defaultData: newDefaultData };
    try {
      await api().tenants().updateDefaultData(activeTenant.id, request);
      message.success('Default data updated successfully, refresh page to see them in action.');
    } catch (err) {
      captureAndShowError(err, 'updating default data');
    }
  };
  return (
    <Container>
      <PageHeader title="Settings" noColon />
      <FormDefaultSettings defaultData={activeTenant.defaultData} onDefaultDataChange={onDefaultDataChange} />
    </Container>
  );
};
