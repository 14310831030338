import React from 'react';

import { useHistory, Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeature } from '@recurrency/core-api';
import { ColumnType } from 'antd/lib/table';

import { isAdmin, shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { Button } from 'components/Button';
import { ButtonLink } from 'components/Links';
import { SearchFrame } from 'components/Search/SearchFrame';
import { Tag } from 'components/Tag';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { IndexName } from 'utils/algolia';
import { formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { humanDateColumn, sortableDollarColumn, sortableNumberColumn } from 'utils/tables';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { AlgoliaOrder } from 'types/algolia-collections';

export const OrderListPage = () => {
  const { activeRole, activeTenant, activeUser } = useGlobalApp();
  const isUserAdminRole = isAdmin(activeRole.foreignId, activeRole.name);
  const history = useHistory();

  const columns: ColumnType<AlgoliaOrder>[] = [
    {
      title: 'Order No',
      dataIndex: 'order_no',
      render: (orderNo: string) => <Link to={routes.orders.orderDetails(orderNo)}>{orderNo}</Link>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        const color = status === 'Canceled' ? 'red' : status === 'Completed' ? 'green' : 'blue';
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
    },
    humanDateColumn({ title: 'Timeline', dataIndex: 'order_date' }),
    { title: 'Order Date', dataIndex: 'order_date', render: (dt: string) => formatDate(dt) },
    {
      title: 'Customer ID',
      dataIndex: 'customer_id',
      render: (id: string) => <Link to={routes.sales.customerDetails(id)}>{id}</Link>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
    },
    sortableDollarColumn({
      title: 'Sales',
      dataIndex: 'total_value',
    }),
    sortableDollarColumn({
      title: 'GM',
      dataIndex: 'gross_margin',
    }),
    sortableNumberColumn({ title: 'Item Count', dataIndex: 'item_count' }),
    {
      title: '',
      dataIndex: 'order_no',
      render: (orderNo: string) => ButtonLink(routes.orders.orderDetails(orderNo)),
    },
  ];

  const newOrder = () => {
    track(TrackEvent.Nav_CtaClick, {
      ctaName: CtaName.NewOrder,
      ctaType: CtaType.Button,
    });
    history.push(routes.orders.orderNew());
  };

  return (
    <SearchFrame<AlgoliaOrder>
      title={
        <InfoTooltip title="Recurrency imports your most recent 25,000 orders for search, but includes all of them when calculating statistics.">
          Orders
        </InfoTooltip>
      }
      indexName={IndexName.Orders}
      queryPlaceholder="Search recent orders by order, customer, ship to, PO, salesrep, or location..."
      columns={columns}
      valueFacets={[
        {
          title: 'Status',
          field: 'status',
        },
        {
          title: 'Order Type',
          field: 'order_type',
        },
        {
          title: 'Sales Rep',
          queryPlaceholder: 'Filter by sales rep...',
          ...(isUserAdminRole
            ? {
                field: 'salesrep_name',
              }
            : {
                hidden: true,
                field: 'salesrep_id',
                // NOTE: This is inconsistent with CustomersSearch
                // which contains {salesrep_id: 'sales:<id>'} objects
                defaultValue: activeRole.foreignId,
              }),
        },
        {
          title: 'Location',
          field: 'location_name',
          queryPlaceholder: 'Filter by location...',
        },
      ]}
      numericFacets={[
        { title: 'Sales', field: 'total_value' },
        { title: 'GM', field: 'gross_margin' },
      ]}
      ctaChild={
        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateOrder) && (
          <Button type="primary" onClick={newOrder}>
            <PlusOutlined /> New Order
          </Button>
        )
      }
    />
  );
};
