import { Skeleton } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import styled from 'styled-components';

type Heights = {
  small: string;
  middle: string;
  large: string;
};

const heights: Heights = {
  small: '30px',
  middle: '40px',
  large: '50px',
};

const getHeights = (size: SizeType = 'middle') => heights[size];

export const SkeletonInput = styled(Skeleton.Input)<{ sizetype: SizeType }>`
  width: 100% !important;
  height: ${(props) => getHeights(props.sizetype)} !important;

  border-radius: ${(props) => props.theme.border.radius};
`;
