import React from 'react';

import { Grid } from 'antd';

import { Typography } from 'components/Typography';
import { PageHeading } from 'components/Typography/Typography';

import * as Styled from './PageHeader.style';

export type Props = {
  prefix?: string; // prefix for title (e.g Customer, Item)
  title: string;
  subTitle?: string;
  headerActions?: React.ReactNode;
  onBack?: () => void;
  backTitle?: string;
  copyable?: boolean; // if set true, title and subtitle will be set as copyable
  noColon?: boolean;
};

export const PageHeader = (props: Props): JSX.Element => {
  const { prefix, title, subTitle, headerActions, onBack, backTitle, copyable = false, noColon = false } = props;

  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;

  return (
    <Styled.Container>
      <Styled.HeaderLeft>
        {onBack && (
          <Styled.Button onClick={onBack}>
            <Styled.LeftIcon />
            {!!backTitle && isDesktopView && <Typography type="subtitle">{backTitle}</Typography>}
          </Styled.Button>
        )}
        <Styled.Title>
          {prefix && <PageHeading>{prefix}</PageHeading>}
          <PageHeading copyable={copyable}>{title}</PageHeading>
          {!noColon && <PageHeading>:</PageHeading>}
          <PageHeading>&nbsp;</PageHeading>
          <PageHeading copyable={copyable}>{subTitle}</PageHeading>
        </Styled.Title>
      </Styled.HeaderLeft>
      <Styled.HeaderActions>{headerActions}</Styled.HeaderActions>
    </Styled.Container>
  );
};
