import { Link } from "react-router-dom";
import styled from "styled-components";
import { Select } from "components/Select";
import { RECURRENCY_BLUE } from "constants/styles";
export const ResultContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => props.isDesktopView ? "row" : "column"};
`;
export const ResultIcon = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-right: ${(props) => props.theme.units.MD}px;
`;
export const ResultTitle = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
`;
export const ResultSubtitle = styled.span`
  color: rgba(0, 0, 0, 0.45);
  margin-left: ${(props) => props.isDesktopView ? 0 : props.theme.units.MD + props.theme.units.LG}px;
`;
export const SearchSelect = styled(Select)`
  width: 100%;
  .ant-select-selection-item {
    color: ${(props) => props.theme.colors.gray500};
  }
`;
export const UnhighlightedLabel = styled.span`
  color: rgba(0, 0, 0, 0.85);
  white-space: pre;
`;
export const HighlightedLabel = styled(UnhighlightedLabel)`
  color: ${RECURRENCY_BLUE};
`;
