import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  idColumn,
  sortableStringColumn,
  // sortableNumberColumn,
  // sortableDollarColumn,
} from 'utils/tables';

export const Items = ({ vendorId }: { vendorId: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (id: string) => <Link to={routes.purchasing.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
    }),
    // sortableNumberColumn({
    //   title: 'Total Qty Available',
    //   dataIndex: 'qtyAvailable',
    // }),
    // sortableNumberColumn({
    //   title: 'Days of Stock',
    //   dataIndex: 'stockDays',
    // }),
    // sortableDollarColumn({
    //   title: 'Last Cost',
    //   dataIndex: 'lastCost',
    // }),
    {
      render: ({ itemId }: { itemId: any }) => ButtonLink(routes.purchasing.itemDetails(itemId)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/vendors/${encodeLegacyApiParam(vendorId || '')}/items`,
      })}
      columns={columns}
      rowKey="itemId"
    />
  );
};
