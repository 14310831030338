import React from 'react';

import { Link } from 'react-router-dom';

import { RightOutlined } from '@ant-design/icons';

import { RECURRENCY_BLUE } from 'constants/styles';

export const linkablePhoneNumber = (phoneNumber: string | number) => <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;

export const linkableEmail = (email: string) => <a href={`mailto:${email}`}>{email}</a>;

export const linkableAddress = (addr: string, label?: string) => (
  <a href={`http://maps.google.com/?q=${addr}`} target="_blank" rel="noopener noreferrer">
    {label || addr}
  </a>
);

export const ButtonLink = (uri: string) => (
  <Link to={uri}>
    <RightOutlined twoToneColor={RECURRENCY_BLUE} />
  </Link>
);

export const ExternalLink = ({
  to,
  children,
  style,
  onClick,
}: {
  to: string;
  children: React.ReactChild;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => (
  <a href={to} style={style} target="_blank" rel="noopener noreferrer" onClick={onClick}>
    {children}
  </a>
);
