import styled from "styled-components";
import { RadioGroup as UnStyledRadioGroup } from "components/Radio";
import { Typography } from "components/Typography";
import { media } from "utils/responsive";
export const Title = styled(Typography)`
  text-align: center;
  margin-top: 2rem;

  ${media.sm`
    margin-top: 4rem;
  `}
`;
export const RadioGroup = styled(UnStyledRadioGroup)`
  margin-bottom: 1.5rem;
`;
