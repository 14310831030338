import React from 'react';

import { RadioGroupProps } from 'antd/lib/radio';

import { StyledRadioGroup } from './RadioGroup.style';

export type Props = RadioGroupProps;

export const RadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps): JSX.Element => (
  <StyledRadioGroup {...props} />
);
