import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import { idColumn, sortableStringColumn } from 'utils/tables';

export const Suppliers = ({ vendorId }: { vendorId: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Supplier ID',
      dataIndex: 'supplierId',
      render: (id: string) => <Link to={routes.purchasing.vendorDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Supplier Name',
      dataIndex: 'supplierName',
    }),
    {
      render: ({ supplierId }: { supplierId: string }) => ButtonLink(routes.purchasing.vendorDetails(supplierId)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/vendors/${encodeLegacyApiParam(vendorId || '')}/suppliers`,
      })}
      columns={columns}
      rowKey="supplierId"
    />
  );
};
