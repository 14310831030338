import React, { useState } from 'react';

import { TenantFeature, TenantFeatureFlags } from '@recurrency/core-api';

import { Button } from 'components/Button/Button';
import { Table } from 'components/Table';

import { sortableStringColumn } from 'utils/tables';

export function TenantFeatureFlagsTable({
  featureFlags,
  onFeatureFlagsChange,
}: {
  featureFlags: TenantFeatureFlags;
  onFeatureFlagsChange: (newFeatureFlags: TenantFeatureFlags) => void;
}) {
  const defaultSelected = Object.entries(featureFlags)
    .filter(([_, enabled]) => enabled)
    .map(([featureName, _]) => featureName);
  const [selectedFeatureNames, setSelectedFeatureNames] = useState<string[]>(defaultSelected);
  const tableRowsData: Array<{ featureName: string }> = Object.values(TenantFeature).map((featureName) => ({
    featureName,
  }));
  const columns = [
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'featureName',
    }),
  ];

  const handleSetFeaturesButtonClick = () => {
    onFeatureFlagsChange(Object.fromEntries(selectedFeatureNames.map((featureName) => [featureName, true])));
  };

  const rowSelection = {
    selectedRowKeys: selectedFeatureNames,
    onChange: (selectedKeys: TenantFeature[]) => {
      setSelectedFeatureNames(selectedKeys);
    },
  };

  return (
    <Table
      title="Feature Flags"
      columns={columns}
      data={tableRowsData}
      rowKey="featureName"
      rowSelection={rowSelection}
    >
      <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" onClick={handleSetFeaturesButtonClick}>
          Set Enabled Features
        </Button>
      </div>
    </Table>
  );
}
