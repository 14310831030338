import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Input } from 'components/Input';

export interface SearchInputProps {
  query?: string;
  onQueryChange?: (newQuery: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  className?: string;
  size?: SizeType;
}

export const SearchInput = ({
  query,
  onQueryChange,
  placeholder = '',
  autoFocus = false,
  style,
  className,
  size,
}: SearchInputProps) => (
  <Input
    prefix={<SearchOutlined />}
    type="search"
    autoFocus={autoFocus}
    value={query ?? ''}
    placeholder={placeholder}
    onChange={(event) => onQueryChange?.(event.currentTarget.value)}
    style={style}
    className={className}
    size={size}
  />
);
