/**
 * StateStore is a simple object that holds state,
 * provides a setState function to change state,
 * and observe function for any listeners to be notified when state changes.
 */
export class StateStore<StateT> {
  public state: StateT;
  private _listeners: Array<(state: StateT) => void> = [];

  constructor(initialState: StateT) {
    this.state = initialState;
  }

  setState(newState: StateT) {
    this.state = newState;
    for (const listener of this._listeners) {
      listener(newState);
    }
    return this.state;
  }

  update(partialNewState: Partial<StateT>) {
    this.setState({ ...this.state, ...partialNewState });
    return this.state;
  }

  /**
   * adds a listener that gets called when state changes.
   * The return value of observe is a function that removes the listener */
  observe(listener: (state: StateT) => void) {
    if (!this._listeners.includes(listener)) {
      this._listeners.push(listener);
    }
    return () => {
      const idx = this._listeners.indexOf(listener);
      if (idx !== -1) {
        this._listeners.splice(idx, 1);
      }
    };
  }
}
