import React, { useState, useCallback } from 'react';

import copy from 'copy-to-clipboard';

import { Tooltip } from 'components/Tooltip';

import { TextType } from './types';
import { Align, StyledTypography } from './Typography.style';

export type Props = {
  style?: React.CSSProperties;
  type?: TextType;
  weight?: string;
  responsive?: boolean;
  margin?: number;
  align?: Align;
  copyable?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
};

export const Typography = (props: Props): JSX.Element => {
  const { children, margin = 0, copyable = false } = props;

  if (copyable) {
    return <CopyableTypography {...props} />;
  }

  return (
    <StyledTypography margin={margin} {...props}>
      {children}
    </StyledTypography>
  );
};

const CopyableTypography = (props: Props): JSX.Element => {
  const { children, margin = 0 } = props;

  const [copyDone, setCopyDone] = useState(false);

  const handleCopy = useCallback(() => {
    copy(children as string);
    setCopyDone(true);

    setTimeout(() => {
      setCopyDone(false);
    }, 1000);
  }, [children]);

  return (
    <Tooltip title={copyDone ? 'Copied' : 'Copy'} placement="bottom">
      <StyledTypography onClick={handleCopy} margin={margin} {...props}>
        {children}
      </StyledTypography>
    </Tooltip>
  );
};

export const PageHeading = (props: Props): JSX.Element => <Typography type="large" weight="bold" {...props} />;
