import React from 'react';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { encodeLegacyApiParam } from 'utils/routes';
import { sortableStringColumn, sortableNumberColumn, sortableBooleanColumn } from 'utils/tables';

export const UOMTable = ({ invMastUid: itemId }: { invMastUid: string | undefined }) => {
  const columns = [
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Unit Size',
      dataIndex: 'unitSize',
    }),
    sortableBooleanColumn({
      title: 'Default',
      dataIndex: 'default',
      defaultSortOrder: 'descend',
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(itemId || '')}/unit-of-measures`,
      })}
      columns={columns}
    />
  );
};
