import React, { useState } from 'react';

import { Form } from 'antd';
import moment from 'moment';

import { Button } from 'components/Button';
import {
  responsiveFormLayout,
  AlgoliaSelectFormItem,
  CheckboxFormItem,
  DatePickerFormItem,
  InputFormItem,
  TextAreaFormItem,
  SelectFormItem,
  defaultFormTailLayout,
} from 'components/FormItems';

import { IndexName } from 'utils/algolia';

import { AlgoliaBranch, AlgoliaVendor } from 'types/algolia-collections';

export const OrderHeader = ({
  setVendorData,
  vendorId,
  locationData,
  location,
  setLocation,
  orderHeader,
}: {
  setVendorData: FIXME;
  vendorId: FIXME;
  locationData: FIXME;
  location: FIXME;
  setLocation: FIXME;
  orderHeader: FIXME;
}) => {
  const [form] = Form.useForm();
  const [showVendorConnectedInfo, setShowVendorConnectedInfo] = useState(!!vendorId);

  return (
    <Form
      name="orderHeaderForm"
      form={form}
      onFinishFailed={console.error}
      initialValues={{ date: moment(), approved: true, ...orderHeader }}
      {...responsiveFormLayout}
    >
      <CheckboxFormItem label="Approved" name="approved" />

      <AlgoliaSelectFormItem<AlgoliaVendor>
        label="Vendor"
        name="vendor"
        indexName={IndexName.Vendors}
        initialValue={orderHeader.vendor}
        rules={[{ required: true, message: 'Please add a vendor.' }]}
        mapHitFn={(hit) => ({
          key: hit.vendor_id,
          name: hit.vendor_name,
          value: `${hit.vendor_id}: ${hit.vendor_name}`,
        })}
        setFieldsValue={form.setFieldsValue}
        postValueChange={(value: FIXME, option: FIXME) => {
          const isEmpty = value === '';
          if (isEmpty) {
            setShowVendorConnectedInfo(false);
          } else {
            setShowVendorConnectedInfo(value);
          }

          if (option) {
            const { key, name } = option;
            setVendorData({
              key,
              name,
            });
          }
        }}
      />

      {!showVendorConnectedInfo && <InputFormItem label="Supplier" disabled placeholder="Please choose a vendor" />}
      {showVendorConnectedInfo && (
        <SelectFormItem label="Supplier" name="vendor" disabled placeholder="Please choose a vendor" />
      )}

      {locationData?.location === null && (
        <AlgoliaSelectFormItem<AlgoliaBranch>
          label="Ship To"
          name="location"
          initialValue={location?.value ?? ''}
          indexName={IndexName.Branches}
          rules={[{ required: true, message: 'Please add a location.' }]}
          mapHitFn={(hit) => ({
            key: hit.branch_id,
            value: `${hit.branch_id}: ${hit.branch_description}`,
          })}
          setFieldsValue={form.setFieldsValue}
          postValueChange={(value: FIXME, option: FIXME) => {
            if (option) {
              const { key } = option;
              setLocation({ id: key, value });
            }
          }}
        />
      )}
      {locationData?.location && (
        <InputFormItem
          label="Location"
          name="location"
          value={locationData.location}
          prefix={`${locationData.location}: ${locationData.locationName}`}
          disabled
        />
      )}

      <DatePickerFormItem
        label="Required Date"
        name="date"
        rules={[{ required: true, message: 'Please add a required date.' }]}
      />
      <InputFormItem label="PO No" name="pono" rules={[{ required: true, message: 'Please add a PO number' }]} />

      <TextAreaFormItem label="Comments" name="comments" />
      <Form.Item {...defaultFormTailLayout}>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};
