import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UpdatePartialTaskRequest } from '@recurrency/core-api/dist/api';
import { PageHeader, Descriptions, Modal, Divider, message } from 'antd';

import { TaskStatus } from 'pages/tasks/types';

import { Button } from 'components/Button';
import { DescriptionsContainer } from 'components/DescriptionsContainer';
import { Container } from 'components/Layout/Container';
import { ButtonLink, linkableEmail, linkablePhoneNumber } from 'components/Links';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { Tag } from 'components/Tag';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { formatDate } from 'utils/formatting';
import { routes, usePathParams, IdPathParams } from 'utils/routes';

const { confirm } = Modal;

export const TaskDetailsPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const { activeTenant } = useGlobalApp();

  const { data, isLoading, error, reload } = useApi().tasks().getTaskById(false, id);
  // incorrect type AxiosResponse<void> from core-api
  const task = data as FIXME;

  const history = useHistory();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !task) {
    return <CenteredLoader />;
  }

  const toggleTask = async (status: TaskStatus) => {
    try {
      const request: UpdatePartialTaskRequest = {
        status: status === TaskStatus.Completed ? TaskStatus.New : TaskStatus.Completed,
        tenantId: activeTenant.id,
      };
      await api().tasks().patchUpdateTask(id, request);
      message.success(status === TaskStatus.Completed ? 'Task marked as incomplete.' : 'Task marked as completed.');
      reload();
    } catch (err) {
      captureAndShowError(err, 'updating task status');
    }
  };

  const postDelete = async () => {
    try {
      await api().tasks().deleteTask(id);
      message.success('Task deleted.');
      history.push(routes.tasks.taskList());
    } catch (err) {
      captureAndShowError(err, 'deleting task');
    }
  };

  const deleteTask = async () => {
    confirm({
      title: 'Are you sure you want to delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postDelete();
      },
      onCancel() {},
    });
  };

  return (
    <Container>
      <PageHeader
        ghost={false}
        title={task.title}
        subTitle={
          task.status === TaskStatus.Completed ? (
            <Tag color="green">Completed</Tag>
          ) : (
            <Tag color="orange">Incomplete</Tag>
          )
        }
        onBack={() => history.push(routes.tasks.taskList())}
        extra={[
          <Button id="taskdetails_delete" key="3" onClick={deleteTask}>
            Delete
          </Button>,
          <Button id="taskdetails_edit" key="2" onClick={() => history.push(routes.tasks.taskEdit(task.id))}>
            Edit
          </Button>,
          <Button id="taskdetails_complete" key="1" type="primary" onClick={() => toggleTask(task.status)}>
            {task.status === TaskStatus.Completed ? 'Mark Incomplete' : 'Complete'}
          </Button>,
        ]}
      >
        <DescriptionsContainer title="Task Information" size="default" column={3} layout="vertical" bordered>
          <Descriptions.Item label="Customer ID">{task.metadata.customer?.foreignId}</Descriptions.Item>
          <Descriptions.Item label="Customer" span={2}>
            {task.metadata.customer?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Contact ID">{task.metadata.contact?.foreignId}</Descriptions.Item>
          <Descriptions.Item label="Contact" span={2}>
            {task.metadata.contact?.name}
          </Descriptions.Item>
          {task.contactDirectPhone && (
            <Descriptions.Item label="Phone" span={3}>
              {linkablePhoneNumber(task.contactDirectPhone)}
            </Descriptions.Item>
          )}
          {task.contactEmailAddress && (
            <Descriptions.Item label="Email" span={3}>
              {linkableEmail(task.contactEmailAddress)}
            </Descriptions.Item>
          )}
          {task.contactDirectFax && (
            <Descriptions.Item label="Fax" span={3}>
              {task.contactDirectFax}
            </Descriptions.Item>
          )}
          {task.contactOrderDate && (
            <Descriptions.Item label="Last Order Date" span={2}>
              {formatDate(task.contactOrderDate)}
            </Descriptions.Item>
          )}
          {task.contactOrderNo && (
            <Descriptions.Item label="Last Order #" span={1}>
              {task.contactOrderNo} {ButtonLink(routes.sales.itemDetails(task.contactOrderNo))}
            </Descriptions.Item>
          )}
        </DescriptionsContainer>
        <Divider />
        <DescriptionsContainer size="middle" column={2} bordered>
          <Descriptions.Item label="Deadline">{formatDate(task.dueAt)}</Descriptions.Item>
          <Descriptions.Item label="Reminder Date">
            {task.reminderAt ? formatDate(task.reminderAt) : 'None listed.'}
          </Descriptions.Item>
          <Descriptions.Item label="Assigned By">
            <span id="taskdetails_assigned_by">{`${task.user.firstName} ${task.user.lastName}`}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Assigned To">
            <span id="taskdetails_assigned_by">{`${task.assignee.firstName} ${task.assignee.lastName}`}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Remarks" span={2}>
            <div className="new-line">{task.body}</div>
          </Descriptions.Item>
          {task.metadata?.items ? (
            <Descriptions.Item label="Items" span={2}>
              {task.metadata.items.map((item: FIXME) => (
                <Link to={routes.sales.itemDetails(item.foreignId)} key={item.foreignId} style={{ display: 'block' }}>
                  <strong>#{item.foreignId}:</strong> {item.name}
                </Link>
              ))}
            </Descriptions.Item>
          ) : null}
        </DescriptionsContainer>
        <Divider />
      </PageHeader>
    </Container>
  );
};
