import { Space } from "antd";
import styled from "styled-components";
import { Table } from "components/Table";
import { Tag } from "components/Tag";
export const Box = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
`;
export const BoxPadding = styled.div`
  padding: 12px;
`;
export const ItemTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-cell {
    padding: 8px 4px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-table {
    td.ant-table-cell {
      vertical-align: top;
      .ant-select,
      .ant-input {
        width: 100%;
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
      }
    }
  }
`;
export const HeaderTable = styled(ItemTable)`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  overflow: hidden;
  tr {
    &.ant-table-row {
      cursor: pointer;
    }
  }
`;
export const TableAction = styled.div`
  cursor: pointer;

  &:hover {
    svg {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;
export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  min-height: 30px;
`;
export const SelectionCell = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  ${(props) => props.selected && `border-left: 3px solid ${props.theme.colors.blue};`}
`;
export const TableCellDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0.7;
`;
export const Footer = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;
export const TagContainer = styled.div`
  text-transform: capitalize;
`;
export const RationaleTag = styled(Tag)`
  margin-right: 0;
  margin-bottom: 2px;
`;
