import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { Button } from 'components/Button';
import { FinanceChart } from 'components/Charts/FinanceChart';
import { RadioGroup } from 'components/Radio';
import { Table } from 'components/Table';

import { routes } from 'utils/routes';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { poColumns, purchaseColumns, chartOptions, ChartOption } from './data';
import {
  ItemExtendedDescription,
  ItemId,
  ItemDescription,
  RightColumnTitle,
  RightColumnHeader,
  RightColumn,
  ChartContainer,
  DetailSection,
  SectionTitle,
  ChartOptionContainer,
  ChartWrapper,
  ActionWrapper,
} from './ForecastsPage.style';

export const ForecastDetails = ({ details: detailData, data }: any) => {
  const history = useHistory();

  const [chartOption, setChartOption] = useState<ChartOption>(ChartOption.DAILY);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [recommendedPurchases, setRecommendedPurchases] = useState<any[]>([]);

  const id = data?.item_id;

  const description = data?.item_desc;
  const fullDesc = data?.extended_desc;

  const chartData = useMemo(() => detailData?.forecasts ?? [], [detailData]);
  const uom = useMemo(() => detailData?.forecastUom, [detailData]);
  const recentPos = detailData?.recentPurchases;

  useEffect(() => {
    setRecommendedPurchases(detailData?.recommendedPurchases ?? []);
  }, [detailData]);

  const selectedChartData = useMemo(() => {
    let originData = [];
    if (chartOption === ChartOption.DAILY) {
      originData = chartData?.daily ?? [];
    } else if (chartOption === ChartOption.WEEKLY) {
      originData = chartData?.weekly ?? [];
    } else {
      originData = chartData?.monthly ?? [];
    }

    const prev: any = [];
    const future: any = [];

    originData.forEach(({ date, usage }: { date: string; usage: number }) => {
      if (moment(date).isBefore()) {
        prev.push({ time: date, value: usage });
      } else {
        future.push({ time: date, value: usage });
      }
    });

    if (future.length) prev.push(future[0]);

    return {
      prev,
      future,
    };
  }, [chartData, chartOption]);

  const handleCreatePurchaseOrder = useCallback(() => {
    history.push(
      routes.purchasing.purchaseOrderNew({
        itemId: id,
        itemFullDesc: `${description} - ${fullDesc}`,
      }),
    );
  }, [description, fullDesc, id, history]);

  const handleCreateOrderFromTable = useCallback(() => {
    if (selectedRows.length > 0) {
      const selectedRow = selectedRows[0]; // Handle one for now, we will queue these later
      history.push(
        routes.purchasing.purchaseOrderNew({
          vendorId: selectedRow.supplierId,
          vendorName: selectedRow.supplierName,
          locationId: selectedRow.locationId,
          locationName: selectedRow.locationName,
          itemId: id,
          itemFullDesc: `${description} - ${fullDesc}`,
          qty: selectedRow.targetQty,
          uom: selectedRow.targetUom,
          price: selectedRow.targetPrice,
        }),
      );
    }
  }, [selectedRows, id, description, fullDesc, history]);

  const handleCreateTask = useCallback(() => {
    track(TrackEvent.Nav_CtaClick, {
      ctaName: CtaName.NewTask,
      ctaType: CtaType.Button,
    });
    history.push(routes.tasks.taskNew({ title: `Create PO for ${description}` }));
  }, [history, description]);

  const handleChangeChartOption = useCallback((e) => {
    setChartOption(e.target.value);
  }, []);

  const onSelectChange = useCallback((selectedRowKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  }, []);

  // used locationId as a rowKey since the duplications of Ids exist in the data
  const handleDeclinePO = useCallback(() => {
    const filteredPurchases = recommendedPurchases.filter((data: any) => !selectedRowKeys.includes(data.locationId));

    setRecommendedPurchases(filteredPurchases);

    // clear selections
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [recommendedPurchases, selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderHeaderActions = () => (
    <div>
      <Button onClick={handleCreateTask} type="default" style={{ marginRight: '8px' }}>
        Create Task
      </Button>
      <Button onClick={handleCreatePurchaseOrder} type="primary" disabled style={{ marginRight: '8px' }}>
        Create PO
      </Button>
    </div>
  );

  const renderRecommendedPurchaseActions = () => (
    <ActionWrapper>
      <Button onClick={handleCreateOrderFromTable} type="primary" style={{ marginRight: '8px' }}>
        Accept
      </Button>
      <Button type="default" onClick={handleDeclinePO}>
        Decline
      </Button>
    </ActionWrapper>
  );

  const renderChart = useMemo(
    () => <FinanceChart data={selectedChartData} tooltipPrefix={uom} />,
    [selectedChartData, uom],
  );

  return (
    <RightColumn>
      <RightColumnHeader>
        <a href={routes.purchasing.itemDetails(id)} target="_blank" rel="noopener noreferrer">
          <RightColumnTitle>
            <ItemId>{id}</ItemId>
            <ItemDescription>{description}</ItemDescription>
            <ItemExtendedDescription>{fullDesc}</ItemExtendedDescription>
          </RightColumnTitle>
        </a>
        {renderHeaderActions()}
      </RightColumnHeader>
      <ChartContainer>
        <ChartOptionContainer>
          <RadioGroup
            value={chartOption}
            onChange={handleChangeChartOption}
            options={chartOptions}
            optionType="button"
            buttonStyle="solid"
          />
        </ChartOptionContainer>
        <ChartWrapper>{renderChart}</ChartWrapper>
      </ChartContainer>
      <DetailSection>
        <SectionTitle>Recommended Purchases</SectionTitle>
        <ActionWrapper>{renderRecommendedPurchaseActions()}</ActionWrapper>
        <Table
          rowSelection={rowSelection}
          rowKey="locationId"
          columns={purchaseColumns}
          data={recommendedPurchases}
          size="small"
        />
      </DetailSection>
      <DetailSection>
        <SectionTitle>Recent POs</SectionTitle>
        <Table rowKey="poNo" columns={poColumns} data={recentPos} size="small" />
      </DetailSection>
    </RightColumn>
  );
};
