import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Form, Input, message } from 'antd';
import moment from 'moment';

import { TaskStatus } from 'pages/tasks/types';

import { Button } from 'components/Button';
import { ContactsAutoComplete } from 'components/ContactsAutoComplete';
import {
  DatePickerFormItem,
  InputFormItem,
  TextAreaFormItem,
  defaultFormLayout,
  defaultFormTailLayout,
  CheckboxFormItem,
} from 'components/FormItems';
import { AlgoliaSelectFormItem } from 'components/FormItems/AlgoliaSelectFormItem';
import { AssigneeSelectFormItem } from 'components/FormItems/AssigneeSelectFormItem';
import { Container } from 'components/Layout/Container';
import { CenteredLoader, CenteredError } from 'components/Loaders';
import { NewContactModal } from 'components/Modal/NewContactModal';
import { PageHeading } from 'components/Typography/Typography';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { IndexName } from 'utils/algolia';
import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes, usePathParams } from 'utils/routes';

import { UNITS } from 'constants/styles';

import { AlgoliaCustomer } from 'types/algolia-collections';

// TODO @noj: combine EditTask and NewTask into a single component
export const TaskEditPage = () => {
  const { activeTenant } = useGlobalApp();
  const { id } = usePathParams();

  const { data, isLoading, error } = useApi().tasks().getTaskById(false, id);
  // incorrect core-api return type
  const task = data as FIXME;
  const [showContacts, setShowContacts] = useState<boolean>();

  const [isCreateNewContactModalOpen, setIsCreateNewContactModalOpen] = useState(false);

  const history = useHistory();

  const [form] = Form.useForm();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !task) {
    return <CenteredLoader />;
  }

  const onSubmit = async (data: FIXME) => {
    try {
      let contact;
      if (data.contact) {
        const contactSegments = data.contact.split(': ');
        if (contactSegments.length > 1) {
          contact = { foreignId: contactSegments[0], name: contactSegments[1] };
        }
      }
      const request = {
        status: data.complete ? TaskStatus.Completed : TaskStatus.New,
        title: data.title,
        body: data.body,
        tenantId: activeTenant.id,
        assigneeUserId: data.assigneeId,
        dueAt: data.dueAt.toDate(),
        reminderAt: data.reminderAt ? data.reminderAt.toDate() : undefined,
        customer: {
          name: data.customerName,
          foreignId: data.customerId,
        },
        contact,
      };
      await api().tasks().patchUpdateTask(id, request);
      message.success('Task updated.');
      history.push(routes.tasks.taskList());
    } catch (err) {
      captureAndShowError(err, 'updating task');
    }
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'contactForm') {
          const { taskForm } = forms;

          taskForm.setFieldsValue({
            contact: `${values.firstName} ${values.lastName}`,
          });
        }
      }}
    >
      <Container>
        <PageHeading style={{ marginTop: '24px', marginBottom: '24px', textAlign: 'center' }}>Edit Task</PageHeading>
        <div
          style={{
            borderLeft: 'rgb(76, 130, 232) 4px solid',
            marginBottom: UNITS.XL,
            paddingLeft: UNITS.XL,
            marginLeft: '12rem',
            marginRight: '16rem',
          }}
        >
          <Form
            {...defaultFormLayout}
            form={form}
            onFinish={onSubmit}
            onError={console.error}
            initialValues={{
              id: task.id,
              title: task.title,
              assignedById: task.user.id,
              assignedByName: `${task.user.firstName} ${task.user.lastName}`,
              assignedToId: task.assignee.id,
              assignedToName: `${task.assignee.firstName} ${task.assignee.lastName}`,
              customerId: task.metadata.customer.foreignId,
              customerName: task.metadata.customer.name,
              body: task.body,
              complete: task.status === TaskStatus.Completed,
              dueAt: moment(task.dueAt).isValid() ? moment(task.dueAt) : undefined,
              reminderAt: moment(task.reminderAt).isValid() ? moment(task.reminderAt) : undefined,
            }}
          >
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>

            <InputFormItem label="Title" name="title" rules={[{ required: true, message: 'Please add a title.' }]} />

            <DatePickerFormItem
              label="Deadline"
              name="dueAt"
              rules={[{ required: true, message: 'Please select a deadline.' }]}
            />

            <DatePickerFormItem label="Reminder Date" name="reminderAt" />

            <Form.Item hidden name="customerId" rules={[{ required: true, message: 'Please add a customer id.' }]} />

            <Form.Item
              hidden
              name="customerName"
              rules={[{ required: true, message: 'Please add a customer name.' }]}
            />

            <CheckboxFormItem label="Complete" name="complete" />

            <AlgoliaSelectFormItem<AlgoliaCustomer>
              label="Customer"
              name="customerName"
              indexName={IndexName.Customers}
              rules={[{ required: true, message: 'Please add a customer.' }]}
              mapHitFn={(hit) => ({
                key: hit.customer_id,
                value: `${hit.customer_id}: ${hit.customer_name}`,
              })}
              setFieldsValue={form.setFieldsValue}
              initialValue={task?.metadata?.customer?.name || ''}
              postValueChange={(value: FIXME, option: FIXME) => {
                if (value === '') {
                  setShowContacts(false);
                }
                if (option) {
                  setShowContacts(option.key);
                  form.setFieldsValue({
                    customerId: option.key,
                    customerName: option.value.split(': ')[1],
                  });
                }
              }}
              salesRepRefined
            />

            <ContactsAutoComplete
              customerId={showContacts || task?.metadata?.customer?.foreignId}
              setIsCreateNewContactModalOpen={setIsCreateNewContactModalOpen}
              setFieldsValue={form.setFieldsValue}
              initialValue={task?.metadata?.contact?.name || ''}
            />

            <AssigneeSelectFormItem
              name="assigneeId"
              label="Assigned To"
              disabled={false}
              rules={[{ required: true, message: 'Please add an assignee.' }]}
              setFieldsValue={form.setFieldsValue}
              initialValue={task?.assignee.id}
            />

            <InputFormItem disabled label="Assigned By" name="assignedByName" />

            <Form.Item hidden name="assignedById">
              {' '}
              <Input />
            </Form.Item>

            <TextAreaFormItem label="Comments" name="body" />

            <Form.Item {...defaultFormTailLayout}>
              <Button id="submitTaskButton" type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>

      <NewContactModal
        isOpen={isCreateNewContactModalOpen}
        onCancel={() => setIsCreateNewContactModalOpen(false)}
        customerId={task.customerId}
      />
    </Form.Provider>
  );
};
