import { Modal as AntdModal } from "antd";
import styled from "styled-components";
export const Modal = styled(AntdModal)`
  .ant-modal-header {
    border-radius: ${(props) => props.theme.border.radius};
  }

  .ant-modal-content {
    border-radius: ${(props) => props.theme.border.radius};
  }

  .ant-modal-footer {
    .ant-btn {
      border-radius: ${(props) => props.theme.border.radius};
    }
  }
`;
