import React, { forwardRef } from 'react';

import { SelectProps as AntSelectProps } from 'antd/lib/select';

import { SkeletonInput } from 'components/Loader';

import * as Styled from './Select.style';

export type SelectOption<DataT = unknown> = {
  value: string;
  label?: string | JSX.Element;
  disabled?: boolean;
  style?: React.CSSProperties;
  mode?: 'multiple' | 'tags';
  /** Any custom data that should be passed on onSelect */
  data?: DataT;
};

export interface SelectProps extends AntSelectProps<any> {
  isLoading?: boolean;
}

export const Select = forwardRef((props: SelectProps, ref: any) => {
  const { options = [], isLoading = false, ...otherProps } = props;
  return isLoading ? (
    <SkeletonInput active sizetype={otherProps?.size ?? 'middle'} />
  ) : props.mode === 'tags' ? (
    <Styled.TagSelect ref={ref} options={options} {...otherProps} />
  ) : (
    <Styled.Select ref={ref} options={options} {...otherProps} />
  );
});
