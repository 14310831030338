import React, { useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Steps, Row, Col, Typography, Form, Modal } from 'antd';

import { Container } from 'components/Layout/Container';
import { NewContactModal } from 'components/Modal/NewContactModal';

import { useLegacyApi } from 'hooks/useApi';

import { captureAndShowError } from 'utils/error';
import { routes, useHashState } from 'utils/routes';

import { UNITS } from 'constants/styles';

import { LineItems } from './Steps/LineItems';
import { OrderHeader } from './Steps/OrderHeader';
import { OrderSubmit } from './Steps/OrderSubmit';
import { steps, transformLineItemToList } from './utils';

const { confirm } = Modal;

export const PurchaseOrderNewPage = () => {
  const history = useHistory();
  const [hashState] = useHashState<FIXME>();

  const initOrderHeader: FIXME = {};

  const vendorIdQuery = Number(hashState.vendorId);
  const vendorNameQuery = hashState.vendorName;
  if (vendorIdQuery && vendorNameQuery) {
    initOrderHeader.vendor = `${vendorIdQuery}: ${vendorNameQuery}`;
  }

  const locationIdQuery = hashState.locationId;
  const locationNameQuery = hashState.locationName;
  if (locationIdQuery && locationNameQuery) {
    initOrderHeader.location = `${locationIdQuery}: ${locationNameQuery}`;
  }

  const itemIdQuery = hashState.itemId;
  const itemFullDescQuery = hashState.itemFullDesc;
  const itemCombinedName = `${itemIdQuery}: ${itemFullDescQuery}`;
  const itemQtyQuery = Number(hashState.qty);
  const itemUomQuery = hashState.uom;
  const itemPriceQuery = Number(hashState.price);
  const hasBasicItem = itemIdQuery && itemFullDescQuery;
  const hasFullItem = hasBasicItem && itemQtyQuery && itemUomQuery && itemPriceQuery;

  const [orderHeader, setOrderHeader] = useState(initOrderHeader);
  const [lineItemsFormValues, setLineItemsFormValues] = useState(
    hasFullItem
      ? {
          item_0: itemCombinedName,
          price_0: itemPriceQuery,
          quantity_0: itemQtyQuery,
          uom_0: itemUomQuery,
        }
      : {},
  );
  const [lineItems, setLineItems] = useState(
    hasBasicItem
      ? [
          {
            key: itemIdQuery,
            value: itemCombinedName,
          },
        ]
      : [{}],
  );
  const [location, setLocation] = useState({
    id: locationIdQuery,
    value: initOrderHeader.location,
  });
  const [isCreateNewContactModalOpen, setIsCreateNewContactModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { data: locationData } = useLegacyApi('/v3/salesrep');

  const handleSetVendorData = useCallback(
    ({ key, name }) => {
      setOrderHeader({
        ...orderHeader,
        vendor: `${key}: ${name}`,
      });
      history.push(
        routes.purchasing.purchaseOrderNew({
          vendorId: key,
          vendorName: name,
        }),
      );
    },
    [history, orderHeader],
  );

  const deleteLineItem = useCallback(
    (indexToDel) => {
      const newItems = lineItems.filter((_, index) => indexToDel !== index);
      setLineItems(newItems);
    },
    [lineItems],
  );

  const addLineItem = useCallback(() => {
    setLineItems([...lineItems, {}]);
  }, [lineItems]);

  const handleFormFinish = useCallback(
    async (name, { values }) => {
      if (name === 'orderHeaderForm') {
        setOrderHeader(values);
        setCurrentStep(1);
      } else if (name === 'lineItemsForm') {
        setLineItemsFormValues(values);
        setCurrentStep(2);
      } else if (name === 'orderSubmitForm') {
        confirm({
          title: 'Confirm write to P21?',
          icon: <ExclamationCircleOutlined />,
          content: 'Press OK to export this purchase order to P21.',
          onOk: async () => {
            try {
              // TODO: ACTUAL SUBMISSION IS DISABLED FOR NOW! THIS WILL LOOK REAL BUT IT ISN'T!

              // const request = {
              //   description:
              //     orderHeader.comments && orderHeader.comments.length > 0
              //       ? orderHeader.comments
              //       : undefined,
              //   date: orderHeader.date.toDate(),
              //   purchaseOrder: orderHeader.pono,
              //   tenantId: activeTenant.id,
              //   approved: orderHeader.approved,
              //   metadata: {
              //     items: transformLineItemToList(lineItemsFormValues),
              //     freightType: orderHeader.freight,
              //     location: {
              //       foreignId: location.id,
              //       name: location.value,
              //     },
              //     customer: splitCombinedIdString(orderHeader.customer),
              //     contact: splitCombinedIdString(orderHeader.contact),
              //     shipTo: splitCombinedIdString(orderHeader.shipTo),
              //   },
              //   status: 'submitted', // Always directly submits for now
              // };
              // await api().quotes().createQuote(request);
              history.push(routes.purchasing.purchaseOrderList());
              message.success('PO export successful.');
            } catch (err) {
              captureAndShowError(err, 'exporting PO');
            }
          },
          onCancel() {
            history.push(routes.purchasing.purchaseOrderList());
            message.error('PO entry cancelled.');
          },
        });
      }
    },
    [history], // lineItemsFormValues, location, orderHeader, activeTenant.id],
  );

  return (
    <Form.Provider onFormFinish={handleFormFinish}>
      <Container>
        <Row style={{ marginTop: UNITS.XL, marginBottom: UNITS.XL }}>
          <Col>
            <Typography.Title level={4}>Purchase Order Entry</Typography.Title>
          </Col>
        </Row>
        <Steps current={currentStep} style={{ marginBottom: UNITS.XL }}>
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} />
          ))}
        </Steps>
        {currentStep === 0 && (
          <OrderHeader
            setVendorData={handleSetVendorData}
            vendorId={vendorIdQuery}
            locationData={locationData}
            location={location}
            setLocation={setLocation}
            orderHeader={orderHeader}
          />
        )}
        {currentStep === 1 && (
          <LineItems
            lineItemsFormValues={lineItemsFormValues}
            setCurrentStep={setCurrentStep}
            locationId={(orderHeader || {}).location}
            vendorId={vendorIdQuery}
            addLineItem={addLineItem}
            deleteLineItem={deleteLineItem}
            lineItems={lineItems}
            setLineItems={setLineItems}
          />
        )}
        {currentStep === 2 && (
          <OrderSubmit
            orderHeader={orderHeader}
            lineItems={transformLineItemToList(lineItemsFormValues)}
            setCurrentStep={setCurrentStep}
          />
        )}
        <NewContactModal isOpen={isCreateNewContactModalOpen} onCancel={() => setIsCreateNewContactModalOpen(false)} />
      </Container>
    </Form.Provider>
  );
};
