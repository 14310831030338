import React from 'react';

import { CompassOutlined } from '@ant-design/icons';
import { Role, Tenant, TenantFeature } from '@recurrency/core-api';

import { isPurchaserRole, shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { upperNavModules } from 'components/Layout/Navigation/navModulesData';

import { ExtendedUser } from 'hooks/useGlobalApp';

import { routes } from 'utils/routes';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { MenuOption } from './types';

interface NavCategoryMenuOption extends MenuOption {
  featureFlag?: TenantFeature;
  onSelect?: () => void;
}
interface NavCategoryConf {
  options: NavCategoryMenuOption[];
  /** takes the active user role to determine whether to show this category */
  isEnabledForRole?: (role: Maybe<Role>) => boolean;
}

export function getNavigationOptions(
  activeRole: Maybe<Role>,
  activeTenant: Tenant,
  activeUser: ExtendedUser,
): MenuOption[] {
  const navSubModulesBySlug: Obj<NavCategoryMenuOption[]> = Object.fromEntries(
    upperNavModules.map((navModule) => [
      navModule.slug,
      navModule.subModules.map((subModule) => ({
        label: subModule.label,
        href: subModule.routeFn(),
        featureFlag: subModule.featureFlag,
      })),
    ]),
  );

  const navCategories: Obj<NavCategoryConf> = {
    Purchases: {
      options: navSubModulesBySlug.purchasing,
      isEnabledForRole: isPurchaserRole,
    },
    Sales: {
      options: [
        ...navSubModulesBySlug.sales,
        {
          label: 'New Prospect',
          href: routes.sales.customerNew(),
          featureFlag: TenantFeature.SalesCreateProspect,
          onSelect: () =>
            track(TrackEvent.Nav_CtaClick, {
              ctaName: CtaName.NewProspect,
              ctaType: CtaType.GlobalSearch,
            }),
        },
      ],
    },
    Orders: {
      options: [
        ...navSubModulesBySlug.orders,
        {
          label: 'New Order',
          href: routes.orders.orderNew(),
          featureFlag: TenantFeature.OrdersCreateOrder,
          onSelect: () =>
            track(TrackEvent.Nav_CtaClick, {
              ctaName: CtaName.NewOrder,
              ctaType: CtaType.GlobalSearch,
            }),
        },
        {
          label: 'New Quote',
          href: routes.orders.quoteNew(),
          featureFlag: TenantFeature.OrdersCreateQuote,
          onSelect: () =>
            track(TrackEvent.Nav_CtaClick, {
              ctaName: CtaName.NewQuote,
              ctaType: CtaType.GlobalSearch,
            }),
        },
      ],
    },
    Tasks: {
      options: [
        ...navSubModulesBySlug.tasks,
        {
          label: 'New Task',
          href: routes.tasks.taskNew(),
          onSelect: () =>
            track(TrackEvent.Nav_CtaClick, {
              ctaName: CtaName.NewTask,
              ctaType: CtaType.GlobalSearch,
            }),
        },
      ],
    },
  };

  return Object.entries(navCategories)
    .filter(([_, conf]) => (conf.isEnabledForRole ? conf.isEnabledForRole(activeRole) : true))
    .map(([category, conf]) =>
      // add category as subtext and give all options compass icon
      conf.options
        .filter((option) =>
          option.featureFlag ? shouldShowFeatureFlag(activeTenant, activeUser, option.featureFlag) : true,
        )
        .map((option) => ({
          subtext: category,
          icon: React.createElement(CompassOutlined),
          ...option,
        })),
    )
    .flat();
}
