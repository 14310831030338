import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { SearchTasksRequest, TaskFilter, TaskType, UpdatePartialTaskRequest } from '@recurrency/core-api';
import { message, Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { TaskStatus } from 'pages/tasks/types';

import { Button } from 'components/Button';
import { ButtonLink } from 'components/Links';
import { CenteredError } from 'components/Loaders';

import { useApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { capitalize, formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { humanDateColumn, sortableDateColumn, sortableStringColumn } from 'utils/tables';

import { RECURRENCY_RED } from 'constants/styles';

import { RadioGroup } from './Radio';
import { Table } from './Table';

export const Tasks = () => {
  const { activeTenant } = useGlobalApp();

  const [showCompleted, setShowCompleted] = useState(false);
  const [showCreated, setShowCreated] = useState(false);

  const query: SearchTasksRequest = {
    tenantId: activeTenant.id,
    filter: showCreated ? TaskFilter.Created : TaskFilter.Assigned,
    status: showCompleted ? TaskStatus.Completed : TaskStatus.New,
    type: [TaskType.Personal, TaskType.Customer, TaskType.Item, TaskType.Reorder],
  };

  const { data, error, isLoading, reload } = useApi().tasks().searchTasks(query);

  const today = moment().format('YYYY-MM-DD');

  const columns: ColumnType<FIXME>[] = [
    sortableStringColumn({
      title: 'Task',
      dataIndex: 'title',
      render: (title: string, { id }: { id: string }) => <Link to={routes.tasks.taskDetails(id)}>{title}</Link>,
    }),
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: string) => capitalize(type),
      defaultFilteredValue: ['personal', 'customer', 'item', 'reorder'],
      filters: [
        { text: 'Personal', value: 'personal' },
        { text: 'Customer', value: 'customer' },
        { text: 'Item', value: 'item' },
        { text: 'Reorder', value: 'reorder' },
      ],
      onFilter: (value, task) => task.type === value,
    },
    sortableStringColumn({
      title: 'Customer',
      dataIndex: ['metadata', 'customer', 'foreignId'],
      render: (id: string, { metadata }: { metadata: { customer?: { name: string; foreignId: string } } }) => (
        <Link to={routes.sales.customerDetails(id || '')}>{id ? `${id}: ${metadata.customer?.name}` : null}</Link>
      ),
    }),
    humanDateColumn({
      title: 'Due',
      dataIndex: 'dueAt',
    }),
    sortableDateColumn({
      title: 'Deadline',
      dataIndex: 'dueAt',
      defaultSortOrder: 'descend',
      render: (date: string) => {
        const formatted = formatDate(date);

        if (moment(today).isSame(formatted)) {
          return <div style={{ color: RECURRENCY_RED }}>{formatted}</div>;
        }

        return formatted;
      },
    }),
    sortableDateColumn({
      title: 'Last Modified',
      dataIndex: 'updatedAt',
    }),
    {
      render: ({ id }) => ButtonLink(routes.tasks.taskDetails(id)),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys]: FIXME = useState([]);
  const [selectedRows, setSelectedRows]: FIXME = useState([]);

  const onSubmit = async () => {
    const statusString = selectedRows.length > 1 ? 'statuses' : 'status';
    try {
      const promises = [];
      for (const task of selectedRows) {
        const request: UpdatePartialTaskRequest = {
          status: task.status === TaskStatus.Completed ? TaskStatus.New : TaskStatus.Completed,
          tenantId: activeTenant.id,
        };
        const p = api().tasks().patchUpdateTask(task.id, request);
        promises.push(p);
      }
      await Promise.all(promises);

      message.success(`Task ${statusString} updated.`);
      setSelectedRows([]);
      setSelectedRowKeys([]);
      reload();
    } catch (err) {
      captureAndShowError(err, `updating task ${statusString}`);
    }
  };

  const onSelectChange = (selectedRowKeys: FIXME, selectedRows: FIXME) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const clearSelections = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <div>
      <Table
        title={showCompleted ? 'Completed Tasks' : 'Incomplete Tasks'}
        rowSelection={rowSelection}
        columns={columns}
        data={data?.items || []}
        rowKey="id"
        isLoading={isLoading}
      >
        <div
          style={{
            marginBottom: '1rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button type="primary" onClick={onSubmit} disabled={!hasSelected} style={{ marginBottom: '10px' }}>
              {showCompleted ? 'Mark Incomplete' : 'Complete Task'}
            </Button>
            <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
          </div>
          <div>
            <RadioGroup
              onChange={({ target: { value } }) => {
                setShowCreated(value === 'created');
                clearSelections();
              }}
              value={showCreated ? 'created' : 'assigned'}
              style={{ marginBottom: '10px' }}
            >
              <Radio.Button value="assigned">Assigned to Me</Radio.Button>
              <Radio.Button value="created">Created by Me</Radio.Button>
            </RadioGroup>{' '}
            <RadioGroup
              onChange={({ target: { value } }) => {
                setShowCompleted(value === 'completed');
                clearSelections();
              }}
              value={showCompleted ? 'completed' : 'incomplete'}
            >
              <Radio.Button value="incomplete">Incomplete</Radio.Button>
              <Radio.Button value="completed">Completed</Radio.Button>
            </RadioGroup>
          </div>
        </div>
      </Table>
    </div>
  );
};
