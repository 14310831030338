import React from 'react';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Availability } from 'components/ItemDetail/Tabs/Availability';
import { RecentSales } from 'components/ItemDetail/Tabs/RecentSales';
import { NavTabs } from 'components/NavTabs';

import { encodeLegacyApiParam } from 'utils/routes';
import { salesTableColumns } from 'utils/tables';

import { customerQuotesTableColumns } from './tableConfig';

export const SalesTable = ({ itemId, customerId }: { itemId: string | undefined; customerId: string | undefined }) => (
  <div style={{ marginTop: '2rem' }}>
    <NavTabs
      tabs={[
        {
          header: 'Availability by Location',
          content: <Availability invMastUid={itemId} />,
        },
        {
          header: 'Recent Sales of Item',
          content: <RecentSales invMastUid={itemId} />,
        },
        {
          header: 'Sales of Item to Customer',
          content: (
            <AsyncTable
              tableProps={useLegacyApiTableProps({
                apiPath: `/v3/items/${encodeLegacyApiParam(itemId || '')}/sales/${encodeLegacyApiParam(
                  customerId || '',
                )}/customer`,
              })}
              columns={salesTableColumns}
              rowKey={(row, index) => `${row?.orderNo}-${index}`}
              size="small"
            />
          ),
        },
        {
          header: 'Quotes of Item to Customer',
          content: (
            <AsyncTable
              tableProps={useLegacyApiTableProps({
                apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/item/${encodeLegacyApiParam(
                  itemId || '',
                )}/quotes`,
              })}
              columns={customerQuotesTableColumns}
              rowKey={(row, index) => `${row?.orderNo}-${index}`}
              size="small"
            />
          ),
        },
      ]}
    />
  </div>
);
