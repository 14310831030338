import React from 'react';

import { useHistory } from 'react-router-dom';

import { UpdateTenantRequest } from '@recurrency/core-api';
import { Form, message, PageHeader } from 'antd';

import { Button } from 'components/Button';
import { InputFormItem, defaultFormLayout, defaultFormTailLayout, CheckboxFormItem } from 'components/FormItems';
import { Container } from 'components/Layout/Container';
import { CenteredLoader, CenteredError } from 'components/Loaders';

import { useApi } from 'hooks/useApi';

import { api } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes, IdPathParams, usePathParams } from 'utils/routes';

import { UNITS } from 'constants/styles';

export const TenantEditPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const { data, isLoading, error } = useApi().tenants().getTenantById(id);
  // incorrect core-api return type
  const tenant = data as FIXME;
  const history = useHistory();
  const [form] = Form.useForm();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !tenant) {
    return <CenteredLoader />;
  }

  const onSubmit = async (data: UpdateTenantRequest) => {
    data.id = tenant.id;
    try {
      await api().tenants().updateTenant(tenant.id, data);
      message.success('Tenant updated.');
      history.push(routes.internal.tenantDetails(tenant.id));
    } catch (err) {
      captureAndShowError(err, `updating tenant`);
    }
  };

  return (
    <Form.Provider>
      <Container>
        <PageHeader title="Edit Tenant" onBack={() => history.goBack()} />
        <div
          style={{
            borderLeft: 'rgb(76, 130, 232) 4px solid',
            marginBottom: UNITS.XL,
            paddingLeft: UNITS.XL,
            marginLeft: '12rem',
            marginRight: '16rem',
          }}
        >
          <Form {...defaultFormLayout} form={form} onFinish={onSubmit} onError={console.error} initialValues={tenant}>
            <InputFormItem label="Name" name="name" rules={[{ required: true, message: 'Please add a name.' }]} />

            <InputFormItem
              label="Company Name"
              name="companyName"
              rules={[{ required: true, message: 'Please add a company name.' }]}
            />

            <InputFormItem
              label="Algolia Key (AIS)"
              name="ais"
              rules={[{ required: true, message: 'Please add an Algolia key.' }]}
            />

            <CheckboxFormItem label="Active?" name="isActive" />

            <Form.Item {...defaultFormTailLayout}>
              <Button id="submitTenantButton" type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    </Form.Provider>
  );
};
