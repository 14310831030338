import React from 'react';

import { Typography } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

import { formatUSD } from 'utils/formatting';

import { RECURRENCY_BLUE, RECURRENCY_DARK_VIOLET } from 'constants/styles';

const monthTicks = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const colorCode = [RECURRENCY_DARK_VIOLET, RECURRENCY_BLUE];

export const PerformanceChart = ({ title, years, data }: any) => (
  <div>
    <Typography.Title level={4} style={{ textAlign: 'center' }}>
      {title}
    </Typography.Title>
    <BarChart
      barCategoryGap={4}
      width={450}
      height={300}
      data={data}
      layout="horizontal"
      margin={{ top: 5, right: 10, bottom: 5, left: 30 }}
    >
      <XAxis type="category" dataKey="date" ticks={monthTicks} />
      <YAxis type="number" tickFormatter={formatUSD} />
      <Tooltip formatter={(value) => formatUSD(Number(value))} />
      {years.map((year: any, index: any) => (
        <Bar key={index} dataKey={year} fill={colorCode[index]} />
      ))}
    </BarChart>
  </div>
);
