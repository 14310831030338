import moment from 'moment';

import { RECURRENCY_RED, RECURRENCY_GREEN } from 'constants/styles';

import { IdNameObj } from 'types/legacy-api';

const ZERO_MARGIN = 0.03;

export const getPercentColor = (percent: string) => (parseFloat(percent) > 0 ? RECURRENCY_GREEN : RECURRENCY_RED);

function parseNumber(value: Maybe<number | string>) {
  const num = Number(value);
  if (value === '' || value === null || value === undefined || Number.isNaN(num)) {
    return NaN;
  }

  return num;
}

/**
 * format number with given decimal point
 * @param value string or number type value that can be represented as a number
 * @param decimal count of decimal point with 4 as a default value
 */

export const formatNumber = (value: Maybe<number | string>, maxFractionDigits = 2) => {
  let num = parseNumber(value);
  if (Number.isNaN(num)) {
    return '-';
  }

  // this avoids the -0 bug which shows for very small numbers
  if (maxFractionDigits === 2) {
    num = roundTo2Decimals(num) || 0;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  });

  return formatter.format(num);
};

export const roundTo2Decimals = (value: number): number => Math.round(value * 100) / 100;

/**
 * Takes a USD currency value and returns an abbreviated string ("compact notation")
 *
 * 3600 => "$3.6K"
 * 74757 => "$74.8K"
 * -1234567890 => "-$1.23B"
 */
export const formatUSDAbbreviated = (value: number) => {
  if (Number.isNaN(value)) {
    return '-';
  }

  // handle -0 case
  if (value === 0) value = 0;

  // TODO: only thing that uses this now assumes USD and en-US, probably want to be more agnostic, I assume we'll want to do other currencies at some point, as well....
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 1,
  });

  return formatter.format(Number(value));
};

export const formatUSD = (value: Maybe<number | string>, showCents = false) => {
  if (value === null || value === undefined || Number.isNaN(Number(value))) {
    return '-';
  }

  // handle -0 case
  if (value === 0) value = 0;

  const isValueNearZero = value !== 0 && value > -1 * ZERO_MARGIN && value < ZERO_MARGIN;

  const numDigits = showCents ? (isValueNearZero ? 4 : 2) : 0;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: numDigits,
    minimumFractionDigits: numDigits,
  });

  const formatted = formatter.format(Number(value)).toLocaleString();
  return formatted;
};

export const formatDate = (date: string | undefined, withTimestamp = false) => {
  if (!date || !moment(date).isValid()) {
    return '-';
  }

  return moment(date).format(withTimestamp ? 'YYYY-MM-DD h:mm:ss' : 'YYYY-MM-DD');
};

export const formatSecondsDate = (unixSeconds: number): string => formatDate(moment.unix(unixSeconds).format());

export const formatHumanDate = (dateString: string) => {
  const date = moment(dateString);
  if (date.isValid()) {
    const today = moment().startOf('day');
    if (date.startOf('day').isSame(today)) {
      return 'today';
    }
    return moment(date).fromNow();
  }
  return '-';
};

export const formatPercent = (percent: Maybe<number | string>, numDecimals = 2) => {
  const numPercent = Number(percent);
  if (percent === null || percent === undefined || Number.isNaN(numPercent)) {
    return '-';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: numDecimals,
  });

  const formatted = formatter.format(Number(numPercent)).toLocaleString();
  return formatted;
};

export const dashIfEmpty = (value: Maybe<string>, zeroValue?: string) => (value && value !== zeroValue ? value : '-');

export const formatAddress = (
  street: Maybe<string>,
  city: Maybe<string>,
  state: Maybe<string>,
  zip: Maybe<string | number>,
) => {
  if (!street || !city || !state || !zip) {
    return '-';
  }

  return `${street}, ${city}, ${state} ${zip}`;
};

export const joinTruthy = (values: Array<string | number | boolean | undefined | null>, joinStr: string): string =>
  values.filter((v) => !!v).join(joinStr);

export const formatPartialAddress = ({
  address1,
  address2,
  city,
  state,
  zip,
}: {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string | number;
}) => joinTruthy([address1, address2, city, joinTruthy([state, zip], ' ')], ', ');

export const joinAnd = (strs: string[]) => {
  if (strs.length <= 2) {
    return strs.join(' and ');
  }
  return [strs.slice(0, -1).join(', '), strs[strs.length - 1]].join(', and ');
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const pluralize = (count: number, singular: string, plural?: string, inclusive = false) =>
  (inclusive ? `${count} ` : '') + (count === 1 ? singular : plural || `${singular}s`);

/** prefixes string with 'a' or 'an' depending on whether str is vowel or not */
export const addArticle = (str: string) => `${/^[aeiou]/.test(str) ? 'an' : 'a'} ${str.toLowerCase()}`;

export const formatId = (id: Maybe<string>): string => id || '-';

export const splitIdNameStr = (idNameStr: string): IdNameObj => {
  // using regex for split so we only split on the first colon (with spaces on both sides trimmed)
  const [foreignId, name] = idNameStr.trim().split(/ *: +(.+)/);
  return { foreignId, name };
};

export const splitIfIdNameStr = (idNameStr: string | undefined): IdNameObj | undefined =>
  idNameStr ? splitIdNameStr(idNameStr) : undefined;

export const joinIdNameObj = (foreignIdNameRef: IdNameObj) =>
  `${foreignIdNameRef.foreignId}: ${(foreignIdNameRef.name || '').trim()}`;

export const joinIfIdNameObj = (foreignIdNameRef: IdNameObj | undefined): string | undefined =>
  foreignIdNameRef ? joinIdNameObj(foreignIdNameRef) : undefined;

export const formatIdNameObj = (foreignIdNameRef: IdNameObj | undefined) =>
  foreignIdNameRef ? joinIdNameObj(foreignIdNameRef) : '-';

export const isStringEqual = (a: string | number, b: string | number) => `${a}` === `${b}`;

/** convert to 'Title Case' e.g 'hello world' -> 'Hello World' */
export const toTitleCase = (str: string) => str.split(' ').map(capitalize).join(' ');

/** Convert to 'dash-case' e.g 'Availability & Cost' -> 'availability-cost' */
export const toDashCase = (str: string) => str.toLowerCase().replace(/[^a-z0-9]+/g, '-');

/** Convert to 'snake_case' e.g 'Availability & Cost' -> 'availability_cost' */
export const toSnakeCase = (str: string) => str.toLowerCase().replace(/[^a-z0-9]+/g, '_');

/** e.g 'sales-dashboard' to 'Sales Dashboard' */
export const dashCaseToTitleCase = (str: string) => toTitleCase(str.replace('-', ' '));
