export enum TrackEvent {
  App_VersionPoll = 'App.VersionPoll',
  Api_Error = 'Api.Error',
  Nav_PageLoad = 'Nav.PageLoad',
  Nav_HistoryChange = 'Nav.HistoryChange',
  Nav_TabChange = 'Nav.TabChange',
  Nav_GlobalSearch_Focus = 'Nav.GlobalSearch.Focus',
  Nav_GlobalSearch_OptionSelect = 'Nav.GlobalSearch.OptionSelect',
  Nav_GlobalSearch_Abandon = 'Nav.GlobalSearch.Abandon',
  Nav_CtaClick = 'Nav.CtaClick',
  Nav_ReferClick = 'Nav.ReferClick',
  Components_AlgoliaConnectedFormItem_LoadMore = 'Components.AlgoliaConnectedFormItem.LoadMore',
  Components_Loaders_ShowError = 'Components.Loaders.ShowError',
  Quotes_EditQuote_StepChange = 'Quotes.EditQuote.StepChange',
  Quotes_EditQuote_SaveDraft = 'Quotes.EditQuote.SaveDraft',
  Quotes_EditQuote_Submit = 'Quotes.EditQuote.Submit',
  Quotes_EditQuote_ReplacePriceClick = 'Quotes.EditQuote.ReplacePriceClick',
  Quotes_EditQuote_QuantityClick = 'Quotes.EditQuote.QuantityClick',
  Quotes_EditQuote_AddRecommendedItem = 'Quotes.EditQuote.AddRecommendedItem',
  Quotes_EditQuote_LineItemLinkClick = 'Quotes.EditQuote.LineItemLinkClick',
  Orders_EditOrder_Submit = 'Orders.EditOrder.Submit',
  Orders_ConvertQuoteToOrder = 'Orders.ConvertQuoteToOrder',
  Customers_NewProspect_Submit = 'Customers.NewProspect.Submit',
  Customers_NewProspect_StepChange = 'Customers.NewProspect.StepChange',
  Components_SearchFrameQuery = 'Components.SearchFrameQuery',
}

export enum CtaName {
  NewQuote = 'newQuote',
  NewTask = 'newTask',
  NewOrder = 'newOrder',
  NewProspect = 'newProspect',
}

export enum CtaType {
  Button = 'Button',
  GlobalSearch = 'GlobalSearch',
  PrimaryNavNewRecord = 'PrimaryNavNewRecord',
}

export enum OrderType {
  Quote = 'quote',
  Order = 'order',
}

export enum ReplacePriceClickSource {
  Recommended = 'recommended',
  Last = 'last',
  Min = 'min',
  Max = 'max',
  Fixed = 'fixed',
}

export enum ReplaceQuantityClickSource {
  AvailableAtLocation = 'availableAtLocation',
  Last = 'last',
}

export enum RecommendedItemType {
  Reorders = 'reorders',
  OrderedOnce = 'orderedOnce',
  New = 'new',
}

export interface EventProps {
  /**
   * When the AppVersionPoller polls for a version update.
   * NOTE: This happens every ~5m, so it's a good indicator of users who have recurrency open in a tab
   */
  [TrackEvent.App_VersionPoll]: {
    version: string;
    isLatestVersion: boolean;
  };

  /**
   * When an api call errors or is too slow (>5s)
   */
  [TrackEvent.Api_Error]: {
    reqMethod: string;
    reqUrl: string;
    resStatusCode: string;
    resDurationMs: number;
    resErrorMessage: string;
  };

  /** When initial page loads  */
  [TrackEvent.Nav_PageLoad]: {
    windowUserAgent: string;
    windowInnerWidth: number;
    windowInnerHeight: number;
    timeMsToIndexHtmlLoad?: number;
    timeMsToAppCssLoad?: number;
    timeMsToAppJsLoad?: number;
    timeMsToDomContentLoad?: number;
    timeMsToAppInit?: number;
    timeMsToNavShellRender?: number;
    sizeKbCompressedAppJs?: number;
    sizeKbCompressedAppCss?: number;
  };

  /**
   * When the url is changed via react-router in-app navigation.
   */
  [TrackEvent.Nav_HistoryChange]: {
    /** prevPath is undefined on initial load */
    prevPath: string | undefined;
  };

  /**
   * When ?tab=x is changed either via history or tab click of NavTabs component
   */
  [TrackEvent.Nav_TabChange]: {
    tab: string;
  };

  /**
   * When user focuses on global search input either via keyboard or mouse.
   */
  [TrackEvent.Nav_GlobalSearch_Focus]: {
    interactType: 'KeyboardShortcut' | 'Mouse';
  };

  /**
   * When user clicks or keyboard selects an search result.
   */
  [TrackEvent.Nav_GlobalSearch_OptionSelect]: {
    searchQuery: string;
    searchQueryLength: number;
    categoryLabel: string;
    optionLabel: string;
    optionHref: string;
    numOptions: number;
  };

  /**
   * When user starts a search but does not select an option
   */
  [TrackEvent.Nav_GlobalSearch_Abandon]: {
    searchQuery: string;
    searchQueryLength: number;
    numOptions: number;
  };

  /** When user clicks a CTA */
  [TrackEvent.Nav_CtaClick]: {
    ctaName: CtaName;
    ctaType: CtaType;
  };

  /** When user clicks the "Refer" button */
  [TrackEvent.Nav_ReferClick]: Obj<never>;

  /**
   * When user clicks 'Load More' on AlgoliaConnectedFormItem.
   * This event helps us evaluate whether this is discoverable UX.
   */
  [TrackEvent.Components_AlgoliaConnectedFormItem_LoadMore]: {
    searchQuery: string;
    searchQueryLength: number;
    /** number of results returned from algolia */
    numResults: number;
  };

  /**
   * When CenteredError is shown due to an uncaught error that bubbles up while rendering,
   * Or when an api call fails.
   */
  [TrackEvent.Components_Loaders_ShowError]: {
    errorName: string;
    errorMessage: string;
    errorStack: string;
    componentStack?: string;
    sentryUrl?: string;
  };

  /** When user continues to another step in quote flow */
  [TrackEvent.Quotes_EditQuote_StepChange]: {
    step: string;
    orderType: OrderType;
  };

  /** When user saves a quote as draft */
  [TrackEvent.Quotes_EditQuote_SaveDraft]: {
    numLineItems: number;
    totalPrice: number;
    totalGrossMargin: number;
    avgGrossMarginPercentage: number;
    step: string;
    /** undefined means new draft, string id means an existing saved draft */
    quoteId: string | undefined;
  };

  /** When user submits a quote */
  [TrackEvent.Quotes_EditQuote_Submit]: {
    numLineItems: number;
    totalPrice: number;
    totalGrossMargin: number;
    avgGrossMarginPercentage: number;
    quoteId: string | undefined;
  };

  /** When user submits an order */
  [TrackEvent.Orders_EditOrder_Submit]: {
    numLineItems: number;
    totalPrice: number;
    totalGrossMargin: number;
    avgGrossMarginPercentage: number;
  };

  /** When user clicks a replace price button */
  [TrackEvent.Quotes_EditQuote_ReplacePriceClick]: {
    priceType: ReplacePriceClickSource;
    orderType: OrderType;
  };

  /** When user clicks a replace quantity button */
  [TrackEvent.Quotes_EditQuote_QuantityClick]: {
    quantityType: ReplaceQuantityClickSource;
    orderType: OrderType;
  };

  /** When user clicks to add a recommended item from the sidebar */
  [TrackEvent.Quotes_EditQuote_AddRecommendedItem]: {
    recommendedItemType: RecommendedItemType;
    orderType: OrderType;
  };

  /** When user clicks on link icon besides item selector in LineItems step */
  [TrackEvent.Quotes_EditQuote_LineItemLinkClick]: Obj<never>;

  /** When user submits a new prospect  */
  [TrackEvent.Customers_NewProspect_Submit]: Obj<never>;

  /** When user continues to another step in prospect flow */
  [TrackEvent.Customers_NewProspect_StepChange]: {
    step: string;
  };

  /** When user converts quote to order */
  [TrackEvent.Orders_ConvertQuoteToOrder]: Obj<never>;

  [TrackEvent.Components_SearchFrameQuery]: {
    indexName: string;
    searchQuery: string;
    searchQueryLength: number;
    facetValueFilterKeys: string[]; // name of filtered keys only e.g 'salesrep'
    facetNumericFilterKeys: string[]; // name of filtered values only e.g 'gm'
    sortByField?: string; // e.g company_stock
    sortByDir?: string; // asc | desc
  };
}

interface TrackSuperProps {
  userId: string;
  userName: string;
  userEmail: string;
  userRole: string;
  tenantId: string;
  tenantName: string;

  /** current window.location.pathname when event was fired */
  curPath: string;
  /** matching route path e.g '/sales/customers/details/:id'.
   * undefined if no matching route */
  curRoutePath: string | undefined;
}

export const trackSuperProps: Partial<TrackSuperProps> = {};
export function setSuperProps(props: Partial<TrackSuperProps>) {
  Object.assign(trackSuperProps, props);
}

export function identify({
  id,
  name,
  email,
  role,
  tenantId,
  tenantName,
}: {
  id: string;
  name: string;
  email: string;
  role: string;
  tenantId: string;
  tenantName: string;
}) {
  setSuperProps({
    userId: id,
    userName: name,
    userEmail: email,
    userRole: role,
    tenantId,
    tenantName,
  });

  if (window.analytics) {
    window.analytics.identify(id, {
      id,
      name,
      email,
      tenantId,
      tenantName,
    });
  }

  if (window.FS) {
    window.FS.identify(id, {
      displayName: name,
      email,
      tenantId,
    });
  }

  if (window.Cohere) {
    window.Cohere.identify(id, {
      displayName: name,
      email,
    });
  }

  if (window.zeIdentify) {
    window.zeIdentify({
      name,
      email,
    });
  }
}

export function track<EventNameT extends TrackEvent>(eventName: EventNameT, eventProps: EventProps[EventNameT]) {
  const mergedEventProps: Obj = { ...trackSuperProps, ...eventProps };
  // console.log('track', eventName, mergedEventProps); // uncomment to enable track logging
  try {
    // some adblockers set window.analytics as null, so we guard with .track as a function
    if (window.analytics && typeof window.analytics.track === 'function') {
      if (window.FS?.getCurrentSessionURL) {
        // see https://developer.fullstory.com/current-session-url
        mergedEventProps.fullStoryUrl = window.FS.getCurrentSessionURL(/* withTimestamp */ true);
      }
      window.analytics.track(eventName, mergedEventProps);
    }

    if (window.FS && typeof window.FS.event === 'function') {
      window.FS.event(eventName, eventProps);
    }
  } catch (err) {
    // tracking shouldn't ever fail, but incase it does due to unknown reason
    // don't break UI, gracefully log to console and move on
    console.error('track.error', err);
  }
}
