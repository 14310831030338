import React from 'react';

import { Col, Row } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { getTotalPrice, getNumLineItems } from 'pages/orders/quotes/quoteUtils';

import { Typography } from 'components/Typography';

import { truthy as isTruthy } from 'utils/boolean';
import { formatDate, formatUSD } from 'utils/formatting';
import { sortableStringColumn, sortableNumberColumn, sortableDollarWithCentsColumn } from 'utils/tables';
import { OrderType } from 'utils/track';

import { QuoteEditHashState, QuoteLineItem } from 'types/hash-state';

import * as Styled from './QuoteReview.style';

export const QuoteReview = ({ quoteState, orderType }: { quoteState: QuoteEditHashState; orderType: OrderType }) => {
  const lineItems = quoteState.items || [];
  const lineItemColumns: ColumnType<QuoteLineItem>[] = [
    sortableStringColumn({
      title: 'Item ID',
      dataIndex: 'foreignId',
    }),
    sortableStringColumn({ title: 'Item', dataIndex: 'name' }),
    sortableNumberColumn({ title: 'Quantity', dataIndex: 'quantity' }),
    sortableStringColumn({ title: 'UOM', dataIndex: 'unitOfMeasure' }),
    sortableDollarWithCentsColumn({ title: 'Price', dataIndex: 'price' }),
    {
      title: 'Total Price',
      render: (_, lineItem) => formatUSD((lineItem.price || 0) * (lineItem.quantity || 0), true),
      align: 'right' as const,
    },
  ];

  const headerRows = [
    {
      title: 'Company',
      content: quoteState.company,
    },
    {
      title: 'Customer',
      content: quoteState.customer,
    },
    {
      title: 'Ship To',
      content: quoteState.shipTo,
    },
    {
      title: 'Contact',
      content: quoteState.contact,
    },
    {
      title: 'Required Date',
      content: formatDate(quoteState.requiredDate),
    },
    orderType === OrderType.Quote
      ? {
          title: 'Valid Until Date',
          content: formatDate(quoteState.validUntilDate),
        }
      : null,
    {
      title: 'Location',
      content: quoteState.location,
    },
    {
      title: 'Freight',
      content: quoteState.freightType,
    },
    {
      title: 'Carrier',
      content: quoteState.carrier,
    },
    {
      title: 'PO No',
      content: quoteState.poNo,
    },
    {
      title: 'Comments',
      content: quoteState.comments,
    },
  ].filter(isTruthy);

  return (
    <Styled.Container>
      <Row gutter={24}>
        <Col xs={24} lg={18}>
          <Styled.Content>
            <Styled.Header>
              <Typography>Items ({getNumLineItems(lineItems)})</Typography>
            </Styled.Header>
            <Styled.ItemTable data={lineItems} columns={lineItemColumns} rowKey="id" pagination={false} size="small" />
          </Styled.Content>
          <Styled.Footer>
            <Styled.FooterContent>
              <Typography type="middle">Total Lines: {getNumLineItems(lineItems)}</Typography>
              <Typography type="middle">Subtotal: {formatUSD(getTotalPrice(lineItems), true)}</Typography>
            </Styled.FooterContent>
          </Styled.Footer>
        </Col>
        <Col xs={24} lg={6}>
          <Styled.Box>
            {headerRows.map((headerRow, idx) => (
              <Styled.HeaderRow key={idx}>
                <Styled.HeaderTitle>
                  <Typography type="subtitle">{headerRow.title}:</Typography>
                </Styled.HeaderTitle>
                <div>{headerRow.content}</div>
              </Styled.HeaderRow>
            ))}
          </Styled.Box>
          <br />
        </Col>
      </Row>
    </Styled.Container>
  );
};
