import { Space } from "antd";
import styled from "styled-components";
import { Table } from "components/Table";
import { Typography } from "components/Typography";
export const Container = styled.div`
  width: 100%;
  height: 100%;

  margin: 16px 0;

  .ant-collapse-content-box {
    padding: 8px;
  }
`;
export const ItemTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;

  .ant-table.ant-table-small .ant-table-cell {
    padding: 8px 4px;
  }

  .ant-table {
    td.ant-table-cell {
      vertical-align: top;
      .ant-select,
      .ant-input {
        width: 100%;
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
      }
    }
  }
`;
export const Header = styled.div`
  padding-bottom: 16px;
`;
export const Subheader = styled.div``;
export const Footer = styled(Space)`
  position: sticky;
  display: block;
  top: 20px;
`;
export const ButtonGroup = styled(Space)`
  width: 100%;
  display: flex;
  .ant-space-item {
    width: 100%;
  }
`;
export const Box = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  padding: 12px;
`;
export const RecBox = styled.div`
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const RecBody = styled.div`
  width: 100%;
`;
export const RecTitle = styled.div`
  padding-left: 8px;
`;
export const RecId = styled.span`
  font-weight: bold;
`;
export const RecSubtitle = styled.div`
  opacity: 0.8;
  font-size: 0.8em;
  padding-left: 8px;
  font-weight: bold;
`;
export const Error = styled(Typography).attrs({
  type: "subtitle"
})`
  color: ${(props) => props.theme.colors.red};
  margin-bottom: 8px;
`;
export const RecItemsHeaderTitle = styled(Typography).attrs({
  type: "subtitle",
  weight: "bold"
})`
  display: inline-block;
`;
