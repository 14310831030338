// Transition
export function transition(timing = 0.1) {
  return `
      -webkit-transition: all ${timing}s;
      -moz-transition: all ${timing}s;
      -ms-transition: all ${timing}s;
      -o-transition: all ${timing}s;
      transition: all ${timing}s;
  `;
}

// Border Radius
export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

// Box Shadow
export function boxShadow(shadow = 'none') {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `;
}
