import React from 'react';

import { Row, Col } from 'antd';

import { SearchInput } from 'components/Search/SearchInput';
import { Tag } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';
import { PageHeading } from 'components/Typography/Typography';

import { formatUSD } from 'utils/formatting';

import { UNITS } from 'constants/styles';

import * as Styled from './TopPanel.style';

export interface AppliedFilters {
  valueFilters: Array<{ title: string; values: string[]; onRemove: () => void }>;
  numericFilters: Array<{ title: string; min: number; max: number; onRemove: () => void }>;
}

export interface TopPanelProps {
  title: string | React.ReactNode;
  queryPlaceholder: string;
  appliedFilters: AppliedFilters;
  ctaChild?: React.ReactNode;
  query?: string;
  onQueryChange?: (query: string) => void;
}

export const TopPanel = ({
  title,
  queryPlaceholder,
  ctaChild,
  query,
  onQueryChange,
  appliedFilters,
}: TopPanelProps) => (
  <div style={{ marginTop: UNITS.XL, marginBottom: UNITS.XL }}>
    <Row>
      <Col xs={24} sm={24} md={6}>
        <Styled.HeaderWrapper>
          <PageHeading>{title}</PageHeading>
        </Styled.HeaderWrapper>
      </Col>
      <Col xs={24} sm={24} md={18}>
        <Styled.ToolWrapper>
          <SearchInput placeholder={queryPlaceholder} query={query} onQueryChange={onQueryChange} />
          <Styled.ToolActionWrapper>{ctaChild}</Styled.ToolActionWrapper>
        </Styled.ToolWrapper>

        {(appliedFilters.valueFilters.length > 0 || appliedFilters.numericFilters.length > 0) && (
          <Styled.AppliedFilters>
            <span style={{ marginRight: '10px' }}>Applied Filters:</span>
            <span>
              {appliedFilters.valueFilters.map((filter) => (
                <Tooltip key={filter.title} title={filter.values.map((v) => `'${v}'`).join(' or ')}>
                  <Tag closable visible onClose={filter.onRemove}>
                    {filter.title} ({filter.values[0]}
                    {filter.values.length > 1 ? `, ${filter.values.length - 1} more...` : ''})
                  </Tag>
                </Tooltip>
              ))}
              {appliedFilters.numericFilters.map((filter) => (
                <Tooltip key={filter.title} title={`between ${formatUSD(filter.min)} and ${formatUSD(filter.max)}`}>
                  <Tag closable visible onClose={filter.onRemove}>
                    {filter.title}
                  </Tag>
                </Tooltip>
              ))}
            </span>
          </Styled.AppliedFilters>
        )}
      </Col>
    </Row>
  </div>
);
