import React, { useState, useEffect } from 'react';

import { CloseCircleOutlined, PercentageOutlined, LinkOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

import { Button } from 'components/Button';
import { EntityDescriptions } from 'components/Descriptions';
import { AlgoliaSelectFormItem, InputFormItem, SelectFormItem } from 'components/FormItems';

import { useLegacyApi } from 'hooks/useApi';

import { IndexName } from 'utils/algolia';
import { formatDate, formatNumber } from 'utils/formatting';
import { routes } from 'utils/routes';
import { validatePrice, validateGM } from 'utils/validation';

import { UNITS } from 'constants/styles';

import { AlgoliaInventoryItem } from 'types/algolia-collections';

const ItemInfo = ({
  vendorId,
  locationId,
  item,
  price,
  setUOMs,
  setPriceHelp,
  setCostAll,
  priceFieldName,
  uomFieldName,
  qtyFieldname,
  setFieldsValue,
  setPrice,
  setGm,
}: {
  vendorId: FIXME;
  locationId: FIXME;
  item: FIXME;
  price: FIXME;
  setUOMs: FIXME;
  setPriceHelp: FIXME;
  setCostAll: FIXME;
  priceFieldName: FIXME;
  uomFieldName: FIXME;
  qtyFieldname: FIXME;
  setFieldsValue: FIXME;
  setPrice: FIXME;
  setGm: FIXME;
}) => {
  // TODO: we need data here for vendors
  const { data: lineData } = useLegacyApi('/prices/line', {
    vendor: `${vendorId}`,
    item: item.backendKey,
    location: locationId,
  });

  const { lastDate, lastQty, last, suggested, qtyOnHandAll, cost: costAll, uom, uoms } = lineData || {};

  useEffect(() => {
    if (suggested) {
      const suggestedPrice = formatNumber(suggested);
      setFieldsValue({
        [priceFieldName]: Number(suggestedPrice),
        [qtyFieldname]: 1,
      });
      setPrice(suggestedPrice);
    }
  }, [suggested, setPrice, priceFieldName, setFieldsValue, qtyFieldname]);

  useEffect(() => {
    if (costAll) {
      setCostAll(costAll);
    }
  }, [costAll, setCostAll]);

  useEffect(() => {
    if (suggested && costAll) {
      const nextGm = validateGM(((suggested - costAll) / suggested) * 100);
      if (nextGm) {
        setGm(formatNumber(nextGm));
      }
    }
  }, [setGm, suggested, costAll]);

  useEffect(() => {
    if (price < costAll) {
      setPriceHelp({ help: 'This price may be too low', validateStatus: 'warning' });
    } else {
      setPriceHelp({});
    }
  }, [price, costAll, setPriceHelp]);

  useEffect(() => {
    if (uom) {
      setFieldsValue({
        [uomFieldName]: uom,
      });
      setUOMs(uoms.map((u: FIXME) => ({ key: u?.unitOfMeasure, value: u?.unitOfMeasure })));
    }
    // we should not check if `uoms` changes because if we do, we will get into an infinite loop
    // eslint-disable-next-line
  }, [uom, setFieldsValue, setUOMs, uomFieldName]);

  const descriptions = [
    {
      label: 'Last Sold',
      render: formatDate(lastDate),
    },
    {
      label: 'Last Quantity',
      render: lastQty,
    },
    {
      label: 'Last Price',
      render: last ? `$${last}` : '',
    },
    {
      label: 'Quantity Available',
      render: qtyOnHandAll,
    },
    {
      label: 'Recommended Price',
      render: suggested ? `$${formatNumber(suggested)} per ${uom}` : '',
    },
  ];

  return <EntityDescriptions descriptions={descriptions} style={{ paddingLeft: UNITS.XL }} />;
};

export const LineItem = ({
  vendorId,
  locationId,
  onDelete,
  index,
  form,
  lineItems,
  lineItem,
  setLineItems,
}: {
  vendorId: FIXME;
  locationId: FIXME;
  onDelete: FIXME;
  index: FIXME;
  form: FIXME;
  lineItems: FIXME;
  lineItem: FIXME;
  setLineItems: FIXME;
}) => {
  const [item, setItem] = useState<FIXME>(lineItem);
  const [price, setPrice] = useState<FIXME>();
  const [costAll, setCostAll] = useState<FIXME>();
  const [priceHelp, setPriceHelp] = useState({});
  const [gm, setGm] = useState<FIXME>();
  const [UOMs, setUOMs] = useState([]);

  return (
    <div
      style={{
        borderLeft: 'rgb(76, 130, 232) 4px solid',
        marginBottom: UNITS.XL,
        paddingLeft: UNITS.XL,
        position: 'relative',
      }}
    >
      {item?.key && (
        <a href={routes.purchasing.itemDetails(item.key)} target="_blank" rel="noopener noreferrer">
          <Button style={{ position: 'absolute', left: -UNITS.XL, top: '0px' }}>
            <LinkOutlined />
          </Button>
        </a>
      )}
      {onDelete && (
        <Button onClick={onDelete} style={{ position: 'absolute', left: -UNITS.XL, top: '44px' }}>
          <CloseCircleOutlined />
        </Button>
      )}
      <Row gutter={24}>
        <Col span={16}>
          <AlgoliaSelectFormItem<AlgoliaInventoryItem>
            label="Item"
            name={`item_${index}`}
            indexName={IndexName.Items}
            initialValue={item?.value}
            mapHitFn={(hit) => ({
              key: hit.item_id,
              value: `${hit.item_id}: ${hit.item_desc}${hit.extended_desc ? ' - ' : ''}${hit.extended_desc ?? ''}`,
              backendKey: hit.inv_mast_uid,
            })}
            rules={[{ required: true, message: 'Please add an item.' }]}
            setFieldsValue={form.setFieldsValue}
            postValueChange={(value: FIXME, option: FIXME) => {
              const isEmpty = value === '';

              if (isEmpty) {
                setItem(null);
                return;
              }

              if (option) {
                setItem(option);
                setLineItems(
                  lineItems.map((lineItem: FIXME, mapIndex: FIXME) => (mapIndex === index ? option : lineItem)),
                );
              }
            }}
          />
        </Col>
        <Col span={8}>
          <SelectFormItem
            label="UOM"
            name={`uom_${index}`}
            options={UOMs}
            disabled={!item}
            rules={[{ required: true, message: 'Please add a unit of measurement.' }]}
            setFieldsValue={form.setFieldsValue}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <InputFormItem
            label="Quantity"
            type="number"
            disabled={!item}
            name={`quantity_${index}`}
            getValueFromEvent={(event) => {
              const qty = Math.floor(Number(event.target.value));
              return qty;
            }}
            rules={[
              {
                required: Boolean(item),
                type: 'number',
                min: 1,
                message: 'Please add a positive quantity.',
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <InputFormItem
            label="Price"
            type="number"
            disabled={!item}
            {...priceHelp}
            name={`price_${index}`}
            getValueFromEvent={(event) => validatePrice(event.target.value)}
            rules={[{ required: Boolean(item), type: 'number', message: 'Please add a positive price.' }]}
            prefix="$"
            postValueChange={(event) => {
              const nextPrice: FIXME = validatePrice(event.target.value);
              const profit = Number(nextPrice) - Number(costAll);
              const newGM: FIXME = validateGM((profit / Number(nextPrice)) * 100);

              setPrice(nextPrice);
              if (newGM) {
                setGm(formatNumber(newGM));
              }
            }}
          />
        </Col>
        <Col span={8}>
          <InputFormItem
            label="GM Calulator"
            type="number"
            value={gm}
            disabled={!costAll || !price}
            getValueFromEvent={(event) => validateGM(event.target.value)}
            suffix={<PercentageOutlined />}
            rules={[{ required: Boolean(item), type: 'number', message: 'Please add a positive price.' }]}
            postValueChange={(event) => {
              const newGM: FIXME = validateGM(event.target.value);

              const denom = 1 - newGM / 100;
              const nextPrice = formatNumber(costAll / denom);
              const validatedPrice = validatePrice(nextPrice);

              setPrice(validatedPrice);
              setGm(newGM);

              form.setFieldsValue({
                [`price_${index}`]: validatedPrice,
              });
            }}
          />
        </Col>
        {item && item.backendKey && (
          <ItemInfo
            item={item}
            vendorId={vendorId}
            locationId={locationId}
            price={price}
            setUOMs={setUOMs}
            setGm={setGm}
            setFieldsValue={form.setFieldsValue}
            setPriceHelp={setPriceHelp}
            setPrice={setPrice}
            setCostAll={setCostAll}
            priceFieldName={`price_${index}`}
            uomFieldName={`uom_${index}`}
            qtyFieldname={`quantity_${index}`}
          />
        )}
      </Row>
    </div>
  );
};
