import { Layout as AntLayout } from "antd";
import styled from "styled-components";
export const AppContainer = styled(AntLayout)``;
export const AppLayout = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  background: white;
`;
export const ContentLayout = styled(AntLayout)`
  position: relative;
  background-color: white;
  margin-top: ${(props) => props.$isDesktopView ? "0px" : "80px"};
  ${(props) => props.theme.transition(0.05)};
  margin-bottom: 20px;
`;
