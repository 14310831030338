import React, { useEffect, useMemo, useRef } from 'react';

import { InputProps } from 'antd/lib/input';

import { SkeletonInput } from 'components/Loader';

import * as Styled from './Input.style';

export interface Props extends InputProps {
  title?: string;
  isLoading?: boolean;
  validate?: (value?: string) => boolean;
  autoFocus?: boolean;
}

export const Input = (props: Props): JSX.Element => {
  const { title, isLoading = false, validate = () => true, disabled, value, autoFocus = false, ...otherProps } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      // @ts-expect-error (converted from ts-ignore)
      setTimeout(() => inputRef.current.focus(), 1);
    }
  }, [autoFocus, inputRef]);

  const hasError: boolean = useMemo(() => {
    if (disabled) return false;

    return !validate(value as string | undefined);
  }, [disabled, validate, value]);

  if (isLoading) return <SkeletonInput active sizetype={otherProps?.size ?? 'middle'} />;

  if (title) {
    return (
      <Styled.Wrapper>
        <Styled.Typography type="subtitle">{title}</Styled.Typography>
        <Styled.Input ref={inputRef} disabled={disabled} value={value} hasError={hasError} {...otherProps} />
      </Styled.Wrapper>
    );
  }
  return (
    <Styled.Input
      ref={inputRef}
      value={value}
      // NOTE: ant input has error: string, and React dev-mode complains about it
      hasError={hasError}
      disabled={disabled}
      {...otherProps}
    />
  );
};
