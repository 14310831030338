import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Quote as CoreApiQuote } from '@recurrency/core-api';
import { Divider, Form, message, Space, Steps } from 'antd';
import produce from 'immer';
import { useDebounce } from 'use-debounce/lib';

import { isAdmin as getIsAdmin } from 'contexts/Auth0Context';

import {
  hashStateFromQuote,
  quoteRequestFromHashState,
  LineItemsInfoCache,
  getItemPriceInfo,
  LineItemInfoCacheValue,
  orderRequestFromHashState,
  getLineItemTrackProps,
  QuoteLineItemWithInfo,
  validatePrice,
  validateQuantity,
  validateName,
  warnQuantity,
  warnPrice,
  getItemPriceInfoMulti,
  getItemContract,
} from 'pages/orders/quotes/quoteUtils';
import { QuoteStatusType } from 'pages/orders/quotes/types';

import { Button } from 'components/Button';
import { CenteredLoader } from 'components/Loaders';
import { SplitPage } from 'components/SplitPage';
import { Tag } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { throwIfApiError, useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { getAlgoliaItemByItemId } from 'utils/algolia';
import { api } from 'utils/api';
import { captureAndShowError, captureError } from 'utils/error';
import { capitalize, joinIdNameObj, joinIfIdNameObj, splitIfIdNameStr } from 'utils/formatting';
import { isEqual } from 'utils/object';
import { IdPathParams, routePaths, routes, useHashState, usePathParams } from 'utils/routes';
import { isTenantCWC, isTenantSafeware } from 'utils/tenants';
import { OrderType, track, TrackEvent, trackSuperProps } from 'utils/track';

import { RECURRENCY_RED, UNITS } from 'constants/styles';

import { AlgoliaInventoryItemPartial } from 'types/algolia-collections';
import { QuoteEditHashState, QuoteEditStep, QuoteLineItem } from 'types/hash-state';
import { LineItemRequest, QuoteLineItemInfo, SalesRepLocationResponse } from 'types/legacy-api';

import * as Styled from './QuoteEditPage.style';
import { QuoteHeader } from './QuoteHeader';
import { QuoteLineItemRecommendations } from './QuoteLineItemRecommendations';
import { QuoteLineItems } from './QuoteLineItems';
import { QuoteReview } from './QuoteReview';

export const QuoteEditPage = () => {
  const history = useHistory();
  const { activeTenant, activeRole, searchClient } = useGlobalApp();
  const { foreignId, name } = activeRole;
  const isAdmin = getIsAdmin(foreignId, name);
  const tenantId = activeTenant?.id;

  const { id: quoteId } = usePathParams<IdPathParams>();
  const [quoteState, updateQuoteState] = useHashState<QuoteEditHashState>();
  // useDebounce rate-limits the calls to multiline when initializing a quote with a lot of line items
  // as the line items regularly update themselves which would cause another call to the endpoint on each update otherwise.
  const [debouncedQuoteState] = useDebounce(quoteState, 75);
  const { step: currentStep = QuoteEditStep.Header } = quoteState;
  const isOrderType = trackSuperProps.curRoutePath === routePaths.orders.orderNew;
  const orderType = isOrderType ? OrderType.Order : OrderType.Quote;
  const [headerForm] = Form.useForm<QuoteEditHashState>();
  const [validationErrorMsg, setValidationErrorMsg] = useState('');

  const companyId = splitIfIdNameStr(quoteState.company)?.foreignId;
  const customerId = splitIfIdNameStr(quoteState.customer)?.foreignId;
  const locationId = splitIfIdNameStr(quoteState.location)?.foreignId;
  const shipToId = splitIfIdNameStr(quoteState.shipTo)?.foreignId;
  const lineItems = useMemo(() => quoteState.items || [], [quoteState.items]);

  const {
    data: quoteData,
    setData: quoteSetData,
    isLoading: quoteDataIsLoading,
  } = usePromise(async () => {
    if (quoteId) {
      const quoteResponse = await api().quotes().getQuoteById(quoteId);
      // copy quoteData into hash state, but don't override existing state so user can refresh page to get back to same state
      // we do this inside a usePromise so quoteData and quoteState are in sync when quote loads
      updateQuoteState({ ...hashStateFromQuote(quoteResponse.data), ...quoteState });
      return quoteResponse.data;
    }
    return null;
  }, [quoteId]);

  useEffect(() => {
    track(TrackEvent.Quotes_EditQuote_StepChange, { step: QuoteEditStep[currentStep], orderType });
  }, [currentStep, orderType]);

  const { data: salesRepLocationData } = throwIfApiError(useLegacyApi<SalesRepLocationResponse>('/v3/salesrep'));

  // set default location and taker based on salesRepLocationData
  useEffect(() => {
    if (salesRepLocationData && !quoteDataIsLoading) {
      const quoteStateUpdate: QuoteEditHashState = {};
      if (!quoteState.taker) {
        quoteStateUpdate.taker = salesRepLocationData.taker;
      }

      if (!quoteState.location) {
        // Setting default based on user location
        if (salesRepLocationData.locationId && !isAdmin) {
          quoteStateUpdate.location = joinIdNameObj({
            foreignId: String(salesRepLocationData.locationId),
            name: salesRepLocationData.locationName,
          });
        } else {
          // If they don't have an associated location, then use the default provided
          // certain tenants will have their users as ADMIN, so they need a default location per tenant
          quoteStateUpdate.location = joinIfIdNameObj(activeTenant.defaultData.quote?.location);
        }
      }

      updateQuoteState(quoteStateUpdate);
    }
  }, [
    quoteState.location,
    isAdmin,
    salesRepLocationData,
    quoteDataIsLoading,
    updateQuoteState,
    quoteState.taker,
    activeTenant.defaultData.quote?.location,
  ]);

  // when line items change, load corresponding price info stats
  // this is a bit of a hack, since it's similar to usePromise interface
  // but since you can't have variable number of hooks, we need to store state in an object
  const [lineItemsInfoCache, setLineItemsInfoCache] = useState<LineItemsInfoCache>({});

  useEffect(() => {
    // helper function to convert itemId to invMastUid and then fetch price info

    // NOTE: currently no reason to load item info without a customer set
    // Same goes for companyId
    if (!customerId || !companyId || !locationId || !shipToId) {
      return;
    }
    async function loadItemInfo(
      itemId: string,
      quantity: number,
      unitOfMeasure: string | undefined,
      priceInfoParams: LineItemInfoCacheValue['params'],
    ): Promise<{ priceInfo: QuoteLineItemInfo; algoliaItem: AlgoliaInventoryItemPartial }> {
      const algoliaItem = await getAlgoliaItemByItemId(itemId, searchClient, activeTenant.id);

      const lineItem: LineItemRequest = {
        inv_mast_uid: algoliaItem.inv_mast_uid,
        quantity,
      };

      if (unitOfMeasure) {
        lineItem.unit_of_measure = unitOfMeasure;
      }
      const priceInfo = await getItemPriceInfo(lineItem, priceInfoParams);

      return { priceInfo, algoliaItem };
    }

    if (lineItems) {
      if (!isTenantCWC(activeTenant.id)) {
        Promise.all(
          lineItems
            .filter((item) => {
              // TODO: Better rerender logic
              const infoParams = {
                companyId,
                customerId,
                locationId,
                shipToId,
              };
              const cacheKey = generateCacheKey(item);
              if (
                item.foreignId &&
                (lineItemsInfoCache[cacheKey] === undefined ||
                  !isEqual(lineItemsInfoCache[cacheKey].params, infoParams))
              ) {
                setLineItemsInfoCache(
                  produce((infoCache) => {
                    infoCache[cacheKey] = { params: infoParams, isLoading: true };
                  }),
                );
                return true;
              }
              // Need to send every valid line for quantity based pricing
              return !!item.foreignId;
            })
            .map(async (item) => {
              const cacheKey = generateCacheKey(item);
              return {
                algoliaItem: await getAlgoliaItemByItemId(item.foreignId, searchClient, activeTenant.id),
                cacheKey,
                item,
              };
            }),
        ).then(async (algoliaItems) => {
          const lineRequests: Array<LineItemRequest> = algoliaItems.map(({ algoliaItem, item }) => ({
            inv_mast_uid: algoliaItem.inv_mast_uid,
            quantity: item.quantity || 1,
            ...(item.unitOfMeasure
              ? {
                  unit_of_measure: item.unitOfMeasure,
                }
              : {}),
          }));

          if (lineRequests.length === 0) {
            return;
          }

          try {
            const lines: FIXME[] = await getItemPriceInfoMulti(
              companyId,
              customerId,
              locationId,
              shipToId,
              lineRequests,
            );

            setLineItemsInfoCache(
              produce((infoCache) => {
                lines.forEach((line, index) => {
                  const { algoliaItem, cacheKey } = algoliaItems[index];
                  // TODO: Handle single line errors
                  if (line.error) {
                    infoCache[cacheKey] = { ...infoCache[cacheKey], isLoading: false };
                  } else {
                    infoCache[cacheKey] = {
                      params: infoCache[cacheKey].params,
                      isLoading: false,
                      algoliaItem,
                      priceInfo: line,
                    };
                  }
                });
              }),
            );
          } catch (err) {
            captureError(err);
            for (const { cacheKey } of algoliaItems) {
              setLineItemsInfoCache(
                produce((infoCache) => {
                  infoCache[cacheKey] = { ...infoCache[cacheKey], isLoading: false };
                }),
              );
            }
          }

          // TODO: The API should also get a "multiline" endpoint, but for now we'll do a call for each item to get results
          // Min/max prices for Safeware
          if (!isTenantSafeware(activeTenant.id)) {
            return;
          }
          const contractsReq = [];
          for (const line of lineRequests) {
            contractsReq.push(
              getItemContract(line.inv_mast_uid, {
                companyId,
                customerId,
                locationId,
                shipToId,
              }),
            );
          }

          const contracts = await Promise.all(contractsReq);
          setLineItemsInfoCache(
            produce((infoCache) => {
              contracts.forEach((contract, index) => {
                const { algoliaItem, cacheKey } = algoliaItems[index];
                infoCache[cacheKey] = {
                  params: infoCache[cacheKey].params,
                  isLoading: false,
                  algoliaItem,
                  priceInfo: {
                    ...infoCache[cacheKey].priceInfo,
                    contract: contract.error ? null : contract.items,
                  },
                };
              });
            }),
          );
        });
      } else {
        for (const item of lineItems) {
          const itemId = item.foreignId;
          if (itemId) {
            const infoParams = {
              companyId,
              customerId,
              locationId,
              shipToId,
            };

            const cacheKey = generateCacheKey(item);
            if (
              lineItemsInfoCache[cacheKey] === undefined ||
              !isEqual(lineItemsInfoCache[cacheKey].params, infoParams)
            ) {
              setLineItemsInfoCache(
                produce((infoCache) => {
                  infoCache[cacheKey] = { params: infoParams, isLoading: true };
                }),
              );
              loadItemInfo(itemId, item.quantity || 1, item.unitOfMeasure, infoParams)
                .then((itemInfo) => {
                  setLineItemsInfoCache(
                    produce((infoCache) => {
                      infoCache[cacheKey] = {
                        params: infoCache[cacheKey].params,
                        isLoading: false,
                        algoliaItem: itemInfo.algoliaItem,
                        priceInfo: itemInfo.priceInfo,
                      };
                    }),
                  );
                })
                .catch((err) => {
                  captureError(err);
                  setLineItemsInfoCache(
                    produce((infoCache) => {
                      infoCache[cacheKey] = { ...infoCache[cacheKey], isLoading: false };
                    }),
                  );
                });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuoteState, customerId, locationId, shipToId]);

  const generateCacheKey = ({ foreignId }: QuoteLineItem) => `${foreignId}`;

  const lineItemsWithInfo: QuoteLineItemWithInfo[] = useMemo(
    () =>
      lineItems.map((item) => {
        const itemInfo = lineItemsInfoCache[generateCacheKey(item)];
        const itemWithInfo: QuoteLineItemWithInfo = {
          ...item,
          priceInfo: itemInfo?.priceInfo,
          algoliaItem: itemInfo?.algoliaItem,
          isLoading: !!itemInfo?.isLoading,
          errors: {},
          warnings: {},
        };
        if (!itemWithInfo.isLoading && itemWithInfo.foreignId) {
          itemWithInfo.errors = {
            foreignId: validateName(itemWithInfo),
            quantity: validateQuantity(itemWithInfo, locationId),
            price: validatePrice(itemWithInfo),
          };
          itemWithInfo.warnings = {
            quantity: warnQuantity(itemWithInfo, locationId),
            price: warnPrice(itemWithInfo),
          };
        }
        return itemWithInfo;
      }),
    [lineItems, lineItemsInfoCache, locationId],
  );

  const [isQuoteSaving, setIsQuoteSaving] = useState(false);
  const handleQuoteSaveDraft = async () => {
    if (!(await isCurrentStepValid())) {
      return;
    }

    const existingQuoteId = quoteId || quoteData?.id;
    const quoteRequest = quoteRequestFromHashState(quoteState, tenantId);
    let updatedQuote: CoreApiQuote | undefined;

    try {
      setIsQuoteSaving(true);

      if (existingQuoteId) {
        if (quoteData?.status === QuoteStatusType.Draft) {
          updatedQuote = (
            await api()
              .quotes()
              .updateQuote(existingQuoteId, { ...quoteRequest, status: QuoteStatusType.Draft })
          ).data;
          quoteSetData(updatedQuote);
        } else {
          message.error('Cannot convert an already-submitted quote to a draft.');
        }
      } else {
        updatedQuote = (
          await api()
            .quotes()
            .createQuote({ ...quoteRequest, status: QuoteStatusType.Draft })
        ).data;

        quoteSetData(updatedQuote);
      }

      track(TrackEvent.Quotes_EditQuote_SaveDraft, {
        ...getLineItemTrackProps(lineItemsWithInfo),
        quoteId: updatedQuote?.id,
        step: QuoteEditStep[currentStep],
      });
      message.success('Quote draft saved.');
    } catch (err) {
      captureAndShowError(err, 'saving quote');
    } finally {
      setIsQuoteSaving(false);
    }
  };

  const [isQuoteSubmitting, setIsQuoteSubmitting] = useState(false);
  const handleQuoteSubmit = async () => {
    if (!(await isCurrentStepValid())) {
      return;
    }

    const existingQuoteId = quoteId || quoteData?.id;
    const quoteRequest = quoteRequestFromHashState(quoteState, tenantId);
    let updatedQuote: CoreApiQuote | undefined;

    try {
      setIsQuoteSubmitting(true);

      if (existingQuoteId) {
        updatedQuote = (
          await api()
            .quotes()
            .updateQuote(existingQuoteId, { ...quoteRequest, status: QuoteStatusType.Submitted })
        ).data;
        quoteSetData(updatedQuote);
      } else {
        // TODO: handle quote vs order based on type?
        updatedQuote = (
          await api()
            .quotes()
            .createQuote({
              ...quoteRequest,
              status: QuoteStatusType.Submitted,
            })
        ).data;
        quoteSetData(updatedQuote);
      }

      track(TrackEvent.Quotes_EditQuote_Submit, {
        ...getLineItemTrackProps(lineItemsWithInfo),
        quoteId: updatedQuote?.id,
      });
      message.success('Quote submitted to your ERP, it may take up to five minutes to sync.');
      history.push(routes.orders.quoteList());
    } catch (err) {
      captureAndShowError(err, 'submitting quote', 'Please save as a draft, and try again.');
    } finally {
      setIsQuoteSubmitting(false);
    }
  };

  const handleOrderSubmit = async () => {
    if (!(await isCurrentStepValid())) {
      return;
    }

    const orderRequest = orderRequestFromHashState(quoteState, tenantId);

    try {
      setIsQuoteSubmitting(true);

      await api()
        .orders()
        .createOrder({
          ...orderRequest,
          status: QuoteStatusType.Submitted,
        });

      setIsQuoteSubmitting(false);
      track(TrackEvent.Orders_EditOrder_Submit, getLineItemTrackProps(lineItemsWithInfo));
      message.success('Order submitted to your ERP, it may take up to five minutes to sync.');
      history.push(routes.orders.orderList());
    } catch (err) {
      captureAndShowError(err, 'submitting order');
    } finally {
      setIsQuoteSubmitting(false);
    }
  };

  const canGoToStep = useCallback(
    async (step: QuoteEditStep): Promise<boolean> => {
      if (step > QuoteEditStep.Header) {
        try {
          // antd Form makes you jumps through async try/catch hoop just to know whether a form is valid or not
          await headerForm.validateFields();
        } catch (err) {
          setValidationErrorMsg('Please ensure that all required header fields are filled.');
          return false;
        }
      }
      if (step > QuoteEditStep.LineItems) {
        if (lineItems.filter((item) => item.foreignId).length === 0) {
          setValidationErrorMsg('There must be at least one line item.');
          return false;
        }
        if (lineItemsWithInfo.some((lineItem) => lineItem.isLoading)) {
          setValidationErrorMsg('Lines must finish loading before proceeding');
          return false;
        }
        if (lineItemsWithInfo.some((lineItem) => Object.values(lineItem.errors).some((error) => error))) {
          setValidationErrorMsg('Please ensure that all line items are filled correctly.');
          return false;
        }
      }
      setValidationErrorMsg('');
      return true;
    },
    [headerForm, lineItems, lineItemsWithInfo],
  );

  const isCurrentStepValid = useCallback(
    async (): Promise<boolean> => canGoToStep(currentStep + 1),
    [canGoToStep, currentStep],
  );

  useEffect(() => {
    // Clear error message immediately when user has fixed the errors
    if (validationErrorMsg !== '') {
      isCurrentStepValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteState, validationErrorMsg]);

  const goToStep = async (step: QuoteEditStep) => {
    // don't go to next step, until every previous state is valid
    if (step >= currentStep && !(await canGoToStep(step))) {
      return;
    }
    updateQuoteState({ items: lineItems.filter((item) => item.foreignId), step });
  };

  if (quoteDataIsLoading) {
    return <CenteredLoader />;
  }

  return (
    <Styled.Container>
      <Form.Provider>
        <Styled.Header>
          <Space>
            <Styled.VerticalCenter>
              <Typography type="large" weight="bold">
                {quoteId ? 'Edit' : 'New'} {capitalize(orderType)}
              </Typography>
              {quoteData?.status === QuoteStatusType.Draft && (
                <Styled.StatusTag>
                  <Tag color="blue">Draft</Tag>
                </Styled.StatusTag>
              )}
            </Styled.VerticalCenter>
          </Space>
        </Styled.Header>
        <Steps current={currentStep} style={{ marginTop: UNITS.XL }} onChange={(newStep) => goToStep(newStep)}>
          {['Header', 'Line Items', 'Review'].map((step) => (
            <Steps.Step key={step} title={step} />
          ))}
        </Steps>
        {currentStep === QuoteEditStep.Header && (
          <Styled.Content>
            <QuoteHeader
              form={headerForm}
              quoteState={quoteState}
              orderType={orderType}
              onQuoteStateChange={(newState) => updateQuoteState(newState)}
            />
          </Styled.Content>
        )}
        {currentStep === QuoteEditStep.LineItems && (
          <SplitPage
            left={
              <QuoteLineItems
                quoteState={quoteState}
                lineItemsWithInfo={lineItemsWithInfo}
                orderType={orderType}
                onLineItemsChange={(items, jobNumber) => {
                  // NOTE/FIXME: ideally we grab the items here, extract the jobNumber,
                  // and feed in items selection to QuoteLineItems, but that's a non-trivial refactor
                  const quoteStateUpdate: QuoteEditHashState = { items };
                  if (jobNumber && !quoteState.jobNumber) {
                    quoteStateUpdate.jobNumber = jobNumber;
                  }
                  updateQuoteState(quoteStateUpdate);
                }}
              />
            }
            right={
              <QuoteLineItemRecommendations
                quoteState={quoteState}
                orderType={orderType}
                onLineItemsChange={(items) => updateQuoteState({ items })}
              />
            }
          />
        )}
        {currentStep === QuoteEditStep.Review && <QuoteReview quoteState={quoteState} orderType={orderType} />}
        {validationErrorMsg && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 16,
              color: RECURRENCY_RED,
            }}
          >
            {validationErrorMsg}
          </div>
        )}
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            padding: '0 8px',
          }}
        >
          <Button onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 0}>
            <LeftOutlined />
            Previous
          </Button>
          {orderType === OrderType.Quote && (
            <Tooltip title="This will save the quote in Recurrency, but not export to your primary ERP.">
              <Button onClick={handleQuoteSaveDraft} loading={isQuoteSaving}>
                Save Draft
              </Button>
            </Tooltip>
          )}
          {currentStep === QuoteEditStep.Review ? (
            <Tooltip
              title={`This will lock the ${orderType} in Recurrency. Once sent to your primary ERP, you will no longer be able to edit it here.`}
            >
              <Button
                type="primary"
                onClick={orderType === OrderType.Quote ? handleQuoteSubmit : handleOrderSubmit}
                loading={isQuoteSubmitting}
              >
                Send to ERP
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
              Next
              <RightOutlined />
            </Button>
          )}
        </div>
      </Form.Provider>
    </Styled.Container>
  );
};
