import React from 'react';

import { TenantFeature } from '@recurrency/core-api';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { Container } from 'components/Layout/Container';
import { NavTabs } from 'components/NavTabs';
import { Performance } from 'components/Performance';
import { Tasks } from 'components/Tasks';

import { useGlobalApp } from 'hooks/useGlobalApp';

import * as Styled from './DashboardPage.style';
import { TopOpenOrders } from './TopOpenOrders';
import { TopOpenQuotes } from './TopOpenQuotes';
import { TopProductGroups } from './TopProductGroups';

export const DashboardPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();

  const tabs = [
    { header: 'Tasks', content: <Tasks /> },
    {
      header: 'Top Open Orders',
      content: <TopOpenOrders />,
      infoTooltip: 'Report showing your open orders from the past week.',
    },
    {
      header: 'Top Open Quotes',
      content: <TopOpenQuotes />,
      infoTooltip: 'Report showing your open quotes from the past week.',
    },
    { header: 'Top Product Groups', content: <TopProductGroups /> },
  ];

  return (
    <Container>
      <Styled.Row />
      {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.SalesStatistics) && <Performance />}
      <NavTabs tabs={tabs} />
    </Container>
  );
};
