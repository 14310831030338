import { Pagination as AntPagination } from "antd";
import styled from "styled-components";
export const Pagination = styled(AntPagination)`
  &.ant-pagination {
    justify-content: center;
    .ant-pagination-item,
    .ant-select-selector,
    .ant-pagination-item-link {
      border-radius: ${(props) => props.theme.border.radius};
    }
  }
`;
