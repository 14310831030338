import React from 'react';

import { useHistory } from 'react-router';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeature } from '@recurrency/core-api';
import { ButtonProps } from 'antd';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { pluralize } from 'utils/formatting';
import { routes } from 'utils/routes';
import { track, TrackEvent, CtaName, CtaType } from 'utils/track';

import { QuoteEditHashState } from 'types/hash-state';

import { Button } from './Button';

export const NewQuoteButton = ({
  props,
  hashState,
  showItemCount = false,
  label = 'New Quote',
}: {
  props?: ButtonProps;
  hashState?: QuoteEditHashState;
  showItemCount?: boolean;
  label?: string;
}) => {
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  return shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateQuote) ? (
    <>
      <Button
        {...props}
        type="primary"
        onClick={(ev) => {
          props?.onClick?.(ev);
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewQuote,
            ctaType: CtaType.Button,
          });
          history.push(routes.orders.quoteNew(hashState));
        }}
        icon={<PlusOutlined />}
      >
        {label} {showItemCount && `(${pluralize(hashState?.items?.length ?? 0, 'item', 'items', true)})`}
      </Button>
    </>
  ) : null;
};
