import React from 'react';

import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

import { TextArea } from 'components/TextArea';

export interface Props extends FormItemProps {
  rows?: number;
}

export const TextAreaFormItem = ({ label, name, rules, rows = 2 }: Props): JSX.Element => (
  <Form.Item label={label} name={name} rules={rules}>
    <TextArea rows={rows} />
  </Form.Item>
);
