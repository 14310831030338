/* eslint-disable */
// @ts-nocheck
// NOTE: intentionally not type checked because we don't want to change third party snippets
import { waitForSelector, insertAsyncScript } from 'utils/dom';

export function extScriptsInit() {
  // segment.io
  if (process.env.SEGMENT_TOKEN) {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        console.error('Segment snippet included twice.');
      } else {
        analytics.invoked = true;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const t = analytics.methods[e];
          analytics[t] = analytics.factory(t);
        }
        analytics.load = function (e, t) {
          analytics._loadOptions = t;
          insertAsyncScript({
            src: `https://cdn.segment.com/analytics.js/v1/${e}/analytics.min.js`,
          });
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(process.env.SEGMENT_TOKEN);
      }
    }
  }

  // fullstory.com
  if (process.env.FULLSTORY_ORG) {
    window._fs_debug = false;
    window._fs_host = 'fullstory.com';
    window._fs_script = 'edge.fullstory.com/s/fs.js';
    window._fs_org = process.env.FULLSTORY_ORG;
    window._fs_namespace = 'FS';
    (function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        }
        return;
      }
      g = m[e] = function (a, b, s) {
        g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
      };
      g.q = [];

      insertAsyncScript({
        src: `https://${_fs_script}`,
        crossOrigin: 'anonymous',
      });

      g.identify = function (i, v, s) {
        g(l, { uid: i }, s);
        if (v) g(l, v, s);
      };
      g.setUserVars = function (v, s) {
        g(l, v, s);
      };
      g.event = function (i, v, s) {
        g('event', { n: i, p: v }, s);
      };
      g.anonymize = function () {
        g.identify(!true);
      };
      g.shutdown = function () {
        g('rec', !1);
      };
      g.restart = function () {
        g('rec', true);
      };
      g.log = function (a, b) {
        g('log', [a, b]);
      };
      g.consent = function (a) {
        g('consent', !arguments.length || a);
      };
      g.identifyAccount = function (i, v) {
        o = 'account';
        v = v || {};
        v.acctId = i;
        g(o, v);
      };
      g.clearUserCookie = function () {};
      g.setVars = function (n, p) {
        g('setVars', [n, p]);
      };
      g._w = {};
      y = 'XMLHttpRequest';
      g._w[y] = m[y];
      y = 'fetch';
      g._w[y] = m[y];
      if (m[y])
        m[y] = function () {
          return g._w[y].apply(this, arguments);
        };
      g._v = '1.3.0';
    })(window, document, window._fs_namespace, 'script', 'user');
  }

  // cohere.so
  if (process.env.COHERE_TOKEN) {
    const Cohere = (window.Cohere = window.Cohere || []);
    if (Cohere.invoked) console.error('Tried to load Cohere twice');
    else {
      Cohere.invoked = true;
      Cohere.snippet = '0.2';
      Cohere.methods = ['init', 'identify', 'stop', 'showCode'];
      Cohere.methods.forEach(function (o) {
        Cohere[o] = function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(o), Cohere.push(t);
        };
      });
      insertAsyncScript({
        src: 'https://static.cohere.so/main.js',
        crossOrigin: 'anonymous',
      });
    }
    window.Cohere.init(process.env.COHERE_TOKEN);
  }

  // headwayapp.co
  if (process.env.HEADWAYAPP_ACCOUNT) {
    insertAsyncScript({
      src: 'https://cdn.headwayapp.co/widget.js',
      onload: async () => {
        if (window.Headway) {
          // headwayapp can only be initialized once the selector exists in DOM, so we wait for it
          const selector = '.headwayapp-badge';
          await waitForSelector(selector);
          window.Headway?.init({
            selector,
            account: process.env.HEADWAYAPP_ACCOUNT,
            trigger: selector,
          });
        }
      },
    });
  }

  // zendesk.com chat widget
  if (process.env.ZENDESK_KEY) {
    // zeIdentify tracks userProps until zendesk script is initialized
    window.zeIdentify = (userProps) => {
      window.zeIdentify.userProps = userProps;
    };

    insertAsyncScript({
      src: `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_KEY}`,
      id: 'ze-snippet',
      onload: () => {
        window.zE(() => {
          if (window.zeIdentify.userProps) {
            window.zE.identify(window.zeIdentify.userProps);
          }
          window.zeIdentify = (userProps) => window.zE.identify(userProps);
        });
      },
    });
  }
}
