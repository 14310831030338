import { QuoteItemResult } from '@recurrency/core-api';

export enum QuoteStatusType {
  Submitted = 'submitted',
  Draft = 'draft',
}

export interface QuoteWithTotalInfo extends QuoteItemResult {
  totalLines: number;
  totalPrice: number;
}
