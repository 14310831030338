import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import createAuth0Client, { Auth0Client, Auth0ClientOptions, User } from '@auth0/auth0-spa-js';
import { Role, Tenant, TenantFeature } from '@recurrency/core-api';
import { setUser as sentrySetUser, setTag as sentrySetTag } from '@sentry/react';
import algoliasearch from 'algoliasearch';

import { CenteredError, CenteredLoader } from 'components/Loaders';

import { ExtendedUser, globalAppStore } from 'hooks/useGlobalApp';

import { api } from 'utils/api';
import { captureError } from 'utils/error';
import { getActiveRole, getActiveTenant } from 'utils/roleAndTenant';
import { setDefaultTenantSlugForMakePath } from 'utils/routes';
import { identify } from 'utils/track';

import { config } from 'settings/environment';

export interface Auth0ContextValue {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
}

interface Auth0RedirectAppState {
  pathname: string;
  hash: string;
}

export const isAdmin = (foreignId: string | undefined, name: string | undefined): boolean =>
  (!foreignId || foreignId === '') && name === 'admin';

export function shouldShowFeatureFlag(activeTenant: Tenant, activeUser: ExtendedUser, feature: TenantFeature) {
  // recurrency admins should always see all feature flags
  return activeTenant.featureFlags?.[feature] || !!activeUser.isRecurrencyAdmin;
}

export const isPurchaserRole = (role: Maybe<Role>): boolean =>
  !!role && (role.name === 'purchaser' || isAdmin(role.foreignId, role.name));
// TODO: return to smart role selection like this, rather than manual
// return roles.some((role) => role.name === 'purchaser' || isAdmin(role.foreignId, role.name));

export const isSalesRole = (role: Maybe<Role>): boolean => !!role && role.name === 'sales';

export const Auth0Context = React.createContext<Auth0ContextValue | null>(null);

export const Auth0Provider = ({
  children,
  ...initOptions
}: {
  children: React.ReactNode;
} & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const auth0ClientRef = useRef<Auth0Client>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const history = useHistory();

  useEffect(() => {
    const initAuth0 = async () => {
      auth0ClientRef.current = await createAuth0Client(initOptions);
      globalAppStore.update({
        logout: () => auth0ClientRef.current!.logout({ returnTo: window.location.origin }),
        loginWithRedirect: () =>
          auth0ClientRef.current!.loginWithRedirect({
            appState: {
              pathname: window.location.pathname,
              hash: window.location.hash,
            } as Auth0RedirectAppState,
          }),
      });

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        try {
          const { appState }: { appState?: Auth0RedirectAppState } =
            await auth0ClientRef.current.handleRedirectCallback();

          if (appState) {
            // restore location pathname and hash
            history.replace(appState.pathname + appState.hash);
          }
        } catch (err) {
          // authentication failed, don't render App and redirect to login page
          captureError(err);
          auth0ClientRef.current.loginWithRedirect();
          return;
        }
      }

      const _isAuthenticated = await auth0ClientRef.current.isAuthenticated();
      setIsAuthenticated(_isAuthenticated);

      if (_isAuthenticated === false) {
        // if unauthenticated, don't render App and redirect to login page
        globalAppStore.state.loginWithRedirect();
      } else {
        setUser(await auth0ClientRef.current.getUser());
        const accessToken = await auth0ClientRef.current.getTokenSilently();

        try {
          globalAppStore.update({ accessToken });
          const { data } = await api().users().getMe();
          const { user: apiUser, tenants, isAdmin } = data;

          const activeTenant = getActiveTenant(tenants);
          const { roles } = activeTenant.tenantUser;
          const activeRole = getActiveRole(roles);
          const activeUser: ExtendedUser = {
            ...apiUser,
            fullName: `${apiUser.firstName} ${apiUser.lastName}`,
            isRecurrencyAdmin: isAdmin,
          };

          setDefaultTenantSlugForMakePath(activeTenant.slug);

          globalAppStore.update({
            activeUser,
            activeTenant,
            activeRole,
            roles,
            tenants,
            searchClient: algoliasearch(config.algoliaAppId, activeTenant.ais),
          });

          sentrySetUser(activeUser);
          sentrySetTag('user.tenant_id', activeTenant.id);
          sentrySetTag('user.role', activeRole.name);

          identify({
            id: activeUser.id,
            name: activeUser.fullName,
            email: activeUser.email,
            role: activeRole.name,
            tenantId: activeTenant.id,
            tenantName: activeTenant.name,
          });
        } catch (err) {
          captureError(err);
          setError(err as Error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={
        {
          isAuthenticated,
          isLoading,
          user,
        } as Auth0ContextValue
      }
    >
      {/* don't render app if auth info is loading, or has an error */}
      {isLoading ? <CenteredLoader /> : error ? <CenteredError error={error} /> : children}
    </Auth0Context.Provider>
  );
};
