import React from 'react';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { linkablePhoneNumber, linkableEmail, linkableAddress } from 'components/Links';

import { formatAddress } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { idColumn, sortableStringColumn } from 'utils/tables';

export const ShipTos = ({ customerId }: { customerId: string }) => {
  const columns = [
    idColumn({
      title: 'Ship To ID',
      dataIndex: 'shipToId',
    }),
    sortableStringColumn({
      title: 'Ship To',
      dataIndex: ['shipToAddress', 'name'],
    }),
    {
      title: 'Physical Address',
      dataIndex: ['shipToAddress', 'phys_address1'],
      render: (_: any, record: FIXME) =>
        linkableAddress(
          formatAddress(
            record.shipToAddress?.physAddress1,
            record.shipToAddress?.physCity,
            record.shipToAddress?.physState,
            record.shipToAddress?.physPostalCode,
          ),
        ),
    },
    sortableStringColumn({
      title: 'Email',
      dataIndex: ['shipToAddress', 'emailAddress'],
      render: (email: any) => linkableEmail(email),
    }),
    {
      title: 'Phone',
      dataIndex: ['shipToAddress', 'centralPhoneNumber'],
      render: (phone: any) => linkablePhoneNumber(phone),
    },
    sortableStringColumn({
      title: 'Freight',
      dataIndex: ['freight', 'freightCd'],
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/shiptos`,
      })}
      columns={columns}
      rowKey="shipToId"
    />
  );
};
