import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { routes } from 'utils/routes';
import { idColumn, orderAndQuoteColumns } from 'utils/tables';

export const TopOpenQuotes = () => {
  const columns = useMemo(
    () => [
      idColumn({
        title: 'Quote No',
        dataIndex: 'orderNo',
        render: (quoteNo: string) => <Link to={routes.orders.orderDetails(quoteNo)}>{quoteNo} </Link>,
      }),
      ...orderAndQuoteColumns({ type: 'quote' }),
      {
        render: ({ orderNo }: { orderNo: string }) => ButtonLink(routes.orders.orderDetails(orderNo)),
      },
    ],
    [],
  );

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: '/v3/quotes/top',
      })}
      columns={columns}
      rowKey="orderNo"
      pagination={false}
    />
  );
};
