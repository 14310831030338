import React from 'react';

import { Link } from 'react-router-dom';

import { LogoutOutlined, QuestionCircleOutlined, SettingOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

import { ExternalLink } from 'components/Links';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { routes } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

export const DropdownMenu = () => {
  const { logout } = useGlobalApp();

  return (
    <Menu style={{ backgroundColor: '#FAFAFA', borderRight: 'none' }}>
      <Menu.Item key="help" icon={<QuestionCircleOutlined />}>
        <a href="mailto:hello@recurrency.com">Get Help</a>
      </Menu.Item>
      <Menu.Item key="refer" icon={<UsergroupAddOutlined />}>
        <ExternalLink to="https://form.typeform.com/to/tUSM5Xck" onClick={() => track(TrackEvent.Nav_ReferClick, {})}>
          Refer - Earn $2,500
        </ExternalLink>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <Link to={routes.account.accountEdit()}>Settings</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item danger key="logout" icon={<LogoutOutlined />} onClick={() => logout()}>
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );
};
