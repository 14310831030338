import React from 'react';

import { Empty } from 'antd';
import styled from 'styled-components';

const NoDataMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoDataMessage = () => (
  <NoDataMessageContainer>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  </NoDataMessageContainer>
);
