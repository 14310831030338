import React from 'react';

import type { ObjectWithObjectID } from '@algolia/client-search';
import { Row, Col } from 'antd';
import { ColumnType, TableProps } from 'antd/lib/table';
import { ExpandableConfig } from 'rc-table/lib/interface';
import { useDebounce } from 'use-debounce/lib';

import { Container } from 'components/Layout/Container';
import { CenteredError } from 'components/Loaders';
import { Pagination } from 'components/Pagination';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { IndexName, MAX_VALUES_PER_FACET, searchAlgoliaIndex, searchAlgoliaIndexForFacetValues } from 'utils/algolia';
import { isObjEmpty, objOmitKeys, objRemoveUndefinedValues } from 'utils/object';
import { useHashState } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { UNITS } from 'constants/styles';

import { SearchFrameHashState } from 'types/hash-state';

import { FacetCard } from './FacetCard';
import { ValuesFilterList } from './FacetValuesList';
import { HitResultsTable } from './HitResultsTable';
import { RangeSlider } from './RangeSlider';
import { TopPanel } from './TopPanel';

export interface NumericFacet<HitT> {
  title: string;
  field: keyof HitT extends string ? string : never;
  labelFormatFn?: (value: number) => string;
}

export interface ValueFacet<HitT> {
  title: string;
  field: keyof HitT extends string ? string : never;
  hidden?: boolean;
  queryPlaceholder?: string;
  // Use a string to choose a specific value or true to use the first value
  defaultValue?: string | boolean;
  /** Default is single = radio, multiple = checkbox, default is multiple */
  filterType?: 'single' | 'multiple';
  capitalizeItemLabels?: boolean;
}

export interface ExternalFacet {
  title: string;
  field: string;
  options: string[];
  hidden?: boolean;
  queryPlaceholder?: string;
  // Use a string to choose a specific value or true to use the first value
  defaultValue?: string;
  /** Default is single = radio, multiple = checkbox, default is multiple */
  filterType?: 'single' | 'multiple';
  onSelect?: (oldSelection: string[], newSelection?: string[]) => void;
  showItemCount?: boolean;
}

export interface SearchFrameProps<HitT> {
  indexName: IndexName;
  /** large title of the search page */
  title: string | React.ReactNode;
  queryPlaceholder: string;
  valueFacets?: ValueFacet<HitT>[];
  numericFacets?: NumericFacet<HitT>[];
  externalFacets?: ExternalFacet[];
  columns: ColumnType<HitT>[];
  isPercent?: boolean;
  hitsPerPage?: number;
  setIsPercent?: (value: boolean) => void;
  // function to run on the list of hits before displaying
  hitFn?: (hits: HitT[]) => Any[];
  /** component that shows besides search, e.g New Order CTA button */
  ctaChild?: React.ReactNode;
  tableRowKey?: TableProps<HitT>['rowKey'];
  tableRowSelection?: TableProps<HitT>['rowSelection'];
  expandable?: ExpandableConfig<any>;
}

export function SearchFrame<HitT extends ObjectWithObjectID>({
  indexName,
  title,
  queryPlaceholder,
  valueFacets = [],
  numericFacets = [],
  externalFacets = [],
  columns,
  isPercent,
  hitsPerPage,
  setIsPercent,
  hitFn,
  ctaChild,
  tableRowKey,
  tableRowSelection,
  expandable,
}: SearchFrameProps<HitT>) {
  const [hashState, updateHashState] = useHashState<SearchFrameHashState>();
  const { activeTenant, searchClient } = useGlobalApp();
  const [debouncedQuery] = useDebounce(hashState.query, 75);

  const algoliaSearchOptions = {
    indexName,
    tenantId: activeTenant.id,
    query: debouncedQuery,
    facetValueFilters: hashState.where ?? {},
    facetNumericFilters: hashState.whereNum ?? {},
    facetFields: [...numericFacets, ...valueFacets].map((f) => f.field),
    maxValuesPerFacet: MAX_VALUES_PER_FACET,
    hitsPerPage,
    page: hashState.page,
    sortBy: hashState.sortBy,
  };

  const {
    data: algoliaResponse,
    isLoading,
    error,
  } = usePromise(
    () => {
      track(TrackEvent.Components_SearchFrameQuery, {
        indexName: algoliaSearchOptions.indexName,
        searchQuery: algoliaSearchOptions.query ?? '',
        searchQueryLength: algoliaSearchOptions.query?.length ?? 0,
        facetValueFilterKeys: Object.keys(algoliaSearchOptions.facetValueFilters),
        facetNumericFilterKeys: Object.keys(algoliaSearchOptions.facetNumericFilters),
        sortByField: algoliaSearchOptions.sortBy?.field,
        sortByDir: algoliaSearchOptions.sortBy?.order,
      });
      return searchAlgoliaIndex<HitT>(searchClient, algoliaSearchOptions);
    },
    [algoliaSearchOptions],
    {
      cacheKey: `searchframe/algolia/${indexName}/${activeTenant.id}`,
    },
  );

  const { facetValueFilters, facetNumericFilters } = algoliaSearchOptions;

  // update default value facets (if any)
  for (const facet of valueFacets) {
    if (facet.defaultValue && !facetValueFilters[facet.field]) {
      if (typeof facet.defaultValue === 'string') {
        facetValueFilters[facet.field] = [facet.defaultValue];
      } else if (
        typeof facet.defaultValue === 'boolean' &&
        algoliaResponse?.facets &&
        !isObjEmpty(algoliaResponse.facets[facet.field])
      ) {
        // add first facet value as filter (usually shown as radiolist)
        facetValueFilters[facet.field] = [Object.keys(algoliaResponse.facets[facet.field])[0]];
      }
    }
  }

  // update external value facets (if any)
  for (const facet of externalFacets) {
    if (facet.defaultValue && !hashState?.extFilter?.[facet.field]) {
      updateHashState({
        extFilter: objRemoveUndefinedValues({
          ...hashState.extFilter,
          [facet.field]: [facet.defaultValue],
        }),
        page: 0,
      });
    }
  }

  if (!isObjEmpty(facetValueFilters)) {
    updateHashState({ where: facetValueFilters });
  }

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container
      top={
        <TopPanel
          queryPlaceholder={queryPlaceholder}
          title={title}
          ctaChild={ctaChild}
          query={hashState.query}
          onQueryChange={(query) => updateHashState({ query, page: 0 })}
          appliedFilters={{
            valueFilters: valueFacets
              .filter((facet) => facetValueFilters[facet.field] && !facet.hidden)
              .map((facet) => ({
                title: facet.title,
                values: facetValueFilters[facet.field],
                onRemove: () => {
                  updateHashState({
                    where: objOmitKeys(hashState.where || {}, facet.field),
                    page: 0,
                  });
                },
              })),
            numericFilters: numericFacets
              .filter((facet) => facetNumericFilters[facet.field])
              .map((facet) => ({
                title: facet.title,
                min: facetNumericFilters[facet.field].min,
                max: facetNumericFilters[facet.field].max,
                onRemove: () => {
                  updateHashState({
                    whereNum: objOmitKeys(hashState.whereNum || {}, facet.field),
                    page: 0,
                  });
                },
              })),
          }}
        />
      }
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={6}>
          {algoliaResponse ? (
            <>
              {externalFacets.map((facet, idx) => (
                <FacetCard key={idx} show={!facet.hidden} title={facet.title}>
                  <ValuesFilterList
                    valueCounts={facet.options.reduce((acc, curr) => ({ ...acc, [curr]: 1 }), {})}
                    selectedValues={hashState?.extFilter?.[facet.field] ?? []}
                    queryPlaceholder={facet.queryPlaceholder}
                    filterType={facet.filterType}
                    onSelectedValuesChange={(values) => {
                      updateHashState({
                        extFilter: objRemoveUndefinedValues({
                          ...hashState.extFilter,
                          [facet.field]: values,
                        }),
                        page: 0,
                      });
                      facet.onSelect?.(hashState?.extFilter?.[facet.field] ?? [], values);
                    }}
                    showItemCount={facet.showItemCount}
                  />
                </FacetCard>
              ))}
              {valueFacets.map((facet, idx) => (
                <FacetCard key={idx} show={!facet.hidden} title={facet.title}>
                  <ValuesFilterList
                    valueCounts={algoliaResponse.facets?.[facet.field] ?? {}}
                    selectedValues={facetValueFilters[facet.field] ?? []}
                    queryPlaceholder={facet.queryPlaceholder}
                    capitalizeItemLabels={facet.capitalizeItemLabels}
                    filterType={facet.filterType}
                    onSelectedValuesChange={(values) => {
                      updateHashState({
                        where: objRemoveUndefinedValues({
                          ...hashState.where,
                          [facet.field]: values,
                        }),
                        page: 0,
                      });
                    }}
                    searchFacetValuesFn={(facetQuery) =>
                      searchAlgoliaIndexForFacetValues(
                        searchClient,
                        facet.field,
                        facetQuery,
                        algoliaSearchOptions,
                      ).then((resp) => resp.facetHits)
                    }
                  />
                </FacetCard>
              ))}
              {numericFacets.length ? (
                <FacetCard key="stats" title="Statistics">
                  {numericFacets.map((facet, idx) => (
                    <React.Fragment key={idx}>
                      <p>{facet.title}</p>
                      <RangeSlider
                        range={algoliaResponse.facets_stats?.[facet.field]}
                        labelFormatFn={facet.labelFormatFn}
                        selectedValue={facetNumericFilters[facet.field]}
                        onSelectedValueChange={(range) => {
                          updateHashState({
                            whereNum: objRemoveUndefinedValues({
                              ...hashState.whereNum,
                              [facet.field]: range,
                            }),
                            page: 0,
                          });
                        }}
                      />
                    </React.Fragment>
                  ))}
                </FacetCard>
              ) : null}
            </>
          ) : null}
        </Col>
        <Col xs={24} sm={24} md={18}>
          <HitResultsTable<HitT>
            isLoading={isLoading}
            columns={columns}
            hits={algoliaResponse?.hits || []}
            hitFn={hitFn}
            isPercent={isPercent}
            setIsPercent={setIsPercent}
            onSortChange={(field, order) => updateHashState({ sortBy: { field, order }, page: 0 })}
            rowKey={tableRowKey}
            rowSelection={tableRowSelection}
            expandable={expandable}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            {
              // only show pagination if there is more than one page
              algoliaResponse && algoliaResponse.nbPages > 1 ? (
                <Pagination
                  size="small"
                  simple
                  style={{ marginBottom: `${UNITS.XXL}px` }}
                  // NOTE: Pagination component uses 1-based index, algolia returns 0 based index
                  current={algoliaResponse.page + 1}
                  total={algoliaResponse.nbPages}
                  pageSize={1}
                  onChange={(page) => updateHashState({ page: page - 1 })}
                />
              ) : null
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
}
