import React from 'react';

import {
  BankFilled,
  BankOutlined,
  CarryOutFilled,
  CarryOutOutlined,
  ControlFilled,
  ControlOutlined,
  FilterFilled,
  FilterOutlined,
  ProfileFilled,
  ProfileOutlined,
  ShoppingFilled,
  ShoppingOutlined,
} from '@ant-design/icons';
import { TenantFeature } from '@recurrency/core-api';

import { routes } from 'utils/routes';

import { RECURRENCY_NEW_DARK_BLUE } from 'constants/styles';

import { NavModule } from './types';

const defaultIconStyle = { height: 18, width: 24, flex: 1, fill: RECURRENCY_NEW_DARK_BLUE };
const unselectedIconStyle = defaultIconStyle; // { ...defaultIconStyle, fill: RECURRENCY_DARK_BLUE };
const selectedIconStyle = defaultIconStyle; // { ...defaultIconStyle, fill: RECURRENCY_BLUE };

const IconWithStyle = (Icon: React.ElementType, selected: boolean): JSX.Element => {
  if (selected) {
    return <Icon {...selectedIconStyle} />;
  }
  return <Icon {...unselectedIconStyle} />;
};

export const upperNavModules: NavModule[] = [
  {
    slug: 'sales',
    label: 'Sales',
    selectedIcon: IconWithStyle(FilterFilled, true),
    unselectedIcon: IconWithStyle(FilterOutlined, false),
    subModules: [
      {
        slug: 'dashboard',
        label: 'Dashboard',
        routeFn: () => routes.sales.dashboard(),
      },
      {
        slug: 'customers',
        entitySlug: 'customer',
        label: 'Customers',
        routeFn: () => routes.sales.customerList(),
      },
      {
        slug: 'items',
        entitySlug: 'item',
        label: 'Inventory',
        routeFn: () => routes.sales.itemList(),
      },
      {
        slug: 'pricing',
        label: 'Pricing',
        routeFn: () => routes.sales.pricing(),
      },
    ],
  },
  {
    slug: 'orders',
    label: 'Quotes & Orders',
    selectedIcon: IconWithStyle(ProfileFilled, true),
    unselectedIcon: IconWithStyle(ProfileOutlined, false),
    subModules: [
      {
        slug: 'opportunities',
        label: 'Opportunities', // aka recommended quotes
        routeFn: () => routes.orders.recommendedQuotes(),
      },
      {
        slug: 'quotes',
        entitySlug: 'quote',
        label: 'Quotes',
        routeFn: () => routes.orders.quoteList(),
      },
      {
        slug: 'orders',
        entitySlug: 'order',
        label: 'Orders',
        routeFn: () => routes.orders.orderList(),
      },
    ],
  },
  {
    slug: 'purchasing',
    label: 'Purchasing',
    selectedIcon: IconWithStyle(ShoppingFilled, true),
    unselectedIcon: IconWithStyle(ShoppingOutlined, false),
    subModules: [
      {
        slug: 'forecasts',
        label: 'Forecasts',
        routeFn: () => routes.purchasing.forecasts(),
        featureFlag: TenantFeature.PurchasingForecasting,
      },
      {
        slug: 'purchase-orders',
        entitySlug: 'purchase-order',
        label: 'Purchase Orders',
        routeFn: () => routes.purchasing.purchaseOrderList(),
      },
      {
        slug: 'vendors',
        entitySlug: 'vendor',
        label: 'Vendors',
        routeFn: () => routes.purchasing.vendorList(),
      },
      {
        slug: 'items',
        entitySlug: 'item',
        label: 'Inventory',
        routeFn: () => routes.purchasing.itemList(),
      },
    ],
  },
  {
    slug: 'tasks',
    label: 'Tasks',
    selectedIcon: IconWithStyle(CarryOutFilled, true),
    unselectedIcon: IconWithStyle(CarryOutOutlined, false),
    subModules: [
      {
        slug: 'tasks',
        entitySlug: 'task',
        label: 'Tasks',
        routeFn: () => routes.tasks.taskList(),
      },
    ],
  },
  {
    slug: 'admin',
    label: 'Admin',
    selectedIcon: IconWithStyle(BankFilled, true),
    unselectedIcon: IconWithStyle(BankOutlined, false),
    subModules: [
      {
        slug: 'settings',
        label: 'Settings',
        routeFn: () => routes.admin.settings(),
      },
    ],
  },
];

export const lowerNavModules: NavModule[] = [
  {
    slug: 'internal',
    label: 'Recurrency Internal',
    selectedIcon: IconWithStyle(ControlFilled, true),
    unselectedIcon: IconWithStyle(ControlOutlined, false),
    subModules: [
      {
        slug: 'users',
        entitySlug: 'user',
        label: 'Users',
        routeFn: () => routes.internal.userList(),
      },
      {
        slug: 'tenants',
        entitySlug: 'tenant',
        label: 'Tenants',
        routeFn: () => routes.internal.tenantList(),
      },
    ],
  },
];

export const navModules = upperNavModules.concat(lowerNavModules);
