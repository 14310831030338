import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import {
  booleanColumn,
  sortableNumberColumn,
  sortableStringColumn,
  sortableDateColumn,
  sortableDollarColumn,
  sortablePercentColumn,
  idColumn,
} from 'utils/tables';

export const Orders = ({ customerId }: { customerId: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    // humanDateColumn({
    //   title: 'Timeline',
    //   dataIndex: 'orderDate',
    // }),
    sortableStringColumn({
      title: 'Order No',
      dataIndex: 'orderNo',
      render: (id: string) => <Link to={routes.orders.orderDetails(id)}>{id}</Link>,
    }),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: 'orderDate',
    }),
    sortableStringColumn({
      title: 'PO No',
      dataIndex: 'poNo',
    }),
    idColumn({
      title: 'Ship To',
      render: (record: FIXME) => `${record.addressId}: ${record.ship2Name}`,
    }),
    idColumn({
      title: 'Location',
      dataIndex: ['location', 'locationId'],
      render: (locationId: string, record: FIXME) => `${locationId}: ${record.location.locationName}`,
    }),
    // TODO: enable this in v3 once there is more meaningful ship to information to display
    // sortableStringColumn({
    //   title: 'Ship To',
    //   dataIndex: 'shipping',
    //   render: (shipping: FIXME) =>
    //     formatAddress(shipping.address?.[0], shipping.city, shipping.state, shipping.zip),
    // }),
    sortableDollarColumn({
      title: 'Total Amount',
      dataIndex: 'totalValue',
    }),
    sortableDollarColumn({
      title: 'Open Amount',
      dataIndex: 'openValue',
    }),
    sortableDollarColumn({
      title: 'Total GM',
      dataIndex: 'gm',
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'gmPct',
    }),
    booleanColumn({
      title: 'Back Ordered',
      dataIndex: 'backordersExist',
    }),
    sortableNumberColumn({
      title: 'Line Count',
      dataIndex: 'itemCount',
      responsive: ['lg', 'xl', 'xxl'],
    }),
    {
      render: ({ orderNo }) => ButtonLink(routes.orders.orderDetails(orderNo)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/orders`,
      })}
      columns={columns}
      rowKey="orderNo"
    />
  );
};
