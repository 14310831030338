import React from 'react';

import { useHistory, Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { ButtonLink } from 'components/Links';
import { SearchFrame } from 'components/Search/SearchFrame';
import { Tag } from 'components/Tag';

import { IndexName } from 'utils/algolia';
import { capitalize, formatSecondsDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableDollarColumn, sortableNumberColumn } from 'utils/tables';

import { AlgoliaPurchaseOrder } from 'types/algolia-collections';

export const PurchaseOrderListPage = () => {
  const history = useHistory();

  const columns: ColumnType<AlgoliaPurchaseOrder>[] = [
    {
      title: 'PO No',
      dataIndex: 'po_no',
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <Tag color={status === 'complete' ? 'green' : 'blue'}>{capitalize(status)}</Tag>,
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      render: (timestampSecs: number) => formatSecondsDate(timestampSecs),
    },
    {
      title: 'Expected Date',
      dataIndex: 'expected_date',
      render: (timestampSecs: number | null) => (timestampSecs ? formatSecondsDate(timestampSecs) : '-'),
    },
    {
      title: 'Vendor ID',
      dataIndex: 'vendor_id',
      render: (id: string) => <Link to={routes.purchasing.vendorDetails(id)}>{id}</Link>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
    },
    sortableDollarColumn({
      title: 'Purchase Value',
      dataIndex: 'purchase_value',
    }),
    sortableNumberColumn({ title: 'Line Count', dataIndex: 'line_count' }),
    {
      title: '',
      dataIndex: 'po_no',
      render: (orderNo: string) => ButtonLink(routes.purchasing.purchaseOrderDetails(orderNo)),
    },
  ];

  return (
    <SearchFrame<AlgoliaPurchaseOrder>
      title="Purchase Orders"
      indexName={IndexName.PurchaseOrders}
      queryPlaceholder="Search recent purchase orders by order, customer, ship to, PO, salesrep, or location..."
      columns={columns}
      valueFacets={[
        {
          title: 'Status',
          field: 'status',
          capitalizeItemLabels: true,
        },
        {
          title: 'Vendor',
          field: 'vendor_desc',
          queryPlaceholder: 'Filter by vendor...',
        },
        {
          title: 'Location',
          field: 'location_desc',
          queryPlaceholder: 'Filter by location...',
        },
      ]}
      numericFacets={[{ title: 'Purchase Value', field: 'purchase_value' }]}
      ctaChild={
        <Button disabled type="primary" onClick={() => history.push(routes.purchasing.purchaseOrderNew())}>
          <PlusOutlined /> New PO
        </Button>
      }
    />
  );
};
