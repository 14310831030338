import React from 'react';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { Container } from 'components/Layout/Container';
import { ButtonLink } from 'components/Links';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { Table } from 'components/Table';
import { PageHeading } from 'components/Typography';

import { useApi } from 'hooks/useApi';

import { formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';

const MAX_RECORDS = 1000;

export const TenantListPage = () => {
  const { data, error, isLoading } = useApi().tenants().getAllTenants(MAX_RECORDS, 0);
  // incorrect core-api return type
  const tenantData = data as FIXME;
  const columns: ColumnType<FIXME>[] = [
    sortableNumberColumn({
      title: 'Tenant ID',
      dataIndex: 'id',
      render: (id: string) => <Link to={routes.internal.tenantDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    sortableStringColumn({
      title: 'Company Name',
      dataIndex: 'companyName',
    }),
    sortableStringColumn({
      title: 'Slug',
      dataIndex: 'slug',
    }),
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date: FIXME) => formatDate(date),
      sorter: (a: FIXME, b: FIXME) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (date: FIXME) => formatDate(date),
      sorter: (a: FIXME, b: FIXME) => a.updatedAt.localeCompare(b.updatedAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      render: ({ id }: { id: FIXME }) => ButtonLink(routes.internal.tenantDetails(id)),
    },
  ];

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !tenantData) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      <PageHeading style={{ marginTop: '24px', marginBottom: '24px' }}>Tenants</PageHeading>
      <Table columns={columns} data={tenantData && tenantData.items} rowKey="id" isLoading={isLoading}>
        <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary">
            <Link to={routes.internal.tenantNew()}>
              <PlusOutlined /> New Tenant
            </Link>
          </Button>
        </div>
      </Table>
    </Container>
  );
};
