import React from 'react';

import {
  TeamOutlined,
  ContainerOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Role } from '@recurrency/core-api';
import { fuzzyFilter, fuzzyMatch } from 'fuzzbunny';

import { isAdmin, isPurchaserRole } from 'contexts/Auth0Context';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { IndexName, getIndexNameForTenant, commonAlgoliaSearchParams } from 'utils/algolia';
import { capitalize } from 'utils/formatting';
import { routes } from 'utils/routes';

import {
  AlgoliaCustomer,
  AlgoliaInventoryItem,
  AlgoliaOrder,
  AlgoliaPurchaseOrder,
  AlgoliaVendor,
} from 'types/algolia-collections';

import { getNavigationOptions } from './navigationOptions';
import { MenuCategory, MenuOption } from './types';

interface AlgoliaCategoryConf<ItemT> {
  label: string;
  icon?: JSX.Element;
  getIndexName: (tenantUid: string) => string;
  /** where the option will link to */
  getItemHref: (item: ItemT) => string;
  /** url for the search/list page with prepopulated query */
  getItemsSearchHref: (query: string) => string;
  /** option label - matching parts from search string will be highlighted */
  getLabel: (item: ItemT) => string;
  /** subtext usually shows id of an item */
  getSubtext?: (item: ItemT) => string;
  /** should this category be searched for active user's role */
  isEnabledForRole?: (activeRole: Role) => boolean;
  /** filters that should be passed to algolia */
  algoliaFacetFilters?: (activeRole?: Role) => string[];
}

/**
 * Helper function to get highlights from a matched string
 * @example
 *   getLabelHighlights("here is a match, yay!", "match")
 *   returns ["here is a", "match", ", yay!"]
 */
function getLabelHighlights(label: string, searchStr: string): string[] | undefined {
  return fuzzyMatch(label, searchStr)?.highlights;
}

const algoliaCategoryConfs: AlgoliaCategoryConf<FIXME>[] = [
  {
    label: 'Customers',
    icon: <TeamOutlined />,
    getIndexName: (tenantUid) => getIndexNameForTenant(tenantUid, IndexName.Customers),
    getItemHref: (item) => routes.sales.customerDetails(item.customer_id),
    getItemsSearchHref: (query: string) => routes.sales.customerList({ query }),
    getLabel: (item) => item.customer_name,
    getSubtext: (item) => item.customer_id,
    algoliaFacetFilters: (activeRole: Role) =>
      isAdmin(activeRole.foreignId, activeRole.name) ? null : [`salesrep_ids:${activeRole.foreignId}`],
  } as AlgoliaCategoryConf<AlgoliaCustomer>,
  {
    label: 'Items',
    icon: <ContainerOutlined />,
    getIndexName: (tenantUid) => getIndexNameForTenant(tenantUid, IndexName.Items),
    getItemHref: (item) => routes.sales.itemDetails(item.item_id),
    getItemsSearchHref: (query: string) => routes.sales.itemList({ query }),
    getLabel: (item) => item.item_desc,
    getSubtext: (item) => item.item_id,
  } as AlgoliaCategoryConf<AlgoliaInventoryItem>,
  {
    label: 'Vendors',
    icon: <ShopOutlined />,
    getIndexName: (tenantUid) => getIndexNameForTenant(tenantUid, IndexName.Vendors),
    getItemHref: (item) => routes.purchasing.vendorDetails(item.vendor_id),
    getItemsSearchHref: (query: string) => routes.purchasing.vendorList({ query }),
    getLabel: (item) => item.vendor_name,
    getSubtext: (item) => item.vendor_id,
    isEnabledForRole: (activeRole) => isPurchaserRole(activeRole),
  } as AlgoliaCategoryConf<AlgoliaVendor>,
  {
    label: 'Purchase Orders',
    icon: <ShoppingCartOutlined />,
    getIndexName: (tenantUid) => getIndexNameForTenant(tenantUid, IndexName.PurchaseOrders),
    getItemHref: (item) => routes.purchasing.purchaseOrderDetails(item.po_no),
    getItemsSearchHref: (query: string) => routes.purchasing.purchaseOrderList({ query }),
    getLabel: (item) => `${capitalize(item.status)} PO for ${item.vendor_name}`,
    getSubtext: (item) => item.po_no,
    isEnabledForRole: (activeRole) => isPurchaserRole(activeRole),
  } as AlgoliaCategoryConf<AlgoliaPurchaseOrder>,
  {
    label: 'Orders',
    icon: <ProfileOutlined />,
    getIndexName: (tenantUid) => getIndexNameForTenant(tenantUid, IndexName.Orders),
    getItemHref: (item) => routes.orders.orderDetails(item.order_no),
    getItemsSearchHref: (query: string) => routes.orders.orderList({ query }),
    getLabel: (item) => `${item.status} ${item.order_type} for ${item.customer_name}`,
    getSubtext: (item) => item.order_no,
    algoliaFacetFilters: (activeRole: Role) =>
      isAdmin(activeRole.foreignId, activeRole.name) ? null : [`salesrep_id:${activeRole.foreignId}`],
  } as AlgoliaCategoryConf<AlgoliaOrder>,
];

export function useGlobalSearch(searchStr: string, maxResultsPerCategory = 3): MenuCategory[] {
  const { searchClient, activeTenant, activeRole, activeUser } = useGlobalApp();
  const activeTenantId = activeTenant.id;

  const categoriesToSearch = algoliaCategoryConfs.filter((cat) =>
    cat.isEnabledForRole ? cat.isEnabledForRole(activeRole) : true,
  );

  const { data: algoliaResults } = usePromise(
    () =>
      searchStr
        ? searchClient
            .search(
              categoriesToSearch.map((catConf) => ({
                query: searchStr,
                indexName: catConf.getIndexName(activeTenantId),
                params: {
                  hitsPerPage: maxResultsPerCategory,
                  ...commonAlgoliaSearchParams,
                  ...(catConf.algoliaFacetFilters ? { facetFilters: catConf.algoliaFacetFilters(activeRole) } : null),
                },
              })),
            )
            .then((resp) => resp.results)
        : Promise.resolve([]),
    [activeTenantId, searchStr],
  );

  const algoliaCategories: MenuCategory[] = algoliaResults
    ? algoliaResults.map((result, resultIdx) => {
        const catConf = categoriesToSearch[resultIdx];
        const menuCategory: MenuCategory = {
          label: catConf.label,
          options: result.hits.map((hit: Obj) => ({
            label: catConf.getLabel(hit),
            labelHighlights: getLabelHighlights(catConf.getLabel(hit), searchStr),
            href: catConf.getItemHref(hit),
            ...(catConf.getSubtext ? { subtext: catConf.getSubtext(hit) } : null),
            ...(catConf.icon ? { icon: catConf.icon } : null),
          })),
        };

        // show the 'Show all X matching Items' special option if there are more matches
        if (result.nbHits > maxResultsPerCategory) {
          menuCategory.options.push({
            label: `Show all ${result.nbHits} matching ${catConf.label.toLowerCase()}`,
            href: catConf.getItemsSearchHref(searchStr),
            ...(catConf.icon ? { icon: catConf.icon } : null),
          });
        }
        return menuCategory;
      })
    : [];

  const navFilteredOptions: MenuOption[] = fuzzyFilter(
    getNavigationOptions(activeRole, activeTenant, activeUser),
    searchStr,
    {
      fields: ['label'],
    },
  )
    .slice(0, maxResultsPerCategory)
    .map(({ item, highlights }) => ({
      ...item,
      labelHighlights: highlights.label,
    }));

  const navCategory: MenuCategory = {
    label: 'Navigation',
    options: navFilteredOptions,
  };

  // filter out empty categories
  const searchResults = [navCategory, ...algoliaCategories].filter((cat) => cat.options.length > 0);

  return searchResults;
}
