import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { TenantFeature } from '@recurrency/core-api';
import { ColumnType } from 'antd/lib/table';

import { shouldShowFeatureFlag } from 'contexts/Auth0Context';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { NewQuoteButton } from 'components/Button/NewQuoteButton';
import { ButtonLink } from 'components/Links';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { joinIdNameObj } from 'utils/formatting';
import { encodeLegacyApiParam, routes } from 'utils/routes';
import { idColumn, sortableStringColumn, salesAndGMColumns } from 'utils/tables';

import { IdNameObj } from 'types/legacy-api';

export const TopItems = ({ customerId, customerName }: { customerId: string; customerName: string }) => {
  const [isPercent, setIsPercent] = useState(false);
  const { activeTenant, activeUser } = useGlobalApp();

  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (id: string) => <Link to={routes.sales.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
    }),
    ...salesAndGMColumns({ isPercent }),
    {
      render: ({ itemId }) => ButtonLink(routes.sales.itemDetails(itemId)),
    },
  ];

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [selectedItemIdNameObjs, setSelectedItemIdNameObjs] = useState<IdNameObj[]>([]);

  const handleRowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: FIXME[]) => {
    // we can assume rowKeys are string[] instead of Array<string | number>
    setSelectedItemIds(selectedRowKeys as string[]);
    setSelectedItemIdNameObjs(selectedRows.map((row) => ({ foreignId: row.itemId, name: row.itemDesc })));
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: 16, alignItems: 'center', marginBottom: 16 }}>
        <NewQuoteButton
          hashState={{
            customer: joinIdNameObj({ foreignId: customerId, name: customerName }),
            items: selectedItemIdNameObjs,
          }}
          showItemCount
          props={{
            disabled: selectedItemIds.length === 0,
            onClick: () => {
              setSelectedItemIdNameObjs([]);
              setSelectedItemIds([]);
            },
          }}
        />
      </div>

      <AsyncTable
        tableProps={useLegacyApiTableProps({
          apiPath: `/v3/customers/${encodeLegacyApiParam(customerId || '')}/top-items`,
        })}
        columns={columns}
        isPercent={isPercent}
        setIsPercent={setIsPercent}
        rowKey="itemId"
        rowSelection={
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeature.OrdersCreateQuote)
            ? {
                selectedRowKeys: selectedItemIds,
                onChange: handleRowSelectionChange,
              }
            : undefined
        }
      />
    </div>
  );
};
