import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { encodeLegacyApiParam, routes } from 'utils/routes';
import { sortableStringColumn, salesAndGMColumns, idColumn } from 'utils/tables';

export const TopCustomers = ({ itemId }: { itemId: string | undefined }) => {
  const [isPercent, setIsPercent] = useState(false);

  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Customer ID',
      dataIndex: 'customerId',
      render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId} </Link>,
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customerName',
    }),
    ...salesAndGMColumns({ isPercent }),
    {
      render: ({ customerId }) => ButtonLink(routes.sales.customerDetails(customerId)),
    },
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(itemId || '')}/top-customers`,
      })}
      columns={columns}
      isPercent={isPercent}
      setIsPercent={setIsPercent}
    />
  );
};
