// NOTE: All fields from legacy api come as snake_case.
// We camelCase them via camelCaseKeys helper in utils/api.ts

// #region api responses
/** GET /v3/recommended-quotes */
export type RecommendedQuotesResponse = LegacyApiItemsResponse<RecommendedQuoteByCustomer>;

/** GET /v3/companies */
export type CompaniesResponse = LegacyApiItemsResponse<Company>;

/** GET /v3/freights */
export type FreightsResponse = LegacyApiItemsResponse<Freight>;

/** GET /v3/carriers */
export type CarriersResponse = LegacyApiItemsResponse<Carrier>;

/** GET /v3/salesreps */
export type SalesRepsResponse = LegacyApiItemsResponse<SalesRep>;

/** GET /v3/branches */
export type BranchesResponse = LegacyApiItemsResponse<Branch>;

/** GET /v3/customers/{customerId}/shiptos */
export type CustomerShipTosResponse = LegacyApiItemsResponse<CustomerShipTo>;

/** GET /v3/customers/{customerId}/contacts */
export type CustomerContactsResponse = LegacyApiItemsResponse<Contact>;

/** GET /v3/customers/{customerId}/recommendations/items */
export type RecommendedNewItemsResponse = LegacyApiItemsResponse<RecommendedNewLineItem>;

/** GET /v3/customers/{customerId}/items/reorders */
export type RecommendedReorderItemsResponse = LegacyApiItemsResponse<RecommendedReorderLineItem>;

/** GET /v3/customers/{customerId}/items/ordered-once */
export type RecommendedOrderedOnceItemsResponse = LegacyApiItemsResponse<RecommendedReorderLineItem>;

/** GET /v3/job-price/ */
export type JobPriceResponse = LegacyApiItemsResponse<ItemJobPrice>;

/** GET /v3/items/{invMastUid}/transfer-lines, GET /v3/orders/{orderNo}/transfer-lines */
export type TransferLinesResponse = LegacyApiItemsResponse<TransferLine>;

/** GET /v3/salesrep */
export interface SalesRepLocationResponse {
  taker: string;
  locationId: number;
  locationName: string;
}

/** GET /v3/items/{itemInvMastUid}/price */
export interface LineItemPriceInfoV3 {
  lastOrderDate: string | null;
  lastQtyOrdered: number | null;
  lastUnitOfMeasure: string | null;
  lastUnitPrice: number | null;
  recommendedUnitPrice: number | null;
  qtyOnHandAll: number | null;
  qtyOnHandLocation: number | null;
  unitCost: number;
  movingAverageUnitCost: number;
  unitOfMeasure: string;
  uoms: LineItemPriceInfoUOM[];
  stats: LineItemStats;
}

/** GET /v3/orders/{orderNo} */
export interface Order {
  orderNo: string;
  customerId: string;
  orderDate: string;
  ship2Name: string;
  ship2Add1: string;
  ship2Add2: null;
  ship2City: string;
  ship2State: string;
  ship2Zip: string;
  ship2Country: string;
  requestedDate: string;
  poNo: string;
  completed: string;
  projectedOrder: string;
  purchaseOrder?: {
    poNo: string;
  };
  carrierId: string;
  addressId: string;
  contactId: string;
  taker: string;
  approved: string;
  cancelFlag: string;
  tenantId: string;
  customer: OrderCustomer;
  orderAddress: OrderAddress;
  contact: OrderContact;
  lines: OrderLine[];
  location: OrderLocation;
  quote: boolean;
  complete: boolean;
  canceled: boolean;
}

export interface LineItemStats {
  mean: number;
  min: number;
  max: number;
  median: number;
  xys: Array<{ x: number; y: number }>;
  gmLow: number;
  gmHigh: number;
}
// #endregion

// #region helpers
export interface IdNameObj {
  /** Id in tenant's source ERP DB */
  foreignId: string;
  /** Human readable name of referred object */
  name: string;
}

export interface LegacyApiItemsResponse<ItemT> {
  items: ItemT[];
  totalCount: number;
}
// #endregion

// #region RecommendedQuote
export interface RecommendedQuoteByCustomer {
  customer: IdNameObj;
  quotes: RecommendedQuote[];
}

export interface RecommendedQuote {
  customer: IdNameObj;
  shipTo: ShipTo;
  location: IdNameObj;
  /** e.g "2021-06-18 17:05:56.581000" */
  createdAt: string;
  items: RecommendedQuoteItem[];
  potentialValue: number;
}

export interface ShipTo extends IdNameObj {
  address: string;
}

export interface RecommendedQuoteItem extends IdNameObj {
  uom: string;
  price: number;
  quantity: number;
  /** Inventory master unique Id */
  invMastUid: string;
  lastLocationId: string;
  rationales: RecommendRationale[];
}

export interface RecommendRationale {
  tag: string;
  tagDetails: string;
  tagExplanation: string;
  tagScore: number;
}
// #endregion

export interface Company {
  /* e.g "CWC" */
  companyId: string;
  companyName: string;
}

export interface Freight {
  companyId: string;
  /** Most likely 'freight code' (poorly named var) */
  freightCd: string;
  /** Is this the foreignId? or postgres id? */
  freightCodeUid: string;
  /** corresponds to name field in ForeignIdNameRef pattern */
  freightDesc: string;
}

export interface Carrier {
  id: string;
  name: string;
}

export interface SalesRep {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Branch {
  branchId: string;
  branchDescription: string;
}

export interface CustomerShipTo {
  shipToId: string;
  shipToAddress: ShipToAddress | null;
  freight: {
    freightCd: string;
    freightCodeUid: string;
    freightDesc: string;
  };
}

export interface ShipToAddress {
  id: string;
  name: string;
  physAddress1: string;
  physAddress2: string;
  physCity: string;
  physState: string;
  physPostalCode: string;
  centralPhoneNumber: string;
  centralFaxNumber: string;
  lastMaintainedBy: string;
  emailAddress: string;
  url: string | null;
}

export interface Contact {
  /** TODO: document what does activityTransNo mean */
  activityTransNo: string | number | null;
  addressName: string;
  cellular: string | null;
  cellularExt: string | null;
  contactId: string;
  contactType: string;
  customerId: string;
  dateLastModified: string;
  directFax: string | null;
  directPhone: string | null;
  emailAddress: string | null;
  faxExt: string | null;
  firstName: string;
  lastHardTouchDate: string | null;
  lastName: string;
  phoneExt: string | null;
  physAddress1: string | null;
  physCity: string | null;
  physCountry: string | null;
  physPostalCode: string | null;
  physState: string | null;
  tenantId: string;
  title: string | null;
}

export interface RecommendedReorderLineItem {
  shipToId: string;
  customerId: string;
  rank: number;
  itemId: string;
  itemDesc: string;
  invMastUid: string;
  productGroupId: string;
  lastOrderDate: string;
  lastQty: number;
  lastUnitPrice: number;
  lastUnitOfMeasure: string;
  extendedDesc: string | null;
  dateLastModified: string;
}

export interface RecommendedNewLineItem {
  customerId: string;
  itemId: string;
  invMastUid: string;
  itemDesc: string;
  extendedDesc: string;
  productGroupId: string;
  rank: number;
  dateLastModified: string;
}

export interface LineItemPriceInfoUOM {
  default: boolean;
  unitOfMeasure: string;
  unitSize: number;
}

/* --- V4 Endpoints and Types --- */

export interface LineItemRequest {
  inv_mast_uid: string;
  quantity: number;
  unit_of_measure?: string;
}

export interface CollectionResponseV4<T> {
  items: T[];
}

export enum UnitOfMeasureTags {
  Sales = 'sales',
  Purchasing = 'purchasing',
  Base = 'base',
}

export interface Quantity {
  amount: number;
  unitOfMeasure: ItemUnitOfMeasure;
}

export type Currency = Quantity;

export interface CustomerPriceContract {
  price: {
    unitOfMeasure: ItemUnitOfMeasure;
    min: Currency | null;
    max: Currency | null;
  };
  messages: string[] | null;
}
export interface ItemUnitOfMeasure {
  symbol: string;
  name: string;
  size: number;
  tags: UnitOfMeasureTags[];
}

export interface MinMaxPricePolicy {
  minUnitPrice?: Quantity;
  maxUnitPrice?: Quantity;
  unitOfMeasure: ItemUnitOfMeasure;
}

export interface ItemAvailability {
  locationId: string;
  qtyOnHand: Quantity;
  qtyAllocated: Quantity;
  qtyBackordered: Quantity;
  qtyInProcess: Quantity;
  qtyInTransit: Quantity;
  movingAverageCost: Currency;
  standardCost: Currency;
  dateLastUpdatedUtc: Date;
}

export interface ItemPriceInfo {
  baseUnitPrice: Currency | null;
  baseUnitOfMeasure: ItemUnitOfMeasure | null;
  recommendedUnitPrice: Currency | null;
  unitOfMeasure: ItemUnitOfMeasure;
}

export interface ItemPriceInfoExtra extends ItemPriceInfo {
  availability: InventoryInfo[];
  cost: ItemCostInfo | null;
}

export interface ItemCostInfo {
  locationId: string;
  invMastUid: string;
  unitCost: Currency | null;
  movingAverageUnitCost: Currency | null;
  unitOfMeasure?: ItemUnitOfMeasure;
}

export type ContractPricePolicy = MinMaxPricePolicy;

export interface ItemContract {
  name: string;
  policies: ContractPricePolicy[];
  messages: string[];
}

export interface InventoryInfo {
  locationId: string;
  qtyInProcess: Quantity;
  qtyInTransit: Quantity;
  qtyAvailable: Quantity;
  dateLastUpdatedUtc: Date;
}

export interface ItemPriceResponseV4 {
  availability: ItemAvailability[];
  baseUnitPrice: Currency | null;
  baseUnitOfMeasure: ItemUnitOfMeasure | null;
  cost: ItemCostInfo;
  price: Currency | null;
  unitOfMeasure: ItemUnitOfMeasure;
  recommendedUnitPrice: Currency | null;
  rule: {
    codeDescription: string;
    id: string;
  };
  unitsOfMeasure: ItemUnitOfMeasure[];
}

export interface ItemCostResponseV4 {
  unitCost: Quantity;
  unitOfMeasure: ItemUnitOfMeasure;
}

export interface QuoteLineItemInfo {
  baseUnit?: ItemUnitOfMeasure;
  defaultUnit?: ItemUnitOfMeasure;
  unitOfMeasureOptions?: ItemUnitOfMeasure[];
  // FIXME: this should be whatever an sales-history item looks like?
  last?: {
    orderDate: string;
    qtyOrdered: number;
    unitOfMeasure: ItemUnitOfMeasure;
    unitPrice: Currency;
  } | null;
  current?: ItemPriceInfo;
  contract?: ItemContract | null;
  cost?: ItemCostInfo;
  availability?: {
    locations: Record<string, ItemAvailability | undefined>;
    total: Omit<
      ItemAvailability,
      'locationId' | 'locationName' | 'dateLastUpdatedUtc' | 'movingAverageCost' | 'standardCost'
    >;
  } | null;
}

export interface ItemJobPrice {
  companyId: string;
  contractNo: string;
  // These were null for the example I tried and am listing for posterity
  customerId: string | null;
  corpAddressId: unknown | null;
  itemDesc: string;
  itemId: string;
  extendedDesc: string;
  jobPrice: number;
  unitOfMeasure: string;
  unitSize: number | null;
}

export interface OrderContact {
  firstName: string;
  lastName: string;
  emailAddress: string;
  addressId: string;
}

export interface OrderCustomer {
  customerName: string;
  creditStatus: string;
  billToContactId: null;
  salesrepId: string;
}

export interface OrderLine {
  oeLineUid: string;
  parentOeLineUid: string;
  unitPrice: number;
  unitSize: number;
  qtyOrdered: number;
  qtyInvoiced: number;
  qtyStaged: number;
  qtyAllocated: number;
  qtyOnPickTickets: number;
  qtyCanceled: number;
  unitOfMeasure: string;
  invMastUid: string;
  itemId: string;
  extendedDesc: string;
  complete: string;
  manualPriceOveride: string;
  orderNo: string;
  tenantId: string;
  salesCost: number;
  commissionCost: number;
  shipLocId: string;
  sourceLocId: string;
  productGroupId: string;
  pricingUnitSize: number;
  pricingUnit: string;
  disposition: null;
  lotBill: string;
  extendedPrice: number;
  cancelFlag: string;
  deleteFlag: string;
  item: OrderItem;
  qtyPicked: number;
  unitCost: number;
  qtyOpen: number;
  totalAmount: number;
  totalGrossMargin: number;
}

export interface OrderItem {
  defaultSellingUnit: string;
  itemDesc: string;
  itemId: string;
}

export interface OrderLocation {
  locationName: string;
  locationId: string;
}

export interface OrderAddress {
  centralFaxNumber: string | null;
  centralPhoneNumber: string | null;
  corpAddressId: string | null;
  emailAddress: string | null;
  name: string | null;
  physAddress1: string;
  physAddress2: string | null;
  physAddress3: string | null;
  physCity: string;
  physCountry: string;
  physPostalCode: string;
  physState: string;
  url: string | null;
}

export interface TransferLine {
  transferNo: number;
  qtyToTransfer: number;
  qtyTransferred: number;
  qtyReceived: number;
  unitOfMeasure: string;
  unitSize: number;
  unitQuantity: number;
  invMastUid: string;
  lineNo: number;
  transferHdr: TransferHdr;
  item: TransferLineItem;
}

export interface TransferLineItem {
  itemId: string;
  itemDesc: string;
}

export interface TransferHdr {
  fromLocationId: string;
  toLocationId: string;
  receivedDate: null;
  shippedFlag: string;
  completeFlag: string;
  transferDate: string;
  carrierId: string;
  carrier: TransferHdrCarrier;
  fromLocation: TransferHdrLocation;
  toLocation: TransferHdrLocation;
}

export interface TransferHdrCarrier {
  name: string;
}

export interface TransferHdrLocation {
  locationName: string;
}
