import React from 'react';

import { TableProps } from 'antd/lib/table';

import * as Styled from './ResponsiveTable.style';

export type Props = TableProps<any>;

export const ResponsiveTable = (props: Props): JSX.Element => (
  <Styled.TableWrapper>
    <Styled.Table {...props} />
  </Styled.TableWrapper>
);
