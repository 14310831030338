import styled from "styled-components";
import { RECURRENCY_BLUE } from "constants/styles";
export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  line-height: 1.3em;
`;
export const LabelTitle = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
`;
export const LabelSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-left: ${(props) => props.theme.units.MD};
`;
export const LabelHighlightPart = styled.span`
  color: ${RECURRENCY_BLUE};
`;
