import React from 'react';

import { useHistory } from 'react-router-dom';

import { Spin } from 'antd';
import { AxiosError } from 'axios';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { track, TrackEvent } from 'utils/track';

import { colors } from 'settings/theme/colors';

import { Button } from './Button';
import { Logo } from './Layout/Topbar/Topbar.style';
import { ExternalLink } from './Links';
import * as Styled from './Loaders.style';

export const CenteredLoader = () => (
  <Styled.LoaderContainer>
    <Spin size="large" />
  </Styled.LoaderContainer>
);

export const SmallLoader = () => (
  <Styled.SmallContainer>
    <Spin size="large" />
  </Styled.SmallContainer>
);

export const CenteredError = ({
  error,
  componentStack,
  sentryEventId,
}: {
  error?: Error;
  componentStack?: string;
  sentryEventId?: string | null;
}) => {
  const history = useHistory();
  const errorMessage = error?.message || error?.toString() || '';
  const { activeUser } = useGlobalApp();
  const sentryUrl = sentryEventId ? `https://sentry.io/recurrency/recurrency-app/events/${sentryEventId}` : undefined;

  if (error) {
    track(TrackEvent.Components_Loaders_ShowError, {
      errorName: error.name,
      errorMessage,
      errorStack: error.stack || '',
      componentStack,
      sentryUrl,
    });

    // log to console so e2e tests fail
    console.error(error);

    const axiosError = error as AxiosError;
    if (axiosError.isAxiosError && axiosError?.response) {
      const status = axiosError.response?.status;

      // TODO: add other status code specific responses
      if (status === 404) {
        return (
          <Styled.LoaderContainer>
            <div>
              Sorry, we can't find the record. <br />
              If this record should exist, please contact{' '}
              <a href="mailto:zsupport@recurrency.com">zsupport@recurrency.com</a>.
            </div>
            <br />
            <Button type="primary" onClick={history.goBack}>
              Go Back
            </Button>
          </Styled.LoaderContainer>
        );
      }
    }
  }

  return (
    <Styled.LoaderContainer>
      <div>
        Sorry, this should not have happened. An internal error has occurred.
        <br />
        Our engineers have already been notified. If this persists, please contact{' '}
        <a href="mailto:zsupport@recurrency.com">zsupport@recurrency.com</a>.
        <br />
        <Button
          type="primary"
          style={{ marginTop: '24px' }}
          // using only location.pathname, so if the error is due to hashState, we clear that out.
          onClick={() => window.location.assign(window.location.pathname)}
        >
          Reload this page
        </Button>
        {/* Show error message in in small light font not steal visual attention (but still visible in fullstory session) */}
        <pre style={{ marginTop: '24px', fontSize: '10px', color: colors.gray500 }}>
          <span>{errorMessage}</span>

          {sentryUrl && activeUser.isRecurrencyAdmin ? (
            <ExternalLink to={sentryUrl}>&nbsp;sentry →</ExternalLink>
          ) : null}
        </pre>
      </div>
    </Styled.LoaderContainer>
  );
};

export const CenteredAccountError = () => {
  const { logout } = useGlobalApp();

  return (
    <Styled.LoaderContainer>
      <div>
        <div>
          <Logo />
        </div>
        <Styled.ErrorTextContainer>
          Your account is almost ready.
          <br />
          Please contact your Recurrency Administrator to finish set up.
        </Styled.ErrorTextContainer>
        <div>
          <Button onClick={() => logout()}>Log Out</Button>
        </div>
      </div>
    </Styled.LoaderContainer>
  );
};
