import React from 'react';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { encodeLegacyApiParam } from 'utils/routes';
import { idColumn, sortableDollarWithCentsColumn } from 'utils/tables';

export const ERPPricing = ({ invMastUid }: { invMastUid: string | undefined }) => {
  const columns: ColumnType<FIXME>[] = [
    idColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: FIXME) => `${record.locationId}: ${record.locationName}`,
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 1',
      dataIndex: 'price1',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 2',
      dataIndex: 'price2',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 3',
      dataIndex: 'price3',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 4',
      dataIndex: 'price4',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 5',
      dataIndex: 'price5',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 6',
      dataIndex: 'price6',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 7',
      dataIndex: 'price7',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 8',
      dataIndex: 'price8',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 9',
      dataIndex: 'price9',
    }),
    sortableDollarWithCentsColumn({
      title: 'Price 10',
      dataIndex: 'price10',
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/items/${encodeLegacyApiParam(invMastUid || '')}/erp-pricing`,
      })}
      columns={columns}
    />
  );
};
