import { Button as AntdButton } from "antd";
import styled from "styled-components";
const heights = {
  small: "30px",
  middle: "40px",
  large: "50px"
};
const getHeights = (size = "middle") => heights[size];
const getColors = (props) => {
  if (props.type === "primary" && !props.disabled) {
    return `
      background-color: ${props.theme.colors.blue};
      border-color: ${props.theme.colors.blue};
    `;
  }
  return `border-color: ${props.type === "link" ? "none" : props.theme.colors.border}`;
};
export const Button = styled(AntdButton)`
  &.ant-btn {
    border-radius: ${(props) => props.theme.border.radius};
    height: ${(props) => getHeights(props.size)};
    ${getColors}
  }
`;
