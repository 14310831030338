import React from 'react';

import { Descriptions } from 'antd';

import { DescriptionsContainer } from 'components/DescriptionsContainer';
import { linkableEmail, linkableAddress } from 'components/Links';
import { Typography } from 'components/Typography';

import { formatAddress } from 'utils/formatting';

import { Order } from 'types/legacy-api';

export const AddressBar = ({ order }: { order: Order }) => {
  const billingAddress = formatAddress(
    order.orderAddress?.physAddress1,
    order.orderAddress?.physCity,
    order.orderAddress?.physState,
    order.orderAddress?.physPostalCode,
  );

  const shippingAddr = formatAddress(order.ship2Add1, order.ship2City, order.ship2State, order.ship2Zip);

  const billingDOM = (
    <div style={{ fontSize: '16px' }}>
      {order.orderAddress?.name && <div>{linkableAddress(billingAddress, order.orderAddress.name)}</div>}
      {order.orderAddress?.physAddress1 && (
        <div>{linkableAddress(billingAddress, order.orderAddress.physAddress1)}</div>
      )}
      {order.orderAddress?.physAddress2 && <div>{order.orderAddress.physAddress2}</div>}
      {order.orderAddress?.physAddress3 && <div>{order.orderAddress.physAddress3}</div>}
      {order.orderAddress?.physCity && order.orderAddress?.physState && order.orderAddress?.physPostalCode && (
        <div>
          {linkableAddress(
            billingAddress,
            `${order.orderAddress.physCity}, ${order.orderAddress.physState} ${order.orderAddress.physPostalCode}`,
          )}
        </div>
      )}
      {order.orderAddress?.physCountry && <div>{order.orderAddress.physCountry}</div>}
      {order.customer?.billToContactId && <Typography type="subtitle">{order.customer.billToContactId}</Typography>}
    </div>
  );

  const shippingDOM = (
    <div style={{ fontSize: '16px' }}>
      {order.ship2Name && <div>{linkableAddress(shippingAddr, order.ship2Name)}</div>}
      {order.ship2Add1 && <div>{linkableAddress(shippingAddr, order.ship2Add1)}</div>}
      {order.ship2Add2 && <div>{order.ship2Add2}</div>}
      {order.ship2City && order.ship2State && order.ship2Zip && (
        <div>{linkableAddress(shippingAddr, `${order.ship2City}, ${order.ship2State} ${order.ship2Zip}`)}</div>
      )}
      {order.ship2Country && <div>{order.ship2Country}</div>}
      {order.addressId && <Typography type="subtitle">{order.addressId}</Typography>}
    </div>
  );

  const shouldShowContact =
    order.contact && (order.contact.firstName || order.contact.lastName || order.contact.emailAddress);
  const contactDOM = (
    <div style={{ fontSize: '16px' }}>
      {order.contact?.firstName && order.contact.lastName && (
        <div>
          {order.contact.firstName} {order.contact.lastName}
        </div>
      )}
      {order.contact?.emailAddress && <div>Email: {linkableEmail(order.contact.emailAddress)}</div>}
      {order.contactId && <Typography type="subtitle">{order.contactId}</Typography>}
    </div>
  );

  return (
    <DescriptionsContainer bordered>
      <Descriptions.Item key="billing" label="Bill To" span={1}>
        {billingDOM}
      </Descriptions.Item>
      <Descriptions.Item key="shipping" label="Ship To" span={1}>
        {shippingDOM}
      </Descriptions.Item>
      {shouldShowContact && (
        <Descriptions.Item key="contact" label="Sold To" span={1}>
          {contactDOM}
        </Descriptions.Item>
      )}
    </DescriptionsContainer>
  );
};
