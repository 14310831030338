import React from 'react';

import { Tabs, Divider } from 'antd';

import { CenteredLoader, CenteredError } from 'components/Loaders';

import { useLegacyApi } from 'hooks/useApi';

import { getElapsedMonths, getLastTwoYears } from 'utils/date';

import { PerformanceCharts } from './PerformanceCharts';
import { PerformanceTable } from './PerformanceTable';
import { ValuesByMonth } from './types';

const transformMonthData = (data: ValuesByMonth): ValuesByMonth => {
  const results: ValuesByMonth = {};
  getElapsedMonths().forEach((month: string, index: number) => {
    results[month.toLowerCase()] = data[index + 1];
  });
  return results;
};

export const Performance = () => {
  const { data, error, isLoading } = useLegacyApi('/v3/sales/revenue-by-month');

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !data) {
    return <CenteredLoader />;
  }

  const lastTwoYears = getLastTwoYears();
  const dashboardData = { ...data };
  if (dashboardData && dashboardData[lastTwoYears[0]] && dashboardData[lastTwoYears[1]]) {
    dashboardData[lastTwoYears[0]] = transformMonthData(dashboardData[lastTwoYears[0]]);
    dashboardData[lastTwoYears[1]] = transformMonthData(dashboardData[lastTwoYears[1]]);
  }

  return (
    <>
      <Tabs size="small">
        <Tabs.TabPane tab="Chart View" key="charts">
          <PerformanceCharts dashboardData={dashboardData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Table View" key="tables">
          <PerformanceTable dashboardData={dashboardData} />
        </Tabs.TabPane>
      </Tabs>
      <Divider />
      <br />
    </>
  );
};
