import {
  Configuration,
  UsersApiFactory,
  TenantsApiFactory,
  TasksApiFactory,
  LegacyApiFactory,
  OAuthApiFactory,
  QuotesApiFactory,
  OrdersApiFactory,
  CustomersApiFactory,
} from '@recurrency/core-api';
import { AxiosError, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { globalAppStore } from 'hooks/useGlobalApp';

import { config as appConfig } from 'settings/environment';

import { captureError } from './error';
import { objMapValues } from './object';
import { track, TrackEvent } from './track';

const getApiConfig = (accessToken?: string) =>
  new Configuration({
    accessToken,
    basePath: appConfig.apiUrl,
  });

export function api() {
  const { accessToken } = globalAppStore.state;

  return {
    users() {
      return wrapErrorHandler(UsersApiFactory(getApiConfig(accessToken)));
    },
    tenants() {
      return wrapErrorHandler(TenantsApiFactory(getApiConfig(accessToken)));
    },
    tasks() {
      return wrapErrorHandler(TasksApiFactory(getApiConfig(accessToken)));
    },
    legacy() {
      return wrapErrorHandler(LegacyApiFactory(getApiConfig(accessToken)));
    },
    oAuth() {
      return wrapErrorHandler(OAuthApiFactory(getApiConfig(accessToken)));
    },
    quotes() {
      return wrapErrorHandler(QuotesApiFactory(getApiConfig(accessToken)));
    },
    orders() {
      return wrapErrorHandler(OrdersApiFactory(getApiConfig(accessToken)));
    },
    customers() {
      return wrapErrorHandler(CustomersApiFactory(getApiConfig(accessToken)));
    },
  };
}

export type ApiFunction = (...args: any[]) => Promise<AxiosResponse<any>>;

function wrapErrorHandler<ApiFactoryT extends Obj<ApiFunction>>(factory: ApiFactoryT): ApiFactoryT {
  return objMapValues(factory, (apiFn: AnyFunction) => (...args: any[]) => {
    const reqStartMs = new Date().getTime();

    return apiFn(...args)
      .then((response: AxiosResponse<any>) => {
        const resDurationMs = new Date().getTime() - reqStartMs;

        if (resDurationMs > 5000) {
          track(TrackEvent.Api_Error, {
            reqMethod: response.config.method as string,
            reqUrl: response.config.url as string,
            resStatusCode: String(response.status),
            resDurationMs: Math.round(resDurationMs),
            resErrorMessage: 'ApiTooSlow: response took longer than 5s',
          });
        }
        return response;
      })
      .catch((error: AxiosError) => {
        const resDurationMs = new Date().getTime() - reqStartMs;

        if (error.response) {
          track(TrackEvent.Api_Error, {
            reqMethod: error.config.method as string,
            reqUrl: error.config.url as string,
            resStatusCode: String(error.response.status),
            resDurationMs: Math.round(resDurationMs),
            resErrorMessage: error.message,
          });

          // user's token expired, re-login
          if (error.response.status === /* Unauthorized */ 401 && error.response.data?.message === 'Unauthorized') {
            // re-login redirect is asynchronous. return never resolving promise so UI stays in loading state
            // otherwise UI will render with undefined data, and that will throw another error.
            globalAppStore.state.loginWithRedirect();
            return new Promise(() => {});
          }

          captureError(error);
        } else if (error.message === 'Network Error') {
          // 'Network Error' corresponds to net:ERR_INTERNET_DISCONNECTED
          // wish axios returned the error code, but it doesn't
          error.message = 'NetworkError: net:ERR_INTERNET_DISCONNECTED';
          track(TrackEvent.Api_Error, {
            reqMethod: error.config.method as string,
            reqUrl: error.config.url as string,
            resStatusCode: '408' /* Request Timeout */,
            resDurationMs: Math.round(resDurationMs),
            resErrorMessage: error.message,
          });
        }

        throw error;
      });
  }) as ApiFactoryT;
}

export const getLegacyOptions = () => {
  const { activeTenant, activeRole } = globalAppStore.state;
  // switch to different endpoints based on env (staging or prod)
  return {
    headers: {
      'X-Current-Tenant': activeTenant?.id,
      'X-Current-Role-Name': activeRole?.name,
      'X-Current-Role-Id': activeRole?.foreignId,
      // url forwarder for core-api which routes to v4 pricing service
      'x-rai-api-v4-url': appConfig.pricingV4Origin,
    },
  };
};

export const legacyApiFetch = async (
  url: string,
  options: {
    data?: Obj | undefined;
    method: 'GET' | 'POST';
  },
): Promise<any> => {
  const { method, data } = options;
  const legacyApiOptions = getLegacyOptions();
  url = url.substring(1);

  if (method === 'GET') {
    if (data) {
      url = `${url}?${queryString.stringify(data)}`;
    }
    return api().legacy().getLegacyEndpoint(url, legacyApiOptions);
  }
  return api().legacy().postLegacyEndpoint(url, data, legacyApiOptions);
};
