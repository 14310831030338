import styled from "styled-components";
export const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 0;

  width: ${(props) => props.isDesktopView ? `${props.theme.secondaryNavigationWidth}px` : "100%"};
  position: fixed;
  top: ${(props) => `${props.theme.primaryNavigationHeight + (props.isDesktopView ? 0 : props.theme.tertiaryNavigationHeight)}px`};
  bottom: 0;
  z-index: 10;

  overflow: auto;
  background-color: ${(props) => props.theme.colors.newBlueGray100};
  transition: ${(props) => props.theme.transition(0.05)};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;
export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.units.XXL};
`;
export const Icon = styled.div`
  display: flex;
  width: ${(props) => props.isDesktopView ? "100%" : "auto"};
  align-self: center;
  justify-content: ${(props) => props.isDesktopView ? "center" : "flex-start"};
  font-size: 1.45em;
`;
export const MenuItem = styled.a`
  background-color: ${(props) => props.selected ? props.theme.colors.newBlueGray200 : "inherit"};
  margin: ${(props) => props.theme.units.SM}px 0;
  cursor: pointer;
  padding: ${(props) => props.theme.units.MD * 1.5}px;
  overflow-x: hidden;
  opacity: ${(props) => props.selected ? 1 : 0.6};
  color: ${(props) => props.theme.colors.newBlueGray900};

  &:hover {
    opacity: 1;
    div {
      color: ${(props) => props.theme.colors.newBlueGray900};
    }
  }
`;
export const MenuItemChild = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const HamburgerTitle = styled.div`
  width: 100%;
  margin-left ${(props) => props.theme.units.LG}px;
`;
