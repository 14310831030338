/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';

import { PromiseState, StatefulPromise } from 'utils/statefulPromise';
import { StateStore } from 'utils/stateStore';

import { usePreviousVal } from './usePreviousVal';

/// interfaces ///
export interface UsePromiseState<DataT, ErrorT> {
  // true on initial load
  isLoading: boolean;
  // true on subsequent loads
  isReloading: boolean;
  data: DataT | undefined;
  error: ErrorT | undefined;
}

export interface UsePromiseResponse<DataT, ErrorT> extends UsePromiseState<DataT, ErrorT> {
  /** set new data, usually called after a resource is changed from PUT/PATCH call that returns latest data */
  setData: (data: DataT) => void;
  /** reload to get latest version of data */
  reload: () => void;
}

export interface UsePromiseOptions<ErrorT> {
  /**
   * usePromise will return cached data for initial state if previously loaded,
   * and whenever network responds with latest data, it will re-update.
   * */
  cacheKey?: string;
  onError?: (error: ErrorT) => void;
}

/// cache helpers ///

export class AsyncResource<DataT, ErrorT = Error> {
  public jsonDeps: string | undefined;
  public statefulPromise: StatefulPromise<DataT, ErrorT> | undefined = undefined;
  public store: StateStore<UsePromiseState<DataT, ErrorT>> = new StateStore<UsePromiseState<DataT, ErrorT>>({
    isLoading: true,
    isReloading: false,
    data: undefined,
    error: undefined,
  });

  reload(promiseFn: () => Promise<DataT>, jsonDeps: string) {
    if (this.statefulPromise) {
      if (this.statefulPromise.state === PromiseState.Pending && this.jsonDeps === jsonDeps) {
        // if jsonDeps haven't changed, then we're already loading the resource
        // if jsonDeps have changed, then we should make new call on the wire and disregard old results
        return;
      }
      this.store.update({ isReloading: true });
      // remove callbacks for old promise
      this.statefulPromise.onValue = undefined;
      this.statefulPromise.onError = undefined;
    }

    // load new promise
    this.jsonDeps = jsonDeps;
    this.statefulPromise = new StatefulPromise(promiseFn());
    this.statefulPromise.onValue = (data: DataT) => {
      this.store.setState({ isLoading: false, isReloading: false, data, error: undefined });
    };
    this.statefulPromise.onError = (error: ErrorT) => {
      this.store.setState({ isLoading: false, isReloading: false, data: undefined, error });
    };
  }

  setData(data: DataT): void {
    if (this.statefulPromise) {
      this.statefulPromise.onValue = undefined;
      this.statefulPromise.onError = undefined;
    }
    this.store.setState({ isLoading: false, isReloading: false, data, error: undefined });
  }
}

// intentionally not exported, since we don't want to expose internals
const asyncResourceMap = new Map<string, AsyncResource<Any, Any>>();

/** clear entire cache */
export function clearCache() {
  asyncResourceMap.clear();
}

/// usePromise ///

/**
 * Returns state of promise that can bound to UI.
 * @example const {data, error, isLoading} = usePromise(() => fetch(url), [url])
 */
export function usePromise<DataT, ErrorT = Error>(
  promiseFn: () => Promise<DataT>,
  deps: Any[],
  options?: UsePromiseOptions<ErrorT>,
): UsePromiseResponse<DataT, ErrorT> {
  // jsonify deps, so we get deep diff, otherwise we'll get an infinite loop
  const jsonDeps = JSON.stringify(deps);
  const cacheKey = options?.cacheKey;
  const prevCacheKey = usePreviousVal(cacheKey);
  const resourceRef = useRef<AsyncResource<DataT, ErrorT>>();

  // if cacheKey changes, link to new resource
  if (cacheKey && cacheKey !== prevCacheKey) {
    resourceRef.current = undefined;
  }

  if (!resourceRef.current) {
    if (cacheKey) {
      if (asyncResourceMap.has(cacheKey)) {
        resourceRef.current = asyncResourceMap.get(cacheKey);
      } else {
        resourceRef.current = new AsyncResource<DataT, ErrorT>();
        asyncResourceMap.set(cacheKey, resourceRef.current);
      }
    } else {
      resourceRef.current = new AsyncResource<DataT, ErrorT>();
    }
  }

  const asyncResource = resourceRef.current!;
  const [promiseState, setPromiseState] = useState<UsePromiseState<DataT, ErrorT>>(asyncResource.store.state);

  // listen to state changes in latest asyncResource
  useEffect(() => {
    setPromiseState(asyncResource.store.state);
    return asyncResource.store.observe(setPromiseState);
  }, [asyncResource]);

  // reload if deps or asyncResource change
  useEffect(() => {
    asyncResource.reload(promiseFn, jsonDeps);
  }, [asyncResource, jsonDeps]);

  const usePromiseResponse = useMemo(
    () => ({
      ...promiseState,
      reload: () => asyncResource.reload(promiseFn, jsonDeps),
      setData: (data: DataT) => asyncResource.setData(data),
    }),
    [promiseState, asyncResource, jsonDeps],
  );

  return usePromiseResponse;
}
