import { Table as AntdTable } from "antd";
import styled from "styled-components";
import { media } from "utils/responsive";
export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  ${media.sm`
    width: auto;
  `}
`;
export const Table = styled(AntdTable)`
  .ant-table.ant-table-small {
    font-size: 10px;
    ${media.sm`
      font-size: 14px;
    `}

    .ant-table-thead .ant-table-column-sorters,
    .ant-table-cell {
      padding: 2px 2px;
      ${media.sm`
        padding: 8px 8px;
      `}

      &:first-child {
        padding-left: 8px;
      }
      &:last-child {
        padding-right: 8px;
      }
    }
  }

  .ant-table-expanded-row {
    background: white;
    & > .ant-table-cell {
      background: white;
    }
  }

  .ant-pagination {
    justify-content: center;
    .ant-pagination-item,
    .ant-select-selector,
    .ant-pagination-item-link {
      border-radius: ${(props) => props.theme.border.radius};
    }
  }
`;
