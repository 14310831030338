import React from 'react';

import { Layout } from 'antd';

import * as Styled from './Container.style';

export const Container = ({ children, top, ...otherProps }: any) => (
  <Styled.Layout {...otherProps}>
    {top && <Layout.Content>{top}</Layout.Content>}
    <Layout.Content>{children}</Layout.Content>
  </Styled.Layout>
);
