import { Avatar as AntAvatar } from "antd";
import { Squash } from "hamburger-react";
import styled from "styled-components";
import { RecurrencyWordmark, UserProfile } from "components/Icons";
export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 80px;

  padding: 0px 14px 0 10px;
  background: #fff;
`;
export const Logo = styled(RecurrencyWordmark)`
  height: 32px;
  fill: ${(props) => props.theme.colors.blue};
`;
export const Hamburger = styled(Squash).attrs((props) => ({
  color: props.theme.colors.darkBlue,
  size: 26,
  duration: 0.2
}))``;
export const Avatar = styled(AntAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.colors.lightBlue};
  cursor: pointer;
`;
export const UserProfileIcon = styled(UserProfile)`
  width: ${(props) => props.theme.units.LG}px;
  height: ${(props) => props.theme.units.LG}px;
  fill: ${(props) => props.theme.colors.darkViolet};
`;
