import React from 'react';

import { Form, Spin } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

export interface Props extends FormItemProps {
  rows?: number;
}

export const SpinnerFormItem = ({ label, name, rules }: Props): JSX.Element => (
  <Form.Item label={label} name={name} rules={rules}>
    <Spin />
  </Form.Item>
);
