import React from 'react';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { encodeLegacyApiParam } from 'utils/routes';
import { salesTableColumns } from 'utils/tables';

export const RecentSales = ({ invMastUid }: { invMastUid: string | undefined }) => (
  <AsyncTable
    tableProps={useLegacyApiTableProps({
      apiPath: `/v3/items/${encodeLegacyApiParam(invMastUid || '')}/sales`,
    })}
    columns={salesTableColumns}
  />
);
