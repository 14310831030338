import React from 'react';

import { Grid } from 'antd';

import * as Styled from './Stats.style';

export type Props = {
  label: string;
  value: string;
  copyable?: boolean;
};

export const Stats = (props: Props): JSX.Element => {
  const { value = '-', label = '-', copyable, ...otherProps } = props;
  const isDesktopView = Grid.useBreakpoint()?.sm ?? false;
  const valueSize = isDesktopView ? 'h3' : 'large';

  return (
    <Styled.Wrapper {...otherProps}>
      <Styled.Value type={valueSize} responsive={false} copyable={copyable}>
        {value}
      </Styled.Value>
      <Styled.Label>{label}</Styled.Label>
    </Styled.Wrapper>
  );
};
